import { useEffect, useState } from "react";
import DataTables from "../../../../Components/Utils/DataTable";
import axios from "../../../../Services/axiosCommon";
import ViewMore from "./viewMore";
import SearchBar from "../../../../Components/Utils/SearchBar";
import { Link } from "react-router-dom";
import { CreateButton } from "../../../../Components/Utils/Buttons";

const AllUsers = () => {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [roleList, setRoleList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSrearch, setIsSreach] = useState(false);
  const column = [
    {
      name: "Name",
      selector: (row) => (
        <div className=" flex justify-between items-center">
          <div className=" w-8 h-8 bg-[#2A475E] flex justify-center items-center text-lg font-medium text-white rounded-full overflow-hidden">
            {row?.imageUrl && row?.imageUrl != "" ? (
              <img width="160%" src={row?.imageUrl} alt="#" />
            ) : (
              `${row?.firstName && row?.firstName.charAt(0)}`
            )}
          </div>
          <span className="capitalize ml-3">
            {row.firstName} {row.lastName}
          </span>
        </div>
      ),
      sortable: true,
      width:"45%"
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width:"25%"
    },
    {
      name: "Role",
      selector: (row) => (
        <p className="capitalize">{row?.role?.role_name || "-"}</p>
      ),
      sortable: true,
      reorder: true,
      width:"15%"
    },
    {
      name: "Status",
      selector: (row) =>
        row.isActive == true ? (
          <span className=" px-2 bg-[#E7F9E7] rounded-xl flex justify-center items-center font-medium text-[#39D42B]">
            Active
          </span>
        ) : (
          <span className=" px-2 bg-[#FFEFF2] rounded-xl flex justify-center items-center font-medium text-[#FF4466]">
            Inactive
          </span>
        ),
        width:"10%"
    },
    {
      cell: (row) => (
        <ViewMore
          userID={row?.userId}
          data={row}
          userAccessId={row?.userRoleId}
          role={row?.role?.role_id}
          getMember={getMember}
          allRoleList={roleList}
        />
      ),
      allowOverflow: true,
      button: true,
      width: "25px",
    },
  ];

  async function getMember(page) {
    await axios
      .get(`/User/?page=${page}&per_page=${perPage}`)
      .then(async (res) => {
        (await res?.data?.data?.userDetails) &&
          setData(res?.data?.data?.userDetails);
        setTotalRows(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePageChange = (page) => {
    getMember(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (isSrearch) {
      if (searchQuery != "") {
        const response = await axios.get(
          `/Search/member/${searchQuery}?page=${page}&per_page=${newPerPage}`
        );

        setData(response?.data?.data);
        setPerPage(newPerPage);
      } else {
        const response = await axios.get(
          `/User/?page=${page}&per_page=${newPerPage}`
        );

        setData(response?.data?.data?.userDetails);
        setPerPage(newPerPage);
      }
    } else {
      const response = await axios.get(
        `/User/?page=${page}&per_page=${newPerPage}`
      );

      setData(response?.data?.data?.userDetails);
      setPerPage(newPerPage);
    }
  };

  async function getRoleList() {
    await axios
      .get(`/Role/`)
      .then((res) => {
        let role = res.data?.data;
        let list = [];
        res.data?.data &&
          role.map((item) => {
            let i = {
              value: item.roleId,
              label: item.roleName,
            };
            list.push(i);
          });
        setRoleList(list);
      })
      .catch((err) => console.log(err));
  }

  function SearchData(e) {
    e.preventDefault();
    if (searchQuery != "") {
      setIsSreach(true);
      axios
        .get(`/Search/member/${searchQuery}?page=${1}&per_page=${perPage}`)
        .then((res) => {
          setTotalRows(res.data?.total_records);
          res.data?.data && setData(res.data?.data);
        })
        .catch((err) => console.log(err));
    } else {
      setIsSreach(false);
      getMember(1);
    }
  }

  useEffect(() => {
    getRoleList();
    getMember(1);
  }, []);
  return (
    <>
      <div className=" w-full h-[100%] p-2">
        <div className=" w-[98%] mx-auto h-[100%] rounded-md overflow-hidden bg-white">
          <div className=" w-full flex justify-between items-center px-2 mb-2">
            <h3 className=" text-sm text-mainColor-400 font-semibold">All Users</h3>
            <div className='flex gap-2'>
            <form className="flex items-center mx-0" onSubmit={SearchData}>
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
            </form>
            <Link to={`/settings/all-user/create`}><CreateButton label="Create"/></Link>
            </div>
          </div>
          <DataTables
            column={column}
            datas={data}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            scrollHight="69vh"
            totalRows={totalRows}
          />
        </div>
      </div>
    </>
  );
};

export default AllUsers;
