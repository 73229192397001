import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);



export function TaskStatusDough(props) {
  const {dataWithLabel,handleSegmentClick=()=>{},finalItem}=props
  let colorArr = [
    {
      color:"#E8A700",
      label:"Open"
    },
    {
      color:"#F35B04",
      label:"On hold"
    },
    {
      color:"#007BEC",
      label:"In progress"
    },
    {
      color:"#F10101",
      label:"Cancelled"
    },    
    {
      color:"#00CC5E",
      label:"Completed"
    }
  ] 

  let temArr = []
  let onlyLabel = dataWithLabel?.label;

  onlyLabel && onlyLabel.forEach(item=>{
    let inside = colorArr.find(i=> i.label.toLowerCase() ==item.toLowerCase())
    if(inside){
      temArr.push(inside.color)
    }
  })

  console.log("WithLabel",finalItem);
  const data = {
    labels:[],
      datasets: [
        {
          // label: [' '],
          data:dataWithLabel?.data||[],
          backgroundColor: temArr,
          borderColor: temArr,
        borderWidth: 1,
        cutout:'70%'
      },
    ],
  };


  
  return(
      
    <Doughnut data={data}
        options={{
          onClick: handleSegmentClick,
          plugins:{
            legend:false,
              title:{
                display:false
              },
              tooltip:{
                backgroundColor:'transparent',
              
               bodyFont:{
                 size:0
               }
              }
            }
        }}
      />
    
  )
  
  
}



export function TaskPriorityDough(props) {
    const {dataWithLabel,handleSegmentClick=()=>{}}=props
    let colorArr = [
      {
        color:"#E8A700",
        label:"Low"
      },
      {
        color:"#F35B04",
        label:"Medium"
      },
      {
        color:"#F10101",
        label:"High"
      },    
    ] 
  
    let temArr = []
    let onlyLabel = dataWithLabel?.label;
  
    onlyLabel && onlyLabel.forEach(item=>{
      let inside = colorArr.find(i=> i.label.toLowerCase() ==item.toLowerCase())
      if(inside){
        temArr.push(inside.color)
      }
    })
  
    console.log("dataWithLabel",onlyLabel);
    const data = {
      labels:[],
        datasets: [
          {
            // label: [' '],
            data:dataWithLabel?.data||[],
            backgroundColor: temArr,
            borderColor: temArr,
          borderWidth: 1,
          cutout:'70%'
        },
      ],
    };
  
  
    
    return(
        
      <Doughnut data={data}
          options={{
            onClick: handleSegmentClick,
            plugins:{
              legend:false,
                title:{
                  display:false
                },
                tooltip:{
                  backgroundColor:'transparent',
                
                 bodyFont:{
                   size:0
                 }
                }
              }
          }}
        />
      
    )
    
    
  }