import React from 'react'

function ViewEquipmentPro({ item, data, open, onCancel }) {
    const displayItem = item || data;
    return (
      <div className=" w-full h-auto grid grid-cols-1  md:grid-cols-3">
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Item Name</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.description || "-"}</p>
        </div>
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Unit</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.unit || "-"}</p>
        </div>
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Type</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.type || "-"}</p>
        </div>
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Planned Days</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.Planned_days || "-"}</p>
        </div>
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Actual Days</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.actual_days || "-"}</p>
        </div>
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Budget Quantity</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.budget_quantity || "-"}</p>
        </div>
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Budget Price</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.budget_Price || "-"}</p>
        </div>
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Actual Quantity</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.actual_quantity || "-"}</p>
        </div>
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Actual Price</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.actual_Price || "-"}</p>
        </div>
        <div className='my-2'>
          <p className='text-[13px] font-medium text-mainColor-400 py-1'>Amount</p>
          <p className='text-xs text-mainColor-400'>{displayItem?.amount || "-"}</p>
        </div>
      </div>
    )
  }

export default ViewEquipmentPro;