import { Button, Modal, notification } from "antd";
import {  useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
// import axiosInstance from "../../../Services/axiosCommon";
import { NuDatePicker, NuTimePicker } from "../../../../../../Components/Utils/NuInput";
import axiosInstance from "../../../../../../Services/axiosCommon";
import { useNumber } from '../../../../../../Context/ProjectIdContext';


const SetReminderTask = ({ open, getData, onCancel, data,firstProject }) => {
  const { number } = useNumber();
    
    console.log("data",data);
  const [api, contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    projectId: "",
    titleId:"",
    taskName: '',
    assignedTo: [],
    copyTo: [],
    priority: "",
    severity: "",
    taskStatus: "",
    // comment: "",
    startDate:"",
    endDate: "",
    reminderDate: "",
    reminderTime: "",
    attachment: "",
    reAssignedTo:[],
    taskTeam: "",
  })

  useState(()=>{
    data &&
      setCreateNew({
        taskName: data?.taskName,
        taskTeam: data?.teamId,
        assignedTo: data?.assignedTo?.map((i, k) => {
          return i?.userId;
        }),
        copyTo: data?.copyTo?.map((i, k) => {
          return (
            // i?.firstName
            i?.userId
          );
        }),
        priority: data?.priority,
        severity: data?.severity,
        taskStatus: data?.taskStatus,
        revisedEndDate: data?.revisedEndDate,
        reasonForDateRevised: data?.reasonForDateRevised,
        reAssignedTo: [],
        comment: data?.comment,
        startDate: data?.startDate,
        endDate: data?.endDate,
        reminderDate: data?.reminderDate,
        reminderTime: data?.reminderTime,
        // attachment: data?.attachment,
        projectId: data?.projectId,
        titleId: data?.titleId,
      });
  },[])



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      reminderDate: yup
       .string()
       .required("reminderDate is Required"),
      reminderTime: yup
       .string()
       .required("reminderTime is Required"),
    }),
    onSubmit: async (value,{resetForm}) => {
    //   setIsLoading(true);
    //   value.projectId = projectId;
    //   value.titleId = titleId;
      let formData = new FormData();
      // value.taskTeam=[value.taskTeam]

      value.projectId = firstProject
      
    //   let asTo = []

       
    //   asTo.push(value.reAssignedTo)

    //    if(data){
    //     value.reAssignedTo = asTo;
    //    }else{
    //     delete value.reAssignedTo;
    //    }

      formData.append('taskData', JSON.stringify(value));
    //   formData.append('attachment', );
      
        // console.log("finding",formData);
        
        axiosInstance
            .put(`/Task/${data.taskId}`, formData)
            .then((res) => {
              if(res.status === 201||res.status === 200) {
              console.log(res);
              getData(number);
              onCancel();
              api.success({
                message: `Reminder time  Updated successfully`,
                // description:'',
                placement: 'bottomRight',
              });
            //   navigate(-1);
            } else{
            //   setIsLoading(false);
              api.error({
                  message: `Reminder time Update failed`,
                  description:res.data.msg|'Network error',
                  placement: 'bottomRight',
                });
          } 
            })
            .catch((err) => {
            //   setIsLoading(false);
              console.log(err);
            })
    },
  });
  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title={ "Set Reminder"}
        width={"25%"}
        className="my-modal"
        centered
        onCancel={onCancel}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuDatePicker isRequired={true} label='Date' formik={formik} name='reminderDate' width='w-full '/>
            <NuTimePicker isRequired={true} label='Time' formik={formik} name='reminderTime' width='w-full '/>
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-sm text-white  mx-auto"
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default SetReminderTask;
