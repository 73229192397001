import React, { useEffect, useState } from 'react'
import axios from '../../../../../../Services/axiosCommon';
import { Link, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../../../Components/Utils/Breadcrumbs';
import Dasboard from '../../../../../../Components/Icons/Dasboard';
import SearchBar from '../../../../../../Components/Utils/SearchBar';
import { CreateButton } from '../../../../../../Components/Utils/Buttons';
import ExpandableTitle from '../Components/ExpandableTitle';
import CreateTitle from './createTittle';
import { NuDatePicker, NuSelect } from '../../../../../../Components/Utils/NuInput';
import { Button, Modal, Select,notification } from 'antd';
import { useNumber } from '../../../../../../Context/ProjectIdContext';
import MobileTaskPlan from '../../../../../Mobile/TaskPlan/MobileTaskPlan';
import ImportAllTask from './import';
import { useFormik } from 'formik';

import { ExclamationCircleFilled } from "@ant-design/icons";
import useFetchMataData from '../../../../../../Components/Hooks/useFetchMataData';
import TaskLayout from '../TaskLayout';
import PlanLayout from '../PlanLayout';
import NuDate from '../../../../../../Components/Utils/NuDate';
import { GetAccess } from '../../../../../../Components/Utils/roles';

const {confirm} = Modal


function TaskPlan() {
  const { officeId,projectId } = useParams();
  // const { projectId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const {value:Priority} = useFetchMataData(`/Metadata/taskpriority`)
  const {value:Status} = useFetchMataData(`/Metadata/taskstatus`)
  // const { number, updateNumber } = useNumber();
  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  const [open, setOpen] = useState(false);
  const [openImport,setOpenImport] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([])
  const [firstProject, setFirstProject] = useState("")
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilter,setIsFilter]= useState(false);
  const [filterData,setFilterData]= useState({
    filterStatus:"",
    filterPriority:"",
    startDate:"",
    endDate:"",
    status: "",
    priority: "",
    type:"all",
  });
  const [afterFilter,setAfterFilter]= useState({})


  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });


  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // async function getProject() {
  //   await axios
  //     .get(`/Team/getUserProjectId`)
  //     .then(async (res) => {
  //       if(number!=0){
  //         console.log("Dssss",number);
  //         setFirstProject(number)
  //       }else if (projectTask) {
  //         console.log("DD",projectTask);
  //         setFirstProject(projectTask);
  //         updateNumber(projectTask)
  //       } else {
  //         res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId)
  //         updateNumber(res?.data?.data[0]?.ProjectId)
  //         console.log("dd",res?.data?.data[0]?.ProjectId);

  //       }
  //       let list = res?.data?.data || [];
  //       let finalData = [];
  //       await res?.data?.data && list.map((item, idx) => {
  //         finalData.push({
  //           value: item.ProjectId,
  //           label: item.ProjectName,
  //         });

  //       })
  //       setProjectList(finalData);

  //       // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
  //       // console.log('====',res?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }


  useEffect(() => {
    // getProject()
    // if(number){
      getTitle(projectId)
      getProjectDetails(projectId)
    // }
  }, [])


  // useEffect(()=>{

  //   if(firstProject){
  //     getProjectDetails(firstProject)
  //   }else if(number){
  //     getProjectDetails(number)
  //   }

  // },[firstProject])

  const [Projectstartdate,setProjectstartdate] =useState("")
  const [Projectenddate,setProjectenddate] =useState("")


  function getProjectDetails(value){
    axios.get(`/Project/${value}`).then(res=>{
      console.log(res.data.data);
      // let startDate = res.data.data?.startDate && NuDate({value:res.data.data?.startDate,format:"DD-MMM-YYYY"})
      // let endDate = res.data.data?.endDate && NuDate({value:res.data.data?.endDate,format:"DD-MMM-YYYY"})
      setProjectstartdate(res.data.data?.startDate);
      setProjectenddate(res.data.data?.endDate);
      
    }).catch(err=>{
      console.log("error",err);
      
    })
  }
// console.log(ProjectData.endDate,"dtt");


  function SearchData(e) {
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(`/mytask/byUser/all_tasks/${firstProject}`,{params:{search:`${searchQuery}`}})
        .then((res) => {
          //   res.data?.data && setData(res.data?.data);
          console.log("search", res.data?.data);
          setData(res.data?.data)
        })
        .catch((err) => console.log(err));
    } else {
      // getTasks();
    }
  }

  // function handleProjectChange(e) {
  //   setFirstProject(e)
  //   updateNumber(e)
  //   setSelectedTask([])
  // }

  const [listOfsubTask,setlistOfsubTask] = useState([])
  const [listOfTask,setlistOfTask] = useState([])
  const [listOfTitle,setlistOfTitle] = useState([])

  function getTitle(x) {
    axios.get(`/mytask/byUser/all_tasks/${x}`).then(res => {
      console.log("res", res.data?.data);
      res.data?.data && setData(res.data?.data)
      let maped = res.data?.data||[];
      let tempTitle = []
      let taskTemp = []
      let subTaskTemp = []
      maped &&  maped.forEach((item) => {
        console.log("title",item?.titleId);
        tempTitle.push(item.titleId)
        let taskList = item?.taskDetails||[]
        taskList && taskList.forEach(task=>{
          console.log("task",task?.taskId);
          taskTemp.push(task?.taskId)
          let subTaskList = task?.subTaskDetails||[]
          subTaskList && subTaskList.forEach(sutask=>{
          console.log("subtask",sutask?.subTaskId);
            subTaskTemp.push(sutask?.subTaskId)
          })
          
        })
      })
      setlistOfTitle(tempTitle)
      setlistOfsubTask(subTaskTemp)
      setlistOfTask(taskTemp)
    })
      .catch(error => { console.log(error); })
  }

  useEffect(() => {
    if (searchQuery == "") {
      getTitle(projectId);
    }
  }, [searchQuery]);

  // useEffect(() => {
  //   if(number !=0){
  //   getTitle(number)
  //   }else{
  //     getProject(firstProject)
  //   }
  // }, [firstProject])


  const userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let MyTaskCreate = userAccess
    ? userAccess.some(
      (permission) => permission.permissionName == "MyTask_create"
    )
    : false;




    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterData,
      validateOnChange: false,
      validateOnBlur: false,
      // validationSchema: yup.object({
      //   mobileNo: yup
      //     .string()
      //     // .required("Mobile Number is required")
      //     .matches(
      //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //       "Enter Valid Mobile Number"
      //     )
      //     .min(10, "Invalid Mobile Number")
      //     .max(10, "Invalid Mobile Number"),
      //   // email: yup.string().required("Email is required"),
      // }),
      onSubmit: async (value, { resetForm }) => {
        setAfterFilter(value)
        setIsFilter(true)
        axios
        .get(`/title/filter_by_status/${projectId}?status=${value.filterStatus}&priority=${value.filterPriority}&start_date=${value.startDate}&end_date=${value.endDate}`)
        .then((res) => {
          setFilterOpen(false)
          setData(res.data?.data);
          
  
        })
        .catch((error) => {
          console.log(error);
        });
      },
    });


  useEffect(() => {
    // console.log("jj",projectNum);
    if (projectTask) {
      setFirstProject(projectTask)
    }
  }, [projectTask])


  function ClearFilter(){
    formik.resetForm({})
    getTitle(projectId);
    setIsFilter(false)
  }


  const [selectedTask,setSelectedTask] = useState([])
  const [selectedSubTask,setSelectedSubTask] = useState([])
  const [selectedTitle,setSelectedTitle] = useState([])
  const [showChangeProgress,setShowChangeProgress] =useState(false)


  const selectTask = ( id) => {
    let testUser;
    if (selectedTask && selectedTask.some((Userid) => Userid === id)) {
      testUser = selectedTask.filter((roleId) => roleId !== id);
      setSelectedTask(testUser);
    } else {
        setSelectedTask([...selectedTask, id]);
    }
    console.log('id',selectedTask);

  };

  const selectSubTask = ( id) => {
    let testUser;
    if (selectedSubTask && selectedSubTask.some((Userid) => Userid === id)) {
      testUser = selectedSubTask.filter((roleId) => roleId !== id);
      setSelectedSubTask(testUser);
    } else {
      setSelectedSubTask([...selectedSubTask, id]);
    }
    console.log('id',selectedTask);

  };

  const selectTitle = ( id) => {
    let testUser;
    if (selectedTitle && selectedTitle.some((Userid) => Userid === id)) {
      testUser = selectedTitle.filter((roleId) => roleId !== id);
      setSelectedTitle(testUser);
    } else {
      setSelectedTitle([...selectedTitle, id]);
    }
    console.log('id',selectedTitle);

  };



  const handleMulipleTaskDelete = async () => {
    let value = {
      projectId:projectId,
      titleIds:"",
      taskIds:selectedTask,
      subTaskIds:""
    }
    confirm({
      title: `Do you Want to delete selected Task?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `All selected will be removed from the Project`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteFuntion(value)
      },
      onCancel() {},
    });
  };



  const handleMulipleSubTaskDelete = async () => {
    let value = {
      projectId:projectId,
      titleIds:"",
      taskIds:"",
      subTaskIds:selectedSubTask
    }
    confirm({
      title: `Do you Want to delete selected SubTask?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `All selected will be removed from the Project`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteFuntion(value)
      },
      onCancel() {},
    });
  };


  const handleMulipleTitleDelete = async () => {
    let value = {
      projectId:projectId,
      titleIds:selectedTitle,
      taskIds:"",
      subTaskIds:""
    }
    confirm({
      title: `Do you Want to delete selected Title?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `All selected Tille will be removed from the Project`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteFuntion(value)
      },
      onCancel() {},
    });
  };


  function deleteFuntion(value){
    axios.post(`/title/multi_delete`,value).then(res=>{
      if(res.data?.status == true){
        getTitle(projectId)
        setSelectedTitle([])
        setSelectedSubTask([])
        setSelectedTask([])
        api.success({
          message: `All Selected  Deleted successfully`,
          // description:'',
          placement: "bottomRight",
        })
      }else{
        api.error({
          message: `Deleted failed`,
          description: res?.data?.msg || "Network error",
          placement: "bottomRight",
        });
      }
    }).catch(error=>{
      api.error({
        message: `Deleted failed`,
        description: error?.response?.data?.msg || "Sever error (or) Network error",
        placement: "bottomRight",
      });
    })
  }

  function multipleStatusSubmit(e){
    e.preventDefault();
    let status = ""
    let priority = ""

    if(formik.values.type =="all"){
      status = formik.values.status
      priority = formik.values.priority
    }else if(formik.values.type=="status"){
      status = formik.values.status
    }else if(formik.values.type=="priority"){
      priority = formik.values.priority
    }


    let subTask = [];
    let Task = [];
    selectedSubTask.length > 0 && selectedSubTask.forEach(id=>{
      let tempSubTask = {
        subTaskId: id,
        status: status,
        priority:priority
      }
      subTask.push(tempSubTask);
    })
    selectedTask.length >0 && selectedTask.forEach(id=>{
      let tempTask = {
        taskId: id,
        status: status,
        priority:priority
      }
      Task.push(tempTask);
    })

    let finalValue = {
      projectId:projectId,
      taskStatus:Task,
      subTaskStatus:subTask,
    }

    axios.post("/title/multi_update", finalValue).then(response=>{
      if(response.data?.status==true){
        getTitle(projectId)
        setSelectedTitle([])
        setSelectedSubTask([])
        setSelectedTask([])
        api.success({
          message: `Staus successfully`,
          // description:'',
          placement: "bottomRight",
        })
        setShowChangeProgress(false)
      }else{
        api.error({
          message: `Staus Update failed`,
          description: response?.data?.msg || "Network error",
          placement: "bottomRight",
        });
      }
    }).catch(error=>{
      api.error({
        message: `Staus Update failed`,
        description: error?.response?.data?.msg || "Sever error (or) Network error",
        placement: "bottomRight",
      });
    })
  }



  
  function selectAll(event){
    if(event.target.checked){
      setSelectedSubTask(listOfsubTask)
      setSelectedTask(listOfTask)
      setSelectedTitle(listOfTitle)
      console.log("list of task",listOfTask);
      console.log("list of subtask",listOfsubTask);
      console.log("list of title",listOfTitle);
    }else{
      setSelectedSubTask([])
      setSelectedTask([])
      setSelectedTitle([])
    }

  }

  const header = [
    {
      headone: <>
       <input type='checkbox' onChange={selectAll} />
      </>,
        width: "w-[3%]",
      },
    {
      headone: "ID",
      width: "w-[4%]",
      isLeft:true,
    },
    {
      headone: "Activity",
      width: "w-[26%]",
      isLeft:true,
    },
    {
      headone: "Assigned to",
      width: "w-[15%]",
      isLeft:true,
    },
  
  
    {
      headone: "Start Date",
      width: "w-[8%]",
      isLeft:true,
    },
    {
      headone: "Due Date",
      width: "w-[8%]",
      isLeft:true,

    },
    {
      headone: "Duration",
      width: "w-[8%]",
      isLeft:true,
    },
    {
      headone: "Priority",
      width: "w-[8%]",
      isLeft:true,
    },
    {
      headone: "Status",
      width: "w-[8%]",
      isLeft:true,
    },
    {
        headone: "Progress",
        width: "w-[7%]",
      },
    {
        headone: "Action",
        width: "w-[5%]",  
        isLeft:true,
    }
  ];

  return (
    <>
    {contextHolder}


    <PlanLayout
                setOpen={setOpen}
                setSearchQuery={setSearchQuery}
                SearchData={SearchData}
                isFilter={isFilter}
                setFilterOpen={setFilterOpen}
                ClearFilter={ClearFilter}
                setOpenImport={setOpenImport}
                CreateAccess={GetAccess()}
                ImportAccess={GetAccess()}
                projectId={projectId}
                officeId={officeId}
    >
      <div className=' w-full my-1 flex items-center gap-3'>
        <div className='w-full flex'>
        {/* <div className=' w-full md:w-4/12 flex gap-2 items-center'>
          <div className="hidden md:block font-medium text-xs">Project </div>
          <Select
            style={{ width: '60%' }}
            options={projectList}
            value={projectList.find((i) => i.value == firstProject) || ""}
            disabled={projectList.length >= 2 ? false : true}
            // allowClear={formik.values[name]?true:false}
            // name={name}
            // placeholder={placeholder}
            onChange={handleProjectChange}
          />
        </div> */}
        <div className='w-full md:w-4/12 flex gap-2 items-center'>
        <p className="hidden md:block font-medium text-xs">Project Date : {Projectstartdate && NuDate({value:Projectstartdate,format:"DD-MMM-YYYY"})} to {Projectenddate && NuDate({value:Projectenddate,format:"DD-MMM-YYYY"})}</p>


        </div>
        </div>
        <div className=' flex gap-3 items-center'>
          {
            selectedTitle.length > 0 && <CreateButton label='Delete Title' svg={false} handleClick={handleMulipleTitleDelete}/>
          }
          <div className=' flex items-center gap-3'>
            {
              selectedTask.length > 0 && <CreateButton label='Delete Task' svg={false} handleClick={handleMulipleTaskDelete}/>
            }
            {/* {
              selectedSubTask.length > 0 && <CreateButton label='Delete SubTasks' svg={false} handleClick={handleMulipleSubTaskDelete}/>
            } */}
          {
            (selectedTask.length > 0 || selectedSubTask.length > 0)&& <CreateButton label='Change Status' svg={false} handleClick={()=>setShowChangeProgress(true)}/>
                
          }
          </div>
          {/* <button className={` px-3 py-2 cursor-pointer bg-mainColor-500 outline-none border-none text-white rounded-md flex items-center`}>Task Plan</button> */}
          {/* <NavLink to="/tasks/all-task" state={{ projectNum: firstProject }}><button className="px-3 h-8 cursor-pointer text-sm text-mainColor-500 bg-white rounded-md flex items-center" style={{ border: "1px solid #3D348B" }}>All Task</button></NavLink> */}
        </div>


        <CreateTitle
          open={open} onCancel={() => { setOpen(false) }} projectList={projectList || []} getData={getTitle}
          firstProject={firstProject}
        />
      </div>
      {screenSize.width<="786"?<MobileTaskPlan Data={data}  getData={getTitle} projectList={projectList} projectId={firstProject}   />:
          
          
          <div className=" w-full h-[calc(100vh-200px)] pb-9 overflow-y-auto">
            <table className="table-auto sticky top-0 z-[99] w-full" style={{border:'none',borderCollapse: 'collapse'}}>
            <thead className=" w-full bg-[#2A475E]">
                <tr className="text-xs  font-medium  py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-2 ${header.width} font-normal ${header?.isLeft?"text-left":"text-center"} text-xs text-white`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead> 
            </table>
            <table className="table-auto w-full" style={{border:'none',borderCollapse: 'collapse'}}>
          <tbody className=''>
            {
                data.map((item, i) => (
                    <ExpandableTitle rowData={item} key={i} getData={getTitle} projectList={projectList} projectId={projectId} titleList={selectedTitle} titleSelect={selectTitle} taskList={selectedTask} taskSelect={selectTask} subTaskList={selectedSubTask} subTaskSelect={selectSubTask} StartDate={Projectstartdate} EndDate={Projectenddate} />
                ))
            }
          </tbody>
            </table>
      </div>
      }
      <div className=" h-6 my-2">
        {/* <Pagination
          total={totalTasks}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        /> */}
      </div>
    </PlanLayout>

    <Modal
        open={filterOpen}
        title={"Filter My Tasks"}
        width={"30%"}
        className="my-modal"
        centered
        onCancel={()=>setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">

            <NuDatePicker
              label="Start Date "
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="startDate"
              width=" w-1/2"
            />
            <NuDatePicker
              label="End Date"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="endDate"
              width=" w-1/2"
            />
            <NuSelect
              label="Status"
              options={Status||[]}
              formik={formik}
              placeholder="Choose"
              name="filterStatus"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
              <NuSelect
              label="Priority"
              options={Priority||[]}
              formik={formik}
              placeholder="Choose"
              name="filterPriority"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="filterStatus"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> */}
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter 
            </Button>
          </div>
        </form>
      </Modal>


      <Modal
        open={showChangeProgress}
        title={"Update Task / Sub-Task"}
        width={"30%"}
        className="my-modal"
        centered
        onCancel={()=>setShowChangeProgress(false)}
        footer={[]}
      >
        <form onSubmit={multipleStatusSubmit}>
          <div className=" w-full flex flex-wrap">
          <NuSelect
              label="Type"
              options={[
                {value:"all",label:"All"},
                {value:"status",label:"Status"},
                {value:"priority",label:"Priority"},
              ]}
              formik={formik}
              placeholder="Choose"
              name="type"
              width=" w-full"
            />
           {
            (formik.values.type == "all" || formik.values.type == "status") &&
            <NuSelect
              label="Status"
              options={Status||[]}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-full"
            />
           } 
          {
            (formik.values.type == "all" || formik.values.type == "priority") &&
            <NuSelect
              label="Priority"
              options={Priority||[]}
              formik={formik}
              placeholder="Choose"
              name="priority"
              width=" w-full"
            />
           }
            
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>

      <ImportAllTask open={openImport} onCancel={() => { setOpenImport(false) }} getData={getTitle} firstProject={firstProject} />

    </>

  )
}

export default TaskPlan;
