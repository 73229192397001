import { Button, Modal, notification } from "antd";
import { NuInputText} from "../../../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import axiosInstance from "../../../../../../Services/axiosCommon";
import { useEffect, useState } from "react";
import * as yup from "yup";
import Attachement from "../../../../Notes/Attachement";
import ViewFiles from "./viewFiles";

const EditComments = ({ data, taskId, getData }) => {
  console.log("ss", data);
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState([]);
  const [createNew, setCreateNew] = useState({
    taskId: "",
    cmt: "",
    files: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      cmt: yup.string().required("Enter Comment "),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      if (removefilePathList) {
        let tempArr = [];
        let finalArr = removefilePathList.filter(
          (items) => !filePathList.includes(items)
        );
        finalArr && finalArr.map(fileName=>{
          tempArr.push(fileName.file)
        })
        value.files_to_remove = tempArr;
      } else {
        value.files_to_remove = [];
      }
      formData.append("data", JSON.stringify(value));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("files", file);
        });
      } else {
        formData.append("files", []);
      }
      axiosInstance
        .put(`/Comment/task/${data?.commentId}`, formData)
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            // console.log(res);
            resetForm();
            setImage({ preview: "", raw: "" });
            getData();
            onCancel();
            api.success({
              message: `Comment Display successfully`,
              // description:'',
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Comment Display failed`,
              description: res.data.msg | "Network error",
              placement: "bottomRight",
            });
          }
        });
    },
  });
  useEffect(() => {
    setCreateNew({
      taskId: taskId,
      cmt: data?.cmt,
    });
    if(data?.attachments!==null && data?.attachments !=="None" && data?.attachments !=="[]"){
      setFilePathList(data?.attachments)
      setRemoveFilePathList(data?.attachments)
    }
  }, [data]);
  function onCancel() {
    setOpen(false);
  }


  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    let fileArr = []

    if (e.target.files.length>0) {
      const files = Array.from(e.target.files);
      files.map(item=>{
        if (item.size < maxFileSize ){
          fileArr.push(item)
        }else{
          api.warning({
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            placement: "bottomRight",
          });
        }
      }) 
      let tempArr = []
      setSelectedFile(fileArr);
      files.map(item=>{
        if (item.size < maxFileSize ){
        let x = URL.createObjectURL(item)
        tempArr.push(x)
        }
      })
      setImage(tempArr)
    }
  }

  function UpdateAttachmentList(item) {
    let filterList = filePathList.filter((itemList) => itemList !== item);
    setFilePathList(filterList);
  }
  return (
    <>
      <span
        className="mr-2 flex justify-center items-center cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7333 4.48077L13.1453 5.8921M12.6413 3.23743L8.82332 7.05543C8.62604 7.25243 8.4915 7.50342 8.43665 7.77677L8.08398 9.5421L9.84932 9.18877C10.1227 9.1341 10.3733 9.0001 10.5707 8.80277L14.3887 4.98477C14.5034 4.87004 14.5944 4.73383 14.6565 4.58393C14.7186 4.43402 14.7505 4.27336 14.7505 4.1111C14.7505 3.94885 14.7186 3.78818 14.6565 3.63828C14.5944 3.48837 14.5034 3.35217 14.3887 3.23743C14.2739 3.1227 14.1377 3.03169 13.9878 2.9696C13.8379 2.90751 13.6772 2.87555 13.515 2.87555C13.3527 2.87555 13.1921 2.90751 13.0422 2.9696C12.8923 3.03169 12.756 3.1227 12.6413 3.23743Z"
            stroke="#3D348B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.417 10.8754V12.8754C13.417 13.2291 13.2765 13.5682 13.0265 13.8182C12.7764 14.0683 12.4373 14.2088 12.0837 14.2088H4.75033C4.3967 14.2088 4.05756 14.0683 3.80752 13.8182C3.55747 13.5682 3.41699 13.2291 3.41699 12.8754V5.5421C3.41699 5.18848 3.55747 4.84934 3.80752 4.59929C4.05756 4.34925 4.3967 4.20877 4.75033 4.20877H6.75033"
            stroke="#3D348B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <Modal
        open={open}
        title={data ? "Edit Comment" : "Create Comment"}
        width={"45%"}
        className="my-modal"
        centered
        onCancel={onCancel}
        footer={[]}
      >
        {contextHolder}
        <div className="w-full">
          <form onSubmit={formik.handleSubmit}>
            <NuInputText
              label="Task Comment"
              placeholder="Enter Comment"
              formik={formik}
              name="cmt"
              width="w-full"
            />
              <div className=" w-full flex gap-2 items-center">
                {filePathList &&
                  filePathList.map((imgs, idx) => {
                    console.log("file Path",imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{ border: "1px solid gray" }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs)}
                        >
                          x
                        </div>
                        <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden">
                        <ViewFiles data={imgs} key={idx} />
                        </div>
                      </div>
                    );
                  })}

                <Attachement
                  handleChange={handleImageChange}
                  preview={image || data?.attachments || ""}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
              </div>

            <div className=" w-full flex justify-end items-center mt-2">
              <Button onClick={onCancel} className=" mr-4 ">
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="bg-mainColor-500"
                type="primary"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default EditComments;
