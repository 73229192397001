import { Link, useLocation, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../Components/Icons/Dasboard";
import { useEffect, useState } from "react";
import axios from '../../../Services/axiosCommon';
import { TextBold, TextSmallBold } from "../../../Components/Utils/Text";
import Calender from "../common/calender/index";
import Members from "../common/Members/Members";
import Upcoming from "../common/Upcoming/Upcoming";
import Delayed from "../common/Delayed/Delayed";
import TaskByMembers from "../common/TaskByMembers/TaskByMembers";
import Tasks from "../Tasks";
import { LineChart } from "../common/Line/LineChart";
import TasksSingle from "../Tasks/taskSingle";
import BarChart from "../common/Bar/Bar";


const ProjectManager = () => {
    const location = useLocation();
    const ProjectName = location.state.projectName||"-";
    const {proDashboardId} =useParams();
    const [taskByMember,setTaskByMember] = useState([])
    const [teamMember,setTeamMember] = useState([])
    const [upCommpingTask,setUpCommpingTask] = useState([])
    const [delayedTask,setDelayedTask] = useState([])
    const [data,setData] = useState([])
    const [sTPC,setSTPC] = useState({})
    const [tPC,setTPC] = useState({})
    const [dataStatus,setDataStatus] = useState([])
    const [sTSC,setSTSC] = useState({})
    const [tSC,setTSC] = useState({})
    const [calender,setCalender] = useState([])
    const [actualTask,setActualTask] = useState([])
    const [plannedTask,setPlannedTask] = useState([])
    const [totalTask,setTotalTask] = useState(0)




      useEffect(() => {
        if(proDashboardId != "" ) {
          getTaskDeatils(proDashboardId);
          getCalenderData(proDashboardId)
          getAllTasks(proDashboardId);
          getTaskByMember(proDashboardId);
          getTeamMember(proDashboardId);
          getActualVsPlanned(proDashboardId);
        }
      },[proDashboardId])


      async function getTaskDeatils(proId){
        await axios
        .get(`/Dashboard/task_details/${proId}`)
        .then(async (res) => {
  
          setTotalTask(res?.data?.total_count);
          let KeysSubTPC = []
          let KeysTPC = []
          let KeysSubTSC = []
          let KeysTSC = []

          let subTPC = res.data?.subtask_priority_counts;
          let tTPC = res.data?.task_priority_counts;
          let subTSC = res.data?.subtask_status_counts ;
          let tTSC = res.data?.task_status_counts;
          setSTPC(subTPC);
          setTPC(tTPC);
          setSTSC(subTSC);
          setTSC(tTSC);
          if(subTPC) {
            KeysSubTPC = Object.keys(subTPC);
          }
          if(tTPC){
            KeysTPC = Object.keys(tTPC);
          }
          if(subTSC) {
            KeysSubTSC = Object.keys(subTSC);
          }
          if(tTSC){
            KeysTSC = Object.keys(tTSC);
          }

          let finalPKeys = merge(KeysSubTPC, KeysTPC);
          setData(finalPKeys);
          let finalSKeys = merge(KeysSubTSC, KeysTSC);
          setDataStatus(finalSKeys);
          // res.data?.data && setTeamMember(res.data?.data)
        })
        .catch((error) => {
          console.log(error);
        });
      }


      function merge(array1, array2) {
        return [... new Set([...array1, ...array2])];
    }


      async function getTaskByMember(proId){
        await axios
        .get(`/Dashboard/taskByMember/${proId}`)
        .then(async (res) => {
          res.data?.data && setTaskByMember(res.data?.data)
        })
        .catch((error) => {
          console.log(error);
        });
      }

      async function getTeamMember(proId){
        await axios
        .get(`/Dashboard/teamMembers/${proId}`)
        .then(async (res) => {
          res.data?.data && setTeamMember(res.data?.data)
        })
        .catch((error) => {
          console.log(error);
        });
      }

      async function getCalenderData(proId){
        await axios
        .get(`/Dashboard/calender/${proId}`)
        .then(async (res) => {
          res.data?.data && setCalender(res.data?.data)
        })
        .catch((error) => {
          console.log(error);
        });
      }

      async function getAllTasks(proId){
        await axios
        .get(`/Dashboard/upcomingAndDelayedTask/${proId}`)
        .then(async (res) => {
          let delayedTaskWithSub = res.data?.delayed_tasks_with_subtasks||[]
          let delayedTaskWithOutSub =res.data?.delayed_tasks_without_subtasks||[]
          let upCommingTaskWithSub =res.data?.upcoming_tasks_with_subtasks||[]
          let upCommingTaskWithOutSub =res.data?.upcoming_tasks_without_subtasks||[]
          let finalDelayedTask = delayedTaskWithSub.concat(delayedTaskWithOutSub);
          let finalUpCommingTask = upCommingTaskWithSub.concat(upCommingTaskWithOutSub);
          setDelayedTask(finalDelayedTask);
          setUpCommpingTask(finalUpCommingTask);
        })
        .catch((error) => {
          console.log(error);
        });
      }


      async function getActualVsPlanned(proId){
        await axios
        .get(`/Dashboard/actualAndPlanCount/${proId}`)
        .then(async (res) => {


          setActualTask(res?.data?.actual_monthly_task_counts||[]);
          setPlannedTask(res?.data?.plan_monthly_task_counts||[]);

        })
        .catch((error) => {
          console.log(error);
        });
      }


  return (
    <div className=" w-full h-full overflow-y-auto 2xl:overflow-hidden px-4 ">
      <div className="md:flex justify-between w-full">
        <div className=" w-[20%]">
        <BreadCrumb
        items={[
          {
            title: (
              <Link to="/dashboard">
                <Dasboard color="gray" width={10} height={10} />{" "}
              </Link>
            ),
          },
          { title: <p className=" text-[12px] ">Dashboard</p> },
        ]}
      />
        </div>
      <div className=" w-[60%] mt-1 text-center">
       <span className=" py-[3px] px-2 text-xs rounded-md bg-slate-200 font-medium">Project : <span className=" font-normal">{ProjectName||"-"}</span></span>
      </div>
      <div className=" w-[20%] h-2">

      </div>
      </div>
      <div className=" w-full h-full">
        <div className=" w-full h-full flex items-center">
            <div className=" w-8/12 p-1 h-full bg-white flex flex-wrap">
                <div className=" w-full h-[32%] 2xl:h-[28%] overflow-hidden p-1">
                  <TasksSingle data={data} STPC={sTPC} TPC={tPC} dataStatus={dataStatus} STSC={sTSC} TSC={tSC} total={totalTask} access={true} />
                </div>

                <div className=" overflow-hidden w-6/12 h-[32.5%] p-1" >
                   <div className=" w-full h-full rounded-lg overflow-hidden p-1" style={{border:"1px solid #E9E8EC"}}>
                   <p className=" py-2">
                   <TextBold text="Delayed Tasks" />
                   </p>
                   <div className=" w-full h-full overflow-y-auto pb-8">
                   <Delayed data={delayedTask} />
                   </div>
                   </div>
                </div>

                <div className=" overflow-hidden w-6/12 h-[35%]  2xl:h-[32.5%] p-1" >
                   <div className=" w-full h-full rounded-lg overflow-hidden p-1" style={{border:"1px solid #E9E8EC"}}>
                    <p className=" py-2">
                   <TextBold text="Upcoming Tasks" />
                    </p>
                    <div className=" w-full h-full overflow-y-auto pb-8">
                    <Upcoming data={upCommpingTask} />
                    </div>
                   </div>
                </div>

                <div className="  w-6/12 h-[40%] p-1">
                <div className=" w-full h-full rounded-lg overflow-hidden p-1" style={{border:"1px solid #E9E8EC"}}>
                <p className=" py-2">
                <TextBold text="Tasks by Members" />
                </p>
                <div className=" w-full h-full overflow-y-auto pb-8">
                <TaskByMembers data={taskByMember} />
                </div>
                   </div>
                </div>
                <div className=" w-6/12 h-[40%] p-1">
                <div className=" w-full h-full rounded-lg overflow-hidden p-1" style={{border:"1px solid #E9E8EC"}}>
                <div className=" p-1 text-xs bg-slate-100 text-center rounded flex justify-center items-center gap-3">
                 <span className=" flex gap-1 items-center"><div className=" w-3 h-3 rounded-full bg-[#3D3394]"></div> Plan</span> Vs <span className=" flex gap-1 items-center"> <div className=" w-3 h-3 rounded-full bg-[#C7C0FF] "></div> Actual</span> 
                </div>
                <div className=" w-full h-[80%]">
                 <BarChart actualData={actualTask} plannedData={plannedTask} />
                </div>
                  {/* <LineChart actualData={actualTask} plannedData={plannedTask} /> */}
                  </div>
                </div>
            </div>
            <div className=" w-4/12 flex flex-col gap-4 h-full p-1" >
                <div className=" w-full h-[48%]  rounded-lg  " style={{border:"1px solid #E9E8EC"}}>
                    <Calender data={calender}/>
                </div>
                <div className=" w-full h-[48%] rounded-lg p-1" style={{border:"1px solid #E9E8EC"}}>
                <p className=" py-2">
                <TextBold text="Members" />
                </p>
                    <Members data={teamMember} />
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectManager;
