import { Link, Outlet, useLocation } from "react-router-dom"
import BreadCrumb from "../../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../../Components/Icons/Dasboard";
import { useEffect, useState } from "react";
import SearchBar from "../../../../../Components/Utils/SearchBar";
import { CreateButton } from "../../../../../Components/Utils/Buttons";
import { Select } from "antd";
import { GetAccess } from "../../../../../Components/Utils/roles";


const ProgressLayout = (
    {
        SearchData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= true,
        ImportAccess= true,
        setOpen=()=>{},
        children,
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        officeId,
        projectId,
        onCreateClick
    }
) =>{
    let {pathname} = useLocation()
    const [module,setModule] = useState("")
    console.log("nnn",module,'off',officeId,'pro',projectId);
    

    useEffect(() =>{
        if (pathname == `/project/progress/man-power/${officeId}/${projectId}`) {
          setModule("Man Power");
        }else if(pathname == `/project/progress/material/${officeId}/${projectId}`){
            setModule("Material")
        }else if(pathname == `/project/progress/equipment/${officeId}/${projectId}`){
            setModule("Equipment")
        }
        else if(pathname == `/project/progress/others/${officeId}/${projectId}`){
            setModule("Others")
        }
        // if(pathname == `/project/progress/man-hour/${officeId}/${projectId}`){
        //     setModule("Man Hour")
        // }else if(pathname == `/project/progress/installation/${officeId}/${projectId}`){
        //     setModule("Installation")
        // }
        // else if(pathname == "/progress/material-expense"){
        //     setModule("Material & Others")
        // }else if(pathname == "/progress/petty-cash-expense"){
        //     setModule("Petty Cash Expense")
        // }
    },[pathname])

    let LinkItem = [
      {
        label: "Man Power",
        link: `/project/progress/man-power/${officeId}/${projectId}`,
        access: GetAccess(),
      },
      {
        label: "Material",
        link: `/project/progress/material/${officeId}/${projectId}`,
        access: GetAccess(),
      },
      {
        label: "Equipment",
        link: `/project/progress/equipment/${officeId}/${projectId}`,
        access: GetAccess(),
      },
      {
        label: "Others",
        link: `/project/progress/others/${officeId}/${projectId}`,
        access: GetAccess(),
      }
      // {
      //     label:"Man Hour",
      //     link:`/project/progress/man-hour/${officeId}/${projectId}`,
      //     access:GetAccess(),
      // },
      // {
      //     label:"Installation",
      //     link:`/project/progress/installation/${officeId}/${projectId}`,
      //     access:GetAccess(),
      // },
      // {
      //     label:"Material & Others",
      //     link:"/progress/material-expense",
      //     access:true,
      // },
      // {
      //     label:"Petty Cash Expense",
      //     link:"/progress/petty-cash-expense",
      //     access:true,
      // }
    ];
    
    return(
        <div className=" w-full h-full py-2 px-4 ">
            <div className="">
            <div className="md:flex justify-between w-full">
            <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Progress</p>)},
            {title:(<p className=' text-[12px] '>{module}</p>)}
            ]} />

            <div className=" flex justify-end items-center gap-3 mr-6">
            <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
              </form>
              <CreateButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <CreateButton label='Clear Filter'  handleClick={ClearFilter} />}
             
              {/* {ImportAccess && <CreateButton label="Import" handleClick={()=>setOpen(true)}    />} */}
              {CreateAccess && <CreateButton label={module} handleClick={onCreateClick} />}
                  {/* <BackButton/> */}
                </div>
            </div>
           
            <div className=" w-full h-8 flex items-center gap-x-4 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                            )
                         }
                        </>
                    )
                })
            }
        </div>
           
            <div className=" w-full h-[calc(100vh-140px)]">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default ProgressLayout;