import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import MainTaskSchedule from '../Views/Web/Project/SubMenu/Tasks/Schedule/main'
import TaskSchedule from '../Views/Web/Project/SubMenu/Tasks/Schedule'
import CreateTask from '../Views/Web/Project/SubMenu/Tasks/Components/createTask'
import EditTask from '../Views/Web/Project/SubMenu/Tasks/Components/editTask'
import CreateSubTask from '../Views/Web/Project/SubMenu/Tasks/Components/createSubTask'
import EditSubTask from '../Views/Web/Project/SubMenu/Tasks/Components/editSubTask'
import MainMyTask from '../Views/Web/Project/SubMenu/Tasks/MyTask/MainMyTask'
import MyTask from '../Views/Web/Project/SubMenu/Tasks/MyTask'
import MainAllTask from '../Views/Web/Project/SubMenu/Tasks/AllTask/MainAllTask'
import AllTask from '../Views/Web/Project/SubMenu/Tasks/AllTask'
import MainTasksPlan from '../Views/Web/Project/SubMenu/Tasks/Plan/main'
import TaskPlan from '../Views/Web/Project/SubMenu/Tasks/Plan'
import MainTasks from '../Views/Web/Project/SubMenu/Tasks/MainTask'
import ViewTask from '../Views/Web/Project/SubMenu/Tasks/Components/viewTask'
import SubTaskView from '../Views/Web/Project/SubMenu/Tasks/Components/viewSubTask'
 




function ProjectTaskRoutes() {
  return (
    <Route path="tasks" element={<MainTasks />}>
    <Route path="view/:taskViewId" element={<ViewTask />} />
    <Route path="subtask/view/:subTaskViewId" element={<SubTaskView />}/>
    <Route path="create" element={<CreateTask />} />
    <Route path="edit/:taskEditId" element={<EditTask />} />    
    <Route path="subtask/create" element={<CreateSubTask />} />
    <Route
      path="subtask/edit/:subTaskEditId"
      element={<EditSubTask />}
    />
    <Route path="task-schedule/:officeId/:projectId" element={<MainTaskSchedule />}>
     <Route index element={<TaskSchedule/>} />
    </Route>
    <Route path='my-task/:officeId/:projectId' element={<MainMyTask/>}>
     <Route index element={<MyTask/>} />
    </Route>
    <Route path='all-task/:officeId/:projectId' element={<MainAllTask/>}>
     <Route index element={<AllTask/>} />
    </Route>
    <Route path='task-plan/:officeId/:projectId' element={<MainTasksPlan/>}>
     <Route index element={<TaskPlan/>} />
     <Route path="create" element={<CreateTask/>} />
     <Route path="view/:taskViewId" element={<ViewTask />} />
    </Route>
  </Route>
  )
}

export default ProjectTaskRoutes