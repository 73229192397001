import { useEffect, useState } from "react";
import { Button, Modal, notification } from "antd"
import { NuInputNumber, NuInputfile, NuTextArea } from "../../../../../../Components/Utils/NuInput";
import { useFormik } from "formik";

import axios from "../../../../../../Services/axiosCommon";
const CreateSubPercentage = ({ open, onCancel, taskId, getData,projectId,data }) => {
    console.log("data",data);
    const [api, contextHolder] = notification.useNotification();
    const [createNew, setCreateNew] = useState({
        subTaskCompletions: '',
        subTaskId:"",
        cmt: "",
    })


    useEffect(()=>{
        setCreateNew({
            subTaskCompletions:data,
        })
    },[])

    const [image, setImage] = useState({ preview: "", raw: "" });
  
    const handleChangeImg = (e) => {
      if (e.target.files.length) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
      }
    };



    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: yup.object({

        // }),
        onSubmit: async (value,{resetForm}) => {

            let completion = {
                subTaskCompletions:value.subTaskCompletions
            }
            let comments = {
                subTaskId:taskId,
                cmt:value.cmt,
            }

            axios
                .put(`/SubTask/${taskId}/subtask-completions`, completion)
                .then((res) => {
                    if (res.status === 201 || res?.data?.status == true) {
                        console.log(res);
                        getData(projectId);
                        if(value.cmt||image.raw){
                            AddSubTaskComment(comments,resetForm)
                            }
                        api.success({
                            message: `SubTask Persentage Updated successfully`,
                            // description:'',
                            placement: 'bottomRight',
                        });
                        onCancel();
                    } else {
                        api.error({
                            message: `SubTask Persentage Update failed`,
                            description: res.data.msg || 'Network error',
                            placement: 'bottomRight',
                        });
                    }
                })
                .catch((error) => {
                    api.error({
                      message: `Task Status  Created failed`,
                      description: error?.response?.data?.msg || 'Sever error (or) Network error',
                      placement: 'bottomRight',
                    });
                  });
        }
    })


    function AddSubTaskComment(value,resetForm){
        let formData = new FormData();
        formData.append("data", JSON.stringify(value));
        formData.append("files", image.raw);
        axios.post("/Comment/subtask", formData).then((res) => {
          if (res.status === 201 || res.status === 200) {
            // console.log(res);
            resetForm();
            setImage({ preview: "", raw: "" });
            // getComments();
            //   onCancel();
            api.success({
              message: `Comment Add successfully`,
              // description:'',
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Comment Add failed`,
              description: res.data.msg ||"Network error",
              placement: "bottomRight",
            });
          }
        }).catch(err=>{
            console.log(err);
        });
    }

    function onClose(){
        formik.resetForm()
        onCancel()
      }

    return (
        <Modal open={open} title='Task Percentage' width={'40%'} centered onCancel={onClose} footer={[]}>
            {contextHolder}
            <form onSubmit={formik.handleSubmit}>
                <NuInputNumber label='Task Percentage' placeholder='0' formik={formik} name='subTaskCompletions' width='w-full' max={100} />
                <NuTextArea label='Comment' placeholder='Enter Comment' formik={formik} name='cmt' width='w-full' />
                <NuInputfile label='Upload' placeholder='Select File' handleChange={handleChangeImg}  name='cmt' width='w-full' />
                <div className=' w-full flex justify-end items-center mt-2'>
                    <Button onClick={onClose} className=' mr-4'>
                        Cancel
                    </Button>
                    <Button htmlType='submit' type='primary'>
                        Submit
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

export default CreateSubPercentage;