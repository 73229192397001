import { Button, Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// import CustomImgUpload from "../../Components/Utils/CustomImgUpload";
import {
  NuInputEmail,
  NuInputNumber,
  NuInputPassword,
} from "../../../Components/Utils/NuInput";
import axios from "../../../Services/axiosCommon";
import { useNavigate } from "react-router-dom";

const ChangePassword = ({onClose}) => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(true);
  const [nextStep, setNextStep] = useState(false);
  const [finalStep, setFinalStep] = useState(false);

  const [createNew, setCreateNew] = useState({
    email: " ",
    OTP:'',
    retype_password: "",
    type_password: "",
  });

  const onCancel = () => {
    navigate(-1);
    setOpen(false);
  };

  useEffect(() => {}, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   name: yup
    //     .string()
    //     .required("Name Required")
    //     .matches(/^(?![0-9]*$)[a-zA-Z]+$/, "Enter Valid Name"),
    //   dob: yup
    //     .string()
    //     .max(new Date(Date.now() - 567648000000), "Must be at least 18 years")
    //     .required("Date of Birth Required"),
    //   gender: yup.string().required("Gender is Required"),
    //   city: yup.string().matches(/^[A-Za-z]+$/, "Enter Valid City Name"),
    //   state: yup.string().required("Place Select State"),
    //   pincode: yup
    //     .string()
    //     .required("Pincode is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Pincode"
    //     )
    //     .min(6, "Invalid Pincode")
    //     .max(6, "Invalid Pincode"),
    //   address: yup
    //     .string()
    //     .matches(
    //       /^(?![0-9]*$)(?![0-9\s]*$)[a-zA-Z0-9'\.\-\s\,\/\(\)\#]+$/,
    //       "Enter Valid Address"
    //     ),

    //   mobileNo: yup
    //     .string()
    //     .required("Mobile Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(10, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    //   joinDate: yup.string().required("Join Date is required"),
    // }),
    onSubmit: async (value,{resetForm}) => {
      let obj = {
        'email':value.email,
        'retype_password': value.retype_password,
        'type_password': value.type_password,
      }
      if(value.type_password != "" && value.retype_password !=""){
        if(value.retype_password == value.type_password){
          axios.put(`/Auth/resetpassword`,obj).then(res=>{
            if(res.data.status){
              resetForm();
              api.success({
                message: `Password Reset successfully`,
                description:res.data?.msg||"Password Reset successfully",
                placement: 'bottomRight',
            });
              onCancel();
            }
          }).catch(err=>{
            api.error({
              message: `Password Reset Failed`,
              description: err.response?.data?.msg ||'Network Error',
              placement: 'bottomRight',
          });
          })
        }else{
          api.error({
            message: `Password Mismatch`,
            description: 'Please Enter same password in Re-type Password Field',
            placement: 'bottomRight',
        });
        }
      }else{
        api.error({
          message: `Enter Password`,
          description: 'Please Enter Password in The Password Fields',
          placement: 'bottomRight',
      });
      }
    },
  });



  function GotoNextStep(){
    if(formik.values.email !=" "){
      let obj = {
        'email':formik.values.email
      }
      axios.post(`/Auth/email-otp`,obj).then(res=>{
        if(res.data?.status){
          setNextStep(true)
          api.success({
            message: `OTP Send successfully`,
            description:res.data?.msg||"OTP Has sent the email",
            placement: 'bottomRight',
        });
        }else{
          api.error({
            message: `OTP Sending failed`,
            description: res.data.msg || 'Network error',
            placement: 'bottomRight',
        });
        }
      }).catch(err=>{
        api.error({
          message: `OTP Sending failed`,
          description: err.response?.data?.msg || 'Network error',
          placement: 'bottomRight',
      });
      })
    }else{
      api.error({
        message: `Enter Valid Email `,
        description:  'Please Enter valid Email Address',
        placement: 'bottomRight',
    });
    }
  }


  function GotoFinalStep(){
    if(formik.values.OTP!=" "){
      let obj = {
        'email':formik.values.email,
        'OTP':`${formik.values.OTP}`
      }
      axios.post(`/Auth/verify-otp`,obj).then(res=>{
        if(res.data?.status){
          setNextStep(false);
          setFinalStep(true);
          api.success({
            message: `OTP Verified successfully`,
            description:res.data?.msg||"OTP Has Verified",
            placement: 'bottomRight',
        });
        }else{
          api.error({
            message: `OTP Verified failed`,
            description: res.data.msg || 'Network error',
            placement: 'bottomRight',
        });
        }
      }).catch(err=>{
        api.error({
          message: `OTP Verified failed`,
          description: err.response?.data?.msg || 'Network error',
          placement: 'bottomRight',
      });
        // console.log(err);
      })
    }else{
      api.error({
        message: `Invalid OTP `,
        description: 'Please Enter Valid OTP',
        placement: 'bottomRight',
    });
    }
  }

  return (
    <>
    {contextHolder}
      <Modal
        open={open}
        title={"Reset Password"}
        width={"40%"}
        centered
        className="my-modal"
        onCancel={onCancel}
        footer={[]}
      >
        <div className=" overflow-y-scroll">
          <form onSubmit={formik.handleSubmit}>
            {nextStep == false && finalStep == false && (
              <div>
                <div className=" w-full flex flex-wrap">
                  <NuInputEmail
                    label="Email"
                    placeholder="example@email.com"
                    formik={formik}
                    name="email"
                    width="w-full"
                  />
                </div>
                <div className=" w-full flex justify-end items-center">
                  <Button onClick={onCancel} className=" mr-4">
                    Cancel
                  </Button>
                  <Button type="primary" onClick={GotoNextStep}>Next</Button>
                </div>
              </div>
            )}
            {
              nextStep == true && (
                <div>
                  <div className=" w-full flex flex-wrap">
                    <NuInputNumber
                      label="OTP"
                      placeholder="Enter OTP"
                      formik={formik}
                      name="OTP"
                      width="w-full"
                    />
                  </div>
                  <div className=" w-full flex justify-end items-center">
                    <Button onClick={onCancel} className=" mr-4">
                      Cancel
                    </Button>
                    <Button type="primary" onClick={GotoFinalStep}>Next</Button>
                  </div>
                </div>
              )
            }
            {finalStep == true && (
              <div>
                <div className=" w-full flex flex-wrap">
                  <NuInputPassword
                    label="New Password"
                    placeholder="******"
                    formik={formik}
                    name="type_password"
                    width="w-full"
                  />
                <NuInputPassword
                    label="Re-type Password"
                    placeholder="******"
                    formik={formik}
                    name="retype_password"
                    width="w-full"
                  />
                </div>
                <div className=" w-full flex justify-end items-center">
                <Button onClick={onCancel} className=' mr-4'>
              Cancel
            </Button>
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
                </div>
              </div>
            )}
          </form>
        </div>
      </Modal>
    </>
  );
};

export default ChangePassword;
