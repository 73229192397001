export const ModulePermission=[{name:"Module",roles:[{id:1,name:"Item"},{id:2,name:"Bidding"},{id:3,name:"Project"},{id:4,name:"Budget"},{id:5,name:"Planning"},{id:6,name:"Task"},{id:7,name:"Progress"},{id:8,name:"Finance"},{id:9,name:"Petty Cash"},{id:10,name:"My Diary"},{id:11,name:"Contacts"},{id:12,name:"Timesheet"},{id:13,name:"Teams"},{id:14,name:"Report"},{id:15,name:"Settings"},]},];
export const ItemPermission=[{name:"Activity/Item",roles:[{id:16,name:"View"},{id:17,name:"Create"},{id:18,name:"Edit"},{id:19,name:"Delete"},]},{name:"Terms & Condition",roles:[{id:20,name:"View"},{id:21,name:"Create"},{id:22,name:"Edit"},{id:23,name:"Delete"},]},];
export const BiddingPermission=[{name:"Bids",roles:[{id:24,name:"View"},{id:25,name:"Create"},{id:26,name:"Edit"},{id:27,name:"Delete"},]},{name:"Estimation",roles:[{id:28,name:"View"},{id:29,name:"Create"},{id:30,name:"Edit"},{id:31,name:"Delete"},]},];
export const ProjectPermission=[{name:"Project",roles:[{id:32,name:"View"},{id:33,name:"Create"},{id:34,name:"Edit"},{id:35,name:"Delete"},]},];
export const BudgetPermission=[{name:"Manpower",roles:[{id:36,name:"View"},{id:37,name:"Create"},{id:38,name:"Edit"},{id:39,name:"Delete"},]},{name:"Material",roles:[{id:40,name:"View"},{id:41,name:"Create"},{id:42,name:"Edit"},{id:43,name:"Delete"},]},{name:"Others",roles:[{id:44,name:"View"},{id:45,name:"Create"},{id:46,name:"Edit"},{id:47,name:"Delete"},]},];
export const PlanningPermission=[{name:"Activities",roles:[{id:48,name:"View"},{id:49,name:"Create"},{id:50,name:"Edit"},{id:51,name:"Delete"},]},{name:"Timeline",roles:[{id:52,name:"View"},{id:53,name:"Edit"},]},];
export const TaskPermission=[{name:"My Task",roles:[{id:54,name:"View"},{id:55,name:"Update"},]},{name:"All Task",roles:[{id:56,name:"View"},{id:57,name:"Update"},]},];
export const ProgressPermission=[{name:"Man Hour",roles:[{id:58,name:"View"},{id:59,name:"Create"},{id:60,name:"Edit"},{id:61,name:"Delete"},]},{name:"Installation",roles:[{id:62,name:"View"},{id:63,name:"Create"},{id:64,name:"Edit"},{id:65,name:"Delete"},]},];
export const FinancePermission=[{name:"Incoming",roles:[{id:66,name:"View"},{id:67,name:"Create"},{id:68,name:"Edit"},{id:69,name:"Delete"},]},{name:"Outgoing",roles:[{id:70,name:"View"},{id:71,name:"Create"},{id:72,name:"Edit"},{id:73,name:"Delete"},]},{name:"Other Expenses",roles:[{id:74,name:"View"},{id:75,name:"Create"},{id:76,name:"Edit"},{id:77,name:"Delete"},]},];
export const PettyCashPermission=[{name:"Received",roles:[{id:78,name:"View"},{id:79,name:"Create"},{id:80,name:"Edit"},{id:81,name:"Delete"},]},{name:"Issued",roles:[{id:82,name:"View"},{id:83,name:"Create"},{id:84,name:"Edit"},{id:85,name:"Delete"},]},];
export const MyDiaryPermission=[{name:"My Diary",roles:[{id:86,name:"View"},{id:87,name:"Create"},{id:88,name:"Edit"},{id:89,name:"Delete"},]},];
export const ContactPermission=[{name:"Employees",roles:[{id:90,name:"View"},{id:91,name:"Create"},{id:92,name:"Edit"},{id:93,name:"Delete"},]},{name:"Rental Manpower",roles:[{id:94,name:"View"},{id:95,name:"Create"},{id:96,name:"Edit"},{id:97,name:"Delete"},]},{name:"Vendor",roles:[{id:98,name:"View"},{id:99,name:"Create"},{id:100,name:"Edit"},{id:101,name:"Delete"},]},{name:"Client",roles:[{id:102,name:"View"},{id:103,name:"Create"},{id:104,name:"Edit"},{id:105,name:"Delete"},]},{name:"Sub-Contractor",roles:[{id:106,name:"View"},{id:107,name:"Create"},{id:108,name:"Edit"},{id:109,name:"Delete"},]},];
export const TimesheetPermission=[{name:"Attendance",roles:[{id:110,name:"View"},{id:111,name:"Create"},{id:112,name:"Edit"},{id:113,name:"Delete"},]},{name:"Timesheets",roles:[{id:114,name:"View"},]},];
export const TeamsPermission=[{name:"Team",roles:[{id:115,name:"View"},{id:116,name:"Create"},{id:117,name:"Edit"},{id:118,name:"Delete"},]},];
export const ProfileSettingsPermission=[{name:"Profile Settings",roles:[{id:119,name:"View"},{id:120,name:"Create"},{id:121,name:"Edit"},]}];
export const UserRolesSettingsPermission=[{name:"UserRoles Settings",roles:[{id:122,name:"View"},{id:123,name:"Create"},{id:124,name:"Edit"},{id:125,name:"Delete"},]}];
export const UserSettingsPermission=[{name:"User Settings",roles:[{id:122,name:"View"},{id:123,name:"Edit"},]}];
export const ProjectSettingsPermission=[{name:"Project Settings",roles:[{id:124,name:"View"},{id:125,name:"Create"},{id:126,name:"Delete"},]}];
export const TaskSettingsPermission=[{name:"Activity/Task Settings",roles:[{id:127,name:"View"},{id:128,name:"Create"},{id:129,name:"Delete"},]}];
export const MyDiarySettingsPermission=[{name:"MyDiary Settings",roles:[{id:130,name:"View"},{id:131,name:"Create"},{id:132,name:"Delete"},]}]
export const ManPowerRoleSettingsPermission=[{name:"Man Power Role Settings",roles:[{id:133,name:"View"},{id:134,name:"Create"},{id:135,name:"Edit"},{id:136,name:"Delete"},]}];
export const JobRoleSettingsPermission=[{name:"Job Role Settings",roles:[{id:137,name:"View"},{id:138,name:"Create"},{id:139,name:"Delete"},]}];
export const NotificationSettingsPermission=[{name:"Notification Settings",roles:[{id:140,name:"View"},{id:141,name:"Edit"},]}];
export const UnitSettingsPermission=[{name:"Unit Settings",roles:[{id:142,name:"View"},{id:143,name:"Create"},{id:144,name:"Delete"},]}];
export const FinanceSettingsPermission=[{name:"Finance Settings",roles:[{id:145,name:"View"},{id:146,name:"Create"},{id:147,name:"Delete"},]}];
export const CategorySettingsPermission=[{name:"Category Settings",roles:[{id:148,name:"View"},{id:149,name:"Create"},{id:150,name:"Delete"},]}];
export const DivisionSettingsPermission=[{name:"Division Settings",roles:[{id:151,name:"View"},{id:152,name:"Create"},{id:153,name:"Delete"},]}];
export const BiddingStatusSettingsPermission=[{name:"Bidding Status Settings",roles:[{id:154,name:"View"},{id:155,name:"Create"},{id:156,name:"Delete"},]}];