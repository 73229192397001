import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useNumber } from '../../../../Context/ProjectIdContext';
import axiosInstance from '../../../../Services/axiosCommon';
import { useFormik } from 'formik';
import ItemLayout from '../ItemLayout';
import Pagination from '../../../../Components/Utils/Pagenation';
import { Modal } from 'antd';
import CreateCondition from './CreateCondition';
import ViewMore from './ViewMoreCondition';
import ImportCondition from './import';
import ViewCondition from './ViewCondition';
import { GetAccess } from '../../../../Components/Utils/roles';

function IndexTeamCondition() {
  const { officeId } = useParams();
  const [createModalOpen, setCreateModalOpen] = useState(false); // State to handle modal open/close
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);

    const location = useLocation();
    const projectTask = location?.state?.projectTask || "";
    const { number, updateNumber } = useNumber();
    const [percentOpen, setPercentOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [dataCount, setDataCount] = useState(20);
    const [totalTasks, setTotalProject] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [projectList, setProjectList] = useState([]);
    const [data, setData] = useState([]);
    const [status, setStatus] = useState([]);
    const [priority, setPriority] = useState([]);
    const [firstProject, setFirstProject] = useState("");
    const [isFilter, setIsFilter] = useState(false);
    const [notFirst, setNotFirst] = useState(false);
    const [filterData, setFilterData] = useState({
      SDF: "",
      EDT: "",
      status: "",
      priority: "",
    });
   
  
     function SearchData(e) {
      setNotFirst(true);
      e.preventDefault();
      if (searchQuery != "") {
        axiosInstance
          .get(
            `/terms-and-condition/${officeId}?page=${pageIndex}&per_page=${dataCount}`,
            { params: { search: `${searchQuery}` } }
          )
          .then((res) => {
            setData(res.data?.data);
            setTotalProject(res.data?.total_records);
          })
          .catch((err) => console.log(err));
      } else {
        getTask(firstProject);
      }
    }
  
    function SearchPageDate() {
      axiosInstance
        .get(
          `/terms-and-condition/${firstProject}?page=${pageIndex}&per_page=${dataCount}`,
          { params: { search: `${searchQuery}` } }
        )
        .then((res) => {
          setData(res.data?.data);
          setTotalProject(res.data?.total_records);
        })
        .catch((err) => console.log(err));
    }
  
    function handleProjectChange(e) {
      setFirstProject(e);
      updateNumber(e);
    }
  
    function getTask() {
      axiosInstance
        .get(`/terms-and-condition/${officeId}?page=${pageIndex}&per_page=${dataCount}`)
        .then((res) => {
          setData(res.data?.data);
          setTotalProject(res.data?.total_records);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    useEffect(() => {
      if (searchQuery == "") {
        getTask();
      }
    }, [searchQuery]);
  
    useEffect(() => {
      if (searchQuery != "") {
        SearchPageDate()
      }else{
        getTask();
      }
    },[pageIndex,dataCount])
    return (
      <>
      <ItemLayout
        setOpen={setOpen}
        setSearchQuery={setSearchQuery}
        SearchData={SearchData}
        isFilter={isFilter}
        setFilterOpen={setFilterOpen}
        // ClearFilter={ClearFilter}
        CreateAccess={GetAccess("terms_and_condition_create")}
        ImportAccess={GetAccess("terms_and_condition_create")}
        onCreateClick={() => setCreateModalOpen(true)} // Handle button click
      >
          <div className="w-full h-[calc(100vh-150px)]">
            
            <div className="w-full h-[calc(100vh-170px)] overflow-y-auto relative">
              <table
                className="table-auto w-full"
                style={{ border: "none", borderCollapse: "collapse" }}
              >
                <thead className="w-full bg-[#2A475E] sticky top-0 z-[99]">
                  <tr className="text-xs font-medium py-3">
                    {header.map((header, i) => (
                      <th
                        className={`py-2 ${header.width} px-1 font-normal ${
                          header?.isLeft ? "text-left" : "text-center"
                        } text-xs text-white`}
                        key={i}
                        style={{ border: "none", borderCollapse: "collapse" }}
                      >
                        {header.headone}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item, i) => (
                      <tr className="w-full" key={i}>
                        <td className="py-2 px-1 text-xs text-center font-normal text-mainColor-400">
                          {item?.serial_number || "#"}
                        </td>
                        <td
  className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
  onClick={() => {
    setSelectedItem(item);  // Set the clicked item as the selected item
    setViewOpen(true);  // Open the modal
  }}
>
  {item?.item_code || "-"}
</td>
<td
  className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
  onClick={() => {
    setSelectedItem(item);  // Set the clicked item as the selected item
    setViewOpen(true);  // Open the modal
  }}
>
{item?.description || "-"}
</td>
                       
                        <td className="py-2 px-1 text-xs text-center font-normal text-mainColor-400">
                          <div className="w-full flex justify-center items-center">
                            <ViewMore id={item?.id || ""} getData={getTask} data={item} />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="h-6 my-2">
              <Pagination
                total={totalTasks}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                dataCount={dataCount}
                setDataCount={setDataCount}
              />
            </div>
          </div>
          {/* <Modal
          title="Create Terms & Conditions"
          visible={createModalOpen}
          onCancel={() => setCreateModalOpen(false)}
          footer={null}
        >
          
        </Modal> */}
         <CreateCondition
          open={createModalOpen} onCancel={() => { setCreateModalOpen(false) }} projectList={projectList || []} getData={getTask}
          firstProject={firstProject}/>
          <Modal
  open={viewOpen}
  onCancel={() => setViewOpen(false)}
  title={"View Terms & Conditions"}
  width="50%"
  centered
  className="my-modal"
  footer={[]}
>
  <ViewCondition item={selectedItem} open={viewOpen} onCancel={() => setViewOpen(false)} />
</Modal>
         {/* <Modal
          title="Create Activity"
          visible={open}
          onCancel={() => setOpen(false)}
          footer={null}
        >
          <CreateActivity
            open={open} 
            onCancel={() => setOpen(false)} 
            projectList={projectList || []} 
            firstProject={firstProject}
          />
        </Modal> */}
        </ItemLayout>
      <ImportCondition   open={open} onCancel={()=>setOpen(false)} getData={getTask}  label='Import' officeId={officeId}/>
       
      </>
      );
  }
  
  export default IndexTeamCondition
  
  const header = [
      {
        headone: "ID",
        width: "w-[5%]",
      },
      {
        headone: "Item Code",
        width: "w-[10%]",
        isLeft: true,
      },
      {
        headone: "Description",
        width: "w-[70%]",
        isLeft: true,
      },
      {
        headone: "Action",
        width: "w-[5%]",
      },
  ];

