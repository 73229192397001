import { Link, Outlet, useLocation } from "react-router-dom"
import BreadCrumb from "../../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../../Components/Icons/Dasboard";
import { useEffect, useState } from "react";
import SearchBar from "../../../../../Components/Utils/SearchBar";
import { CreateButton } from "../../../../../Components/Utils/Buttons";
import { GetAccess } from "../../../../../Components/Utils/roles";

const PlanLayout = (
    {
        SearchData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= true,
        ImportAccess= true,
        setOpen=()=>{},
        children,
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        setOpenImport=()=>{},
        officeId,
        projectId
    }
) =>{
    let {pathname} = useLocation()
    const [module,setModule] = useState("")
    
    useEffect(() =>{
        if(pathname == `/project/tasks/task-plan/${officeId}/${projectId}`){
            setModule("Activities")
        }else if(pathname == `/project/tasks/task-schedule/${officeId}/${projectId}`){
            setModule("Timeline")
        }
    },[pathname])

    let LinkItem = [
        {
            label:"Activities",
            link:`/project/tasks/task-plan/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"Timeline",
            link:`/project/tasks/task-schedule/${officeId}/${projectId}`,
            access:GetAccess(),
        },
    ]
    
    return(
        <div className=" w-full h-full py-2 px-4 ">
            <div className="">
            <div className="md:flex justify-between w-full">
            <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Planning</p>)},
            {title:(<p className=' text-[12px] '>{module}</p>)}
            ]} />

            <div className=" flex justify-end items-center gap-3 mr-6">

             {
                (module == "Plan"|| module == "My Task"||module == "All Task") && (
                    <>
                                <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
              </form>
              <CreateButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
                    </>
                
            )
             }

              {isFilter&& <CreateButton label='Clear Filter'  handleClick={ClearFilter} />}
             
             {
                module == "Activities" && <>{ImportAccess && <CreateButton label="Import" handleClick={()=> setOpenImport(true)}     />}</>
             } 

             {
                module == "Activities" && <>{CreateAccess &&<CreateButton handleClick={()=>setOpen(true)} label={"Title"}/>}</> 
             }

             
            
                </div>
            </div>
           
            <div className=" w-full h-8 flex items-center gap-x-4 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                            )
                         }
                        </>
                    )
                })
            }
        </div>
           
            <div className=" w-full h-[calc(100vh-140px)]">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default PlanLayout;