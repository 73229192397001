import { useNavigate } from "react-router-dom";
import Search from "../Icons/Search";
import Plus from "../Icons/Plus";
import Filter from "../Icons/Filter";
import Import from "../Icons/Import";
import Export from "../Icons/Export";
import ClearFilter from "../Icons/ClearFilter";

export function CreateButton({ handleClick = () => {},label="Add",svg=true, color="bg-[#ffc64d]" }) {
  return (
    <button
      className={`px-3  h-8 cursor-pointer text-xs border-none outline-none text-white ${color} rounded-md flex justify-center items-center`}
      // style={{ border: "1px solid #3D348B" }}
      onClick={handleClick}
    >
      {
        svg &&
        <span className="md:mr-2 flex justify-center items-center">
        {label.toLowerCase()=="filter"? <Filter/>:label.toLowerCase()=="import"?<Import />:label.toLowerCase()=="clear filter"?<ClearFilter />:label.toLowerCase()=="export"?<Export />:label.toLowerCase()=="pdf"?<Export />:label.toLowerCase()=="excel"?<Export />: <Plus/>} 
      </span>
      }

     <span className="hidden md:block">{label}</span> 
    </button>
  );
}

export function EditButton({ handleClick = () => {}, value="Edit" }) {
  return (
    <button
      className="px-3 h-8 cursor-pointer text-xs text-[#ffc64d] bg-white rounded-md flex items-center"
      style={{ border: "1px solid #ffc64d" }}
      onClick={handleClick}
    >
      
      <span className="mr-2 flex justify-center items-center">
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7333 4.48077L13.1453 5.8921M12.6413 3.23743L8.82332 7.05543C8.62604 7.25243 8.4915 7.50342 8.43665 7.77677L8.08398 9.5421L9.84932 9.18877C10.1227 9.1341 10.3733 9.0001 10.5707 8.80277L14.3887 4.98477C14.5034 4.87004 14.5944 4.73383 14.6565 4.58393C14.7186 4.43402 14.7505 4.27336 14.7505 4.1111C14.7505 3.94885 14.7186 3.78818 14.6565 3.63828C14.5944 3.48837 14.5034 3.35217 14.3887 3.23743C14.2739 3.1227 14.1377 3.03169 13.9878 2.9696C13.8379 2.90751 13.6772 2.87555 13.515 2.87555C13.3527 2.87555 13.1921 2.90751 13.0422 2.9696C12.8923 3.03169 12.756 3.1227 12.6413 3.23743Z"
            stroke="#ffc64d"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.417 10.8754V12.8754C13.417 13.2291 13.2765 13.5682 13.0265 13.8182C12.7764 14.0683 12.4373 14.2088 12.0837 14.2088H4.75033C4.3967 14.2088 4.05756 14.0683 3.80752 13.8182C3.55747 13.5682 3.41699 13.2291 3.41699 12.8754V5.5421C3.41699 5.18848 3.55747 4.84934 3.80752 4.59929C4.05756 4.34925 4.3967 4.20877 4.75033 4.20877H6.75033"
            stroke="#ffc64d"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      {value}
    </button>
  );
}

export function BackButton() {
  let navigate = useNavigate()
  return (
    <button
      className="px-1 md:px-3 h-6 md:h-8 cursor-pointer text-xs text-white bg-[#ffc64d]  rounded-md flex items-center"
      style={{ border: "1px solid #ffc64d" }}
      onClick={()=>navigate(-1)}
    >
      <span className="md:mr-2 flex justify-center items-center">
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.898 7.70773H4.42869L9.43946 2.69696C9.83991 2.29651 9.83991 1.63936 9.43946 1.23891C9.34447 1.14372 9.23163 1.0682 9.10742 1.01668C8.9832 0.965151 8.85004 0.938629 8.71557 0.938629C8.58109 0.938629 8.44793 0.965151 8.32372 1.01668C8.1995 1.0682 8.08667 1.14372 7.99167 1.23891L1.22509 8.0055C1.1299 8.10049 1.05438 8.21333 1.00285 8.33754C0.951327 8.46176 0.924805 8.59491 0.924805 8.72939C0.924805 8.86387 0.951327 8.99703 1.00285 9.12124C1.05438 9.24546 1.1299 9.35829 1.22509 9.45328L7.99167 16.2199C8.08674 16.3149 8.19959 16.3903 8.3238 16.4418C8.448 16.4932 8.58113 16.5197 8.71557 16.5197C8.85001 16.5197 8.98313 16.4932 9.10733 16.4418C9.23154 16.3903 9.3444 16.3149 9.43946 16.2199C9.53452 16.1248 9.60993 16.012 9.66138 15.8877C9.71282 15.7635 9.7393 15.6304 9.7393 15.496C9.7393 15.3615 9.71282 15.2284 9.66138 15.1042C9.60993 14.98 9.53452 14.8672 9.43946 14.7721L4.42869 9.76132H15.898C16.4627 9.76132 16.9248 9.29926 16.9248 8.73452C16.9248 8.16979 16.4627 7.70773 15.898 7.70773Z"
            fill="#fff"
          />
        </svg>
      </span>
      <span className=" hidden md:block">Back</span>
    </button>
  );
}


export function SubmitButton({isLoading}) {
  return (
    <button
      className={`${isLoading?'bg-red-300 text-xs cursor-wait':'bg-[#ffc64d]'} px-5 py-[9px] cursor-pointer  outline-none border-none text-white rounded-md flex items-center`}
      type="submit"
      // disable={isLoading?true:false}
      disabled={isLoading?true:false}
    >
      Submit
    </button>
  );
}

export function CancelButton({handleClick=()=>{}}) {
  return (
    <button
      className="px-5 py-2 cursor-pointer text-xs  text-[#ffc64d] bg-white  rounded-md flex items-center"
      style={{ border: "1px solid #ffc64d" }}
      onClick={handleClick}
      type="button"
    >
      Cancel
    </button>
  );
}

export function SearchButton({handleClick=()=>{}}) {
  return (
    <button
      className="p-[5.5px] outline-none text-xs border-none  cursor-pointer bg-white  rounded-md flex items-center"
      // style={{ border: "1px solid #3D348B" }}
      onClick={handleClick}
      type="submit"
    >
      <Search />
    </button>
  );
}

export function LongButton({value="Create",isLoading }) {
  return (
            <button
              type="submit"           
              className={`${isLoading?'bg-red-300 text-xs cursor-wait':'bg-[#119DEE]'} w-11/12  py-3 rounded-lg cursor-pointer text-sm outline-none border-none hover:text-white focus:text-white text-white  mx-auto`}
              disabled={isLoading?true:false}
            >
              {value} 
            </button>
          );
        }      
