import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ViewMore from '../../../../Components/Icons/ViewMore';
// import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import { Modal, notification } from 'antd';
import axiosInstance from '../../../../Services/axiosCommon';
import { ExclamationCircleFilled } from '@ant-design/icons';



const BranchViewMore = ({ data, fetcher, id }) => {
  const { confirm } = Modal;
    const {officeId} = useParams();

    // const { contextHolder, handle } = useDeleteData(fetcher, );
    // const handleDelete = async (removevalue) => handle(`/company_branch/${id}`,removevalue, 'branch');

    const [api, contextHolder] = notification.useNotification();
  const handleDelete = async(id,removevalue) =>{
    confirm({
        title: `Do you Want to delete ${removevalue}?`,
        centered:true,
        width:"25%",
        icon: <ExclamationCircleFilled />,
        content: `The ${removevalue} will be removed from the Branch`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/company_branch/${id}`).then((res) => {
            if(res.status === 201||res.status === 200) {
                fetcher();
                api.success({
                  message: `BOQ Item has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `BOQ Item has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `BOQ Item has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}


    // const userAccess = JSON.parse(localStorage.getItem("userAccess"));
    let Edit = true

    let Delete = true

    return (
        <>
            {contextHolder}
            <div className='text-[#344767]  relative dropdown inline-block z-50'>
                <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
                    <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full' style={{ border: '1px solid #C0C0C0' }}>
                        <ViewMore />
                    </span>
                </div>
                <ul className='dropdown-menuOne text-[#344767] text-left  rounded-lg absolute -left-[70px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
                <li className='w-[100px] bg-black block m-0 hover:cursor-pointer'>
                        <span className='bg-white hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium' >
                            <Link className='text-black hover:text-black' to={`/settings/branch/view/${id}`} state={{ data: data }}>View</Link>
                        </span>
                    </li>
                    {Edit && <li className=' w-[100px] bg-black block m-0'>
                    <Link className=' text-black hover:text-black border-none'  to={`/settings/branch/edit/${id}`} state={{ data: data, from: `/settings/branch` }} ><span
                            className='bg-white hover:bg-gray-100 py-2 px-2 border-none block whitespace-no-wrap text-xs font-medium'
                        // onClick={() => setOpen(true)}
                        >
                            Edit
                        </span></Link>
                    </li>}
                    {
                        Delete &&
                        <li className=''>
                            <a
                                className='bg-white hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium'
                                // to='/members'
                                href="#"
                                onClick={() => {
                                    handleDelete(data.branchId, data?.branch_name);
                                }}
                            >
                                Delete
                            </a>
                        </li>
                    }
                </ul>
            </div>
        </>
    );
};

export default BranchViewMore