import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  scales
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  scales
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Actual vs Planned',
    },
  },
  scales: {
    // to remove the labels
    x: {
      // to remove the x-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    // to remove the y-axis labels
    y: {
      beginAtZero:true,
    //   min:0,
      // to remove the y-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },

};

const labels = ["Jan","Feb","Mar","Apr","May","Jun","July","Aug","Sep","Oct","Nov","Dec"];




export function LineChart({actualData,plannedData}) {

    const data = {
        labels,
        datasets: [
          {
            label: 'Actual',
            data: actualData,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            tension: 0.5
          },
          {
            label: 'Planned',
            data: plannedData,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            tension: 0.5
          },
        ],
      };

  return <Line options={options} data={data} />;
}