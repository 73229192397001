import { useEffect, useState } from "react";
import { Button, Modal, notification } from "antd"
import { NuInputNumber, NuInputfile,  NuTextArea } from "../../../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import axios from "../../../../../../Services/axiosCommon";

const UpdatePercentage = ({ open, onCancel, Id, getData,projectId,taskLink,data }) =>{
    const [api, contextHolder] = notification.useNotification();
    // console.log("##",taskId);
    const [createNew, setCreateNew] = useState({
        completion:'',
        taskCompletion:'',
        subTaskCompletions: '',
        taskId:"",
        subTaskId:"",
        cmt: "",
        files: "",
    })
  const [image, setImage] = useState({ preview: "", raw: "" });
  
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema: yup.object({
            
        // }),
        onSubmit: async (value,{resetForm}) => {
            // taskLink?
            if(taskLink){
                // value.taskCompletion = value.completion;
                let completion = {
                    taskCompletion:value.completion,
                }

                let comments = {
                    taskId:Id,
                    cmt:value.cmt,
                }

                axios
                .put(`/Task/${Id}/task-completions`, completion)
                .then((res) => {
                 if ( res?.data?.status ==true) {
                     getData(projectId);
                     if(value.cmt||image.raw){
                     AddTaskComment(comments,resetForm)
                     }
                     api.success({
                        message: `Task Persentage Updated successfully`,
                        description: res.data.msg ||"successfully",
                        placement: 'bottomRight',
                    });
                     onCancel();
                 } else {
                     api.error({
                         message: `Task Persentage Update failed`,
                         description: res.data.msg || 'Network error',
                         placement: 'bottomRight',
                     });
                 }
             })
                .catch((err) => {
                  console.log(err);
                  api.error({
                    message: `Task Persentage Update failed`,
                    description: err?.response?.data.msg || 'Network error',
                    placement: 'bottomRight',
                });
                })
            }else{
                let completion = {
                    subTaskCompletions:value.completion,
                }

                let comments = {
                    subTaskId:Id,
                    cmt:value.cmt,
                }
                axios
                .put(`/SubTask/${Id}/subtask-completions`, completion)
                .then((res) => {
                    if (res?.data?.status == true) {
                        getData(projectId);
                        if(value.cmt||image.raw){
                            AddSubTaskComment(comments,resetForm)
                            }
                        api.success({
                            message: `SubTask Persentage Updated successfully`,
                            description: res.data.msg ||"successfully",
                            placement: 'bottomRight',
                        });
                        onCancel();
                    } else {
                        api.error({
                            message: `SubTask Persentage Update failed`,
                            description: res.data.msg || 'Network error',
                            placement: 'bottomRight',
                        });
                    }
                })
                .catch((error) => {
                    api.error({
                      message: `Task Status  Created failed`,
                      description: error?.response?.data?.msg || 'Sever error (or) Network error',
                      placement: 'bottomRight',
                    });
                  });
            }

            
        }
    })


    function AddTaskComment(value,resetForm){
        let formData = new FormData();
        formData.append("data", JSON.stringify(value));
        formData.append("files", image.raw);
        axios.post("/Comment/task", formData).then((res) => {
          if (res.status === 201 || res?.data?.status == true) {
            // console.log(res);
            resetForm();
            setImage({ preview: "", raw: "" });
            // getComments();
            //   onCancel();
            api.success({
              message: `Comment Add successfully`,
              // description:'',
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Comment Add failed`,
              description: res.data.msg || "Network error",
              placement: "bottomRight",
            });
          }
        }).catch(err=>{
          api.error({
            message: `Comment Add failed`,
            description: err?.response?.data.msg || "Network error",
            placement: "bottomRight",
          });
        });
    }


    function AddSubTaskComment(value,resetForm){
        let formData = new FormData();
        formData.append("data", JSON.stringify(value));
        formData.append("files", image.raw);
        axios.post("/Comment/subtask", formData).then((res) => {
          if (res.status === 201 || res.status === 200) {
            // console.log(res);
            resetForm();
            setImage({ preview: "", raw: "" });
            // getComments();
            //   onCancel();
            api.success({
              message: `Comment Add successfully`,
              // description:'',
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Comment Add failed`,
              description: res.data.msg || "Network error",
              placement: "bottomRight",
            });
          }
        }).catch(err=>{
          api.error({
            message: `Comment Add failed`,
            description: err?.response?.data.msg || "Network error",
            placement: "bottomRight",
          });
        });
    }

    useEffect(() => {
        setCreateNew({
          completion:data
        })
    },[data])


    function handeClose (){
      formik.resetForm()
      onCancel()
    }


    return(
        <Modal open={open} className=" my-modal" title='Task Completion Percentage' width={'40%'} centered onCancel={onCancel} footer={[]}>
            {contextHolder}
          <form onSubmit={formik.handleSubmit}>
          <NuInputNumber label='Task Percentage' placeholder='0' min={0} max={100} formik={formik} name='completion' width='w-full' />
          <NuTextArea label='Comment' placeholder='Enter Comment' formik={formik} name='cmt' width='w-full' />
          <NuInputfile label='Upload' placeholder='Select File' handleChange={handleChangeImg}  name='cmt' width='w-full' />
          <div className=' w-full flex justify-end items-center mt-2'>
            <Button onClick={handeClose} className=' mr-4'>
              Cancel
            </Button>
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
            </div>
            </form>
        </Modal>
    )
}

export default UpdatePercentage;