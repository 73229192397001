import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { CreateButton } from '../../../Components/Utils/Buttons'
import SearchBar from '../../../Components/Utils/SearchBar'
import Dasboard from '../../../Components/Icons/Dasboard'
import BreadCrumb from '../../../Components/Utils/Breadcrumbs'
import { GetAccess } from '../../../Components/Utils/roles'

const ItemLayout = (  {
    SearchData=() => {},
    setSearchQuery=()=>{},
    CreateAccess= false,
    ImportAccess= false,
    setOpen=()=>{},
    children,
    onCreateClick
}
) =>{
let {pathname} = useLocation()
let {officeId} = useParams()
const [module,setModule] = useState("")


useEffect(() =>{
    if(pathname == `/main/item/activity/${officeId}`){
        setModule("Activity/Item")
    }else if(pathname == `/main/item/teamandcondition/${officeId}`){
        setModule("Terms & Condition")
    }
    
},[pathname])

let LinkItem = [
    {
        label:"Activity/Item",
        link:`/main/item/activity/${officeId}`,
        access:GetAccess("activity_item_view"),
    },
    {
        label:"Terms & Conditions",
        link:`/main/item/teamandcondition/${officeId}`,
        access:GetAccess("terms_and_condition_view"),
    },
]

return(
    <div className=" w-full h-full py-2 px-4 ">
        <div className="">
        <div className="md:flex justify-between w-full">
        <BreadCrumb items={[{title:(
         <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
        )},{title:(<p className=' text-[12px] '>Item</p>)},
        {title:(<p className=' text-[12px] '>{module}</p>)}
        ]} />

        <div className=" flex justify-end items-center gap-3 mr-6">
        <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
          <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
          </form>
          {ImportAccess && <CreateButton label="Import" handleClick={()=>setOpen(true)}    />}
          {/* {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>} */}
              {/* <BackButton/> */}
            {CreateAccess && <CreateButton handleClick={onCreateClick} label="Add" className="create-activity-button"></CreateButton>}  

            </div>
        </div>
       
        <div className=" w-full h-8 flex items-center gap-x-4 ">
        {
            LinkItem && LinkItem.map((item, i) => {
                return(
                    <>
                     {
                        item.access && (
                            <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                        )
                     }
                    </>
                )
            })
        }
    </div>
       
        <div className=" w-full h-[calc(100vh-140px)]">
           {children} 
        </div>
        </div>
    </div>
)
}
export default ItemLayout