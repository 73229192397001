import React, { useEffect, useState } from "react";
import axios from "../../../../../../Services/axiosCommon";
import { Link, useLocation, useParams } from "react-router-dom";
import Pagination from "../../../../../../Components/Utils/Pagenation";
import { NuDatePicker, NuSelect } from "../../../../../../Components/Utils/NuInput";
import { Button, Modal, Select } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNumber } from "../../../../../../Context/ProjectIdContext";
// import ProgressLayout from "../ProgressLayout";
import ViewMore from "./ViewMore";
import NuDate from "../../../../../../Components/Utils/NuDate";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import PettyCashLayout from "../PettyCashLayout";
import { GetAccess } from "../../../../../../Components/Utils/roles";

function IssuedIndex(id) {
  const { officeId } = useParams();
  const { projectId } = useParams();
  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  const { number, updateNumber } = useNumber();
  const [percentOpen, setPercentOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalTasks, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [priority, setPriority] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [notFirst, setNotFirst] = useState(false);
  const [filterData, setFilterData] = useState({
    dateFrom:"",
    dateTo:"",
  });
  const [afterFilter, setAfterFilter] = useState({});
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function getProject() {
    await axios
      .get(`/Team/getUserProjectId`)
      .then(async (res) => {
        if (number != 0) {
          setFirstProject(number);
        } else if (projectTask) {
          setFirstProject(projectTask);
        } else {
          res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId);
          updateNumber(res?.data?.data[0]?.ProjectId);
        }
        let list = res?.data?.data || [];
        let finalData = [];
        (await res?.data?.data) &&
          list.map((item, idx) => {
            finalData.push({
              value: item.ProjectId,
              label: item.ProjectName,
            });
          });
        setProjectList(finalData);

        // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
        // console.log('====',res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getStatus = () => {
    axios
      .get(`/Metadata/taskstatus`)
      .then((res) => {
        let data = res.data?.data?.pettycashExpenseDetails?.keyValues;
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item, label: item };
          tempData.push(tempObject);
        });
        res.data?.data?.pettycashExpenseDetails?.keyValues && setStatus(tempData);
      })
      .catch((error) => {
        console.log("dddd");
      });
  };

  const getPriority = () => {
    axios
      .get(`/Metadata/taskpriority`)
      .then((res) => {
        let data = res.data?.data?.pettycashExpenseDetails?.keyValues;
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item, label: item };
          tempData.push(tempObject);
        });
        res.data?.data?.pettycashExpenseDetails?.keyValues && setPriority(tempData);
      })
      .catch((error) => {
        console.log("dddd");
      });
  };

  useEffect(() => {
    getStatus();
    getProject();
    getPriority();
  }, []);

  function SearchData(e) {
    setNotFirst(true);
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(
          `/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`,
          { params: { search: `${searchQuery}` } }
        )
        .then((res) => {
          setData(res.data?.data?.pettycashExpenseDetails);
          setTotalProject(res.data?.data?.totalRecords);
        })
        .catch((err) => console.log(err));
    } else {
      getTask(firstProject);
    }
  }

  function SearchPageDate() {
    axios
      .get(
        `/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`,
        { params: { search: `${searchQuery}` } }
      )
      .then((res) => {
        setData(res.data?.data?.pettycashExpenseDetails);
        setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((err) => console.log(err));
  }

  function handleProjectChange(e) {
    setFirstProject(e);
    updateNumber(e);
  }

  function getTask() {
    axios
      .get(`/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data?.pettycashExpenseDetails);
        setTotalProject(res.data.data.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log("tas",totalTasks);

  useEffect(() => {
    if (searchQuery == "") {
      getTask();
    }
  }, [searchQuery]);

  const userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let MyTaskCreate = userAccess
    ? userAccess.some(
        (permission) => permission.permissionName == "MyTask_create"
      )
    : false;

  useEffect(() => {
    // console.log("jj",projectNum);
    if (projectTask) {
      setFirstProject(projectTask);
      // console.log("gf", firstProject);
    }
  }, [projectTask]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // mobileNo: yup
      //   .string()
      //   // .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(10, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value);
      setIsFilter(true);
      axios
        .get(
          `/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`,
          {
            params: {
              // status: `${value.status}`,
              // SDF: `${value.SDF}`,
              // EDT: `${value.EDT}`,
              // priority: `${value.priority}`,
              dateFrom: `${value.dateFrom}`,
              dateTo: `${value.dateTo}`,
            },
          }
        )
        .then((res) => {
          setFilterOpen(false);
          setData(res?.data?.data?.pettycashExpenseDetails);
          setTotalProject(res.data?.data?.totalRecords);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  function FilterPage() {
    axios
      .get(
        `/pettycashexpense/?page=${pageIndex}&per_page=${dataCount}`,
        {
          params: {
            // status: `${afterFilter.status}`,
            // SDF: `${afterFilter.SDF}`,
            dateFrom: `${afterFilter.dateFrom}`,
            dateTo: `${afterFilter.dateTo}`,
            // EDT: `${afterFilter.EDT}`,
            // priority: `${afterFilter.priority}`,
          },
        }
      )
      .then((res) => {
        setData(res?.data?.data?.pettycashExpenseDetails);
        setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((err) => console.log(err));
  }

  function ClearFilter() {
    formik.resetForm({});
    getTask();
    setIsFilter(false);
  }

  useEffect(() => {
    if (isFilter) {
      FilterPage();
    } else if (searchQuery != "") {
      SearchPageDate();
    } else {
      getTask();
    }
  }, [pageIndex, dataCount]);

  return (
    <>
      <PettyCashLayout
        setOpen={setOpen}
        setSearchQuery={setSearchQuery}
        SearchData={SearchData}
        isFilter={isFilter}
        setFilterOpen={setFilterOpen}
        ClearFilter={ClearFilter}
        CreateAccess={GetAccess()}
        ImportAccess={GetAccess()}
        officeId={officeId}
        projectId={projectId}
      >
        <div className=" w-full h-[calc(100vh-190px)] overflow-y-auto">
          <table
            className="table-auto w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <thead className=" w-full bg-[#2A475E]">
              <tr className="text-xs  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-2 ${header.width} px-1 font-normal ${
                      header?.isLeft ? "text-left" : "text-center"
                    } text-xs text-white`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {data &&
                data.map((item, i) => (
                  <tr className=" w-full " key={i}>
                    <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">
                      {item?.pettycashExpenseId || "#"}
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                    <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/petty-cash/issued/view/${id}`} state={{data:item}}>  <NuAmount value={item?.receivedAmount || 0} /></Link>
                   
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                    <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/petty-cash/issued/view/${id}`} state={{data:item}}>   {(item?.date != "" && item?.date != "None" && (
                        <NuDate value={item?.date} format="DD-MMM-YYYY" />
                      )) ||
                        "-"}</Link>

                    
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                     
                      {item?.paidTo || "-"}
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                    <NuAmount value={item?.billAmount || 0} />
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                    <NuAmount value={item?.balanceAmount || 0} />
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                      {item?.balanceSettled == true? "Yes" : "No" || "0.0"}
                    </td>
                    <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">
                      <div className=" w-full flex justify-center items-center">
                        <ViewMore id={item?.pettycashExpenseId} getData={getTask} data={item} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className=" h-6 my-2">
          <Pagination
            total={totalTasks}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            dataCount={dataCount}
            setDataCount={setDataCount}
          />
        </div>
      </PettyCashLayout>
      <Modal
        open={filterOpen}
        title={"Filter My Tasks"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <NuSelect
              label="Priority"
              options={priority}
              formik={formik}
              placeholder="Choose"
              name="Priority"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />*/}
            <NuDatePicker
              label=" Date From"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="dateFrom"
              width=" w-1/2"
            />
            <NuDatePicker
              label=" Date To"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="dateTo"
              width=" w-1/2"
            /> 
            {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="filterStatus"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> */}
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default IssuedIndex;

const header = [
  {
    headone: "ID",
    width: "w-[3%]",
    // isLeft: true,
  },
  {
    headone: "Amount",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Date",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Paid To",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Bill Amount",
    width: "w-[8%]",
    isLeft: true,
  },
  {
    headone: "Balance Amount",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Balance Settled",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];
