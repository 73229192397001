import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import { Link, useParams } from 'react-router-dom';
import Dasboard from '../../../Components/Icons/Dasboard';
import SearchBar from '../../../Components/Utils/SearchBar';
import axiosInstance from '../../../Services/axiosCommon';
import { CreateButton } from '../../../Components/Utils/Buttons';
import List from '../../../assets/Icons/list';
import Card from '../../../assets/Icons/card';
import Pagination from '../../../Components/Utils/Pagenation';
import EmployeeCard from './EmployeeCard';
import ImportEmployee from './import';
import EmployeeList from './EmployeeList';

function Employeesindex() {
  const {officeId} = useParams();
  const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode") || "card");
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalTasks, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [notFirst, setNotFirst] = useState(false);

  function getEmployee() {
    axiosInstance
      .get(`/User/${officeId}/details?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data?.userDetails);
        setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  console.log("jjjj",data);

  function SearchData(e) {
    setNotFirst(true);
    e.preventDefault();
    if (searchQuery !== "") {
      axiosInstance
        .get(
          `/User/${officeId}/details?page=${pageIndex}&per_page=${dataCount}`,
          { params: { search: `${searchQuery}` } }
        )
        .then((res) => {
          setData(res.data?.data?.userDetails);
          setTotalProject(res.data?.data?.totalRecords);
        })
        .catch((err) => console.log(err));
    } else {
      getEmployee();
    }
  }

  function SearchPageDate() {
    axiosInstance
      .get(
        `/User/${officeId}/details?page=${pageIndex}&per_page=${dataCount}`,
        { params: { search: `${searchQuery}` } }
      )
      .then((res) => {
        setData(res.data?.data?.userDetails);
        setTotalProject(res.data?.data?.totalRecords);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (searchQuery == "") {
       getEmployee();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery != "") {
      SearchPageDate()
    }else{
      getEmployee();
    }
  },[pageIndex,dataCount])

  useEffect(() => {
    // setParams({...params,page:page,per_page:pageCount})
    localStorage.setItem("viewMode", viewMode);
  }, [viewMode]);


  return (
    <div className=" w-full h-full py-2 px-4 ">
        {/* <div className=""> */}
        <div className="md:flex justify-between w-full">
            <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Employess</p>)},
            ]} />
            <div className='  flex justify-center items-center float-right'>
              <div className=" pb-2 w-full flex justify-center gap-2 items-center ">
                <div className=" flex justify-center items-center gap-3 md:mr-6">
        <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
          <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
          </form>
          <div className='flex gap-2'>
            <div onClick={() => setViewMode("list")} className={viewMode === "list" ? "bg-[#ffc64d] h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><List/></div>
            <div onClick={() => setViewMode("card")} className={viewMode === "card" ? "bg-[#ffc64d] h-8 w-10 rounded-md flex justify-center items-center text-white" : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"}><Card/></div>
          </div>
          <CreateButton label="Import" handleClick={()=>setOpen(true)}    />
          <Link to={`/main/employees/${officeId}/create`} >
          <CreateButton label="Employee" className="create-activity-button"></CreateButton> 
          </Link>
          </div>
              </div>
            </div>
        </div>
        <div className='w-full h-[calc(100vh-150px)]'>
        {viewMode === "list" ? (
          <EmployeeList data={data} getData={getEmployee}/>
        ):(
        <div className="w-full h-[calc(100vh-170px)] overflow-y-auto">
        <div className='w-full flex  flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 pt-4'>
            {data && data.map((item, idx) => (           
              <EmployeeCard fetcher={getEmployee} item={item} key={idx} />
            ))}
        </div>
        </div>
        )}
        <div className="h-6 my-2">
            <Pagination
              total={totalTasks}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              dataCount={dataCount}
              setDataCount={setDataCount}
            />
          </div>
        </div>
        <ImportEmployee
        open={open}
        onCancel={() => setOpen(false)}
        getData={getEmployee}
        officeId={officeId}
        label="Import"
      />
    </div>
  )
}

export default Employeesindex;