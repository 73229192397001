import React from 'react'
import Client from './clients';


function ClientSetting() {
  return (
    <div className=' w-full '>
      <div className=' w-full flex justify-between items-start'>
      <h3 className=" text-sm text-mainColor-400 font-semibold">Job Roles</h3>

      </div>
    

    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
      <div className=' w-full md:w-1/2 lg:w-1/3'>
        <Client />
      </div>
      <div className=' w-full md:w-1/2 lg:w-1/3'>
        
      </div>

    </div>
  </div>
  )
}

export default ClientSetting;