import React, { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'
import Employeesindex from '../Views/Web/Employees'
import CreateEmployee from '../Views/Web/Employees/CreateEmployee'
import ViewEmployee from '../Views/Web/Employees/ViewEmployee'
import EditEmployee from '../Views/Web/Employees/EditEmployee'


const MainEmployees =lazy(()=>import('../Views/Web/Employees/MainEmployes')) 

function MembersRoutes() {
  return (
    <Route path="employees/:officeId" element={
        <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
          <MainEmployees/>
        </Suspense>
    }>
      <Route index element={<Employeesindex/>} />
      <Route path='create' element={<CreateEmployee/>} />
      <Route path='edit/:EditEmployeeId' element={<EditEmployee/>} />
      <Route path='view/:ViewEmployeeId' element={<ViewEmployee/>} />
    </Route>
  )
}

export default MembersRoutes