import React from 'react'
import ViewMore from './ViewMore';
import NuDate from '../../../Components/Utils/NuDate';

function EmployeeList({data,getData}) {
  return (
    <div className="w-full h-[calc(100vh-170px)] overflow-y-auto relative">
            <table
              className="table-auto w-full "
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <thead className="w-full bg-[#28A0D9] sticky top-0 z-[99]">
                <tr className="text-xs font-medium py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-2 ${header.width} px-1 font-normal ${
                        header?.isLeft ? "text-left" : "text-center"
                      } text-xs text-white`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, i) => (
                    <tr className="w-full" key={i}>
                      <td className="py-2 px-1 text-xs text-center font-normal text-mainColor-400">
                        {i+1 || "#"}
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                      >
                        {item?.firstName || "-"}
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                      >
                        {item?.gender || "-"}
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                      >
                        {item?.email || "-"}
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                      >
                        {item?.mobileNo || "-"}
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                      >
                        <NuDate value={item?.dob || "-"} formate="DD-MM-YYYY" />
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                      >
                        <NuDate value={item?.dateOfJoin || "-"} formate="DD-MM-YYYY" />
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                      >
                        {item?.nationalId || "-"}
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                      >
                        {item?.passportNumber || "-"}
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                      >
                        {item?.location || "-"}
                      </td>
                      <td className="py-2 px-1 text-xs text-center font-normal text-mainColor-400">
                        <div className="w-full flex justify-center items-center">
                          <ViewMore
                            id={item?.id || ""}
                            getData={getData}
                            data={item}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
  )
}

export default EmployeeList

const header = [
    {
      headone: "ID",
      width: "w-[5%]",
    },
    {
      headone: "Name",
      width: "w-[10%]",
      isLeft: true,
    },
    {
        headone: "Gender",
        width: "w-[10%]",
        isLeft: true,
    },
    {
      headone: "Email",
      width: "w-[10%]",
      isLeft: true,
    },
    {
        headone: "Mobile",
        width: "w-[10%]",
        isLeft: true,
    },
    {
        headone: "DOB",
        width: "w-[10%]",
        isLeft: true,
    },
    {
        headone: "Date Of Join",
        width: "w-[10%]",
        isLeft: true,
    },
    {
        headone: "National Id",
        width: "w-[10%]",
        isLeft: true,
    },
    {
        headone: "Passport No",
        width: "w-[10%]",
        isLeft: true,
    },
    {
        headone: "Location",
        width: "w-[10%]",
        isLeft: true,
    },
    {
      headone: "Action",
      width: "w-[5%]",
    },
  ];