import { Modal, notification } from 'antd'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { NuInputNumber, NuInputText, NuSelect, NuTextArea } from '../../../../../Components/Utils/NuInput';
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';

function EditSubItemsTC({open, getData, onCancel, data,id,estimationId }) {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    description: "",
    item_code: "",
  });
  useEffect(() => {
    data&&setCreateNew({
      item_code: data?.item_code || "",
      description: data?.description || "",
    })
  },[data])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
      item_code:yup.string().required("Item Code is Requried")
    }),
    onSubmit: (value,{resetForm}) => {
      axiosInstance.put(`/estimation/TC/update/${estimationId}/${id}/${data?.id}`, value)
      .then((res) => {
          console.log("res",res.data.status);
          if (res.data.status) {
            getData(id);
            resetForm();
            api.success({
              message: `T&C Item Update successfully`,
              description: res?.data?.msg || "Added In The Activity",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `T&C Item Upadate failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });
  // useEffect(() => {
  //   const totalPrice = formik?.values?.quantity * formik?.values?.unitPrice
  //   formik.setFieldValue("totalPrice", totalPrice);
  // }, [formik?.values?.quantity,formik?.values?.unitPrice]);
  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={"Edit TermsAndCondition Item"}
      width={"50%"}
      className="my-modal"
      centered
      footer={[]}
      onCancel={onCancel}
    >
    <form onSubmit={formik.handleSubmit} >
      <div className=" w-full flex flex-wrap">
      <NuInputText
              label="Item Code"
              placeholder="Item Code"
              formik={formik}
              name="item_code"
              width="w-full"
              isRequired={true}
            />
             <NuTextArea
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full"
            />
            <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
              <CancelButton handleClick={onCancel} />
              <SubmitButton isLoading={isLoading} />
            </div>
      </div>
      </form>
    </Modal>
    </>
  )
}

export default EditSubItemsTC