import { Modal, notification } from 'antd'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { NuInputNumber, NuInputText, NuSelect } from '../../../../../Components/Utils/NuInput';
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';

function EditSubItemsManpower({open, getData, onCancel, data,id,estimationId }) {
  const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    item: "",
    unit: "",
    quantity: "",
    unitPrice: "",
    totalPrice: "",
  });
  useEffect(() => {
    data&&setCreateNew({
      estimationId:data?.estimationId ||"",
      IT_Id:data?.IT_Id ||"",
      role:data?.role ||"",
      type:data?.type ||"",
      quantity:data?.quantity ||"",
      days:data?.days ||"",
      amount:data?.amount ||""
    })
  },[data])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
       role:yup.string().required("Item Name is Requried")
    }),
    onSubmit: (value,{resetForm}) => {
      axiosInstance.put(`/manpower/update/${estimationId}/${id}/${data?.id}`, value)
      .then((res) => {
          console.log("res",res.data.status);
          if (res.data.status) {
            getData(id);
            resetForm();
            api.success({
              message: `Manpower Item Update successfully`,
              description: res?.data?.msg || "Added In The Activity",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Manpower Item Upadate failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });
  useEffect(() => {
    const totalPrice = formik?.values?.quantity * formik?.values?.unitPrice
    formik.setFieldValue("totalPrice", totalPrice);
  }, [formik?.values?.quantity,formik?.values?.unitPrice]);
  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={"Edit BOQ Item"}
      width={"50%"}
      className="my-modal"
      centered
      footer={[]}
      onCancel={onCancel}
    >
    <form onSubmit={formik.handleSubmit} >
      <div className=" w-full flex flex-wrap">
      <NuInputText
              label="Role"
              placeholder="Role"
              formik={formik}
              name="role"
              width="w-full"
              isRequired={true}
          />
          <NuSelect
              label="Type"
              options={[
                {value: 0, label:"Own"},
                {value: 1, label:"Rental"},
              ]}
              formik={formik}
              placeholder="Choose"
              name="type"
              width="w-full md:w-1/3"
          />
          <NuInputNumber
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />
           <NuInputNumber
              label="No. of Days"
              placeholder="No. of Days"
              formik={formik}
              name="days"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              label="Amount"
              placeholder="Amount"
              formik={formik}
              name="amount"
              width="w-full md:w-1/3"
            />
            <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
              <CancelButton handleClick={onCancel} />
              <SubmitButton isLoading={isLoading} />
            </div>
      </div>
      </form>
    </Modal>
    </>
  )
}

export default EditSubItemsManpower