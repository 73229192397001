import React, {useState } from "react";
import {useParams } from "react-router-dom";
import Calender from "./calender";


function Index({data,isTure}) {
  console.log("data",data);
  const { id } = useParams();
  const refreshData = () => {
  };
  const [showDetails, setShowDetails] = useState(false);
  const handleShowDetails = () => {
    setShowDetails(true);
  };
  const [dayDetails, setDayDetails] = useState({});

  return (
    <div className="">
          <div className={`${showDetails ? 'w-[70%]' : 'w-full'} shadow-box`}>
            <Calender
              isTure={isTure}
              eventDatas={data}
              Details={handleShowDetails}
              setDayDetails={setDayDetails}
              refreshData={refreshData}
              id={id}
            />
      </div>
    </div>
  );
}

export default Index;


