// from node modules
import { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";
import { Button, Input, Popover, Progress, Select, notification } from "antd";
import * as yup from "yup";
// from component
import CustomAttachmentUpload from "../../../../../../Components/Utils/CustomAttachmentUpload";
import axios from "../../../../../../Services/axiosCommon";
import NuAvatarGroup from "../../../../../../Components/Layouts/NuAvatarGroupWithoutLink";
import { NuInputText } from "../../../../../../Components/Utils/NuInput";
import BreadCrumb from "../../../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../../../Components/Icons/Dasboard";
import { BackButton } from "../../../../../../Components/Utils/Buttons";
import NuDate from "../../../../../../Components/Utils/NuDate";
// from pages
import EditComments from "./editTaskComment";
import ViewFiles from "./viewFiles";
import DownArrow from "../../../../../../Components/Icons/DownArrow";
import Video from "../../../../../../Components/Icons/Video";
import Doc from "../../../../../../Components/Icons/Doc";
import Xls from "../../../../../../Components/Icons/Xls";
import Txt from "../../../../../../Components/Icons/Txt";
import Pdf from "../../../../../../Components/Icons/Pdf";

const ViewTask = () => {
  // router-dom
  const { taskViewId } = useParams();
  console.log("taskId", taskViewId);
  const location = useLocation();
  let projectNumber = location?.state?.projectId || 0;

  // node modules
  const [api, contextHolder] = notification.useNotification();

  // useState arrays or objects
  const [image, setImage] = useState([]);
  const [data, setData] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [copyTo, setCopyTo] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [priority, setPriority] = useState([]);
  const [members, setMembers] = useState([]);
  const [comment, setComment] = useState([]);
  const [taskProgressDefualt, setTaskProgressDefualt] = useState(0);
  const [createNewComment, setCreateNewComment] = useState({
    taskId: taskViewId,
    cmt: "",
    files: "",
  });
  const [createNew, setCreateNew] = useState({
    projectId: "",
    titleId: "",
    taskName: "",
    assignedTo: [],
    copyTo: [],
    priority: "",
    severity: "",
    taskStatus: "",
    // comment: "",
    startDate: "",
    endDate: "",
    reminderDate: "",
    reminderTime: "",
    attachment: "",
    reAssignedTo: [],
    taskTeam: "",
    noOFWeeks: "",
    noOFDays: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);

  // useState Number or Bool or String
  const [teamId, setTeamId] = useState(0);
  const [taskPriority, setTaskPriority] = useState("");
  const [taskStatusDefualt, setTaskStatusDefualt] = useState("");
  const [openAssignee, setOpenAssignee] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);

  //  get Functions
  // getTask
  async function getTask() {
    await axios
      .get(`Task/${taskViewId}`)
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data);
        (await res?.data?.data?.taskTeam) &&
          setTeamId(res.data?.data?.taskTeam);
        (await res?.data?.data?.priority) &&
          setTaskPriority(res?.data?.data?.priority);
        (await res?.data?.data?.taskStatus) &&
          setTaskStatusDefualt(res?.data?.data?.taskStatus);
        (await res?.data?.data?.taskCompletion) &&
          setTaskProgressDefualt(res?.data?.data?.taskCompletion);

        let data = res.data.data;
        res.data.data &&
          setAssignee(
            data?.assignedDetail?.AssignedTo?.map((i) => {
              return i?.userId;
            })
          );
        res.data.data &&
          setCopyTo(
            data?.assignedDetail?.CopyTo?.map((i) => {
              return i?.userId;
            })
          );
        res.data.data &&
          setCreateNew({
            taskName: data?.taskName,
            taskTeam: data?.taskTeam,
            assignedTo:
              data?.assignedDetail?.AssignedTo?.map((i, k) => {
                return i?.userId;
              }) || [],
            copyTo:
              data?.assignedDetail?.CopyTo?.map((i, k) => {
                return i?.userId;
              }) || [],
            priority: data?.priority,
            severity: data?.severity,
            taskStatus: data?.taskStatus,
            revisedEndDate: data?.revisedEndDate || "None",
            reasonForDateRevised: data?.reasonForDateRevised,
            reAssignedTo: data?.reAssignedTo || [],
            comment: data?.comment,
            startDate: data?.startDate,
            endDate: data?.endDate,
            reminderDate: data?.reminderDate,
            reminderTime: data?.reminderTime,
            // attachment: data?.attachment,
            projectId: data?.projectId,
            titleId: data?.titleId,
            noOFWeeks: data?.noOFWeeks,
            noOFDays: data?.noOFDays,
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // get Comments
  async function getComments() {
    await axios
      .get(`/Comment/task/${taskViewId}`)
      .then(async (res) => {
        console.log("bbb", res?.data?.data);
        (await res?.data?.data) && setComment(res?.data?.data);
        // (await res?.data?.data?.image) && setImageList(res?.data?.data?.image);
        // (await res?.data?.data) && setStartDate(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // get Member in Team
  const getMembers = (id) => {
    // console.log('getmember');
    axios
      .get(`/Team/getUserByTeamId/${id}`)
      .then((response) => {
        let FinalData = [];
        let status = response.data.data;
        status.forEach((item) => {
          let tempObject = {
            value: item.userId,
            label: `${item.firstName} ${item.lastName}`,
          };
          FinalData.push(tempObject);
        });
        response.data.data && setMembers(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  get All Members
  const getMembersAll = () => {
    // console.log('getmember');
    axios
      .get(`/Dashboard/teamMembers/${projectNumber}`)
      .then((response) => {
        let FinalData = [];
        let status = response.data.data;
        status.forEach((item) => {
          let tempObject = { value: item.memberId, label: item.name };
          FinalData.push(tempObject);
        });
        response.data.data && setAllMembers(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // get  TaskPriroty
  const getTaskPriroty = async () => {
    await axios
      .get("/Metadata/taskpriority")
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data?.keyValues;
        status.forEach((item) => {
          let tempObject = { value: item, label: item };
          FinalData.push(tempObject);
        });
        response.data?.data && setPriority(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get Task Status
  const getTaskStatus = async () => {
    await axios
      .get("/Metadata/taskstatus")
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data?.keyValues;
        status.forEach((item) => {
          let tempObject = { value: item, label: item };
          FinalData.push(tempObject);
        });
        response.data?.data && setTaskStatus(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect
  useEffect(() => {
    getTask();
    getComments();
    getMembersAll();
    getTaskPriroty();
    getTaskStatus();
  }, []);

  // Handle Change Functions
  // img change
  const handleChangeImg = (e) => {
    const maxFileSize = 200000000; // 200 MB
    let fileArr = [];

    if (e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      files.map((item) => {
        if (item.size < maxFileSize) {
          fileArr.push(item);
        } else {
          api.warning({
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            placement: "bottomRight",
          });
        }
      });
      let tempArr = [];
      setSelectedFile(fileArr);
      files.map((item) => {
        if (item.size < maxFileSize) {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      });
      setImage(tempArr);
    }
  };

  // assign change

  function handleChangeAssignee(e) {
    console.log("xyz", e);
    setAssignee(e);
  }
  // copy to
  function handleChangeCopyTo(e) {
    setCopyTo(e);
  }
  // priority
  function handleChangePriority(e) {
    setTaskPriority(e);
  }
  // status
  function handleChangeStatus(e) {
    setTaskStatusDefualt(e);
  }

  // progress
  function handleChangeProgress(e) {
    setTaskProgressDefualt(e.target.value);
  }

  // form submitions
  //add comments
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNewComment,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      cmt: yup.string().required("Enter Comment "),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(value));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("files", file);
        });
      } else {
        formData.append("files", []);
      }
      axios
        .post("/Comment/task", formData)
        .then((res) => {
          if (res.status === 201 || res.data.status == true) {
            // console.log(res);
            resetForm();
            setSelectedFile([]);
            setImage([]);
            getComments();
            //   onCancel();
            api.success({
              message: `Comment Display successfully`,
              // description:'',
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Comment Display failed`,
              description: res.data.msg | "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `Comment Display failed`,
            description: err?.response?.data.msg | "Network error",
            placement: "bottomRight",
          });
        });
    },
  });

  //change assignee
  function SubmitAssignee(e) {
    e.preventDefault();
    // if (assignee.length > 0) {
    // }
    if (createNew.reminderTime == "None") {
      createNew.reminderTime = "";
    }
    createNew.assignedTo = assignee;
    let formData = new FormData();
    formData.append("taskData", JSON.stringify(createNew));
    formData.append("attachment", []);
    axios
      .put(`/Task/${taskViewId}`, formData)
      .then((res) => {
        if (res.status === 201 || res.data.status == true) {
          getTask();
          api.success({
            message: `Task Updated successfully`,
            description: res?.data?.msg || "",
            placement: "bottomRight",
          });
          setOpenAssignee(false);
        } else {
          api.error({
            message: `Task Update failed`,
            description: res.data.msg || "Network error",
            placement: "bottomRight",
          });
        }
      })
      .catch((err) => {
        // setIsLoading(false)
        api.error({
          message: `Task Update failed`,
          description: err.response.data.msg || "Network error",
          placement: "bottomRight",
        });
      });
  }

  //  change Priority
  function SubmitPriority(e) {
    e.preventDefault();
    if (createNew.reminderTime == "None") {
      createNew.reminderTime = "";
    }
    createNew.priority = taskPriority;

    let formData = new FormData();
    formData.append("taskData", JSON.stringify(createNew));
    formData.append("attachment", []);
    axios
      .put(`/Task/${taskViewId}`, formData)
      .then((res) => {
        if (res.status === 201 || res.data.status == true) {
          // setIsLoading(false)
          // navigate(-1)
          // getData();
          setOpenPriority(false);
          getTask();
          api.success({
            message: `Task Updated successfully`,
            description: res?.data?.msg || "",
            placement: "bottomRight",
          });
          setOpenStatus(false);
        } else {
          // setIsLoading(false)
          api.error({
            message: `Task Update failed`,
            description: res.data.msg || "Network error",
            placement: "bottomRight",
          });
        }
      })
      .catch((err) => {
        // setIsLoading(false)
        api.error({
          message: `Task Update failed`,
          description: err.response.data.msg || "Network error",
          placement: "bottomRight",
        });
      });
  }

  //  change Status
  function SubmitStatus(e) {
    e.preventDefault();
    if (createNew.reminderTime == "None") {
      createNew.reminderTime = "";
    }
    createNew.taskStatus = taskStatusDefualt;

    let formData = new FormData();
    formData.append("taskData", JSON.stringify(createNew));
    formData.append("attachment", []);
    axios
      .put(`/Task/${taskViewId}`, formData)
      .then((res) => {
        if (res.status === 201 || res.data.status == true) {
          // setIsLoading(false)
          // navigate(-1)
          // getData();
          getTask();
          api.success({
            message: `Task Updated successfully`,
            description: res?.data?.msg || "",
            placement: "bottomRight",
          });
          setOpenStatus(false);
        } else {
          // setIsLoading(false)
          api.error({
            message: `Task Update failed`,
            description: res.data.msg || "Network error ",
            placement: "bottomRight",
          });
        }
      })
      .catch((err) => {
        // setIsLoading(false)
        console.log("dd", err?.response?.data?.msg);
        api.error({
          message: `Task Update failed`,
          description: `${err?.response?.data?.msg}` || "Network error",
          placement: "bottomRight",
        });
      });
  }

  // change Copy to
  function SubmitCopyto(e) {
    e.preventDefault();
    // if (copyTo.length > 0) {

    if (createNew.reminderTime == "None") {
      createNew.reminderTime = "";
    }
    // }
    createNew.copyTo = copyTo;
    let formData = new FormData();
    formData.append("taskData", JSON.stringify(createNew));
    formData.append("attachment", []);
    axios
      .put(`/Task/${taskViewId}`, formData)
      .then((res) => {
        if (res.status === 201 || res.data.status == true) {
          // setIsLoading(false)
          // navigate(-1)
          // getData();
          getTask();
          api.success({
            message: `Task Updated successfully`,
            description: res?.data?.msg || "",
            placement: "bottomRight",
          });
          setOpen(false);
        } else {
          // setIsLoading(false)
          api.error({
            message: `Task Update failed`,
            description: res.data.msg || "Network error",
            placement: "bottomRight",
          });
        }
      })
      .catch((err) => {
        // setIsLoading(false)
        api.error({
          message: `Task Update failed`,
          description: err.response.data.msg || "Network error",
          placement: "bottomRight",
        });
      });
  }

  // change Progress
  function SubmitProgress(e) {
    e.preventDefault();
    // if (copyTo.length > 0) {

    // }
    let completion = {
      taskCompletion: taskProgressDefualt,
    };

    axios
      .put(`/Task/${taskViewId}/task-completions`, completion)
      .then((res) => {
        if (res.status === 201 || res.data.status == true) {
          // setIsLoading(false)
          // navigate(-1)
          // getData();
          getTask();
          api.success({
            message: `Task Updated successfully`,
            description: res?.data?.msg || "",
            placement: "bottomRight",
          });
          setOpenProgress(false);
        } else {
          // setIsLoading(false)
          api.error({
            message: `Task Update failed`,
            description: res.data.msg || "Network error",
            placement: "bottomRight",
          });
        }
      })
      .catch((err) => {
        // setIsLoading(false)
        api.error({
          message: `Task Update failed`,
          description: err.response.data.msg || "Network error",
          placement: "bottomRight",
        });
      });
  }

  // chnage open Copy
  const handleOpenCopyChange = (newOpen) => {
    setOpen(newOpen);
  };

  // change open assignee
  const handleOpenAssigneeChange = (newOpen) => {
    setOpenAssignee(newOpen);
  };

  // change open Status
  const handleOpenStatusChange = (newOpen) => {
    setOpenStatus(newOpen);
  };

  // change open Priority
  const handleOpenPriorityChange = (newOpen) => {
    setOpenPriority(newOpen);
  };

  // change open Progress
  const handleOpenProgressChange = (newOpen) => {
    setOpenProgress(newOpen);
  };

  // other funtion
  // Date diff
  function DateDiff(createDate, expiryDate) {
    let exp = new Date(expiryDate);
    let cre = new Date(createDate);
    let timeDiff = Math.abs(exp.getTime() - cre.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays == 0 ? diffDays + 1 : diffDays + 1;
  }

  // Date diff zero
  function DateDiffWithZero(createDate) {
    let exp = new Date();
    let cre = new Date(createDate);
    let timeDiff = Math.abs(exp.getTime() - cre.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    console.log("time", diffDays);
    return diffDays;
  }

  // Date Remaining
  function DateRemaining(createDate, expiryDate) {
    let startDate = "";
    let dateNow = new Date();
    let startDateTemp = new Date(createDate);
    let endDateTemp = new Date(expiryDate);
    if (dateNow.getTime() >= endDateTemp.getTime()) {
      return "0";
    } else {
      if (dateNow.getTime() < startDateTemp.getTime()) {
        startDate = startDateTemp;
      } else {
        startDate = dateNow;
      }
      let endDate = new Date(expiryDate);
      let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays == 0 ? diffDays + 1 : diffDays + 1;
    }
  }
  // Date Worked
  function DateWorked(createDate, expiryDate) {
    return (
      DateDiff(createDate, expiryDate) - DateRemaining(createDate, expiryDate)
    );
  }

  // Now Date
  function NowTime(time) {
    let finalDate = DateDiffWithZero(time);
    let finalTime = `${time}+0530`;

    if (finalDate > 1) {
      return <NuDate value={time} format="DD-MMM-YYYY" />;
    } else {
      return moment(finalTime).fromNow();
    }
  }
  // for edit
  const userDetails = JSON.parse(localStorage.getItem("userDetails"))||[];
  const loginId = userDetails?.id||1;

  //  Component function
  //  Assignee
  function ChangeAssigee() {
    return (
      <>
        <form onSubmit={SubmitAssignee}>
          <div className=" flex gap-2 items-start">
            <div className=" w-[70%]">
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                options={members}
                defaultValue={assignee}
                // value={members.find((i) => i.value == createSubTask.assignedTo) || ""}
                // disabled={members.length >= 2 ? false : true}
                // allowClear={formik.values[name]?true:false}
                // name={name}
                // placeholder={placeholder}
                onChange={handleChangeAssignee}
              />
            </div>
            <div className=" w-[30%]">
              <Button
                htmlType="submit"
                className=" text-white bg-mainColor-300 w-full"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  }

  // Copy To
  function ChangeCopyTo() {
    return (
      <>
        <form onSubmit={SubmitCopyto}>
          <div className=" flex gap-2 items-start">
            <div className=" w-[70%]">
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                options={allMembers}
                defaultValue={copyTo}
                // value={allMembers.find((i) => i.value == [8,6]) || ""}
                // disabled={allMembers.length <= 1 ? true : false}
                // allowClear={formik.values[name]?true:false}
                // name={name}
                placeholder="Select"
                onChange={handleChangeCopyTo}
              />
            </div>
            <div className=" w-[30%]">
              <Button
                htmlType="submit"
                className=" text-white bg-mainColor-300 w-full"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  }

  // Priority
  function ChangePriority() {
    return (
      <>
        <form onSubmit={SubmitPriority}>
          <div className=" flex gap-2 items-start">
            <div className=" w-[70%]">
              <Select
                // mode="multiple"
                style={{ width: "100%" }}
                options={priority}
                defaultValue={taskPriority}
                // value={allMembers.find((i) => i.value == [8,6]) || ""}
                // disabled={allMembers.length >= 2 ? false : true}
                // allowClear={formik.values[name]?true:false}
                // name={name}
                placeholder="Select"
                onChange={handleChangePriority}
              />
            </div>
            <div className=" w-[30%]">
              <Button
                htmlType="submit"
                className=" text-white bg-mainColor-300 w-full"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  }

  // Status
  function ChangeStatus() {
    return (
      <>
        <form onSubmit={SubmitStatus}>
          <div className=" flex gap-2 items-start">
            <div className=" w-[70%]">
              <Select
                // mode="multiple"
                style={{ width: "100%" }}
                options={taskStatus}
                defaultValue={taskStatusDefualt}
                // value={allMembers.find((i) => i.value == [8,6]) || ""}
                // disabled={allMembers.length >= 2 ? false : true}
                // allowClear={formik.values[name]?true:false}
                // name={name}
                placeholder="Select"
                onChange={handleChangeStatus}
              />
            </div>
            <div className=" w-[30%]">
              <Button
                htmlType="submit"
                className=" text-white bg-mainColor-300 w-full"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  }

  function ChangeProgress() {
    return (
      <>
        <form onSubmit={SubmitProgress}>
          <div className=" flex gap-2 items-start">
            <div className=" w-[60%]">
              <Input
                type="number"
                onChange={handleChangeProgress}
                value={taskProgressDefualt}
                placeholder="0"
                max={100}
              />
            </div>
            <div className=" w-[40%]">
              <Button
                htmlType="submit"
                className=" text-white bg-mainColor-300 w-full"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  }

  // useMemos
  useMemo(() => {
    getMembers(teamId);
  }, [teamId]);

  return (
    <div className=" px-4 py-2">
      {contextHolder}
      <div className="w-full mb-2 flex justify-between items-start">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="/tasks">
                  <p className=" text-[12px] ">Task</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">View</p> },
          ]}
        />
        <BackButton />
      </div>
      <div className="w-full mx-auto shadow-2xl h-[calc(100vh-110px)] overflow-hidden  bg-white p-4 rounded-md flex gap-4">
        <div className=" w-[70%]  overflow-y-auto">
          <div
            className="w-full h-[23%] mx-auto bg-white p-3 mb-3 rounded-lg"
            style={{ border: "1px solid #bfc2ca" }}
          >
            <div className="flex justify-start items-center gap-2">
              <p className="text-[#2A475E] text-xs font-semibold px-3 h-4 bg-slate-300 text-center rounded-md">
                {data?.refNo || "#"}
              </p>
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mt-0.5 hidden md:block"
              >
                <path
                  d="M7.39038 1.58728H3.89038C2.23353 1.58728 0.890381 2.93043 0.890381 4.58728V10.5873C0.890381 12.2441 2.23353 13.5873 3.89038 13.5873H7.89038C9.54723 13.5873 10.8904 12.2441 10.8904 10.5873V5.08728M7.39038 1.58728H7.76906C8.16689 1.58728 8.54842 1.74532 8.82972 2.02662L10.451 3.64794C10.7323 3.92924 10.8904 4.31078 10.8904 4.7086V5.08728M7.39038 1.58728V3.58728C7.39038 4.41571 8.06195 5.08728 8.89038 5.08728H10.8904M2.89038 6.58728H5.89038M2.89038 8.58728H8.64038"
                  stroke="#119DEE"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
              <p className="text-[#2A475E] text-sm font-semibold line-clamp-5">
                {data.taskName}
              </p>
            </div>

            <div className="grid grid-cols-7 grid-rows-3 gap-0 ">
              <div className="col-span-2">
                <div className=" w-full h-full flex justify-start items-center">
                  <p className="text-[#171A21] text-xs font-normal text-left">
                    <span className=" font-medium">Created By&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>{" "}
                    {data?.createdByName || "-"}
                  </p>
                </div>
              </div>
              {/* <div className="col-start-3">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex gap-1 items-center">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Start Date :
                    </p>
                    <p className=" text-[#444546] font-normal text-xs  text-left">
                      {(data?.startDate != "None" && (
                        <NuDate value={data?.startDate} format="DD-MMM-YYYY" />
                      )) ||
                        "-"}
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-start-5">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex gap-1 items-center">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Due Date :
                    </p>
                    <p className="text-[#444546] font-normal text-xs  text-left">
                      {(data?.endDate != "None" && (
                        <NuDate value={data?.endDate} format="DD-MMM-YYYY" />
                      )) ||
                        "-"}
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-start-3 row-start-1">
                <div className=" w-full h-full  flex justify-start items-center">
                  <div className=" md:flex items-center gap-1  rounded ">
                    <p className=" text-[#171A21] font-medium  text-xs text-left">
                      Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    </p>
                    <Popover
                      content={ChangeStatus}
                      overlayStyle={{
                        width: "25vw",
                      }}
                      placement="bottom"
                      title="Change Status"
                      trigger="click"
                      open={openStatus}
                      onOpenChange={handleOpenStatusChange}
                    >
                      <div className="w-20  flex justify-between items-center  cursor-pointer rounded-md text-[#444546] text-xs font-normal   ">
                        {data?.taskStatus || "-"}
                        <DownArrow />
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
              <div className="col-start-5 col-span-2 row-start-1">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1  rounded ">
                    <p className=" text-[#171A21] font-medium  text-xs  text-left">
                      Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    </p>
                    <Popover
                      content={ChangePriority}
                      overlayStyle={{
                        width: "25vw",
                      }}
                      placement="bottom"
                      title="Change Priority"
                      trigger="click"
                      open={openPriority}
                      onOpenChange={handleOpenPriorityChange}
                    >
                      <div className="w-16 flex justify-between items-center  cursor-pointer rounded-md text-[#444546] text-xs font-normal  ">
                        {data?.priority || "-"}
                        <DownArrow />
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
              <div className="col-start-7 col-span-2 row-start-1">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Worked&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    </p>
                    <p className="text-[#444546] font-normal  text-xs text-left">
                      {DateWorked(data?.startDate, data?.endDate) || 0}{" "}
                      {DateWorked(data?.startDate, data?.endDate) > 1
                        ? "days"
                        : "day"}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-start-5 col-span-2 row-start-1">
                <div className=" w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Duration&nbsp;&nbsp;:
                    </p>
                    <p className="text-[#444546] font-normal  text-xs text-left">
                      {DateDiff(data?.startDate, data?.endDate) || 0}{" "}
                      {DateDiff(data?.startDate, data?.endDate) > 1
                        ? "days"
                        : "day"}
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-start-7 row-start-1">
                <div className=" w-full h-full flex justify-center items-center">
                  <p className=" text-[#171A21] font-medium  text-xs ">
                    Progress
                  </p>
                </div>
              </div> */}
              <div className="col-span-2 col-start-1 row-start-2">
                <div className="w-full flex items-center gap-1">
                  <p className=" text-[#171A21] font-medium text-xs text-left">
                    Assigned&nbsp;To&nbsp;&nbsp;&nbsp;:
                  </p>
                  <Popover
                    content={ChangeAssigee}
                    overlayStyle={{
                      width: "25vw",
                    }}
                    open={openAssignee}
                    placement="bottom"
                    title="Change or Reassign"
                    trigger="click"
                    onOpenChange={handleOpenAssigneeChange}
                  >
                    <div
                      className="w-full flex justify-start items-center gap-1 cursor-pointer rounded-md h-9 text-xs"
                      // style={{ border: "1px solid #9DA3AA" }}
                    >
                      <NuAvatarGroup
                        link={true}
                        userdetails={data?.assignedDetail?.AssignedTo}
                      />

                      <DownArrow />
                    </div>
                  </Popover>
                </div>
              </div>
              <div className="col-span-2 col-start-3 row-start-2">
                <div className="w-full flex items-center ">
                  <p className="  text-[#171A21] font-medium text-xs text-left">
                    Copy&nbsp;To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                  </p>
                  <Popover
                    content={ChangeCopyTo}
                    open={open}
                    onOpenChange={handleOpenCopyChange}
                    overlayStyle={{
                      width: "25vw",
                    }}
                    placement="bottom"
                    title="Change or Reassign"
                    trigger="click"
                  >
                    <div
                      className="w-full flex justify-start items-center gap-1 p-[1px] rounded-md h-9 cursor-pointer text-xs"
                      // style={{ border: "1px solid #9DA3AA" }}
                    >
                      <NuAvatarGroup
                        link={true}
                        userdetails={data?.assignedDetail?.CopyTo}
                      />
                      <DownArrow />
                    </div>
                  </Popover>
                </div>
              </div>
              <div className="col-start-5 col-span-2 row-start-2">
                <div className=" w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Duration&nbsp;&nbsp;:
                    </p>
                    <p className="text-[#444546] font-normal  text-xs text-left">
                      {DateDiff(data?.startDate, data?.endDate) || 0}{" "}
                      {DateDiff(data?.startDate, data?.endDate) > 1
                        ? "days"
                        : "day"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-start-7 col-span-2 row-start-2">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1">
                    <p className=" text-[#171A21] font-medium text-xs  text-left">
                      Remaining&nbsp;:
                    </p>
                    <p className="text-[#444546] font-normal text-xs   text-left">
                      {DateRemaining(data?.startDate, data?.endDate) || 0}{" "}
                      {DateRemaining(data?.startDate, data?.endDate) > 1
                        ? "days"
                        : "day"}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-start-5 row-start-2">
                <div className=" w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Duration&nbsp;&nbsp;:
                    </p>
                    <p className="text-[#444546] font-normal  text-xs text-left">
                      {DateDiff(data?.startDate, data?.endDate) || 0}{" "}
                      {DateDiff(data?.startDate, data?.endDate) > 1
                        ? "days"
                        : "day"}
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-start-1 row-start-3">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1  rounded ">
                    <p className=" text-[#171A21] font-medium  text-xs  text-left">
                      Priority&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    </p>
                    <Popover
                      content={ChangePriority}
                      overlayStyle={{
                        width: "25vw",
                      }}
                      placement="bottom"
                      title="Change Priority"
                      trigger="click"
                      open={openPriority}
                      onOpenChange={handleOpenPriorityChange}
                    >
                      <div className="w-16 flex justify-between items-center  cursor-pointer rounded-md text-[#444546] text-xs font-normal  ">
                        {data?.priority || "-"}
                        <DownArrow />
                      </div>
                    </Popover>
                  </div>
                </div>
              </div> */}
              {/* <div className="row-span-2 col-start-7 row-start-2">
                <div className=" w-full h-full flex justify-center items-center px-3">
                <Popover
                  content={ChangeProgress}
                  overlayStyle={{
                    width: "15vw",
                  }}
                  placement="bottom"
                  title="Update Progress"
                  trigger="click"
                  open={openProgress}
                  onOpenChange={handleOpenProgressChange}
                >
                  <div className="w-full h-full  cursor-pointer flex justify-center gap-2 items-center rounded-md text-[#444546] text-xs font-normal py-1  ">
                    <Progress
                      type="circle"
                      size={60}
                      strokeWidth={12}
                      format={(percent) => (
                        <p
                          className={` ${
                            data?.taskCompletion > 95
                              ? "text-[#87d068]"
                              : data?.taskCompletion > 50
                              ? "text-[#108ee9]"
                              : "text-[#D2BD00]"
                          } text-xs font-semibold`}
                        >
                          {percent}
                        </p>
                      )}
                      percent={Math.floor(data?.taskCompletion) || 0}
                      strokeColor={
                        data?.taskCompletion > 95
                          ? {
                              "0%": "#87d068",
                              "100%": "#87d068",
                            }
                          : data?.taskCompletion > 50
                          ? {
                              "0%": "#108ee9",
                              "100%": "#108ee9",
                            }
                          : {
                              "0%": "#D2BD00",
                              "100%": "#D2BD00",
                            }
                      }
                    />
                    <DownArrow />
                  </div>
                </Popover>
                </div>
              </div> */}
              {/* <div className="col-span-4 col-start-1 row-start-3">
                <div className="w-full h-full flex justify-start items-center">
                  <p className=" w-[90px] text-[#171A21] font-medium text-xs text-left">
                    Description&nbsp;&nbsp;&nbsp;:&nbsp;
                  </p>
                  <div className=" w-full max-h-[10vh] text-xs overflow-y-auto">
                    {data?.taskDesc || ""}
                  </div>
                </div>
              </div> */}
              {/* <div className="col-start-5 row-start-2">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Worked&nbsp;&nbsp;&nbsp;&nbsp;:
                    </p>
                    <p className="text-[#444546] font-normal  text-xs text-left">
                      {DateWorked(data?.startDate, data?.endDate) || 0}{" "}
                      {DateWorked(data?.startDate, data?.endDate) > 1
                        ? "days"
                        : "day"}
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-start-3 col-span-2 row-start-3">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1">
                    <p className=" text-[#171A21] font-medium text-xs  text-left">
                      Remaining :
                    </p>
                    <p className="text-[#444546] font-normal text-xs   text-left">
                      {DateRemaining(data?.startDate, data?.endDate) || 0}{" "}
                      {DateRemaining(data?.startDate, data?.endDate) > 1
                        ? "days"
                        : "day"}
                    </p>
                  </div>
                </div>
              </div> */}
            </div>

             {/* <div className="grid grid-cols-7 grid-rows-3 gap-0">
              <div className="col-span-4 ">
                <div className=" w-full h-full flex justify-start items-center">
                  <p className="text-[#171A21] text-xs font-normal text-left">
                    <span className=" font-medium">Created By :</span>{" "}
                    {data?.createdByName || "-"}
                  </p>
                </div>
              </div>
              <div className="col-start-5 ">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex gap-1 items-center">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Start Date :
                    </p>
                    <p className=" text-[#444546] font-normal text-xs  text-left">
                      {(data?.startDate != "None" && (
                        <NuDate value={data?.startDate} format="DD-MMM-YYYY" />
                      )) ||
                        "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-start-6 w-full h-full ">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex gap-1 items-center">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Due Date :
                    </p>
                    <p className="text-[#444546] font-normal text-xs  text-left">
                      {(data?.endDate != "None" && (
                        <NuDate value={data?.endDate} format="DD-MMM-YYYY" />
                      )) ||
                        "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row-span-3 col-start-7 ">
                <div className=" w-full h-full flex justify-start items-center">
                  <div className=" rounded px-2">
                    <p className=" text-[#171A21] font-medium  text-xs ">
                      Progress :
                    </p>
                    <Popover
                      content={ChangeProgress}
                      overlayStyle={{
                        width: "25vw",
                      }}
                      placement="bottom"
                      title="Update Progress"
                      trigger="click"
                      open={openProgress}
                      onOpenChange={handleOpenProgressChange}
                    >
                      <div className="w-12 h-9  cursor-pointer flex justify-center gap-2 items-center rounded-md text-[#444546] text-xs font-normal py-1  ">
                        <Progress
                          type="circle"
                          size={30}
                          strokeWidth={12}
                          format={(percent) => (
                            <p
                              className={` ${
                                data?.taskCompletion > 95
                                  ? "text-[#87d068]"
                                  : data?.taskCompletion > 50
                                  ? "text-[#108ee9]"
                                  : "text-[#D2BD00]"
                              } text-xs font-semibold`}
                            >
                              {percent}
                            </p>
                          )}
                          percent={Math.floor(data?.taskCompletion) || 0}
                          strokeColor={
                            data?.taskCompletion > 95
                              ? {
                                  "0%": "#87d068",
                                  "100%": "#87d068",
                                }
                              : data?.taskCompletion > 50
                              ? {
                                  "0%": "#108ee9",
                                  "100%": "#108ee9",
                                }
                              : {
                                  "0%": "#D2BD00",
                                  "100%": "#D2BD00",
                                }
                          }
                        />
                        <DownArrow />
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
              <div className="col-start-5 row-start-2  ">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1">
                    <p className=" text-[#171A21] font-medium text-xs text-left">
                      Worked :
                    </p>
                    <p className="text-[#444546] font-normal  text-xs text-left">
                      {DateWorked(data?.startDate, data?.endDate) || 0}{" "}
                      {DateWorked(data?.startDate, data?.endDate) > 1
                        ? "days"
                        : "day"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-start-6 row-start-2 ">
                <div className=" w-full h-full  flex justify-start items-center">
                  <div className=" md:flex items-center gap-1  rounded ">
                    <p className=" text-[#171A21] font-medium  text-xs text-left">
                      Status :
                    </p>
                    <Popover
                      content={ChangeStatus}
                      overlayStyle={{
                        width: "25vw",
                      }}
                      placement="bottom"
                      title="Change Status"
                      trigger="click"
                      open={openStatus}
                      onOpenChange={handleOpenStatusChange}
                    >
                      <div className="w-20  flex justify-between items-center  cursor-pointer rounded-md text-[#444546] text-xs font-normal   ">
                        {data?.taskStatus || "-"}
                        <DownArrow />
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
              <div className="col-start-6 row-start-3 ">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1  rounded ">
                    <p className=" text-[#171A21] font-medium  text-xs  text-left">
                      Priority :
                    </p>
                    <Popover
                      content={ChangePriority}
                      overlayStyle={{
                        width: "25vw",
                      }}
                      placement="bottom"
                      title="Change Priority"
                      trigger="click"
                      open={openPriority}
                      onOpenChange={handleOpenPriorityChange}
                    >
                      <div className="w-16 flex justify-between items-center  cursor-pointer rounded-md text-[#444546] text-xs font-normal  ">
                        {data?.priority || "-"}
                        <DownArrow />
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
              <div className="col-start-5 row-start-3 ">
                <div className="w-full h-full flex justify-start items-center">
                  <div className=" md:flex items-center gap-1">
                    <p className=" text-[#171A21] font-medium text-xs  text-left">
                      Remaining :
                    </p>
                    <p className="text-[#444546] font-normal text-xs   text-left">
                      {DateRemaining(data?.startDate, data?.endDate) || 0}{" "}
                      {DateRemaining(data?.startDate, data?.endDate) > 1
                        ? "days"
                        : "day"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-2 col-start-1 row-start-2 ">
                <div className="w-full flex items-center gap-1">
                  <p className=" text-[#171A21] font-medium text-xs text-left">
                    Assigned&nbsp;To&nbsp;:
                  </p>
                  <Popover
                    content={ChangeAssigee}
                    overlayStyle={{
                      width: "25vw",
                    }}
                    open={openAssignee}
                    placement="bottom"
                    title="Change or Reassign"
                    trigger="click"
                    onOpenChange={handleOpenAssigneeChange}
                  >
                    <div
                      className="w-full flex justify-start items-center gap-1 cursor-pointer rounded-md h-9 text-xs"
                      // style={{ border: "1px solid #9DA3AA" }}
                    >
                      <NuAvatarGroup
                        link={true}
                        userdetails={data?.assignedDetail?.AssignedTo}
                      />

                      <DownArrow />
                    </div>
                  </Popover>
                </div>
              </div>
              <div className="col-span-2 col-start-3 row-start-2 ">
                <div className="w-full flex items-center ">
                  <p className="  text-[#171A21] font-medium text-xs text-left">
                    Copy&nbsp;To&nbsp;:&nbsp;
                  </p>
                  <Popover
                    content={ChangeCopyTo}
                    open={open}
                    onOpenChange={handleOpenCopyChange}
                    overlayStyle={{
                      width: "25vw",
                    }}
                    placement="bottom"
                    title="Change or Reassign"
                    trigger="click"
                  >
                    <div
                      className="w-full flex justify-start items-center gap-1 p-[1px] rounded-md h-9 cursor-pointer text-xs"
                      // style={{ border: "1px solid #9DA3AA" }}
                    >
                      <NuAvatarGroup
                        link={true}
                        userdetails={data?.assignedDetail?.CopyTo}
                      />
                      <DownArrow />
                    </div>
                  </Popover>
                </div>
              </div>
              <div className="col-span-4 col-start-1 row-start-3 ">
                <div className="w-full h-full flex justify-start items-center">
                  <p className=" w-[90px] text-[#171A21] font-medium text-xs text-left">
                    Description :
                  </p>
                  <div className=" w-full max-h-[10vh] text-xs overflow-y-auto">
                    {data?.taskDesc || ""}
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className=" w-full grid gap-2 grid-cols-3 md:grid-cols-6">
            <div className=" w-full flex justify-start items-center">
              <p className="text-[#171A21] text-xs font-normal text-left">
                <span className=" font-medium">Created By :</span>{" "}
                {data?.createdByName || "-"}
              </p>
            </div>
            <div className=" w-full flex justify-start items-center">
              <div className=" md:flex gap-1 items-center">
                <p className=" text-[#171A21] font-medium text-xs text-left">
                  Start Date :
                </p>
                <p className=" text-[#444546] font-normal text-xs  text-left">
                  {(data?.startDate != "None" && (
                    <NuDate value={data?.startDate} format="DD-MMM-YYYY" />
                  )) ||
                    "-"}
                </p>
              </div>
            </div>
            <div className=" flex justify-start items-center">
              <div className=" md:flex gap-1 items-center">
                <p className=" text-[#171A21] font-medium text-xs text-left">
                  Due Date :
                </p>
                <p className="text-[#444546] font-normal text-xs  text-left">
                  {(data?.endDate != "None" && (
                    <NuDate value={data?.endDate} format="DD-MMM-YYYY" />
                  )) ||
                    "-"}
                </p>
              </div>
            </div>
            <div className=" flex justify-start items-center">
              <div className=" md:flex items-center gap-1  rounded px-2">
                <p className=" text-[#171A21] font-medium  text-xs  text-left">
                  Priority :
                </p>
                <Popover
                  content={ChangePriority}
                  overlayStyle={{
                    width: "25vw",
                  }}
                  placement="bottom"
                  title="Change Priority"
                  trigger="click"
                  open={openPriority}
                  onOpenChange={handleOpenPriorityChange}
                >
                  <div className="w-16 h-6 flex justify-between items-center  cursor-pointer rounded-md text-[#444546] text-xs font-normal py-1 ">
                    {data?.priority || "-"}
                    <DownArrow />
                  </div>
                </Popover>
              </div>
            </div>
            <div className=" flex justify-start items-center">
              <div className=" md:flex items-center gap-1  rounded px-2">
                <p className=" text-[#171A21] font-medium  text-xs text-left">
                  Status :
                </p>
                <Popover
                  content={ChangeStatus}
                  overlayStyle={{
                    width: "25vw",
                  }}
                  placement="bottom"
                  title="Change Status"
                  trigger="click"
                  open={openStatus}
                  onOpenChange={handleOpenStatusChange}
                >
                  <div className="w-20 h-6 flex justify-between items-center  cursor-pointer rounded-md text-[#444546] text-xs font-normal py-1  ">
                    {data?.taskStatus||"-"}
                    <DownArrow />
                  </div>
                </Popover>
              </div>
            </div>
            <div className=" flex justify-start items-center">
              <div className=" md:flex items-center gap-1  rounded px-2">
                <p className=" text-[#171A21] font-medium  text-xs text-left">
                  Progress :
                </p>
                <Popover
                  content={ChangeProgress}
                  overlayStyle={{
                    width: "25vw",
                  }}
                  placement="bottom"
                  title="Update Progress"
                  trigger="click"
                  open={openProgress}
                  onOpenChange={handleOpenProgressChange}
                >
                  <div className="w-12 h-9  cursor-pointer flex justify-center gap-2 items-center rounded-md text-[#444546] text-xs font-normal py-1  ">
                    <Progress
                      type="circle"
                      size={30}
                      strokeWidth={12}
                      format={(percent) => (
                        <p
                          className={` ${
                            data?.taskCompletion > 95
                              ? "text-[#87d068]"
                              : data?.taskCompletion > 50
                              ? "text-[#108ee9]"
                              : "text-[#D2BD00]"
                          } text-xs font-semibold`}
                        >
                          {percent}
                        </p>
                      )}
                      percent={Math.floor(data?.taskCompletion) || 0}
                      strokeColor={
                        data?.taskCompletion > 95
                          ? {
                              "0%": "#87d068",
                              "100%": "#87d068",
                            }
                          : data?.taskCompletion > 50
                          ? {
                              "0%": "#108ee9",
                              "100%": "#108ee9",
                            }
                          : {
                              "0%": "#D2BD00",
                              "100%": "#D2BD00",
                            }
                      }
                    />
                    <DownArrow />

                  </div>
                </Popover>
              </div>
            </div>
            <div className=" flex justify-start items-center  col-span-3">
              <div className=" w-full flex  items-start gap-2  ">
                <div className="w-1/2 flex items-center gap-1">
                  <p className=" text-[#171A21] font-medium text-xs text-left">
                    Assigned&nbsp;To&nbsp;:
                  </p>
                  <Popover
                    content={ChangeAssigee}
                    overlayStyle={{
                      width: "25vw",
                    }}
                    open={openAssignee}
                    placement="bottom"
                    title="Change or Reassign"
                    trigger="click"
                    onOpenChange={handleOpenAssigneeChange}
                  >
                    <div
                      className="w-full flex justify-start items-center gap-1 cursor-pointer rounded-md h-9 text-xs"
                      // style={{ border: "1px solid #9DA3AA" }}
                    >
                      <NuAvatarGroup
                        link={true}
                        userdetails={data?.assignedDetail?.AssignedTo}
                      />

                    <DownArrow />
                    </div>
                  </Popover>
                </div>

                <div className="w-1/2 flex items-center ">
                  <p className="  text-[#171A21] font-medium text-xs text-left">
                    Copy&nbsp;To&nbsp;:&nbsp;
                  </p>
                  <Popover
                    content={ChangeCopyTo}
                    open={open}
                    onOpenChange={handleOpenCopyChange}
                    overlayStyle={{
                      width: "25vw",
                    }}
                    placement="bottom"
                    title="Change or Reassign"
                    trigger="click"
                  >
                    <div
                      className="w-full flex justify-start items-center gap-1 p-[1px] rounded-md h-9 cursor-pointer text-xs"
                      // style={{ border: "1px solid #9DA3AA" }}
                    >
                      <NuAvatarGroup
                        link={true}
                        userdetails={data?.assignedDetail?.CopyTo}
                      />
                     <DownArrow />
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
            <div className=" flex justify-start items-center px-2">
              <div className=" md:flex items-center gap-1">
                <p className=" text-[#171A21] font-medium text-xs text-left">
                  Duration :
                </p>
                <p className="text-[#444546] font-normal  text-xs text-left">
                  {DateDiff(data?.startDate, data?.endDate) || 0} {DateDiff(data?.startDate, data?.endDate)>1?"days":"day"}
                </p>
              </div>
            </div>
            <div className=" flex justify-start items-center px-2">
              <div className=" md:flex items-center gap-1">
                <p className=" text-[#171A21] font-medium text-xs text-left">
                  Worked :
                </p>
                <p className="text-[#444546] font-normal  text-xs text-left">
                  {DateWorked(data?.startDate, data?.endDate) || 0} {DateWorked(data?.startDate, data?.endDate)>1?"days":"day"}
                </p>
              </div>
            </div>
            <div className=" flex justify-start items-center px-2">
              <div className=" md:flex items-center gap-1">
                <p className=" text-[#171A21] font-medium text-xs  text-left">
                  Remaining :
                </p>
                <p className="text-[#444546] font-normal text-xs   text-left">
                  {DateRemaining(data?.startDate, data?.endDate) || 0} {DateRemaining(data?.startDate, data?.endDate)>1?"days":"day"}
                </p>
              </div>
            </div>
            <div className=" flex justify-start items-center col-span-6">
            <p className=" w-[90px] text-[#171A21] font-medium text-xs text-left">
                  Description :
                </p>
            <div className=" w-full max-h-[10vh] text-xs overflow-y-auto">
          {data?.taskDesc||""}
        </div>
            </div>

          </div>  */}
          </div>
<div>
          <div className=" w-full  min-h-[48vh] max-h-[49vh]  overflow-y-auto">
            {comment?.map((data, idx) => (
              <div className="py-2" key={idx}>
                <div className="flex justify-start items-center gap-2">
                  <div className=" w-8 h-8 rounded-full overflow-hidden capitalize text-white font-medium flex justify-center items-center bg-[#2A475E]">
                    {
                      // show.imageUrl !=" " ||show.imageUrl.length>0?(<img width="160%" src={show.imageUrl[0]} alt='#' />):
                      `${data.firstName.charAt(0)}`
                    }
                  </div>
                  <p className="font-semibold text-xs text-[#707884]">
                    {data?.firstName}
                  </p>
                  <p className="text-[#9DA3AA] text-xs font-normal ">
                    {NowTime(data?.createdAt)}
                  </p>
                  {data?.userId == loginId ? (
                    <EditComments
                      taskId={taskViewId}
                      data={data}
                      getData={getComments}
                    />
                  ) : null}
                </div>
                {/* <p className="text-sm text-[#9DA3AA] font-normal text-justify pl-10 pt-1">{data?.cmt}</p> */}
                {/* <p className="pt-2 text-[#555E6C] text-sm font-normal pl-10">REPLAY</p> */}
                <div>
                  <p className="text-xs text-[#9DA3AA] font-normal text-justify mb-3 pl-10 pr-8 pt-1">
                    {data?.cmt}
                  </p>
                  <div className=" pl-10 flex items-center gap-3 flex-wrap">
                    {data?.attachments.length > 0 &&
                      data?.attachments.map((image, idx) => {
                        return <ViewFiles data={image} key={idx} />;
                      })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="w-full h-0.5 bg-[#F3F7FF] my-4"></div> */}
        <form onSubmit={formik.handleSubmit}>
          {/* <div className=" w-full flex gap-2 items-center"> */}
          <div className=" w-full ">

            <div className="py-1 w-full rounded-md border border-[#EAEBED] border-solid flex justify-evenly">
              <NuInputText
                placeholder="Write A Comment"
                formik={formik}
                name="cmt"
                width="w-full"
              />
              <CustomAttachmentUpload
                handleChange={handleChangeImg}
                multiple={true}
              />
              <button type="submit" className="border-none bg-white">
                <div className="w-6 h-6 rounded-sm  cursor-pointer flex justify-center items-center mr-3">
              

                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                  >
                    <path
                      d="M2.00106 13.5325C1.71412 13.6472 1.44152 13.622 1.18327 13.4567C0.925023 13.2914 0.795898 13.0513 0.795898 12.7362V9.52959C0.795898 9.32873 0.853287 9.14939 0.968065 8.99157C1.08284 8.83375 1.24066 8.73332 1.44152 8.69028L7.68256 7.14078L1.44152 5.59128C1.24066 5.54824 1.08284 5.44781 0.968065 5.28999C0.853287 5.13217 0.795898 4.95283 0.795898 4.75197V1.54537C0.795898 1.22973 0.925023 0.989273 1.18327 0.823993C1.44152 0.658713 1.71412 0.633749 2.00106 0.7491L15.2579 6.34451C15.6166 6.50233 15.7959 6.76775 15.7959 7.14078C15.7959 7.51381 15.6166 7.77923 15.2579 7.93705L2.00106 13.5325Z"
                      fill="#3267FF"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <div className="py-1 w-full md:w-[45%] flex gap-1">
              {selectedFile &&
                selectedFile.map((imageView) => {
                  const videotype = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];
                  let fileExtention = imageView.name.split(".").reverse()[0];
                  return (
                    <div
                      className=" w-32 p-1 h-10 rounded-lg  text-slate-700 text-[10px] font-medium flex gap-1  items-center"
                      style={{ border: "1px solid #bfc2ca" }}
                    >
                      <div className=" w-9 h-9 my-2 rounded overflow-hidden flex justify-center items-center">
                        {videotype.includes(fileExtention) ? <Video /> : null}

                        {fileExtention == ("docx" || "doc" || "txt") ? (
                          <Doc />
                        ) : fileExtention == ("xlsx" || "xls") ? (
                          <Xls />
                        ) : fileExtention == "txt" ? (
                          <Txt />
                        ) : fileExtention == "pdf" ? (
                          <Pdf />
                        ) : (
                          <img
                            src={URL.createObjectURL(imageView)}
                            alt="#"
                            width="120%"
                          />
                        )}
                      </div>{" "}
                      <p className=" line-clamp-2 ">{imageView.name}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </form>
        </div>
        <div className="w-[30%] overflow-y-scroll">
          
        <div
  className="w-full h-[23%] mx-auto bg-white p-2 mb-3 rounded-lg"
  style={{ border: "1px solid #bfc2ca" }}
>
  <p className="text-[#2A475E] text-sm font-semibold line-clamp-5 pb-2">
    Assignee Details
  </p>
  <div className="flex gap-4">
    <div className="w-[65%]">
      <div className="w-full flex px-2 py-2">
        <p className="w-1/2 text-xs font-medium text-[#171A21]">
          Start Date
        </p>
        <p className="w-1/2 text-xs text-[#444546]">
          : {(data?.startDate !== "None" && (
            <NuDate value={data?.startDate} format="DD-MMM-YYYY" />
          )) || "-"}
        </p>
      </div>
      <div className="w-full flex px-2 py-2">
        <p className="w-1/2 text-xs font-medium text-[#171A21]">
          Due Date
        </p>
        <p className="w-1/2 text-xs text-[#444546]">
          : {(data?.endDate !== "None" && (
            <NuDate value={data?.endDate} format="DD-MMM-YYYY" />
          )) || "-"}
        </p>
      </div>
    </div>

    <div className="w-0.5 bg-[#F3F7FF] my-4"></div> {/* Adjusted the height to fit the parent container */}

    <div className="w-[35%] flex flex-col justify-center items-center">
      <div className="w-full grid  py-2 items-center">
        <p className="w-full text-xs font-medium text-[#171A21] pb-2 items-center text-center">
          Progress
        </p>
        <div className="w-full flex justify-center">
          <Popover
            content={ChangeProgress}
            overlayStyle={{ width: "15vw" }}
            placement="bottom"
            title="Update Progress"
            trigger="click"
            open={openProgress}
            onOpenChange={handleOpenProgressChange}
          >
            <div className="w-full h-full cursor-pointer flex justify-center gap-2 items-center rounded-md text-[#444546] text-xs font-normal py-1">
              <Progress
                type="circle"
                size={45}
                strokeWidth={12}
                format={(percent) => (
                  <p
                    className={` ${
                      data?.taskCompletion > 95
                        ? "text-[#87d068]"
                        : data?.taskCompletion > 50
                        ? "text-[#108ee9]"
                        : "text-[#D2BD00]"
                    } text-xs font-semibold`}
                  >
                    {percent}
                  </p>
                )}
                percent={Math.floor(data?.taskCompletion) || 0}
                strokeColor={
                  data?.taskCompletion > 95
                    ? { "0%": "#87d068", "100%": "#87d068" }
                    : data?.taskCompletion > 50
                    ? { "0%": "#108ee9", "100%": "#108ee9" }
                    : { "0%": "#D2BD00", "100%": "#D2BD00" }
                }
              />
              <DownArrow />
            </div>
          </Popover>
        </div>
      </div>
    </div>
  </div>
</div>

        <div
            className="w-full h-[23%] mx-auto bg-white p-3 mb-3 rounded-lg"
            style={{ border: "1px solid #bfc2ca" }}
          >
 <div className="col-span-4 col-start-1 row-start-3">
                <div className="w-full h-full  items-center">
                  {/* <p className=" w-[90px] text-[#171A21] font-medium text-xs text-left">
                    Description&nbsp;&nbsp;&nbsp;:&nbsp;
                  </p> */}
                     <p className="text-[#2A475E] text-sm font-semibold line-clamp-5 pb-2">
                     Scope of Work 
              </p>
                  <div className=" w-full max-h-[10vh] text-xs overflow-y-auto">
                    {data?.taskDesc || ""}
                  </div>
                </div>
              </div>
        </div>
        <div
            className="w-full h-[30%] mx-auto bg-white p-3 mb-3 rounded-lg"
            style={{ border: "1px solid #bfc2ca" }}
          >

        </div>
        </div>
      </div>
      {/* ))}     */}
    </div>
  );
};

export default ViewTask;
