import React, { useEffect, useState } from 'react'
import TitleOthers from './TilteOthers';
import ProgressLayout from '../ProgressLayout';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { Modal, notification } from 'antd';
import * as yup from 'yup';
import CreateOthersTittlePro from './CreateOthersTitlePro';
import TitleOthersPro from './TitleOthersPro';
import { EditButton, LongButton } from '../../../../../../Components/Utils/Buttons';
import { ExclamationCircleFilled } from '@ant-design/icons';
import MultiEditOtherPro from './MultiEdit';
import Pagination from '../../../../../../Components/Utils/Pagenation';
import { NuSelect } from '../../../../../../Components/Utils/NuInput';
import useFetchMataData from '../../../../../../Components/Hooks/useFetchMataData';


function OthersIndex() {
  const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const { officeId } = useParams();
  const { projectId } = useParams();
const [data,setData] = useState([]);
const [open, setOpen] = useState(false);
const [pageIndex, setPageIndex] = useState(1);
const [dataCount, setDataCount] = useState(20);
const [createModalOpen, setCreateModalOpen] = useState(false);
const [filterOpen, setFilterOpen] = useState(false);
const [isFilter,setIsFilter]= useState(false);
const [filterData,setFilterData]= useState({
   unit: ""
});
const [afterFilter,setAfterFilter]= useState({})
const [searchQuery, setSearchQuery] = useState("");
const [totalData, setTotalData] = useState(0);
const { confirm } = Modal;
const [api, contextHolder] = notification.useNotification();
const [multiEditOpen, setMultiEditOpen] = useState(false);
const [selectedTitles, setSelectedTitles] = useState([]);
const [selectedExpandedItems, setSelectedExpandedItems] = useState([]);
const [flatSelectedItemIds, setFlatSelectedItemIds] = useState([]);

const handleMainCheckboxChange = (titleId, items) => {
  setSelectedTitles((prevSelectedTitles) =>
    prevSelectedTitles.includes(titleId)
      ? prevSelectedTitles.filter((id) => id !== titleId)
      : [...prevSelectedTitles, titleId]
  );

  setSelectedExpandedItems((prevSelectedExpandedItems) => ({
    ...prevSelectedExpandedItems,
    [titleId]: selectedTitles.includes(titleId)
      ? [] // Clear items if main checkbox is deselected
      : items.map(item => item.id), // Select all items if main checkbox is selected
  }));
};

const handleExpandedCheckboxChange = (titleId, itemId) => {
  setSelectedExpandedItems((prevSelectedExpandedItems) => ({
    ...prevSelectedExpandedItems,
    [titleId]: prevSelectedExpandedItems[titleId]?.includes(itemId)
      ? prevSelectedExpandedItems[titleId].filter((id) => id !== itemId)
      : [...(prevSelectedExpandedItems[titleId] || []), itemId],
  }));
};

useEffect(() => {
  const allSelectedIds = Object.values(selectedExpandedItems).flat();
  setFlatSelectedItemIds(allSelectedIds);
}, [selectedExpandedItems]);

const isTitleSelected = (titleId) => selectedTitles.includes(titleId);
  function getOthersPro() {
    axiosInstance
      .get(`/pro_other/new/${projectId}?&page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalData(res.data?.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(()=>{
    getOthersPro();
   },[])
  
   function SearchData(e) {
    e.preventDefault();
    if (searchQuery != "") {
      axiosInstance
        .get(
          `/pro_other/new/${projectId}?page=${pageIndex}&per_page=${dataCount}`,
          { params: { search: `${searchQuery}` } }
        )
        .then((res) => {
          setData(res.data?.data);
          setTotalData(res.data?.total);
        })
        .catch((err) => console.log(err));
    } else {
      getOthersPro();
    }
  }
  
   const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      
    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value)
      setIsFilter(true)
      axiosInstance
      .get(`/pro_other/new/${projectId}?page=${pageIndex}&per_page=${dataCount}`,{params:{unit:`${value.unit}`}})
      .then((res) => {
        setFilterOpen(false)
        setData(res.data?.data);
        setTotalData(res.data?.total)
  
      })
      .catch((error) => {
        console.log(error);
      });
    },
  });
  
  function FilterPage(){
    axiosInstance
      .get(`/pro_other/new/${projectId}?page=${pageIndex}&per_page=${dataCount}`,{params:{unit:`${afterFilter.unit}`}})
      .then((res) => {
        setData(res.data?.data);
        setTotalData(res.data?.total)
  
      }).catch(err=>console.log(err))
  }
  
  
  function ClearFilter(){
    formik.resetForm({})
    getOthersPro();
    setIsFilter(false)
  }
  
  useEffect(()=>{
   if(isFilter){
    FilterPage();
   }else if(searchQuery !=""){
      SearchData();
    }else{
      getOthersPro()
    }
  },[pageIndex,dataCount])
  
  useEffect(() => {
    if (searchQuery == "") {
      getOthersPro();
    }
  }, [searchQuery]);

  const handleDelete = async(id) =>{
    confirm({
        title: `Do you Want to delete BOQ Items?`,
        centered:true,
        width:"25%",
        icon: <ExclamationCircleFilled />,
        content: `The BOQ Items will be removed from the BOQ`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/pro_other/multi-delete`, {
            headers: {
              'Content-Type': 'application/json',
            },
            data: { "ids": id } // Pass the payload as the `data` key in `delete`
          }).then((res) => {
            if(res.status === 201||res.status === 200) {
                getOthersPro();
                api.success({
                  message: `Others Multi Item has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `Others Multi Item has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `Others Multi Item has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
  }

  return (
    <ProgressLayout
    officeId={officeId}
    projectId={projectId}
    onCreateClick={() => {
      setCreateModalOpen(true);
    }}
    isFilter={isFilter}
    setFilterOpen={setFilterOpen}
    ClearFilter={ClearFilter}
    SearchData={SearchData}
    setSearchQuery={setSearchQuery}
    >
        <div className="w-full h-[calc(100vh-150px)]">
          <div className=" w-full h-[calc(100vh-170px)]">
        {flatSelectedItemIds.length > 0 ? 
            <div className="w-full flex justify-end gap-2 py-2">
            <EditButton value="Multi Edit" handleClick={() => { setMultiEditOpen(true) }}/>
            <button
              type='button'
              className="px-3 h-8 cursor-pointer text-xs text-red-500 bg-white rounded-md flex items-center"
              style={{ border: "1px solid red" }}
              onClick={() => {handleDelete(flatSelectedItemIds)}}
            >
              Multi Delete
            </button>
          </div>
            :
            null
            }
        <table
            className="table-auto static top-0 w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
                        <thead className=" w-full bg-[#28A0D9]">
              <tr className="text-xs  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-2 ${header.width} px-1 font-normal ${
                      header?.isLeft ? "text-left" : "text-center"
                    } text-xs text-white`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <div className={` w-full ${flatSelectedItemIds.length > 0 ? "h-[calc(100vh-250px)]" : "h-[calc(100vh-200px)]"}  overflow-y-auto relative`}>
          <table
            className="table-auto  w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <tbody>
                {
                    data && data.map((item, i)=>{
                        return (
                            <TitleOthersPro 
                            key={i}
                            data={item}
                        getData={getOthersPro}
                        projectId={projectId}
                        selectedTitles={selectedTitles}
                        selectedExpandedItems={selectedExpandedItems}
                        onMainCheckboxChange={handleMainCheckboxChange}
                        onExpandedCheckboxChange={
                          handleExpandedCheckboxChange
                        }
                        isTitleSelected={isTitleSelected}
                            />
                        )
                    })
                }
            </tbody>
          </table>
          </div>
        </div>
        <div className=" h-7 my-[2px] px-3">
            <Pagination
              total={totalData}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              dataCount={dataCount}
              setDataCount={setDataCount}
            />
          </div>
        </div>
        <CreateOthersTittlePro
          open={createModalOpen}
          onCancel={() => {
            setCreateModalOpen(false);
          }}
          getData={getOthersPro}
        />
        <MultiEditOtherPro
          open={multiEditOpen}
          onCancel={() => {
            setMultiEditOpen(false);
          }}
          getData={getOthersPro}
          seletedIds={flatSelectedItemIds}
        />
        <Modal
        open={filterOpen}
        title={"Filter BOQ"}
        width={"30%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuSelect
              label="Unit"
              options={ Unit || [] }
              formik={formik}
              placeholder="Choose"
              name="unit"
              width="w-full"
            />
            <LongButton value='Filter'/>
          </div>
        </form>
      </Modal>
    </ProgressLayout>
  )
}

export default OthersIndex



const header = [
  // {
  //   headone: "ID",
  //   width: "w-[3%]",
  //   // isLeft: true,
  // },
  {
      headone: "Sl.No",
      width: "w-[5%]",
      isLeft: true,
    },
  {
    headone: "Description",
    width: "w-[20%]",
    isLeft: true,
  },

  {
    headone: "Unit",
    width: "w-[9%]",
    isLeft: true,
  },
  {
    headone: "Budget  Qty. ",
    width: "w-[9%]",
    isLeft: true,

  },

  {
    headone: "Budget Price",
    width: "w-[14%]",
    isLeft: true,

  },
  {
    headone: "Actual  Qty. ",
    width: "w-[9%]",
    isLeft: true,

  },

  {
    headone: "Actual Price",
    width: "w-[14%]",
    isLeft: true,

  },
  {
    headone: "Amount",
    width: "w-[15%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];