import { useState } from 'react';
import { Link } from 'react-router-dom';
import ImportSubTask from './importSubTask';
import More from '../../../../../../Components/Icons/More';
import { Modal, notification } from 'antd';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { ExclamationCircleFilled } from "@ant-design/icons";
import SetReminderTask from './setReminder';

// import CreateClients from './createClient';
// import ClientView from './clientView';


const {confirm} = Modal


const ViewMoreTask = ({ data, getClients,id,projectId }) => {

  const [openImport, setOpenImport] = useState(false);
  const [openReminder,setOpenReminder] = useState(false);
  const [api, contextHolder] = notification.useNotification();


  const handleDelete = async (id, removevalue) => {
    confirm({
      title: `Do you Want to delete ${removevalue}?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `The ${removevalue} will be removed from the Task Plans`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        axiosInstance.delete(`/Task/${id}`).then((response) => {
            if (response.data?.status == true) {
              getClients(projectId);
              api.success({
                message: `Task  Deleted successfully`,
                // description:'',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Task Deleted failed`,
                description: response?.data?.msg || "Network error",
                placement: "bottomRight",
              });
            }
          })
          .catch((error) => {
            api.error({
              message: `Task  Deleted failed`,
              description: error?.response?.data?.msg || "Sever error (or) Network error",
              placement: "bottomRight",
            });
          });
      },
      onCancel() {},
    });
  };


  return (
    <>
      <div className='text-[#344767]  relative dropdown inline-block z-50'>
        {contextHolder}
        <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
          <span className='pt-1 z-10'>
            <More/>
          </span>
        </div>
        <ul className='dropdown-menuOne bg-red-400  text-[#344767] text-left  rounded-lg absolute -left-[100px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
        <li className=' w-[100px] bg-black block m-0'>

             
            <Link
              className='bg-white text-black hover:text-black hover:bg-gray-100 py-2 px-2 z-10 block whitespace-no-wrap text-xs font-medium' 
             to={`/tasks/view/${id}`} state={{projectId:projectId}}>View</Link>
          
          </li>
          <li className=' w-[100px] bg-black block m-0'>

            <Link 
              className='bg-white text-black hover:text-black hover:bg-gray-100 py-2 px-2 z-10 block whitespace-no-wrap text-xs font-medium' 
            to={`/tasks/edit/${id}`} state={{data:data,projectId:projectId}}>Edit</Link>
            
          </li>
          {/* <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white text-black hover:text-black hover:bg-gray-100 py-2 px-2 z-10 block whitespace-no-wrap text-xs font-medium'
              onClick={() => setOpenImport(true)}
            >
              <Link className=' text-black hover:text-black' to={`#`}>Import Subtask</Link>
            </span>
          </li> */}
          <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 py-2 px-2 z-10 block whitespace-no-wrap text-xs font-medium'
              onClick={() => setOpenReminder(true)}
            >
              <Link className=' text-black hover:text-black' to={`#`}>Set Reminder</Link>
            </span>
          </li>
          <li className=''>
            <a
              className='bg-white text-black hover:text-black hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              to='#'
              onClick={() => {
                handleDelete(id,data?.taskName);
              }}
            >
              Delete
            </a>
          </li>
        </ul>
      </div>
      {/* <CreateMember data={data} isOpen={showSideModal} handleClose={handleClose} refreshData={fetcher} /> */}
      {/* <CreateClients
        open={open}
        // onCreate={onCreate}
        getData={getClients}
        data={data}
        onCancel={() => {
          setOpen(false);
        }}
      /> */}
      {/* <ClientView data={data} openModel={openModel} closeModel={()=>setopenModel(false)} /> */}
      <ImportSubTask  data={id}
open={openImport} onCancel={() => { setOpenImport(false) }} getData={getClients}
firstProject={projectId} />
      <SetReminderTask  data={data}
open={openReminder} onCancel={() => { setOpenReminder(false) }} getData={getClients}
firstProject={projectId} />
    </>
  );
};

export default ViewMoreTask;
