// import { faGear,faQrcode,faUserInjured,faFile,faUserPen,faUserGroup,faHandshake} from "@fortawesome/free-solid-svg-icons"

import Dashboard from "../../Components/Icons/Dasboard"
import Client from "../Icons/Client";
import Members from "../Icons/Member";
import Notes from "../Icons/Notes";
import Project from "../Icons/Project";
import Settings from "../Icons/Settings";
import Task from "../Icons/Task";
import Team from "../Icons/Team";
import Vendor from "../Icons/Vendor";
import SubCon from "../Icons/SubCon";
import PlanTask from "../Icons/PlanTask";
import Report from "../Icons/Report";
import Chat from "../Icons/Chat";


const userAccess = JSON.parse(localStorage.getItem("userAccess"))||[];
let ProjectView = userAccess?userAccess.some((permission)=>permission.permissionName == "Project_view" ):false;
let TaskView = userAccess?userAccess.some((permission)=>permission.permissionName == "MyTask_view" )||userAccess.some((permission)=>permission.permissionName == "AllTask_view" ):false;
let MembersView = userAccess?userAccess.some((permission)=>permission.permissionName == "Member_view" ):false;
let TeamsView = userAccess?userAccess.some((permission)=>permission.permissionName == "Team_view" ):false;
let ClientView = userAccess?userAccess.some((permission)=>permission.permissionName == "Client_view" ):false;
let SettingView = userAccess?userAccess.some((permission)=>permission.permissionName == "Settings_view" ):false;
let NotesView =userAccess?userAccess.some((permission)=>permission.permissionName == "Notes_view" ):false;
let VendorView =userAccess?userAccess.some((permission)=>permission.permissionName == "Vendor_view" ):false;
let SubContractView =userAccess?userAccess.some((permission)=>permission.permissionName == "Subcontract_view" ):false;
// let NotesView = userAccess?userAccess.some((permission)=>permission.permissionName == "Note_view" ):true;


// function Dasboard () {
//     // console.log("m",x);
//     return (
//         <Dashboard />
//     )
// }

export const NavItems=[
    {
        /*dashboard*/
        path:"/main/dashboard",
        icon: <><Dashboard /></>,
        title:'Dashboard',
        access:true

    },
    {
        path:"/main/projects",
        icon:<><Project /></>,
        title:'Projects',
        access:true

    },
    {
        path:"/tasks-plan",
        icon:<><PlanTask /></>,
        title:'Plan Tasks ',
        access:TaskView
        

    },
    {
        path:"/tasks",
        icon:<><Task /></>,
        title:'Tasks',
        access:TaskView
    },
    {
        path:"/tasks-schedule",
        icon:<><PlanTask /></>,
        title:'Task Schedule',
        access:TaskView
        
    },
    {
        path:"/main/mainNotes",
        icon:<><Notes/></>,
        title:'My Diary',
        access:true
    },
    {
        path:"/members",
        icon:<><Members/></>,
        title:'Members',
        access:true
    },
    {
        path:"/teams",
        icon:<><Team/></>,
        title:'Teams',
        access:TeamsView
    },
    {
        path:"/clients",
        icon:<><Client /></>,
        title:'Clients',
        access:ClientView
        
    },
    {
        path:"/vendor",
        icon:<><Vendor /></>,
        title:'Vendors',
        access:VendorView
        
    },
    {
        path:"/sub-con",
        icon:<><SubCon /></>,
        title:'Sub-Con',
        access:SubContractView
        
    },
    {
        path:"/main/reports",
        icon:<><Report/></>,
        title:'Reports',
        access:true 
        
    },
    {
        path:"/chat/view",
        icon:<><Chat/></>,
        title:'Chat',
        access:SettingView        
    },
    {
        path:"/settings",
        icon:<><Settings/></>,
        title:'Settings',
        access:SettingView 
        
    },
    
];