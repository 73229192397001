import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import axiosInstance from '../../../../Services/axiosCommon';
import NuAmount from '../../../../Components/Utils/NuAmount';
import NuDate from '../../../../Components/Utils/NuDate';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CancelButton, CreateButton, SubmitButton } from '../../../../Components/Utils/Buttons';
// import DataTable from '../../../../Components/Layout/DataTable';
import BranchViewMore from './ViewMore';
// import UseGetData from '../../../../Components/Hooks/useFetchData';
import { currencies, formattedBloodGroups } from '../../../../assets/CountryMini';
import * as yup from 'yup'
import Pagination from '../../../../Components/Utils/Pagenation';
import { Modal } from 'antd';
import { NuInputText, NuSelect, NuSelectwithSearch } from '../../../../Components/Utils/NuInput';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
// import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import DataTables from '../../../../Components/Utils/DataTable';


function BranchIndex() {
  const { value: Designation } = useFetchMataData('Designation') 
  const { value: Status } = useFetchMataData('Status')
  
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState(20)
  const [data,setData] = useState([]);
  const [totalCount,setTotalCount] = useState([]);
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  
  function getBranch() {
    axiosInstance
      .get(`/company_branch/?page=${page}&per_page=${pageCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalCount(res.data?.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getBranch();
   },[])
 
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };

      const[filterEmployees,setfilterEmployees]=useState({
        gender:"",
        bloodGroup:"",
        religion:"",
        designation:"",
        status:"",
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterEmployees,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, gender: value?.gender, bloodGroup: value?.bloodGroup,religion: value?.religion,designation: value?.designation,status: value?.status,})
          closeFilterDialog();
          setFilter(true)
          setfilterEmployees({
            gender: "",
            bloodGroup: "",
            religion: "",
            designation: "",
            status: "",
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          gender: "",
          bloodGroup: "",
          religion: "",
          designation: "",
          status: "",
       
        })
        formik.values.gender=""
        formik.values.bloodGroup=""
        formik.values.religion=""
        formik.values.designation=""
        formik.values.status=""
          setFilter(false)
      }

  let AllUserCreateAccess = true;//userAccess

    let headers = [
        {
          label: <span>ID</span>,
          width: "w-[5%]",
        },
        {
          label: <span>Branch Name</span>,
          width: "w-[15%]",
        },
        {
          label: <span>Mobile</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Email</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Website</span>,
          width: "w-[15%]",
        },
        {
          label: <span>Address</span>,
          width: "w-[17%]",
        },
        {
          label: <span>Currency</span>,
          width: "w-[13%]",
        },
        {
          label: <span>Actions</span>,
          width: "w-[5%]",
        },
      ];

      useEffect(()=>{
        setParams({...params,page:page,per_page:pageCount})
       },[page,pageCount])
     
  return (
    <div className='w-full h-full px-4 py-2'>
    <div className='w-full h-[46px]  flex justify-between items-center'>
    <BreadCrumb items={[
      {title:(<Link to="/main/dashboard">
      <Dashboard color="gray" width={10} height={10} />{" "}
    </Link>)},
      {title:(<p className=' text-[12px] '>Settings</p>)},
      {title:(<p className=' text-[12px] '>Branchs</p>)}
      ]} />
    <div className=' flex gap-2'>
    <SearchBar 
          handleChange={(e) =>
            setParams({ ...params, search: e.target.value })
          }
          />
          <CreateButton label='Filter'
          handleClick={() => setShowFilterDialog(true)}/>
           {filter && ( 
             <CreateButton
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
          <CreateButton label='Import' />
          <Link to={`/settings/branch/create`}><CreateButton label='Branch' /></Link>
    </div>
    </div>

    <DataTables
     bodyHeight='max-h-[calc(100vh-260px)]'
     height='h-[63vh]'
     headers={headers} 
     total={totalCount}
     pageIndex={page}
     setPageIndex={setPage}
     dataCount={pageCount}
     setDataCount={setPageCount}
    >
    {data.length>0?data.map((item, i) => {
            let getCurrency;
            currencies.find((i) => {
              if(i.value == item?.currency){
                getCurrency=i.label;
              }
            })
            return (
              <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                <td className="w-[5%] p-2 px-[1px] text-center ">{item?.branchId||"#"}</td>
                <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/settings/branch/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.mobile?.display ||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.email||""}</td>
                <td className="w-[15%] p-2 px-[1px] text-center ">{item?.website||""}</td>
                <td className="w-[17%] p-2 px-[1px] text-center">{item?.address||""}</td>
                <td className="w-[13%] p-2 px-[1px] text-center">{getCurrency ||""}</td>
                <td className="w-[5%] p-2 px-[1px] text-center ">
                    <BranchViewMore data={item} fetcher={getBranch} id={item?.branchId}/>
                </td>
              </tr>
            );
          })
          :
          <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
        }
    </DataTables>
    {/* <div className='w-full h-10'>
            <Pagination
             pageIndex={page}
             setPageIndex={setPage}
             dataCount={pageCount}
             setDataCount={setPageCount}
             total={totalCount}
            />
            </div> */}

      <Modal
        open={showFilterDialog}
        title={"Filter Employees"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="">
                    
                    <NuInputText label='Gender' placeholder='M/F/T' formik={formik} name='gender' width='w-full ' />
                    <NuSelectwithSearch
                                    options={formattedBloodGroups}
                                    label={'Blood Group'}
                                    placeholder={'AB-'}
                                    formik={formik}
                                    name={'bloodGroup'}
                                    width={'w-full'}
                                />
                    <NuInputText label='Religion' placeholder='Religion' formik={formik} name='religion' width='w-full' />
                                  <NuSelect
                                    label='Desigination'
                                    placeholder='choose'
                                    formik={formik}
                                    options={Designation}
                                    name='designation'
                                    width='w-full' />
                                    <NuSelect
                                    label={'Status'}
                                    options={Status}
                                    placeholder={'Choose'}
                                    name={'status'}
                                    formik={formik}
                                    width={'w-full'}
                                />
                    </div>
                    
                   <div className="flex justify-end gap-2 mt-5">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
  </div>
  )
}

export default BranchIndex;