import React, { useEffect, useState } from "react";
import TimesheetLayout from "../TimesheetLayout";
import { useNumber } from "../../../../../../Context/ProjectIdContext";
import { useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../../../../../Services/axiosCommon";
import { Button, Modal, Select, notification } from "antd";
import TableData from "./TableData";
import { CreateButton } from "../../../../../../Components/Utils/Buttons";
import { NuInputNumber, NuSelect, NuTextArea } from "../../../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import NuDate from "../../../../../../Components/Utils/NuDate";
import { GetAccess } from "../../../../../../Components/Utils/roles";

function AttendanceIndex() {
  const { officeId } = useParams();
  const { projectId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  // const { number, updateNumber } = useNumber();
  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  const [isChecked, setIsChecked] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [allList,setAllList] = useState([]);
  const [openAll, setOpenAll] = useState(false);
  const [createNew,setCreateNew] = useState({
    users: [],
    date: "",
    working_hours: 0,
    status: "",
    over_time: 0
  });
  const [attendenceData,setAttendenceData] = useState([]);
  const [userId,setUserId] = useState([]);
  // console.log("user",userId);
  // async function getProject() {
  //   await axiosInstance
  //     .get(`/Team/getUserProjectId`)
  //     .then(async (res) => {
  //       if (number != 0) {
  //         setFirstProject(number);
  //       } else if (projectTask) {
  //         console.log("DD", projectTask);
  //         setFirstProject(projectTask);
  //         updateNumber(projectTask);
  //       } else {
  //         res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId);
  //         updateNumber(res?.data?.data[0]?.ProjectId);
  //         console.log("dd", res?.data?.data[0]?.ProjectId);
  //       }
  //       let list = res?.data?.data || [];
  //       let finalData = [];
  //       (await res?.data?.data) &&
  //         list.map((item, idx) => {
  //           finalData.push({
  //             value: item.ProjectId,
  //             label: item.ProjectName,
  //           });
  //         });
  //       setProjectList(finalData);

  //       // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
  //       // console.log('====',res?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const currentDate = ""
  // getCurrentDate();

  async function getMembersByProject(projectId) {
    console.log("fgfd",projectId);
    const currentDate = NuDate({value:new Date(), format:"YYYY-MM-DD"});
    await axiosInstance
      .get(`/Attendance/get-attendance-by-project-members?projectId=${projectId}&date=${currentDate}`)
      .then(async (res) => {
        console.log("finding",res?.data?.data);
        res?.data?.data && setAttendenceData(res?.data?.data);
        let list = res?.data?.data || [];
        let finalData = [];
        (await res?.data?.data) &&
          list.map((item, idx) => {
            finalData.push(item.userId);
          });
        setUserId(finalData);
      }
      ).catch((error)=>{
        console.log(error);
      })
    }

  // function handleProjectChange(e) {
  //   setFirstProject(e);
  //   updateNumber(e);
  // }

  useEffect(() => {
    // if (firstProject) {
      getMembersByProject(projectId);
    // }
  }, []);

  // useEffect(() => {
  //   // console.log("jj",projectNum);
  //   if (projectTask) {
  //     setFirstProject(projectTask);
  //   }
  // }, [projectTask]);

  // useEffect(() => {
  //   getProject();
  // }, []);

  const header = [
    {
      headone: <>
      <input type='checkbox' onChange={selectAll} />
      </>,
      width: "w-[3%]",
    },
    {
      headone: "Name",
      width: "w-[29%]",
      isLeft: true,
    },
    {
      headone: "Role",
      width: "w-[20%]",
      isLeft: true,
    },
    // {
    //   headone: "Division",
    //   width: "w-[15%]",
    //   isLeft: true,
    // },
    {
      headone: "Status",
      width: "w-[14%]",
      isLeft: true,
    },
    {
      headone: "Working Hour",
      width: "w-[17%]",
      isLeft: true,
    },
    {
      headone: "Over Time (Hour)",
      width: "w-[17%]",
      isLeft: true,
    },
    // {
    //   headone: "Action",
    //   width: "w-[5%]",
    //   // isLeft:true,
    // },
  ];

  // let data = [
  //   {
  //     id:1,
  //     name: "Priti Jani",
  //     role: "UI/UX Designer",
  //     division: "Design",
  //     status: "A",
  //     hour: 10.00,
  //     ot: 3.40,
  //   },
  //   {
  //     id:2,
  //     name: "John",
  //     role: "React Developer",
  //     division: "Developer",
  //     status: "P",
  //     hour: 10,
  //     ot: 0,
  //   },
  //   {
  //     id:3,
  //     name: "David",
  //     role: "React Developer",
  //     division: "Developer",
  //     status: "P",
  //     hour: 10,
  //     ot: 0,
  //   },
  //   {
  //     id:4,
  //     name: "Mary",
  //     role: "React Developer",
  //     division: "Developer",
  //     status: "P",
  //     hour: 10,
  //     ot: 2,
  //   },
  // ];


  function selectAll(event){
    if(event.target.checked){
      setAllList(userId)
    }else{
      setAllList([])
    }

  }
  const handleData = ( id) => {
    let testUser;
    if (allList && allList.some((Userid) => Userid === id)) {
      testUser = allList.filter((roleId) => roleId !== id);
      setAllList(testUser);
    } else {
      setAllList([...allList, id]);
    }
    console.log('id',allList);

  };
  console.log("ccc",allList);


  const formikAll = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (value, { resetForm }) => {
      const currentDate = NuDate({value:new Date(), format:"YYYY-MM-DD"});
      const allValues = {
        ...value,
        date: currentDate,
        users: allList
      }
      axiosInstance
      .put(`/Attendance/multi-edit/${firstProject}`, allValues)
      .then((res) => {
        if (res.status == 201 || res.data.status == true) {
          api.success({
            message: `Add successfully`,
            description: res?.data?.msg || " created",
            placement: "bottomRight",
          });
          setOpenAll(false)
          resetForm();
          getMembersByProject(firstProject);
          setAllList([]);
        } else {
          setIsLoading(false);
          api.error({
            message: `Unsuccessfully`,
            description: res?.data?.msg || "creation failed",
            placement: "bottomRight",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
  });

  return (
    <>
      <TimesheetLayout CreateAccess={GetAccess()} ImportAccess={GetAccess()} officeId={officeId} projectId={projectId}>
        <div className="md:flex gap-3 w-full">
          {/* <div className=" w-full md:w-3/12 flex gap-2 items-center mb-3">
            <div className="hidden md:block font-medium text-xs">Project </div>
            <Select
              style={{ width: "100%" }}
              options={projectList}
              value={projectList.find((i) => i.value == firstProject) || ""}
              disabled={projectList.length >= 2 ? false : true}
              // allowClear={formik.values[name]?true:false}
              // name={name}
              // placeholder={placeholder}
              onChange={handleProjectChange}
            />
          </div> */}
          {
            allList.length > 0 && (<div className=" w-full my-1"><CreateButton svg={false} label="Attendance" handleClick={()=>setOpenAll(true)} /></div>)
          }
        </div>

        <div className=" w-full h-[calc(100vh-200px)] pb-9 overflow-y-auto">
          <table
            className="table-auto sticky top-0 z-[99] w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <thead className=" w-full bg-[#2A475E]">
              <tr className="text-xs  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-2 ${header.width} font-normal ${
                      header?.isLeft ? "text-left" : "text-center"
                    } text-xs text-white`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <table
            className="table-auto w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <tbody className="">
              {
                attendenceData && attendenceData.map((item, idx) =>{
                  return(
                    <TableData item={item} allList={allList} key={idx} handleData={handleData} projectId={firstProject} refreshData={getMembersByProject} currentDate={currentDate}/>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </TimesheetLayout>
      
      <Modal
        open={openAll}
        title={"Update All"}
        width={"20%"}
        className="my-modal"
        centered
        onCancel={()=>setOpenAll(false)}
        footer={[]}
      >
        <form onSubmit={formikAll.handleSubmit}>
          <div className=" w-full flex flex-wrap">

            <NuSelect
              label="Status"
              options={
                [
                  {value: 1,label:"Present"}, 
                  {value: 2,label:"Absent"}, 
                ]
              }
              formik={formikAll}
              placeholder="Choose"
              name="status"
              width=" w-full"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
              <NuInputNumber
              label="Working Hour"
              formik={formikAll}
              placeholder="Enter Working Hour"
              name="working_hours"
              width=" w-full"
              max={24}
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <NuInputNumber
              label="Over Time"
              formik={formikAll}
              placeholder="Enter Over Time"
              name="over_time"
              width=" w-full"
              max={24-Number(formikAll.values.working_hours)}
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <NuTextArea
              label="Notes"
              formik={formikAll}
              placeholder="Enter about the"
              name="notes"
              width=" w-full"
              max={24-Number(formikAll.values.notes)}
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE]   mx-auto"
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default AttendanceIndex;
