import React,{lazy} from "react";
import { Route } from "react-router-dom";
import MainPettyCash from "../Views/Web/Project/SubMenu/PettyCash/MainPettyCash";
import MainReceived from "../Views/Web/Project/SubMenu/PettyCash/Received/MainReceived";
import ReceivedIndex from "../Views/Web/Project/SubMenu/PettyCash/Received/ReceivedIndex";
import CreateReceived from "../Views/Web/Project/SubMenu/PettyCash/Received/CreateReceived";
import EditReceived from "../Views/Web/Project/SubMenu/PettyCash/Received/EditReceived";
import ViewReceived from "../Views/Web/Project/SubMenu/PettyCash/Received/ViewReceived";
import MainIssued from "../Views/Web/Project/SubMenu/PettyCash/Issued/MainIssued";
import IssuedIndex from "../Views/Web/Project/SubMenu/PettyCash/Issued/IssuedIndex";
import CreateIssued from "../Views/Web/Project/SubMenu/PettyCash/Issued/CreateIssued";
import EditIssued from "../Views/Web/Project/SubMenu/PettyCash/Issued/EditIssued";
import ViewIssued from "../Views/Web/Project/SubMenu/PettyCash/Issued/ViewIssued";



function ProjectPettyCashRoutes() {
    return (
      <Route path="petty-cash" element={<MainPettyCash />}>
        <Route path="received/:officeId/:projectId" element={<MainReceived />}>
          <Route index element={<ReceivedIndex />} />
          <Route path="create" element={<CreateReceived />} />
          <Route path="edit/:receivedEditId" element={<EditReceived />} />
          <Route path="view/:receivedViewId" element={<ViewReceived />} />
        </Route>
        <Route path="issued/:officeId/:projectId" element={<MainIssued />}>
          <Route index element={<IssuedIndex />} />
          <Route path="create" element={<CreateIssued />} />
          <Route path="edit/:issuedEditId" element={<EditIssued />} />
          <Route path="view/:issuedViewId" element={<ViewIssued />} />
        </Route>
      </Route>
    );
  }
  
  export default ProjectPettyCashRoutes;