import React, { lazy } from 'react'
import { Suspense } from 'react';
import { Route } from 'react-router-dom'

const User = lazy(()=>import('../Views/Web/User/index'));
const UserProfile  = lazy(()=>import('../Views/Web/User/userProfile'))
const EditProfile  = lazy(()=>import('../Views/Web/User/editProfile'))
const ChangePassword  = lazy(()=>import('../Views/Web/User/changePassword'))
const ProfileNotificationSettings = lazy(()=>import('../Views/Web/User/notication'))

function UserDetailsRoutes() {
  return (
    <Route path="user-details" element={
     <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
      <User />
     </Suspense>
    }>
    <Route index element={
     <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
      <UserProfile />
     </Suspense>
      } />
    <Route path="edit" element={
     <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
      <EditProfile />
     </Suspense>
     } />
    <Route path="password-change" element={
     <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
      <ChangePassword />
     </Suspense>
    } />
    <Route path="notifications" element={
     <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
      <ProfileNotificationSettings/>
     </Suspense>
      } />
    </Route>
  )
}

export default UserDetailsRoutes