import { useEffect } from "react";
import { useState } from "react";
import axios from "../../../../Services/axiosCommon";
import ViewMore from "./viewMore";
import { CreateButton } from "../../../../Components/Utils/Buttons";
import { Link } from "react-router-dom";

const RoleSetting = () => {
  const [data, setData] = useState([]);

  const getData = () => {
    axios
      .get(`/Role/`)
      .then((res) => {
        console.log(res.data);
        res?.data?.data && setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="">
      <div className="w-full my-1 flex   justify-end gap-3 items-center">
        <Link to="/settings/roles/create">
          <CreateButton />
        </Link>
      </div>
      <div className=" py-1 h-[75vh] overflow-hidden pb-8">
        <div className={`flex flex-wrap  gap-4 overflow-y-scroll ${data && data.length >10 ?"h-full" : "h-auto "}`} >
          {data &&
            data.map((item, index) => {
              return (
                <div
                  key={index}
                  className=" bg-white w-[23%] shadow-lg rounded-md my-2 h-32"
                >
                  <div className=" flex bg-[#2A475E] text-xs rounded-t-md px-3 py-1 justify-between items-center">
                    <p className=" text-white font-semibold">
                      {item.roleName || "-"}
                    </p>
                    <ViewMore data={item} id={item?.roleId} getMember={getData} />
                  </div>
                  <div className=" mb-3"></div>
                  <div></div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default RoleSetting;
