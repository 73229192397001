



export function ActivatePill (){
    return(
        <div className="bg-[#007BEC] rounded-md flex items-center px-[6px] py-[2px]">
        <p className="w-[10px] h-[10px] bg-white rounded-full"></p>
        <p className="text-white pl-2 text-[12px]">Activate</p>
      </div>
    )
}

export function ProgressPill (){
    return(
        <div className="bg-[#007BEC] rounded-md flex items-center px-[6px] py-[2px]">
        <p className="w-[10px] h-[10px] bg-white rounded-full"></p>
        <p className="text-white pl-2 text-[12px]">In Progress</p>
      </div>
    )
}


export function CompletePill (){
    return(
        <div className="bg-[#00CC5E] rounded-md flex items-center px-[6px] py-[2px]">
        <p className="w-[10px] h-[10px] bg-white rounded-full"></p>
        <p className="text-white pl-2 text-[12px]">Completed</p>
      </div>
    )
}

export function FinishPill (){
    return(
        <div className="bg-[#00CC5E] rounded-md flex items-center px-[6px] py-[2px]">
        <p className="w-[10px] h-[10px] bg-white rounded-full"></p>
        <p className="text-white pl-2 text-[12px]">Finished</p>
      </div>
    )
}

export function HoldPill (){
    return(
        <div className="bg-[#F10101] rounded-md flex items-center px-[6px] py-[2px]">
        <p className="w-[10px] h-[10px] bg-white rounded-full"></p>
        <p className="text-white pl-2 text-[12px]">On Hold</p>
      </div>
    )
}


export function CancelPill (){
    return(
        <div className="bg-[#FF6700] rounded-md flex items-center px-[6px] py-[2px]">
        <p className="w-[10px] h-[10px] bg-white rounded-full"></p>
        <p className="text-white pl-2 text-[12px]">Canceled</p>
      </div>
    )
}

export function RejectedPill (){
    return(
        <div className="bg-[#F10101] rounded-md flex items-center px-[6px] py-[2px]">
        <p className="w-[10px] h-[10px] bg-white rounded-full"></p>
        <p className="text-white pl-2 text-[12px]">Rejected</p>
      </div>
    )
}


export function StartPill ({text}){
  return(
      <div className=" bg-[#F7DC05] rounded-md flex items-center px-[6px] py-[2px]">
      <p className="w-[10px] h-[10px] bg-white rounded-full"></p>
      <p className="text-white pl-2 text-[12px]">Yet to Start</p>
    </div>
  )
}


export function OtherPill ({text}){
    return(
        <div className=" bg-violet-300 rounded-md flex items-center px-[6px] py-[2px]">
        <p className="w-[10px] h-[10px] bg-white rounded-full"></p>
        <p className="text-white pl-2 text-[12px]">{text}</p>
      </div>
    )
}