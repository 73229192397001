import React from 'react'

function ViewTCItems({ item, data, open, onCancel }) {
    const displayItem = item || data;
  return (
    <div className="w-full flex flex-col flex-wrap gap-5">
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Item Code</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.item_code || "-"}</p>
      </div>
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Description</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.description || "-"}</p>
      </div>
    </div>
  )
}

export default ViewTCItems