import React, { useEffect, useState } from "react";
import axios from "../../../../../../Services/axiosCommon";
import { Button, Modal } from "antd";
import { useFormik } from "formik";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import Pagination from "../../../../../../Components/Utils/Pagenation";
import ViewMore from "./ViewMore";
import NuDate from "../../../../../../Components/Utils/NuDate";
import NuAmount from "../../../../../../Components/Utils/NuAmount";
import PettyCashLayout from "../PettyCashLayout";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import { NuDatePicker } from "../../../../../../Components/Utils/NuInput";
import { GetAccess } from "../../../../../../Components/Utils/roles";

export default function ReceivedIndex(id) {
  const { officeId } = useParams();
  const { projectId } = useParams();
  const {value} = useFetchMataData(`/Metadata/noteStatus`)
  const [data, setData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [notFirst, setNotFirst] = useState(false);
  const [filterData, setFilterData] = useState({
    dateFrom:"",
    dateTo:"",
  });
  const [afterFilter, setAfterFilter] = useState({});

  async function getNotes() {
    await axios
      .get(`/petty_cash/${projectId}/detail?page=${pageIndex}&per_page=${dataCount}`)
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data?.pettyCashDetails);
        setTotalRows(res.data?.data?.totalRecords);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }


  const [open, setOpen] = useState(false);
  useEffect(() => {
    getNotes();
  }, []);

  function SearchData(e) {
    setNotFirst(true)
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(`/petty_cash/?page=${pageIndex}&per_page=${dataCount}`,
        { params: { search: `${searchQuery}` } })
        .then((res) => {
          res.data?.data && setData(res.data?.data?.pettyCashDetails);
        })
        .catch((err) => console.log(err));
    } else {
      getNotes();
    }
  }

  function SearchPageDate() {
    axios
      .get(
        `/petty_cash/?page=${pageIndex}&per_page=${dataCount}`,
        { params: { search: `${searchQuery}` } }
      )
      .then((res) => {
        setData(res.data?.data?.pettyCashDetails);
        setTotalRows(res.data?.data?.totalRecords);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (searchQuery == "") {
      getNotes();
    }
  }, [searchQuery]);


  useEffect(() => {
      getNotes();
  }, [pageIndex,dataCount]);

  const userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let NotesCreateAccess = userAccess
    ? userAccess.some(
        (permission) => permission.permissionName == "Notes_create"
      )
    : false;

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterData,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // mobileNo: yup
        //   .string()
        //   // .required("Mobile Number is required")
        //   .matches(
        //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        //     "Enter Valid Mobile Number"
        //   )
        //   .min(10, "Invalid Mobile Number")
        //   .max(10, "Invalid Mobile Number"),
        // email: yup.string().required("Email is required"),
      }),
      onSubmit: async (value, { resetForm }) => {
        setAfterFilter(value);
        setIsFilter(true);
        axios
          .get(
            `/petty_cash/?page=${pageIndex}&per_page=${dataCount}`,
            {
              params: {
                // status: `${value.status}`,
                // SDF: `${value.SDF}`,
                // EDT: `${value.EDT}`,
                // priority: `${value.priority}`,
                dateFrom: `${value.dateFrom}`,
                dateTo: `${value.dateTo}`,
              },
            }
          )
          .then((res) => {
            setFilterOpen(false);
            setData(res?.data?.data?.pettyCashDetails);
            setTotalRows(res.data?.data?.totalRecords);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });
  
    function FilterPage() {
      axios
        .get(
          `/petty_cash/?page=${pageIndex}&per_page=${dataCount}`,
          {
            params: {
              // status: `${afterFilter.status}`,
              // SDF: `${afterFilter.SDF}`,
              dateFrom: `${afterFilter.dateFrom}`,
              dateTo: `${afterFilter.dateTo}`,
              // EDT: `${afterFilter.EDT}`,
              // priority: `${afterFilter.priority}`,
            },
          }
        )
        .then((res) => {
          setData(res?.data?.data?.pettyCashDetails);
          setTotalRows(res.data?.data?.totalRecords);
        })
        .catch((err) => console.log(err));
    }
  
    function ClearFilter() {
      formik.resetForm({});
      getNotes();
      setIsFilter(false);
    }
  
    useEffect(() => {
      if (isFilter) {
        FilterPage();
      } else if (searchQuery != "") {
        SearchPageDate();
      } else {
        getNotes();
      }
    }, [pageIndex, dataCount]);

  return (
    <>
       <PettyCashLayout       
       setOpen={setOpen}
       setSearchQuery={setSearchQuery}
       SearchData={SearchData}
       isFilter={isFilter}
       setFilterOpen={setFilterOpen}
       ClearFilter={ClearFilter}
       CreateAccess={GetAccess()}
       ImportAccess={GetAccess()}
       officeId={officeId}
       projectId={projectId}
       >

<div className=" w-full h-[calc(100vh-180px)] overflow-y-auto">
            <table
              className="table-auto w-full"
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <thead className=" w-full bg-[#2A475E]">
                <tr className="text-xs  font-medium  py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-2 ${header.width} px-1 font-normal ${header?.isLeft?"text-left":"text-center"} text-xs text-white`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead> 
              <tbody className="">
                {data && data.map((item, i) => (
                                   <tr className=" w-full " key={i}>
                                   <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">{item?.serial_number||item?.pettyCashId||"#"}</td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                   <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/petty-cash/received/view/${id}`} state={{data:item}}> {item?.projectName||"-"}</Link>
                                   </td>
                                   {/* <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">{item?.category||"-"}</td> */}
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">{item?.requestedBy||"-"}</td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">{item?.date !="" && item?.date !="None" && <NuDate value={item?.date} format="DD-MMM-YYYY" /> ||"-"}</td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                   <NuAmount value={item?.requestedAmount||0} />
                                   
                                    {/* {item?.amount||"0.0"} */}
                                   </td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                   <NuAmount value={item?.approvedAmount||0} />

                                    {/* {item?.receivedAmount||"0.0"} */}
                                   </td>
                                   <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                   <NuAmount value={item?.balanceAmount||0} />

                                    {/* {`${item?.amount - item?.receivedAmount}`||"0.0"} */}
                                    </td>
                                   <td className="py-2 px-1  text-xs text-left truncate font-normal text-mainColor-400">{item?.purpose||"-"}</td>
                                   <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">
                                     <div className=" w-full flex justify-center items-center">
                                     <ViewMore
                                       id={item?.pettyCashId}
                                       getData={getNotes}
                                       data={item}
                                      />
                                     </div>
                                   </td>
                                 </tr>
                ))}
              </tbody>
            </table>
          </div>
      
      <div className=" h-6 my-2">
        <Pagination
          total={totalRows}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>

       </PettyCashLayout>

       <Modal
        open={filterOpen}
        title={"Filter My Tasks"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <NuSelect
              label="Priority"
              options={priority}
              formik={formik}
              placeholder="Choose"
              name="Priority"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />*/}
            <NuDatePicker
              label=" Date From"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="dateFrom"
              width=" w-1/2"
            />
            <NuDatePicker
              label=" Date To"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="dateTo"
              width=" w-1/2"
            /> 
            {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="filterStatus"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> */}
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter
            </Button>
          </div>
        </form>
      </Modal>

    </>
  );
}

const header = [
  {
    headone: "ID",
    width: "w-[3%]",
    // isLeft: true,
  }, 
  {
    headone: "Project",
    width: "w-[20%]",
    isLeft: true,
  },
  {
    headone: "Requeste By",
    width: "w-[10%]",
    isLeft: true,
  },
  // {
  //   headone: "Cost Per Hour",
  //   width: "w-[8%]",
  // },
  // {
  //   headone: "Invoice #",
  //   width: "w-[8%]",
  //   isLeft: true,

  // },
  {
    headone: "Date",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Requested Amount",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Approved Amount",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Balance Amount",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Purpose",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];