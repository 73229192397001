import { Modal, notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { NuInputText} from '../../../../Components/Utils/NuInput';
import axios from '../../../../Services/axiosCommon';
import { CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';




function EditRole({ open, getData, onCancel, data,id }) {
  const [api, contextHolder] = notification.useNotification();
  const [createNewRole,setCreateNewRole] = useState({
    roleName:"",
  });

  console.log("dd",data.roleName,id);

  useEffect(()=>{
    data&& setCreateNewRole({
      roleName:data?.roleName
    })
  },[data])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNewRole,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   name: yup
    //     .string()
    //     .required("Name Required")
    //     .matches(/^(?![0-9]*$)[a-zA-Z]+$/, "Enter Valid Name"),
    //   dob: yup
    //     .string()
    //     .max(new Date(Date.now() - 567648000000), "Must be at least 18 years")
    //     .required("Date of Birth Required"),
    //   gender: yup.string().required("Gender is Required"),
    //   city: yup.string().matches(/^[A-Za-z]+$/, "Enter Valid City Name"),
    //   state: yup.string().required("Place Select State"),
    //   pincode: yup
    //     .string()
    //     .required("Pincode is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Pincode"
    //     )
    //     .min(6, "Invalid Pincode")
    //     .max(6, "Invalid Pincode"),
    //   address: yup
    //     .string()
    //     .matches(
    //       /^(?![0-9]*$)(?![0-9\s]*$)[a-zA-Z0-9'\.\-\s\,\/\(\)\#]+$/,
    //       "Enter Valid Address"
    //     ),

    //   mobileNo: yup
    //     .string()
    //     .required("Mobile Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(10, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    //   joinDate: yup.string().required("Join Date is required"),
    // }),
    onSubmit: async (value,{resetForm}) => {
        axios
        .put(`/Role/${id}`, value)
        .then((response) => {
            console.log(response.data.status);
          if (response.status === 201 || response.data.status == true) {
            resetForm();
            getData();
            api.success({
              message: `User Role  Update successfully`,
              description:response?.data?.msg ||'',
              placement: 'bottomRight',
            });
            onCancel();
          } else {
            api.error({
              message: `User Role Update failed`,
              description: response?.data?.msg || 'Network error',
              placement: 'bottomRight',
            });
          }
        })
        .catch((error) => {
          api.error({
            message: `User Role Update failed`,
            description: error?.response?.data?.msg || 'Sever error (or) Network error',
            placement: 'bottomRight',
          });
        });
    },
  });





  return (
    <Modal
      open={open}
      title={data ? 'Edit Role Name' : 'Create Role Name'}
      width={'40%'}
      centered
      onCancel={onCancel}
      footer={[]}
    >
        {contextHolder}
        <form onSubmit={formik.handleSubmit}>
        <NuInputText label='First Name' placeholder='First Name' formik={formik} name="roleName" width=' w-full' />

            <div className=" w-full mt-4 mb-3 flex gap-3 justify-end items-center">
              <CancelButton
                handleClick={() => {
                  onCancel()
                }}
              />
              <SubmitButton />
            </div>
        </form>
    </Modal>
  );
}

export default EditRole;
