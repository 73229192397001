import { Outlet } from "react-router-dom"
const RoleMain = () =>{

    return(
        <div className=" w-full h-full  overflow-y-scroll">
         <Outlet/>
        </div>
    )
}


export default RoleMain;