import React, { useEffect, useState } from 'react'
import BidLayout from '../../BidLayout'
import axiosInstance from '../../../../../Services/axiosCommon';
import { useParams } from 'react-router-dom';
import ViewAttachments from './ViewAttachments';
import { Modal, notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import CreateAttachments from './CreateAttachments';


function AttachmentsIndex() {
  const { estmationId } = useParams();
  const [data,setData] = useState([]);
  const [listOfItem,setListOfItem] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);


  function getData(){
    axiosInstance.get(`/estimation/attachments/${estmationId}`).then(res=>{
      if(res.data.status==true){
        setData(res.data?.data||[]);
      }else{
        console.log(res.data.error||res.data.msg);
      }
    }).catch(err=>{
      console.log(err);
      
    })
  }

  function handleList(value){
    console.log(value);
    console.log("ssssss");
    
    
    if(listOfItem.includes(value)){
      let newValue = listOfItem.filter(item=> item !== value)
      setListOfItem(newValue)
      console.log("part one");
      
    }else{
      setListOfItem([
        ...listOfItem,
        value
      ])
      console.log("part two");
      console.log(listOfItem);
      
    }
  }

  useEffect(()=>{
    getData();
  },[])

  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();


  const handleDelete = async(id) =>{
    confirm({
        title: `Do you Want to delete Attachments Items?`,
        centered:true,
        width:"25%",
        icon: <ExclamationCircleFilled />,
        content: `The BOQ Items will be removed from the BOQ`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.put(`/estimation/attachments`, {
            estmationId: Number(id),
            filesToRemove:listOfItem
          }).then((res) => {
            if(res?.data.status==true) {
              getData();
                api.success({
                  message: `Attachment Multi Item has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `Attachment Multi Item has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `Attachment Multi Item has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}

  return (
    <>
    <BidLayout
     onCreateClick={()=>{setCreateModalOpen(true)}}
    >
      {contextHolder}
      {
        listOfItem.length > 0 && (
          <button
          type='button'
          className="px-3 h-8 cursor-pointer text-xs text-red-500 bg-white rounded-md flex items-center"
          style={{ border: "1px solid red" }}
          onClick={() => {handleDelete(estmationId)}}
        >
          Multi Delete
        </button>
        )
      }
              <div className=" w-full h-[calc(100vh-150px)]">
        <table
            className="table-auto static top-0 w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
                        <thead className=" w-full bg-[#28A0D9]">
              <tr className="text-xs  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-2 ${header.width} px-1 font-normal ${
                      header?.isLeft ? "text-left" : "text-center"
                    } text-xs text-white`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <div className=" w-full h-[calc(100vh-175px)] overflow-y-auto relative">
          <table
            className="table-auto  w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <tbody>
                {
                    data && data.map((item, i)=>{
                        return (
                            <ViewAttachments 
                              item={item} 
                              key={i}
                              getData={getData}
                              estid={estmationId}
                              titleList={listOfItem}
                              titleSelect={handleList}
                              index={i+1}
                            />
                        )
                    })
                }
            </tbody>
          </table>
          </div>
        </div>
    </BidLayout>
    <CreateAttachments
      open={createModalOpen}
      onCancel={()=>setCreateModalOpen(false)}
      getData={getData}
    />
    </>
  )
}

export default AttachmentsIndex


const header = [
  // {
  //   headone: "ID",
  //   width: "w-[3%]",
  //   // isLeft: true,
  // },
  {
      headone: "Sl.No",
      width: "w-[5%]",
      isLeft: true,
    },
  {
    headone: "Description",
    width: "w-[70%]",
    isLeft: true,
  },
  {
    headone: "Format",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Size",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];