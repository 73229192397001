import React, { useRef } from "react";
import { FileAddOutlined } from "@ant-design/icons";
// import { icon } from "@fortawesome/fontawesome-svg-core";
function CustomAttachmentUpload({ preview, handleChange, width, label,multiple=false }) {
  const fileInputRef = useRef();
  return (
    <div className={`${width} text-xs`}>
      <div className="flex items-center">
        <div
          className=" border-none outline-none mx-3  text-white flex justify-between items-center  cursor-pointer rounded-lg mt-2"
          onClick={() => fileInputRef.current.click()}
        >
          {/* <div className=" w-9 h-7 overflow-hidden bg-slate-300 rounded-md">
            {label?<img src={label} width="100%" alt="#" />:null}
          </div> */}
            {/* <svg   width="12"
            height="15" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
</svg> */}
          <svg
            width="12"
            height="15"
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mt-3 mb-2 mx-3"
          >
            <path
              d="M8.92087 9.46421L8.84249 9.40212L8.78039 9.4805L6.5825 12.2548C5.67329 13.4025 4.00738 13.5956 2.85973 12.6864C1.71208 11.7772 1.51895 10.1113 2.42815 8.96363L7.76469 2.22752C8.03129 1.891 8.42065 1.67417 8.84711 1.62473C9.27358 1.57529 9.70222 1.69729 10.0387 1.96389C10.3753 2.23049 10.5921 2.61985 10.6415 3.04632C10.691 3.47278 10.569 3.90142 10.3024 4.23794L5.81968 9.89627C5.61916 10.1494 5.24744 10.1925 4.99434 9.99195C4.74124 9.79144 4.69815 9.41972 4.89866 9.16662L6.24271 7.47008L6.30481 7.3917L6.22642 7.3296L5.41809 6.68922L5.33971 6.62712L5.27761 6.7055L3.93356 8.40204C3.63403 8.78014 3.49696 9.26173 3.5525 9.74089C3.60805 10.22 3.85167 10.6575 4.22977 10.9571C4.60786 11.2566 5.08946 11.3937 5.56862 11.3381C6.04777 11.2826 6.48524 11.0389 6.78477 10.6609L11.2675 5.00252C12.2453 3.76828 12.0375 1.97659 10.8033 0.99879C9.56908 0.0209951 7.77738 0.228706 6.79959 1.46294L1.46305 8.19905C0.130911 9.88056 0.413639 12.3194 2.09515 13.6515C3.77666 14.9836 6.21545 14.7009 7.5476 13.0194L9.74549 10.2451L9.80759 10.1667L9.7292 10.1046L8.92087 9.46421Z"
              fill="#9DA3AA"
              stroke="#9DA3AA"
              stroke-width="0.2"
            />
          </svg>
        </div>
        <input
          onChange={handleChange}
          multiple={multiple}
          ref={fileInputRef}
          type="file"
    
          accept="audio/*,video/*,image/*,.pdf,.doc,.docx,.ppt, .pptx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"

          hidden
        />
      </div>
    </div>
  );
}

export default CustomAttachmentUpload;
