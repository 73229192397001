import React from "react";

export  function TextMediumXS({
  color = "text-gray-800",
  truncate,
  wrap = "wrap",
  whitespace = "normal",
  content,
  align
}) {
  return (
    <p
      className={` text-xs text-${align} font-medium ${color} text-${wrap} whitespace-${whitespace} ${
        truncate ? "truncate" : ""
      }`}
    >
      {content}
    </p>
  );
}

export  function TextMediumSM({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align
  }) {
    return (
      <p
        className={` text-sm  text-${align} font-medium text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        }`}
      >
        {content}
      </p>
    );
  }

  export  function TextMediumBase({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left"
  }) {
    return (
      <p
        className={` text-base text-${align} font-medium text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        }`}
      >
        {content}
      </p>
    );
  }

  export  function TextMediumXL({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left"
  }) {
    return (
      <p
        className={` text-xl text-${align} font-medium text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        }`}
      >
        {content}
      </p>
    );
  }

  export  function TextMediumXXL({
    color = "gray-800",
    truncate,
    wrap = "wrap",
    whitespace = "normal",
    content,
    align="left"
  }) {
    return (
      <p
        className={` text-2xl text-${align} font-medium text-${color} text-${wrap} whitespace-${whitespace} ${
          truncate ? "truncate" : ""
        }`}
      >
        {content}
      </p>
    );
  }
