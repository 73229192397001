import React from 'react'

function Members({data}) {
  return (
    <div className=" w-full overflow-y-scroll h-[35vh]">
    <table className="overflow-hidden text-center  text-s border-collapse w-full overflow-y-scroll">
      <thead className="border-b  rounded-md overflow-hidden font-medium  text-sm"
                style={{
                  borderTop: "1px solid #E9E8EC ",
                  borderBottom: "1px solid #E9E8EC ",
                }}
      >
        <tr
          className=""
        >
          <th
            scope="col"
            className="sticky py-2 w-[70%] text-start pl-2 font-medium text-[#6C6580] text-xs"
          >
            Name
          </th>
          <th
            scope="col"
            className="sticky py-2 w-[30%] font-medium text-[#6C6580] text-xs"
          >
            Designation
          </th>
        </tr>
      </thead>
      <tbody className=" px-2 ">
        {data &&
          data.map((Item, Index) => {
            return (
              <tr
                key={Index}
                className="p-3 border-b transition duration-300 ease-in-out hover:bg-neutral-100  h-9"
                // style={{ borderBottom: "1px solid #E9E8EC " }}
              >
                <td className=" text-xs text-start pl-3  text-[#4F4768]">
                  <div className=' w-full flex items-center truncate gap-2'>
                  <div className=" w-7 h-7 rounded-full overflow-hidden capitalize text-white font-medium flex justify-center items-center bg-mainColor-600">
                    {
                      Item?.image?<img src={Item?.image} alt='#'/>:`${Item?.name.charAt(0)}`
                    }
                </div> {Item?.name||'-'}
                  </div>
                </td>
                <td className=" font-medium text-xs text-[#4F4768]">
                  {Item?.designation || "-"}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
  )
}

export default Members