import React, { useState } from 'react';
import ToggleUp from '../../../../../../Components/Icons/ToggleUp';
import ToggleDown from '../../../../../../Components/Icons/ToggleDown';
import ExpandableEquipment from './ExpandableEquipment';
import { Modal, notification } from 'antd';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { ExclamationCircleFilled } from '@ant-design/icons';
import DeleteIcon from '../../../../../../Components/Icons/Delete';
import CreateSubItemsEquipmentPro from './CreateSubitemsEquipmentPro';


const TitleEquipment = ({data, getData, projectId, selectedTitles, selectedExpandedItems, onMainCheckboxChange, onExpandedCheckboxChange, isTitleSelected}) => {
  const { confirm } = Modal;
    const [isExpanded, setIsExpanded] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
  
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
      // sessionStorage.setItem(`${projectId}-title-${rowData?.titleId}`,!isExpanded);
    };
    const [api, contextHolder] = notification.useNotification();
    const handleDelete = async(id,removevalue) =>{
      confirm({
          title: `Do you Want to delete ${removevalue}?`,
          centered:true,
          width:"25%",
          icon: <ExclamationCircleFilled />,
          content: `The ${removevalue} will be removed from the BOQ`,
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            axiosInstance.delete(`/title/${id}`).then((res) => {
              if(res.status === 201||res.status === 200) {
                  getData();
                  api.success({
                    message: `Equipment Title has deleted successfully`,
                    description:res?.data?.msg||' ',
                    placement: 'bottomRight',
                  });
              } else{
                  api.error({
                      message: `Equipment Title has delete failed`,
                      description:res?.data?.msg||'Network error',
                      placement: 'bottomRight',
                    });
              }
              })
              .catch((error) => {
                  api.error({
                      message: `Equipment Title has delete failed`,
                      description:error?.response?.data?.msg||'Sever error (or) Network error',
                      placement: 'bottomRight',
                    });
              });
          },
          onCancel() {
          },
        });
  }
    
  

  return (
    <>
    {contextHolder}
      <tr className=' sticky top-0 w-[100%] z-[49]  bg-[#EFF6FF]' style={{borderTop:'2px solid white',borderBottom:'2px solid white'}}>
        <td className=' w-[5%] py-2 pl-1 text-center '>
          <div className=' w-full flex'>
          <input 
            type="checkbox" 
            checked={isTitleSelected(data.title_ID)}
              onChange={() => onMainCheckboxChange(data.title_ID, data.data)}
            />
        { 
          true && <div className='  w-3 h-7 rounded-md cursor-pointer  ' onClick={handleToggle}>{isExpanded ?<ToggleUp/>:<ToggleDown/>}</div>
        }
        
          </div>
        </td>
        <td colspan="7" className=' w-full py-1   text-xs font-medium text-mainColor-400'>
          <div className=' w-full flex gap-2 justify-between items-center '>
           <div className=' w-[55%] flex gap-2 items-center '>
           { data?.title ||"-"}
           <div className=" w-[13px] h-[13px] flex justify-center items-center">
           </div>
           </div>

          <div className='  w-[45%] ml-6 flex flex-col text-left gap-1 font-normal'>
            {/* <p className='  text-[11px] text-red-400 '> Sch (Start : {rowData?.startDate && rowData?.startDate !="None" &&<NuDate value={rowData?.startDate} format="DD-MMM-YYYY"/>} | Due : {rowData?.endDate && rowData?.endDate !="None" && <NuDate value={rowData?.endDate} format="DD-MMM-YYYY"/>} | Total : {rowData?.totalDays||0} {rowData?.totalDays&& rowData?.totalDays>1?"days":"day"})</p> */}
            {/* <p className='  text-[11px] text-red-400 '> Task (Total : {rowData?.totalTasks||0} | Open : {rowData?.taskOpened||0} | Close : {rowData?.taskClosed||0})</p> */}
          </div>
          </div>
        </td>

        <td className=' w-[5%] text-center px-1  py-0'>
            <div className='w-full h-full flex items-center gap-2 '>
              {/* <div className=' w-[48%] h-10 flex items-center'>
              <ViewMoreTitle getTitle={getData} projectId={projectId} data={rowData} />
              </div> */}
              <div className=' w-full h-10 flex justify-end gap-1 items-center'>
              <div className='w-6 h-6 bg-white rounded-full flex justify-center items-center cursor-pointer' 
              onClick={() => {
                setCreateModalOpen(true);
              }}
              >
                +
              </div>
              <div className='mt-2 cursor-pointer' onClick={() => { handleDelete(data?.title_ID,data?.title) }}>
              <DeleteIcon/>
              </div>
              {/* {MyTaskCreate && <Link to="/tasks/create" state={{data:rowData,Start:StartDate,End:EndDate}} ><Plus color='#3267FF' /></Link>} */}
              </div>
            </div>
              
          </td >
       
      </tr>
      {
        isExpanded && <ExpandableEquipment 
          data={data.data} 
          getData={getData}
          titleId={data.title_ID} 
          selectedExpandedItems={selectedExpandedItems} 
          onExpandedCheckboxChange={onExpandedCheckboxChange} 
          projectId={projectId}
        />
      }
      <CreateSubItemsEquipmentPro
         open={createModalOpen}
         onCancel={() => {
           setCreateModalOpen(false);
         }}
         getData={getData}
         projectId={projectId}
         itId={data?.title_ID}
      />
      </>
  );
};

export default TitleEquipment;

