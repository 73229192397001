import { Modal, notification } from "antd";
import axiosInstance from "../../../Services/axiosCommon";
import More from "../../../Components/Icons/More";
import { Link } from "react-router-dom";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useState } from "react";
import { GetAccess } from "../../../Components/Utils/roles";



const ViewMore = ({ data, getData,id ,onCancel}) => {
  const { confirm } = Modal;
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const handleDelete = async(id,removevalue) =>{
    confirm({
        title: `Do you Want to delete ${removevalue}?`,
        width:"25%",
        centered:true,
        icon: <ExclamationCircleFilled />,
        content: `The ${removevalue} will be removed from the Activity/Item List`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/Activity/${id}`).then((res) => {
            if(res.status === 201||res.status === 200) {
                getData();
                api.success({
                  message: ` deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: ` delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: ` delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}



  return (
    <>
    {contextHolder}
      <div className='text-[#344767]  relative dropdown inline-block z-50'>
        <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
          <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full'>
            <More/>
          </span>
        </div>
        <ul className='dropdown-menuOne   text-[#344767] text-left  rounded-lg absolute -left-[100px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>

         <li className='w-[100px] bg-black block m-0'>
          <Link to={`/main/employees/${data?.branchId}/view/${data?.userId}`} state={{data: data,from:`/main/employees/${data?.branchId}`}}>
            <span
              className='bg-white hover:bg-gray-100 cursor-pointer py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              // onClick={() => setViewOpen(true)}
            >
              View
            </span>
            </Link>
          </li>
         {GetAccess("activity_item_edit") && <li className=' w-[100px] bg-black block m-0'>
         <Link to={`/main/employees/${data?.branchId}/edit/${data?.userId}`} state={{data: data,from:`/main/employees/${data?.branchId}`}}>
            <span
              className='bg-white hover:bg-gray-100 cursor-pointer py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              onClick={() => setOpen(true)}
            >
              Edit
            </span>
          </Link>
          </li>}
          {/* {
            ProjectDelete && */}
          {
            GetAccess("activity_item_delete") && (
              <li className=' w-[100px] bg-black block m-0'>
              <span
                className='bg-white hover:bg-gray-100 cursor-pointer py-2 px-2 block whitespace-no-wrap text-xs font-medium'
                onClick={
                  () => {
                    handleDelete(data.id,data?.item_code);
                  }
                }
              >
                Delete
              </span>
            </li>
            )
          }
          {/* } */}
        </ul>
      </div>
      {/* <EditActivity
            data={data}
open={open} onCancel={() => { setOpen(false) }} getData={getData}
firstProject={id}
      /> */}
      {/* <Modal
        open={viewOpen}
        onCancel={()=>setViewOpen(false)}
        title={"View Activity"}
        width="50%"
        centered
           className="my-modal"
     
        footer={[]}
      >
        <ViewActivity data={data} open={viewOpen} onCancel={() => setViewOpen(false)} />
      </Modal> */}
      {/* <CreateMember data={data} isOpen={showSideModal} handleClose={handleClose} refreshData={fetcher} /> */}
      {/* <CreateClients
        open={open}
        // onCreate={onCreate}
        getData={getProject}
        data={data}
        onCancel={() => {
          setOpen(false);
        }}
      /> */}
      {/* <ClientView data={data} openModel={openModel} closeModel={()=>setopenModel(false)} /> */}
    </>
  );
};

export default ViewMore;

