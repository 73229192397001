import React, { useEffect, useState } from "react";
import { TextMediumXS } from "../../../../Components/Text/MediumText";
import { CreateButton, EditButton } from "../../../../Components/Utils/Buttons";
import { TextBoldSM, TextBoldXS } from "../../../../Components/Text/BoldText";
import AxiosInstance from "../../../../Services/axiosCommon";
import { TextXS } from "../../../../Components/Text/NormalText";
import { Link } from "react-router-dom";
import NuDate from "../../../../Components/Utils/NuDate";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";


const { confirm } = Modal;

function CompanyInfoIndex() {
  const [hasData, setHasData] = useState(false);
  const [company, setCompany] = useState({
    companyName: "",
    companyWebsite: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    mobile: "",
    email: "",
    currency: "",
    crNumber: "",
    taxNumber: "",
    financialStartYear: "",
    financialEndYear: "",
    isCreated:""
  });

  function getCompanyINfo() {
    AxiosInstance.get(`/CompanyInfo/1`)
      .then((res) => {
        res.data?.data?.companyName && setHasData(true);
        res.data?.data &&
          setCompany({
            city: res.data?.data?.city,
            companyId: res?.data?.data?.companyId,
            companyLogo: res.data?.data?.companyLogo,
            companyName: res.data?.data?.companyName,
            companyWebsite: res.data?.data?.companyWebsite,
            state: res.data?.data?.state,
            country: res.data?.data?.country,
            email: res.data?.data?.email,
            mobile: res.data?.data?.mobile,
            pincode: res.data?.data?.pincode,
            currency: res.data?.data?.currency,
            crNumber: res.data?.data?.crNumber,
            taxNumber: res.data?.data?.taxNumber,
            financialStartYear: res.data?.data?.financialStartYear,
            financialEndYear: res.data?.data?.financialEndYear,
            isCreated:res.data?.data?.isCreated
          });
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getCompanyINfo();
  }, []);

  function CreateBranch(){
    confirm({
      title: `Do you want to Create Branch?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: ``,
      onOk() {
        AxiosInstance.post(`/company_branch/auto_entry/1`).then(res=>{
          if(res.data.status==true){
            getCompanyINfo()
          }
        })
      },
      onCancel() {},
    });
    
  }

  return (
    <div className=" w-full h-full p-2 overflow-y-scroll">
      <div className=" w-full flex justify-between ">
        <TextBoldSM content={"Company Info"} />
        <Link to={`/settings/company-info/edit`}>
          {hasData ? <EditButton /> : <CreateButton />}{" "}
        </Link>
      </div>
      <div className=" w-full py-3" style={{ borderBottom: "1px solid gray" }}>
        <TextMediumXS content={"Company Logo"} />
        <div className=" pl-12 py-3 ">
          <div className=" w-3/12 h-48 rounded-md overflow-hidden bg-stone-200">
            <img src={company.companyLogo || ""} alt="#" width="100%" />
          </div>
        </div>
      </div>
      <div className=" w-full py-3">
        <TextMediumXS content={"Company Information"} />
      </div>
      <div className="w-full flex flex-wrap  ">
        <div className=" w-full grid grid-cols-3 gap-4  ml-40">
          <SubComponent
            width="w-1/2"
            label="Company Name"
            value={company.companyName || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Company Number"
            value={company.mobile || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Country"
            value={company.country || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="State"
            value={company.state || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="City"
            value={company.city || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Pincode"
            value={company.pincode || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Email"
            value={company.email || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Website"
            value={company.companyWebsite || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="Currency"
            value={company.currency || "-"}
          />
            <SubComponent
            width="w-1/2"
            label="CR Number"
            value={company.crNumber || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="TAX Number"
            value={company.taxNumber || "-"}
          />
        </div>
      </div>
      <div className=" w-full py-3">
        <TextMediumXS content={"Financial Year"} />
      </div>
      <div className="w-full flex flex-wrap  ">
        <div className=" w-full grid grid-cols-3 gap-4  ml-40">
          <SubComponent
            width="w-1/2"
            label="Start"
            value={company.financialStartYear !="None" && company.financialStartYear !="" && <NuDate value={company.financialStartYear} format="DD-MMM-YYYY" />  || "-"}
          />
          <SubComponent
            width="w-1/2"
            label="End"
            value={company.financialEndYear !="None" && company.financialEndYear !="" && <NuDate value={company.financialEndYear} format="DD-MMM-YYYY" /> || "-"}
          />
        </div>
      </div>
      <div className=" w-full   flex justify-end py-3">
        <CreateButton cursor={company.isCreated?"cursor-not-allowed":"cursor-pointer"} color={company.isCreated?"bg-gray-400":"bg-baseColor-secondary"} svg={false} label="Add Branch" disabled={company.isCreated} handleClick={CreateBranch}  />
      </div>
    </div>
  );
}
export default CompanyInfoIndex;

function SubComponent({ label, value }) {
  return (
    <div className={` my-3 px-2`}>
      <div className=" w-full mx-auto">
        <TextBoldXS content={label} />
        <TextXS content={value} />
      </div>
    </div>
  );
}
