import React from 'react'
import { BackButton } from '../../../../Components/Utils/Buttons'
import { Link, useLocation } from 'react-router-dom'
import Dasboard from '../../../../Components/Icons/Dasboard'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import NuDate from '../../../../Components/Utils/NuDate'
import NuAmount from '../../../../Components/Utils/NuAmount'
import { NuLabel } from '../../../../Components/Utils/NuInput'
import ViewFiles from './FileView'

function ViewBids() {
  const location = useLocation();
  const data = location.state?.data;
  return (
    <div className="w-full h-[calc(100vh-60px)]  px-4 py-2 overflow-hidden ">
    <div className="md:flex justify-between w-full">
      <BreadCrumb
        items={[
          {
            title: (
              <Link to="/dashboard">
                <Dasboard color="gray" width={10} height={10} />{" "}
              </Link>
            ),
          },
          { title: <p className=" text-[12px] ">Bidding </p> },
          { title: <p className=" text-[12px] ">Bids</p> },
          { title: <p className=" text-[12px] ">View</p> },
        ]}
      />
      <div className=" flex justify-between items-center float-right">
        <BackButton />
      </div>
    </div>
    <div className=" w-full  h-[calc(100vh-120px)] overflow-y-auto mt-3 rounded-lg shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] p-4 ">
      <div className=" w-full h-auto grid grid-cols-1  md:grid-cols-3">
      <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Reference No </p>
          <p className=' text-xs  text-mainColor-400'>{data?.referenceNo||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Project </p>
          <p className=' text-xs  text-mainColor-400'>{data?.projectName||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Bid Value </p>
          <p className=' text-xs  text-mainColor-400'> <NuAmount value={data?.bidValue||0}/></p>
        </div>

        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>FInal Value </p>
          <p className=' text-xs  text-mainColor-400'> <NuAmount value={data?.finalValue||0}/></p>
        </div>

        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Submission Due Date </p>
          <p className=' text-xs  text-mainColor-400'>{data?.dueDate !="" && data?.dueDate !="None" && <NuDate value={data?.dueDate} format="DD-MMM-YYYY" /> ||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Submitted On </p>
          <p className=' text-xs  text-mainColor-400'>{data?.submittedOn !="" && data?.submittedOn !="None" && <NuDate value={data?.submittedOn} format="DD-MMM-YYYY" /> ||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Scope Of Work </p>
          <p className=' text-xs  text-mainColor-400'>{data?.scopeOfWork||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Client Name </p>
          <p className=' text-xs  text-mainColor-400'>{data?.clientName||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Submitted By </p>
          <p className=' text-xs  text-mainColor-400'>{data?.submittedByName||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Status </p>
          <p className=' text-xs  text-mainColor-400'>{data?.biddingStatus||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Comments </p>
          <p className=' text-xs  text-mainColor-400'>{data?.comments||"-"}</p>
        </div>
      </div>
      <div className='w-full'>
         <p className=' text-[13px] font-medium text-mainColor-400'>Attachment View</p>
         <div className=" w-full flex gap-2 items-center flex-wrap mt-2">
                {data?.attachments.length >0  &&
                  data?.attachments?.map((imgs, idx) => {
                    console.log("img",imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{ border: "1px solid gray" }}
                      >
                        <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden">
                        <ViewFiles data={imgs} key={idx} />
                        </div>
                      </div>
                    );
                  })}
            </div>
      </div>
    </div>
  </div>
  )
}

export default ViewBids;