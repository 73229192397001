import React, { Suspense, lazy } from 'react'
import { Route } from 'react-router-dom'

const MainNotes =lazy(()=>import('../Views/Web/Notes/MainNotes')) 
const Notes =lazy(()=>import('../Views/Web/Notes')) 
const NoteCreate =lazy(()=>import('../Views/Web/Notes/CreateNote')) 
const NoteEdit =lazy(()=>import('../Views/Web/Notes/EditNote'))
const NotesView =lazy(()=>import('../Views/Web/Notes/ViewNotes'))

function NoteRoutes() {
  return (
    <Route path="mainNotes/:officeId" element={
      <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
        <MainNotes />
      </Suspense>
    }>
    <Route index element={<Notes />} />
    <Route path="CreateNotes" element={
      <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
        <NoteCreate />
      </Suspense>
    } />
    <Route path="editNotes/:notesId" element={
      <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
       <NoteEdit />
      </Suspense>
    } />
    <Route path="viewNotes/:notesId" element={
      <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
        <NotesView />
      </Suspense>
    } />
  </Route>
  )
}

export default NoteRoutes