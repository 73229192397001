
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../../Services/axiosCommon';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal ,notification} from "antd";
import More from '../../../Components/Icons/More';

const ViewMore = ({ data, getProject,id }) => {
  const {officeId} = useParams();
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();
  const handleDelete = async(id,removevalue) =>{
    confirm({
        title: `Do you Want to delete ${removevalue}?`,
        centered:true,
        icon: <ExclamationCircleFilled />,
        content: `The ${removevalue} will be removed from the Project List`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/Project/deleteProject/${id}`).then((res) => {
            if(res.status === 201||res.status === 200) {
              getProject();
                api.success({
                  message: `Project has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `Project has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `Project has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}




  return (
    <>
    {contextHolder}
      <div className='text-[#344767]  relative dropdown inline-block z-50'>
        <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
          <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full' style={{border:'1px solid #C0C0C0'}}>
            {/* <svg width='6' height='18' viewBox='0 0 4 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.6875 7.72339C0.859072 7.72339 0.1875 7.05182 0.1875 6.22339C0.1875 5.39496 0.859072 4.72339 1.6875 4.72339C2.51593 4.72339 3.1875 5.39496 3.1875 6.22339C3.1875 7.05182 2.51593 7.72339 1.6875 7.72339Z'
                fill='#171A21'
              />
              <path
                d='M0.1875 1.72339C0.1875 2.55182 0.859073 3.22339 1.6875 3.22339C2.51593 3.22339 3.1875 2.55182 3.1875 1.72339C3.1875 0.894962 2.51593 0.223389 1.6875 0.223389C0.859073 0.223389 0.1875 0.894961 0.1875 1.72339Z'
                fill='#171A21'
              />
              <path
                d='M1.6875 12.2234C0.859072 12.2234 0.1875 11.5518 0.1875 10.7234C0.1875 9.89496 0.859072 9.22339 1.6875 9.22339C2.51593 9.22339 3.1875 9.89496 3.1875 10.7234C3.1875 11.5518 2.51593 12.2234 1.6875 12.2234Z'
                fill='#171A21'
              />
            </svg> */}
            <More/>
          </span>
        </div>
        <ul className='dropdown-menuOne   text-[#344767] text-left  rounded-lg absolute -left-[100px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>

         {true && <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              // onClick={() => setOpen(true)}
            >
              <Link className=' text-black hover:text-black' to={`/main/projects/${officeId}/edit/${id}`} state={{ data: data }} >Edit</Link>
            </span>
          </li>} 
          {
            true &&
            <li className=''>
            <a
              className='bg-white hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              // to='/members'
              href="#"
               onClick={() => {
                handleDelete(data.projId,data?.projName);
              }}
            >
              Delete
            </a>
          </li>
          }
        </ul>
      </div>
      {/* <CreateMember data={data} isOpen={showSideModal} handleClose={handleClose} refreshData={fetcher} /> */}
      {/* <CreateClients
        open={open}
        // onCreate={onCreate}
        getData={getProject}
        data={data}
        onCancel={() => {
          setOpen(false);
        }}
      /> */}
      {/* <ClientView data={data} openModel={openModel} closeModel={()=>setopenModel(false)} /> */}
    </>
  );
};

export default ViewMore;

