import React from 'react'
import { Route } from 'react-router-dom';
import { Wrapper } from '../Components/Utils/roles';
import MainItem from '../Views/Web/Items/MainItem';
import { IndexActivity, MainActivity, ViewActivity } from '../Views/Web/Items/Activity';
import { CreateCondition, EditCondition, IndexTeamCondition, MainCondition, ViewCondition } from '../Views/Web/Items/TeamandCondition';


function ItemRoutes() {
    return (
        <Route path="item" element={<MainItem />}>
          <Route path="activity/:officeId" element={<MainActivity />}>
            <Route index element={ < Wrapper accessKey={"activity_item_view"}  Component={IndexActivity} /> } />
            {/* <Route path="create" element={<CreateActivity />} /> */}
            {/* <Route path="edit/:activityEditId" element={<EditActivity />} /> */}
            <Route path="view/:activityViewId" element={ < Wrapper accessKey={"activity_item_view"}  Component={ViewActivity} />} />
          </Route>
          <Route path="teamandcondition/:officeId" element={<MainCondition />}>
            <Route index element={ < Wrapper accessKey={"terms_and_condition_view"}  Component={IndexTeamCondition} /> } />
            <Route path="create" element={ < Wrapper accessKey={"terms_and_condition_create"}  Component={CreateCondition} /> } />
            <Route path="edit/:teamandconditionEditId" element={ < Wrapper accessKey={"terms_and_condition_edit"}  Component={EditCondition} /> } />
            <Route path="view/:teamandconditionViewId" element={ < Wrapper accessKey={"terms_and_condition_view"}  Component={ViewCondition} /> } />
          </Route>
        </Route>
      );
}

export default ItemRoutes