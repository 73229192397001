import React from "react";
import { useEffect, useState } from "react";
import {
  NuInputNumber,
  NuInputText,
  NuLabel,
  NuSelect,
  NuTextArea,
} from "../../../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../../../../Services/axiosCommon";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../../../Components/Utils/Buttons";
import BreadCrumb from "../../../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../../../Components/Icons/Dasboard";
import * as yup from "yup";
import { notification } from "antd";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";

function CreateOthers({ data }) {
  const {projectId } = useParams();
  const navigate = useNavigate();
  const {value:Category} = useFetchMataData(`/Metadata/category`)
  const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const {value:Division} = useFetchMataData(`/Metadata/division`)
  
  const [api, contextHolder] = notification.useNotification();
  const [projectList,setProjectList] =useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    projectId: "",
    activity: "",
    category: "",
    division: "",
    amount: "",
    unit: "",
    quantity: "",
    comments: ""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
      
    }),
    onSubmit: async (value) => {
      setIsLoading(true);
      value.projectId = projectId;
      data
        ? axios
            .put(`/others/${data?.id}`, value)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/others/", value)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });


  // async function getProject() {
  //   await axios
  //     .get(`/Team/getUserProjectId`)
  //     .then(async (res) => {

  //       let list = res?.data?.data || [];
  //       let finalData = [];
  //       (await res?.data?.data) &&
  //         list.map((item, idx) => {
  //           finalData.push({
  //             value: item.ProjectId,
  //             label: item.ProjectName,
  //           });
  //         });
  //       setProjectList(finalData);

  //       // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
  //       // console.log('====',res?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // useEffect(() => {
  //   getProject()
  // },[])

  return (
    <div className="w-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="#">
                  <p className=" text-[12px] ">Costing</p>
                </Link>
              ),
            },
            {
              title: (
                <Link to="/costing/material">
                  <p className=" text-[12px] ">Material</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div>
        <div
          className=" w-full mx-auto h-[85%] rounded-[6px] mt-2"
          style={{ border: "1px solid #C5C5C5" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex justify-between items-center">
              <NuLabel label="Material" />
              {/* <BackButton /> */}
            </div>
            <div className="flex flex-wrap justify-start">

                {/* <NuSelect
                  label="Project"
                  options={projectList||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="projectId"
                  width="w-full md:w-1/3"
                  isRequired={true}
                />
                 */}
              <NuInputText
                 label="Activity"
                 formik={formik}
                 placeholder="Activity"
                 name="activity"
                 width="w-full md:w-1/3"
                 isRequired={true}
                /> 

                <NuSelect
                  label="Category"
                  options={Category||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full md:w-1/3"
                  isRequired={true}
                /> 

                <NuSelect
                  label="Division"
                  options={Division||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full md:w-1/3"
                  isRequired={true}
                /> 

                <NuInputNumber
                 label="Amount"
                 formik={formik}
                 placeholder="Amount"
                 name="amount"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />

                <NuSelect
                  label="Unit"
                  options={Unit||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="unit"
                  width="w-full md:w-1/3"
                  isRequired={true}
                />
 
                <NuInputNumber
                 label="Quantity"
                 formik={formik}
                 placeholder="Quantity"
                 name="quantity"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />      

                <NuTextArea
                  label="Comments"
                  placeholder="Comments"
                  formik={formik}
                  name="comments"
                  width="w-full md:w-2/3"
                />

            </div>
            <div className="flex justify-between items-center">
              <div className="w-1/2">

              </div>

              <div
                className=" mr-2 rounded-md"
                style={{ border: "1px solid #C5C5C5" }}
              >

              </div>
            </div>

            <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateOthers;