import React, { useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
// import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
// import listPlugin from "@fullcalendar/list";
// import timeGridPlugin from "@fullcalendar/timegrid";
import { useEffect } from "react";

function Index(props) {
  const {
    eventsSource,
    initialView,
    editable,
    timeZone,
    selectable,
    isTure = false,
  } = props;

  const calendarRef = useRef(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    if (name === "month") {
      setCurrentMonth(Number(value));
    } else if (name === "year") {
      setCurrentYear(Number(value));
    }
  };

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current
        .getApi()
        .gotoDate(`${currentYear}-${currentMonth.toString().padStart(2, "0")}`);
    }
  }, [currentMonth, currentYear]);

  function generateArrayOfYears() {
    var max = new Date().getFullYear() + 25;
    var min = max - 100;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    // setFullYear(years)
    return years.map((year, idx) => {
      return (
        <option value={year} key={idx} className=" mx-auto text-xs ">
          {year}
        </option>
      );
    });
  }

  function prevMonth() {
    if (currentMonth == 1) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  }

  function nextMonth() {
    if (currentMonth == 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  }

  return (
    <div>
      <FullCalendar
        ref={calendarRef}
        timeZone={timeZone ? timeZone : "local"}
        plugins={[dayGridPlugin]}
        initialView={initialView || "dayGridMonth"}
        initialDate={`${currentYear}-05`}
        customButtons={{
          Testing: {
            text: (
              <>
                {isTure && (
                  <div className=" flex justify-between items-center">
                    <p
                      title=""
                      className=" flex items-center text-[12px] px-[3px] py-1 font-medium text-[#FF0000] rounded-xl "
                    >
                      <div className=" w-2 h-2 rounded-full bg-[#FF0000] mx-2"></div>
                      Yet to start
                    </p>
                    <p
                      title=""
                      className="flex items-center text-[12px] py-2 px-[3px] font-medium rounded-xl
            text-[#ffd700]
            [#DEC800] 
            
            [#FFFCDF]"
                    >
                      <div className=" w-2 h-2 rounded-full bg-[#ffd700] mx-2"></div>
                      In progress
                    </p>
                    <p
                      title=""
                      className="flex items-center text-[10px] py-2 px-[3px] font-medium text-[#008000] rounded-xl"
                    >
                      <div className=" w-2 h-2 rounded-full bg-[#008000] mx-2"></div>
                      Completed
                    </p>
                  </div>
                )}
                <div className=" text-black">
                  <button
                    onClick={prevMonth}
                    className=" outline-none border-none cursor-pointer bg-white font-bold text-base"
                  >
                    &#x276E;
                  </button>
                  <select
                    name="month"
                    value={currentMonth}
                    onChange={handleDropdownChange}
                    className=" mx-2 font-medium text-xs outline-none border-none"
                  >
                    <option value={1} className=" mx-2  ">
                      January
                    </option>
                    <option value={2} className=" mx-2  ">
                      February
                    </option>
                    <option value={3} className=" mx-2  ">
                      March
                    </option>
                    <option value={4} className=" mx-2  ">
                      April
                    </option>
                    <option value={5} className=" mx-2  ">
                      May
                    </option>
                    <option value={6} className=" mx-2  ">
                      June
                    </option>
                    <option value={7} className=" mx-2  ">
                      July
                    </option>
                    <option value={8} className=" mx-2  ">
                      August
                    </option>
                    <option value={9} className=" mx-2  ">
                      September
                    </option>
                    <option value={10} className=" mx-2  ">
                      October
                    </option>
                    <option value={11} className=" mx-2  ">
                      November
                    </option>
                    <option value={12} className=" mx-2  ">
                      December
                    </option>
                  </select>
                  <select
                    name="year"
                    value={currentYear}
                    onChange={handleDropdownChange}
                    className=" mx-2 font-medium text-xs outline-none border-none"
                  >
                    {generateArrayOfYears()}
                  </select>
                  <button
                    onClick={nextMonth}
                    className=" outline-none border-none cursor-pointer bg-white font-bold text-base"
                  >
                    &#x276F;
                  </button>
                </div>
              </>
            ),
          },
        }}
        headerToolbar={{
          left: "",
          center: `Testing`,
          right: "",
        }}
        editable={editable || false}
        selectable={selectable || false}
        selectMirror={true}
        dayMaxEvents={false}
        dayMaxEventRows={false}
        select={() => {}}
        eventClick={(e) => {}}
        resourceAreaWidth="100%"
        resourceAreaHeight="20%"
        eventSources={eventsSource}
        fixedWeekCount={false}
        showNonCurrentDates={false}
        height={"40vh"}
        // contentHeight='330px'
      />
    </div>
  );
}

export default Index;
