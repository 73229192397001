import {Modal, notification } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { NuSelect } from "../../../../Components/Utils/NuInput";
import axios from "../../../../Services/axiosCommon";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";


const CreateStatus = ({ open, getData, onCancel,userID,status }) => {
  const [api, contextHolder] = notification.useNotification();
    const [createNew, setCreateNew] = useState({
        user_status:false,
     });

useEffect(() => {
  setCreateNew({
    user_status:status?.isActive||false
  })
},[status])
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: async (value) => {
          console.log(value);
           axios
              .put(`/User/updateStatus/${userID}`, value)
              .then((res) => {
                getData(1);
                api.success({
                  message: `User Status  Updated successfully`,
                  description: res?.data?.msg||" ",
                  placement: 'bottomRight',
                });
                onCancel();
              })
              .catch((err) => {
                console.log(err);
                api.success({
                  message: `User Status  Updated successfully`,
                  description: err?.response?.data?.msg||" ",
                  placement: 'bottomRight',
                });
              })
         
            }
    });
  
    return (
      <Modal open={open} title={'User Status'}  centered onCancel={onCancel} footer={[]}>
        {contextHolder}
        <div className=' overflow-y-scroll'>
          <form onSubmit={formik.handleSubmit}>
            <div className=' w-full flex flex-wrap my-5'>       
  
              <NuSelect
                label='Status'
                options={[
                    { value: true, label: "Active" },
                    { value: false, label: "Inactive" },
                  ]}
                formik={formik}
                placeholder='Choose'
                name='user_status'
                width='w-full'
              />
  
            </div>
            <div className=' w-full flex justify-end gap-4 items-center'>
              <CancelButton handleClick={onCancel} />
              <SubmitButton/>
            </div>
          </form>
        </div>
      </Modal>
    );
  };
  
  export default CreateStatus;