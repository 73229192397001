import React from 'react'
import { Outlet } from 'react-router-dom';

function ReportsMain() {
  return (
    <div className=" w-full h-full">
    <Outlet />
  </div>
  )
}

export default ReportsMain;