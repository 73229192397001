import React, { useState } from "react";
import { Link } from "react-router-dom";
import ViewMoreTask from "../../Web/Project/SubMenu/Tasks/Components/viewMoreTask";
import ViewMoreSubTask from "../../Web/Project/SubMenu/Tasks/Components/viewMoreSubtask";
import NuDate from "../../../Components/Utils/NuDate";
import { Progress } from "antd";
import UpdatePercentage from "../../Web/Project/SubMenu/Tasks/Components/update";

function MyTask({ item, firstProject,getData }) {
    const [percentOpen, setPercentOpen] = useState(false);
  return (
    <div className=" bg-slate-50 rounded my-2 px-2 py-3">
      <div className=" w-full flex px-1 bg-slate-100 items-center justify-between">
        {/* <div className=" w-[5%] text-center "></div> */}
        <div className=" w-[97%] px-1">
          <Link
            className=" text-mainColor-400 hover:text-mainColor-400  line-clamp-1 text-sm"
            to={
              item?.taskLink
                ? `/tasks/view/${item?.id}`
                : `/tasks/subtask/view/${item?.id}`
            }
            state={{ projectId: firstProject }}
          >
            {" "}
            {item?.name || "-"}
          </Link>
        </div>
        <div className="">
          {item?.taskLink ? (
            <ViewMoreTask data={item} id={item?.id} projectId={firstProject} />
          ) : (
            <ViewMoreSubTask
              data={item}
              id={item?.id}
              projectId={firstProject}
            />
          )}
        </div>
      </div>
      <div className=' w-full flex px-1 py-1 items-center text-xs justify-between'>
        <div>
        <p className=' font-semibold'>Start Date</p>
            {item?.startDate != "None" || item?.startDate != " " ? (
            <NuDate value={item?.startDate} format="DD-MMM-YYYY" />
          ) : (
            "-"
          )}
        </div>
        <div>
        <p className=' font-semibold'>Due Date</p>
            {item?.endDate != "None" || item?.endDate != " " ? (
            <NuDate value={item?.endDate} format="DD-MMM-YYYY" />
          ) : (
            "-"
          )}
        </div>
        <div>
        <p className=' font-semibold'>Priority</p>
            {item?.priority || "-"}
        </div>
        <div className=" w-[10%]">
        <Progress
              onClick={() => {
                setPercentOpen(true);
              }}
              type="circle"
              size={30}
              strokeWidth={12}
              format={(percent) => (
                <p
                  className={`cursor-pointer ${
                    item?.completion > 95
                      ? "text-[#87d068]"
                      : item?.completion > 50
                      ? "text-[#108ee9]"
                      : "text-[#D2BD00]"
                  } text-xs font-semibold`}
                >
                  {percent}
                </p>
              )}
              percent={Math.floor(item?.completion )|| 0}
              strokeColor={
                item?.completion > 95
                  ? {
                      "0%": "#87d068",
                      "100%": "#87d068",
                    }
                  : item?.completion > 50
                  ? {
                      "0%": "#108ee9",
                      "100%": "#108ee9",
                    }
                  : {
                      "0%": "#D2BD00",
                      "100%": "#D2BD00",
                    }
              }
            />
        </div>
      </div>
      <UpdatePercentage
              projectId={firstProject}
              open={percentOpen}
              onCancel={() => {
                setPercentOpen(false);
              }}
              Id={item?.id}
              data={item?.completion}
              getData={getData}
              taskLink={item?.taskLink}
       />
    </div>
  );
}

export default MyTask;
