import { useEffect, useState } from "react";
import axios from "../../../Services/axiosCommon";
import Pagination from "../../../Components/Utils/Pagenation";
import { Link, useParams } from "react-router-dom";
import ProjectCard from "./projectCard";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../Components/Icons/Dasboard";
import { SecondHeading } from "../../../Components/Utils/Heading";
import SearchBar from "../../../Components/Utils/SearchBar";
import {CreateButton } from "../../../Components/Utils/Buttons";
import ImportProject from "./Import";

const AllProject = () => {
  const { officeId } = useParams();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [proTeam, setProTeam] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalProject,setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [notFirst,setNotFirst]=useState(false)

  const userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let ProjectCreate = userAccess
    ? userAccess.some(
        (permission) => permission.permissionName == "Project_create"
      )
    : false;

  async function getProject() {
    await axios
      .get(`/Project/${officeId}/details?page=${pageIndex}&per_page=${dataCount}`)
      .then(async (res) => {
        await res?.data?.data && setData(res?.data?.data);
        await res?.data?.totalProject && setTotalProject(res?.data?.totalProject);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  useEffect(()=>{
    getProject()
  },[pageIndex,dataCount])




      
  function SearchData(e) {
    e.preventDefault();
    setNotFirst(true)
    if (searchQuery != "") {
      axios
        .get(`/Search/project/${searchQuery}`)
        .then((res) => {
          res?.data && setTotalProject(res?.data?.total_records);
          res.data?.data && setData(res.data?.data);
          console.log("search", res.data);
        })
        .catch((err) => console.log(err));
    } else {
      console.log("Seacrch Data");
      getProject();
    }
  }

  useEffect(() => {
    if (searchQuery == "" && notFirst) {
      getProject();
    }
  }, [searchQuery]);

  
  return (
    <div className="w-full h-[calc(100vh-60px)] px-4 py-2 overflow-hidden ">
      <div className="md:flex justify-between w-full">
      <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Project</p>)}]} />
            <div className='  flex justify-center items-center float-right'>
              <div className=" pb-2 w-full flex justify-center gap-2 items-center ">
                <div className=" flex justify-center items-center gap-3 md:mr-6">
                <form className="flex items-center mx-0" onSubmit={SearchData}>
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
              </form>
               <CreateButton label="Import" handleClick={()=>setOpen(true)}   />
               <Link to={`/main/projects/${officeId}/create`}><CreateButton label="Project"/></Link>
                </div>
              </div>
            </div>
      </div>
            <SecondHeading title={`Project : ${totalProject}`} />
      <div className=" w-full h-[calc(100vh-170px)]  p-4  grid grid-cols-1 md:grid-cols-2  2xl:grid-cols-3 gap-7 overflow-y-auto">
        {data &&
          data.map((project, index) => {
            return (
              // <Link to={`/project/dashboard/${officeId}/${project?.projId}`}>
              <div className="" key={index}>
                <ProjectCard item={project} getProject={getProject} />
              </div>
              // </Link>
            );
          })}
          {/* <Link to={`/project/dashboard/${officeId}/1`}>
          <p className="cursor-pointer">Project One</p>
          </Link> */}
      </div>
      <div className=" h-6 my-2">
      <Pagination
          total={totalProject}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>
      <ImportProject  open={open} onCancel={()=>setOpen(false)} getData={getProject}  label='Add Title' officeId={officeId}/>

    </div>
  );
};

export default AllProject;
