import { Link, Outlet, useLocation, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { GetAccess } from "../../../Components/Utils/roles";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../Components/Icons/Dasboard";
import SearchBar from "../../../Components/Utils/SearchBar";
import { BackButton, CreateButton } from "../../../Components/Utils/Buttons";
import Bidding from "../../../Components/Icons/Bidding";


const BidLayout = (
    {
        SearchData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= true,
        ImportAccess= true,
        setOpen=()=>{},
        children,
        isFilter=false,
        setFilterOpen=()=>{},
        ClearFilter=()=>{},
        // officeId,
        projectId,
        onCreateClick
    }
) =>{
    let {officeId,estmationId} =useParams()
    let {pathname} = useLocation()
    const [module,setModule] = useState("")
    
    useEffect(() =>{
        if(pathname == `/main/bidding/${officeId}/estimation/${estmationId}/boq`){
            setModule("BOQ")
        }else if(pathname == `/main/bidding/${officeId}/estimation/${estmationId}/material`){
            setModule("Material")
        }else if(pathname == `/main/bidding/${officeId}/estimation/${estmationId}/manpower`){
            setModule("Manpower")
        }else if(pathname == `/main/bidding/${officeId}/estimation/${estmationId}/equipment`){
            setModule("Equipment")
        }else if(pathname == `/main/bidding/${officeId}/estimation/${estmationId}/others`){
            setModule("Others")
        }else if(pathname == `/main/bidding/${officeId}/estimation/${estmationId}/profit-margin`){
            setModule("Profit Margin")
        }else if(pathname == `/main/bidding/${officeId}/estimation/${estmationId}/attachments`){
            setModule("Attachments")
        }else if(pathname == `/main/bidding/${officeId}/estimation/${estmationId}/tc`){
            setModule("T & C")
        }
    },[pathname])

    let LinkItem = [
        {
            label:"BOQ",
            link:`/main/bidding/${officeId}/estimation/${estmationId}/boq`,
            access:GetAccess(),
        },
        {
            label:"Material",
            link:`/main/bidding/${officeId}/estimation/${estmationId}/material`,
            access:GetAccess(),
        },
        {
            label:"Manpower",
            link:`/main/bidding/${officeId}/estimation/${estmationId}/manpower`,
            access:GetAccess(),
        },
        {
            label:"Equipment",
            link:`/main/bidding/${officeId}/estimation/${estmationId}/equipment`,
            access:GetAccess(),
        },
        {
            label:"Others",
            link:`/main/bidding/${officeId}/estimation/${estmationId}/others`,
            access:GetAccess(),
        },
        {
            label:"Profit Margin",
            link:`/main/bidding/${officeId}/estimation/${estmationId}/profit-margin`,
            access:GetAccess(),
        },
        {
            label:"Attachments",
            link:`/main/bidding/${officeId}/estimation/${estmationId}/attachments`,
            access:GetAccess(),
        },
        {
            label:"T & C",
            link:`/main/bidding/${officeId}/estimation/${estmationId}/tc`,
            access:GetAccess(),
        },
    ]
    
    return(
        <div className=" w-full h-full py-2 px-4 ">
            <div className="">
            <div className="md:flex justify-between w-full">
            <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Biddings</p>)},
            {title:(<p className=' text-[12px] '>Bid</p>)},
            {title:(<p className=' text-[12px] '>Estimation</p>)},
            {title:(<p className=' text-[12px] '>{module}</p>)}
            ]} />

            <div className=" flex justify-end items-center gap-3 mr-6">
            <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
              </form>
              <CreateButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <CreateButton label='Clear Filter'  handleClick={ClearFilter} />}
              {ImportAccess && <CreateButton label="Import" handleClick={()=>setOpen(true)}    />}

              {CreateAccess && <CreateButton handleClick={onCreateClick} label={module == "BOQ" || module == "Material" || module == "T & C" || module == "Manpower" || module == "Equipment" || module == "Others" ? `${module} Title` : module }/>}
                  <BackButton/>
                  <Link to={`/main/bidding/bids/${officeId}`}>
                  <button
      className="px-1 md:px-3 h-6 md:h-8 cursor-pointer text-xs text-white bg-[#ffc64d]  rounded-md flex items-center"
      style={{ border: "1px solid #ffc64d" }}
    >
      <span className="md:mr-2 flex justify-center items-center">
        <Bidding/>
      </span>
      Bid
      </button>
      </Link>
                </div>
            </div>
           
            <div className=" w-full h-8 flex items-center gap-x-4 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #FFB51A"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                            )
                         }
                        </>
                    )
                })
            }
        </div>
           
            <div className=" w-full h-[calc(100vh-140px)]">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default BidLayout;