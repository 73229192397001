import React, { useState } from "react";
import ViewMore from "./ViewMore";


const ExpandableTC = ({data,getData, titleId, selectedExpandedItems, onExpandedCheckboxChange,estimationId }) => {
   
  return (
    <>
    {
        data.map((item, index) => (
            <tr className={`w-[100%] bg-white text-xs font-normal text-[#71797E]`} style={{borderBottom:'1px solid #c7dfe0'}} key={index}>
            <td className=" w-[5%] py-3 px-1">
                <div className=" w-full gap-1 flex">
                <input 
            type="checkbox" 
            checked={selectedExpandedItems[titleId]?.includes(item.id) || false}
            onChange={() => onExpandedCheckboxChange(titleId, item.id)}
            />
                {index+1}
                </div>
            </td>
            <td className=" w-[10%] py-3 px-1">{item?.item_code || "-"}</td>
            <td className=" w-[80%] py-3 px-1">{item?.description || "-"}</td>
            <td className=" w-[5%] py-3 pl-3">
                         <ViewMore id={titleId || ""}
                            getData={getData}
                            data={item}
                            estimationId={estimationId}
                            /> 
            </td>
           </tr>
        ))
    }
    </>
  );
};

export default ExpandableTC;
