import { Link, Outlet, useLocation } from "react-router-dom"
import BreadCrumb from "../../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../../Components/Icons/Dasboard";
import { useEffect, useState } from "react";
import SearchBar from "../../../../../Components/Utils/SearchBar";
import { CreateButton } from "../../../../../Components/Utils/Buttons";
import { Select } from "antd";
import { GetAccess } from "../../../../../Components/Utils/roles";


const PettyCashLayout = (
    {
        SearchData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= true,
        ImportAccess= true,
        setOpen=()=>{},
        children,
        setFilterOpen=()=>{},
        isFilter,
        ClearFilter=()=>{},
        officeId,
        projectId
    }
) =>{
    let {pathname} = useLocation()
    const [module,setModule] = useState("")
    

    useEffect(() =>{
        if(pathname == `/project/petty-cash/received/${officeId}/${projectId}`){
            setModule("Received")
        }else if(pathname == `/project/petty-cash/issued/${officeId}/${projectId}`){
            setModule("Issued")
        }
        // else if(pathname == "/progress/material-expense"){
        //     setModule("Material & Others")
        // }else if(pathname == "/progress/petty-cash-expense"){
        //     setModule("Petty Cash Expense")
        // }
    },[pathname])

    let LinkItem = [
        {
            label:"Received",
            link:`/project/petty-cash/received/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        {
            label:"Issued",
            link:`/project/petty-cash/issued/${officeId}/${projectId}`,
            access:GetAccess(),
        },
        // {
        //     label:"Material & Others",
        //     link:"/progress/material-expense",
        //     access:true,
        // },
        // {
        //     label:"Petty Cash Expense",
        //     link:"/progress/petty-cash-expense",
        //     access:true,
        // }
    ]
    
    return(
        <div className=" w-full h-full py-2 px-4 ">
            <div className="">
            <div className="md:flex justify-between w-full">
            <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Petty Cash</p>)},
            {title:(<p className=' text-[12px] '>{module}</p>)}
            ]} />

            <div className=" flex justify-end items-center gap-3 mr-6">
            <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
              </form>
              {/* {ImportAccess && <CreateButton label="Import" handleClick={()=>setOpen(true)}    />} */}
              <CreateButton label='Filter'  handleClick={()=>{setFilterOpen(true);setSearchQuery("");}} />
              {isFilter&& <CreateButton label='Clear Filter'  handleClick={ClearFilter} />}
            
              {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}
                  {/* <BackButton/> */}
                </div>
            </div>
           
            <div className=" w-full h-8 flex items-center gap-x-4 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                            )
                         }
                        </>
                    )
                })
            }
        </div>
           
            <div className=" w-full h-[calc(100vh-140px)]">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default PettyCashLayout;