import React, { useEffect, useState } from "react";
import axios from "../../../../../../Services/axiosCommon";
import { Link, useLocation, useParams } from "react-router-dom";
import Pagination from "../../../../../../Components/Utils/Pagenation";
import { NuDatePicker, NuSelect } from "../../../../../../Components/Utils/NuInput";
import { Button, Modal,  Select } from "antd";
import { useFormik } from 'formik';
import * as yup from "yup";
import { useNumber } from "../../../../../../Context/ProjectIdContext";
import CostingLayout from "../CostingLayout";
import ViewMore from "./ViewMore";
import useFetchMataData from "../../../../../../Components/Hooks/useFetchMataData";
import { GetAccess } from "../../../../../../Components/Utils/roles";
import NuAmount from "../../../../../../Components/Utils/NuAmount";



function Others(id) {
  const { officeId,projectId } = useParams();
  const {value:Category} = useFetchMataData(`/Metadata/category`)
  const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const {value:Division} = useFetchMataData(`/Metadata/division`)

  // const location = useLocation();
  // const projectTask = location?.state?.projectTask || "";
  const { number, updateNumber } = useNumber();
  const [percentOpen,setPercentOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalTasks, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([])
  const [priority, setPriority] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [isFilter,setIsFilter]= useState(false);
  const [notFirst,setNotFirst]=useState(false)
  const [filterData,setFilterData]= useState({
    division: "",
    category: "",
    unit: ""
  });
  const [afterFilter,setAfterFilter]= useState({})
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });


  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function SearchData(e) {
    setNotFirst(true)
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(`/others/${projectId}/details?page=${1}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
        .then((res) => {
          setData(res.data?.data);
        setTotalProject(res.data?.total_records)

        })
        .catch((err) => console.log(err));
    } else {
      getTask(firstProject);
    }
  }

  function SearchPageDate(){
    axios
    .get(`/others/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
    .then((res) => {
      setData(res.data?.data);
      setTotalProject(res.data?.total_records)

    }).catch((err) => console.log(err))
  }



  function getTask() {
    axios
      .get(`/others/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (searchQuery == "" && notFirst) {
      getTask();
    }
  }, [searchQuery]);

  useEffect(() => {
    getTask();
  }, []);

  const userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let MyTaskCreate = userAccess
    ? userAccess.some(
        (permission) => permission.permissionName == "MyTask_create"
      )
    : false;

  // useEffect(() => {
  //   // console.log("jj",projectNum);
  //   if (projectTask) {
  //     setFirstProject(projectTask);
  //     // console.log("gf", firstProject);
  //   }
  // }, [projectTask]);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({

    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value)
      setIsFilter(true)
      axios
      .get(`/others/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`,{params:{division:`${value.division}`,category:`${value.category}`,unit:`${value.unit}`}})
      .then((res) => {
        setFilterOpen(false)
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)

      })
      .catch((error) => {
        console.log(error);
      });
    },
  });

  function FilterPage(){
    axios
      .get(`/others/${projectId}/details?page=${pageIndex}&per_page=${dataCount}`,{params:{status:`${afterFilter.status}`,SDF:`${afterFilter.SDF}`,SDT:`${afterFilter.SDT}`,EDF:`${afterFilter.EDF}`,EDT:`${afterFilter.EDT}`,priority:`${afterFilter.priority}`}})
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)

      }).catch(err=>console.log(err))
  }


  function ClearFilter(){
    formik.resetForm({})
    getTask(firstProject);
    setIsFilter(false)
  }

  useEffect(()=>{
   if(isFilter){
    FilterPage();
   }else if(searchQuery !=""){
      SearchPageDate();
    }else{
    getTask(firstProject)
    }
  },[pageIndex,dataCount])

  console.log("zzzz",firstProject);

  return (
    <>
      <CostingLayout
        setOpen={setOpen}
        setSearchQuery={setSearchQuery}
        SearchData={SearchData}
        isFilter={isFilter}
        setFilterOpen={setFilterOpen}
        ClearFilter={ClearFilter}
        CreateAccess={GetAccess()}
        ImportAccess={GetAccess()}
        officeId={officeId}
        projectId={projectId}
      >
        <div className=" w-full h-[calc(100vh-180px)] overflow-y-auto">
          <table
            className="table-auto w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <thead className=" w-full bg-[#2A475E]">
              <tr className="text-xs  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-2 ${header.width} font-normal ${
                      header?.isLeft ? "text-left" : "text-center"
                    } text-xs px-2 text-white`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {data.map((item, i) => (
                <tr className=" w-full " key={i}>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                    {item?.serial_number || "#"}
                  </td>
                  <td className="py-2  text-xs text-left font-normal text-mainColor-400 truncate">
                  <Link className='hover:text-baseColor-primary text-mainColor-400 truncate'  to={`/project/costing/others/${officeId}/${projectId}/view/${item?.id}`} state={{data:item}}>  {item?.activity || "-"}</Link>
                
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                  {/* <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/project/costing/others/${officeId}/${projectId}/view/${item?.id}`} state={{data:item}}>  */}
                   {item?.category || "-"}
                   {/* </Link> */}
                   
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                  <NuAmount value={item?.amount||0} />
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                    {item?.division || "-"}
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                    {item?.quantity || "-"}
                  </td>
                  <td className="py-2  text-xs text-center font-normal text-mainColor-400">
                    <div className=" w-full flex justify-center items-center">
                      <ViewMore
                        id={item?.id}
                        getData={getTask}
                        data={item}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className=" h-6 my-2">
          <Pagination
            total={totalTasks}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            dataCount={dataCount}
            setDataCount={setDataCount}
          />
        </div>
      </CostingLayout>
      <Modal
        open={filterOpen}
        title={"Filter Others"}
        width={"30%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-col flex-wrap">
               <NuSelect
                  label="Category"
                  options={Category||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full"
                  isRequired={true}
                /> 

                <NuSelect
                  label="Division"
                  options={Division||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full"
                  isRequired={true}
                />

                <NuSelect
                  label="Unit"
                  options={Unit||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="unit"
                  width="w-full"
                  isRequired={true}
                />
            
            <button
               type="submit"
              // size="large"
              className="w-[95%] bg-[#119DEE] text-white  mx-auto py-1 border-none outline-none rounded-md"
            >
              Filter 
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default Others;

const header = [
  {
    headone: "ID",
    width: "w-[3%]",
    // isLeft: true,
  }, 
  {
    headone: "Activity",
    width: "w-[26%]",
    isLeft: true,
  },
  {
    headone: "Category",
    width: "w-[13%]",
    // isLeft: true,
  },
  {
    headone: "Amount",
    width: "w-[13%]",
  },
  {
    headone: "Division",
    width: "w-[10%]",
  },
  {
    headone: "Quantity",
    width: "w-[10%]",
  },
  {
    headone: "Action",
    width: "w-[5%]",
    isLeft: true,
  },
];
