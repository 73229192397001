import { Bar } from 'react-chartjs-2';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChart(props) {
  const {actualData,plannedData} =props
  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const data = {
    labels,
    datasets: [
      {
        data: actualData||[],
        backgroundColor: '#C7C0FF',
        borderColor: '#C7C0FF',
        borderWidth: 1,
      },
      {
        data: plannedData||[],
        backgroundColor: '#3D3394',
        borderColor: '#3D3394',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
        ticks: {
          color: "red",
        },
      },
      y: {
        grid: {
          display: false, // Hide y-axis grid lines
        },
        ticks: {
          color: "white",
        },
      },
    },
    interaction: {
      mode: 'index', // Remove tooltips
    },
  };

  return (
    <Bar
      data={data}
      height={200}
      options={options}
    />
  );
}

export default BarChart;
