import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import MainProject from '../Views/Web/Project/projectMain'
import AllProject from '../Views/Web/Project'
import CreateProject from '../Views/Web/Project/createProject'
import EditProject from '../Views/Web/Project/editProject'
import ViewProject from '../Views/Web/Project/viewProject'

// const MainProject  =lazy(()=>import("../Pages/Web/Project/projectMain")) 
// const AllProject =lazy(()=>import("../Pages/Web/Project")) 
// const CreateProject =lazy(()=>import("../Pages/Web/Project/createProject"))
// const EditProject =lazy(()=>import("../Pages/Web/Project/editProject"))
// const ViewProject =lazy(()=>import("../Pages/Web/Project/ProjectView"))

const ProjectRoutes = () => {
  return (
    <Route path="projects/:officeId" element={<MainProject />}>
    <Route index element={<AllProject />} />
    <Route path="create" element={<CreateProject />} />
    <Route path="edit/:projectEditId" element={<EditProject />} />
    <Route path="view/:projId" element={<ViewProject />} />
  </Route>
  )
}

export default ProjectRoutes