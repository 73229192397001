import React, { useEffect, useState } from 'react'
import DataTables from '../../../../Components/Utils/DataTable';
import axiosInstance from '../../../../Services/axiosCommon';
import NuDate from '../../../../Components/Utils/NuDate';

function BackupIndex() {
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [roleList, setRoleList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSrearch, setIsSreach] = useState(false);
    console.log("zzzz",data);
    const column = [
        {
            name: "Day",
            selector: (row) => row?.day_folder,
            sortable: true,
            width:"10%"
          },
      {
        name: "File Name",
        selector: (row) => row?.filename,
        sortable: true,
        width:"45%"
      },
      {
        name: "Date",
        selector: (row) => (
          <p className="capitalize">{NuDate({value:row?.modified_at,format:"DD-MM-YYYY"}) || "-"}</p>
        ),
        sortable: true,
        reorder: true,
        width:"15%"
      },
      {
        name: "Month",
        selector: (row) => row.month_folder,
        sortable: true,
        width:"10%"
      },
      {
        name: "Year",
        selector: (row) => row.year_folder,
        sortable: true,
        width:"10%"
      },
      {
        name: "Download",
        selector: (row) => "D",
        sortable: true,
        width:"10%"
      },    
    ];
  
    async function getBackUp() {
      await axiosInstance
        .get(`/Auth/available_files`)
        .then(async (res) => {
          console.log("fcc",res?.data?.data);
          (await res?.data?.data) &&
            setData(res?.data?.data);
          // setTotalRows(res.data?.data?.totalRecords);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    // const handlePageChange = (page) => {
    //   getMember(page);
    // };
  
    // const handlePerRowsChange = async (newPerPage, page) => {
    //   if (isSrearch) {
    //     if (searchQuery != "") {
    //       const response = await axiosInstance.get(
    //         `/Search/member/${searchQuery}?page=${page}&per_page=${newPerPage}`
    //       );
  
    //       setData(response?.data?.data);
    //       setPerPage(newPerPage);
    //     } else {
    //       const response = await axiosInstance.get(
    //         `/User/?page=${page}&per_page=${newPerPage}`
    //       );
  
    //       setData(response?.data?.data?.userDetails);
    //       setPerPage(newPerPage);
    //     }
    //   } else {
    //     const response = await axiosInstance.get(
    //       `/User/?page=${page}&per_page=${newPerPage}`
    //     );
  
    //     setData(response?.data?.data?.userDetails);
    //     setPerPage(newPerPage);
    //   }
    // };
  
    async function getRoleList() {
      await axiosInstance
        .get(`/Role/`)
        .then((res) => {
          let role = res.data?.data;
          let list = [];
          res.data?.data &&
            role.map((item) => {
              let i = {
                value: item.roleId,
                label: item.roleName,
              };
              list.push(i);
            });
          setRoleList(list);
        })
        .catch((err) => console.log(err));
    }
  

  
    useEffect(() => {
      getRoleList();
      getBackUp();
    }, []);
  return (
    <>
          <div className=" w-full h-[100%] p-2">
        <div className=" w-[98%] mx-auto h-[100%] rounded-md overflow-hidden bg-white">
          <div className=" w-full flex justify-between items-center px-2 mb-2">
            <h3 className=" text-sm text-mainColor-400 font-semibold">Backup</h3>

          </div>
          <DataTables
            column={column}
            datas={data}
            // handlePageChange={handlePageChange}
            // handlePerRowsChange={handlePerRowsChange}
            scrollHight="63vh"
            // totalRows={totalRows}
          />
        </div>
      </div>
    </>
  )
}

export default BackupIndex;