import { Avatar, Popover } from 'antd';
import React from 'react';

function NuAvatarGroupWithOutLink({ userdetails, }) {
  return (
    <>
      <Avatar.Group
        maxCount={10}
        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer',textTransform: "capitalize" }}
      >
        {userdetails &&
          userdetails.map((item,index) => {
            return (
            <Popover  
            content={<>
              <div className=' w-full flex items-center gap-3'>
              <Avatar key={index} src={item.imageUrl||item.image||'#'} style={{ backgroundColor: '#2A475E',fontSize:'12px',fontWeight:600  }}>
                {item?.firstName?.charAt(0)||item?.name.charAt(0)||item?.userId}
              </Avatar>
              <p>{item?.firstName||item?.name||item?.userId}</p>
              </div>
            </>}             
            placement="top"
            
            >
              <Avatar key={index} src={item.imageUrl||item.image||'#'} style={{ backgroundColor: '#2A475E',fontSize:'12px',fontWeight:600  }}>
                {item?.firstName?.charAt(0)||item?.name.charAt(0)||item?.userId}
                
              </Avatar>
              </Popover>
            );
          })}
      </Avatar.Group>
    </>
  );
}

export default NuAvatarGroupWithOutLink;
