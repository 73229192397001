import { Modal, notification } from 'antd'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { NuInputText } from '../../../../../Components/Utils/NuInput';
import { useParams } from 'react-router-dom';
import { LongButton } from '../../../../../Components/Utils/Buttons';

function CreateOthersTittle({open,onCancel,getData}) {
  const { estmationId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    name: "",
    est_Id: estmationId,
    type: 6,
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
      name:yup.string().required("Tittle is Requried")
    }),
    onSubmit: (value,{resetForm}) => {    
      setIsLoading(true);
      axiosInstance
      .post("IT/create", value)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            getData();
            resetForm();
            setIsLoading(false);
            api.success({
              message: `Tittle Creation successfully`,
              description: res?.data?.msg || "Added In Tittle",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            setIsLoading(false);
            api.error({
              message: `Tittle Creation failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          api.error({
            message: `Tittle Creation failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });
  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={"Create Other Title"}
      width={"50%"}
      className="my-modal"
      centered
      footer={[]}
      onCancel={onCancel}
    >
    <form onSubmit={formik.handleSubmit} >
      <div className=" w-full flex flex-wrap">
         <NuInputText
              label="Tittle"
              placeholder="Tittle"
              formik={formik}
              name="name"
              width="w-full"
          />
          <LongButton isLoading={isLoading} />
      </div>
      </form>
    </Modal>
    </>
  )
}

export default CreateOthersTittle