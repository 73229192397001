import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from "yup";

import axios from '../../../../../../Services/axiosCommon';
import BreadCrumb from '../../../../../../Components/Utils/Breadcrumbs';
import Dasboard from '../../../../../../Components/Icons/Dasboard';
import SearchBar from '../../../../../../Components/Utils/SearchBar';
import { CreateButton } from '../../../../../../Components/Utils/Buttons';
import Pagination from '../../../../../../Components/Utils/Pagenation';
import { NuDatePicker, NuSelect } from '../../../../../../Components/Utils/NuInput';
import { Button, Modal, Select } from 'antd';
import ExpandableAllTask from '../Components/ExpandableAllTask';
import { useNumber } from '../../../../../../Context/ProjectIdContext';
import MobileTask from '../../../../../Mobile/Task/MobileMyTask';
import TaskLayout from '../TaskLayout';
import { GetAccess } from '../../../../../../Components/Utils/roles';


function AllTask() {
  const { officeId } = useParams();
  const { projectId } = useParams();
  const location = useLocation();
  // const { number, updateNumber } = useNumber();
  const projectTask = location?.state?.projectNum || "";
  const [filterOpen, setFilterOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalTasks, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([])
  const [priority, setPriority] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [isFilter,setIsFilter]= useState(false);
  const [notFirst,setNotFirst]=useState(false);
  const [members,setMembers] =useState([]);
  console.log("ccc",firstProject);
  const [filterData,setFilterData]= useState({
    startDate:"",
    endDate:"",
    status: "",
    priority: "",
    assignedTo: ""
  });
  const [afterFilter,setAfterFilter]= useState({})
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });


  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);  

  // async function getProject() {
  //   await axios
  //     .get(`/Team/getUserProjectId`)
  //     .then(async (res) => {
  //       if(number!=0){
  //         setFirstProject(number)
  //       }else if (projectTask) {
  //         setFirstProject(projectTask);
  //       } else {
  //         res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId)
  //         updateNumber(res?.data?.data[0]?.ProjectId)

  //       }
  //       let list = res?.data?.data || [];
  //       let finalData = [];
  //       (await res?.data?.data) &&
  //         list.map((item, idx) => {
  //           finalData.push({
  //             value: item.ProjectId,
  //             label: item.ProjectName,
  //           });
  //         });
  //       setProjectList(finalData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const getStatus = () =>{
    axios.get(`/Metadata/taskstatus`).then(res=>{
        let data = res.data?.data?.keyValues;
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item, label: item };
          tempData.push(tempObject);
        });
        res.data?.data?.keyValues && setStatus(tempData);
    }).catch(error=>{
        console.log("dddd");
    })
}

const getPriority = () =>{
  axios.get(`/Metadata/taskpriority`).then(res=>{
      let data = res.data?.data?.keyValues;
      let tempData = [];
      data.forEach((item) => {
        let tempObject = { value: item, label: item };
        tempData.push(tempObject);
      });
      res.data?.data?.keyValues && setPriority(tempData);
  }).catch(error=>{
      console.log("dddd");
  })
}

const getMembers =(proId)=>{
  // console.log('getmember');
  axios.get(`/DropDown/team_member/${proId}`).then((response)=>{
   console.log("hhh",response.data.data)
   let FinalData = []
   let status = response.data.data;
   status.forEach((item) => {
    console.log("ddd",item.memberId);
     let tempObject = { value: item.userId, label: item.Name };
     FinalData.push(tempObject);
   });
   response.data.data && setMembers(FinalData);

  }).catch((error)=>{console.log(error);});
};

console.log("vvv",members);

  useEffect(() => {
    getStatus()
    // getProject();
    getPriority();
    getMembers();
  }, []);

  function SearchData(e) {
    setNotFirst(true)
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(`/mytask/byUser/members_all_tasks/${projectId}?page=${pageIndex}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
        .then((res) => {
          setData(res.data?.data);
        setTotalProject(res.data?.total_records)

        })
        .catch((err) => console.log(err));
    } else {
      getTask(firstProject);
    }
  }

  function SearchPageDate(){
    axios
    .get(`/mytask/byUser/members_all_tasks/${projectId}?page=${pageIndex}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
    .then((res) => {
      setData(res.data?.data);
      setTotalProject(res.data?.total_records)

    }).catch((err) => console.log(err))
  }

  // function handleProjectChange(e) {
  //   setFirstProject(e);
  //   updateNumber(e);
  // }

  function getTask(x) {
    axios
      .get(`/mytask/byUser/members_all_tasks/${x}?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (searchQuery == "" &&notFirst) {
      getTask(projectId);
    }
  }, [searchQuery]);

  // useEffect(() => {
  //   if(firstProject !=0){
  //     getTask(firstProject)
  //    }else{
  //      getProject(firstProject)
  //    }
  // }, [firstProject]);

  const userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let MyTaskCreate = userAccess
    ? userAccess.some(
        (permission) => permission.permissionName == "MyTask_create"
      )
    : false;

  useEffect(() => {
    if (projectTask) {
      setFirstProject(projectTask);
    }
  }, [projectTask]);




  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      mobileNo: yup
        .string()
        .matches(
          /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Enter Valid Mobile Number"
        )
        .min(10, "Invalid Mobile Number")
        .max(10, "Invalid Mobile Number"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value)
      setIsFilter(true)
      axios
      .get(`/mytask/byUser/members_all_tasks/${firstProject}?page=${pageIndex}&per_page=${dataCount}`,{params:{status:`${value.status}`,startDate:`${value.startDate}`,endDate:`${value.endDate}`,priority:`${value.priority}`,assignedTo:`${value.assignedTo}`}})
      .then((res) => {
        setFilterOpen(false)
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)

      })
      .catch((error) => {
        console.log(error);
      });
    },
  });

  function FilterPage(){
    axios
      .get(`/mytask/byUser/members_all_tasks/${projectId}?page=${pageIndex}&per_page=${dataCount}`,{params:{status:`${afterFilter.status}`,startDate:`${afterFilter.startDate}`,endDate:`${afterFilter.endDate}`,priority:`${afterFilter.priority}`,assignedTo:`${afterFilter?.assignedTo}`}})
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)
      }).catch(err=>console.log(err))
  }


  function ClearFilter(){
    formik.resetForm({})
    getTask(projectId);
    setIsFilter(false)
  }

  useEffect(()=>{
   if(isFilter){
    FilterPage();
   }else if(searchQuery !=""){
      SearchPageDate();
    }else{
    getTask(projectId)
    }
  },[pageIndex,dataCount])

  useEffect(() => {
    // if (firstProject) {
      getMembers(projectId);
    // }
  }, []);

  console.log("qqqqqq",data);
  return (
    <>
    <TaskLayout
                setSearchQuery={setSearchQuery}
                SearchData={SearchData}
                isFilter={isFilter}
                setFilterOpen={setFilterOpen}
                ClearFilter={ClearFilter}
                CreateAccess={GetAccess()}
                ImportAccess={GetAccess()}
                officeId={officeId}
                projectId={projectId}
    >

{/* <div className=' w-full my-1 md:flex items-center gap-3'>


<div className=' w-10/12 md:w-4/12 flex gap-2 items-center'>
  <div className="hidden md:block font-semibold text-xs">Project </div>
  <Select
    style={{ width: '70%',fontSize:'12px' }}
    options={projectList}
    value={projectList.find((i) => i.value == firstProject) || ""}
    disabled={projectList.length >= 2 ? false : true}
    // allowClear={formik.values[name]?true:false}
    // name={name}
    // placeholder={placeholder}
    onChange={(e)=>handleProjectChange(e)}
  />
</div>



</div> */}
{
screenSize.width<="786"?<MobileTask Data={data} getData={getTask} firstProject={firstProject}  />:
<div className=" w-full h-[calc(100vh-200px)] overflow-y-auto">
<table className="table-auto w-full" style={{ border: 'none', borderCollapse: 'collapse' }}>
  <thead className=" w-full bg-[#2A475E]">
    <tr className="text-xs  font-medium   py-2 ">
      {header.map((header, i) => (
        <th className={`py-2 ${header.width} font-normal ${header?.isLeft?"text-left":"text-center"} text-xs px-2 text-white`} key={i} style={{ border: 'none', borderCollapse: 'collapse' }}>
          {header.headone}
        </th>
      ))}
    </tr>
  </thead>
  <tbody className=''>
    {
      data.map((item, i) => (

        <ExpandableAllTask firstProject={firstProject} item={item} key={i} getData={getTask} projectId={projectId} officeId={officeId} />
                   ))
    }
  </tbody>
</table>
</div>
}

<div className=" h-6 my-2">
<Pagination
  total={totalTasks}
  pageIndex={pageIndex}
  setPageIndex={setPageIndex}
  dataCount={dataCount}
  setDataCount={setDataCount}
/>
</div>
    </TaskLayout>
      <Modal
        open={filterOpen}
        title={"Filter All Tasks"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={()=>setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuSelect
              label="Assigned To"
              options={members}
              formik={formik}
              placeholder="Choose"
              name="assignedTo"
              width=" w-1/2"
            />
            <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-1/2"
            />
            <NuDatePicker
              label="Start Date From"
              formik={formik}
              placeholder="Choose"
              name="startDate"
              width=" w-1/2"
            />
            {/* <NuDatePicker
              label="Start Date To"
              formik={formik}
              placeholder="Choose"
              name="SDT"
              width=" w-1/2"
            />
            <NuDatePicker
              label="End Date From"
              formik={formik}
              placeholder="Choose"
              name="EDF"
              width=" w-1/2"
            /> */}
            <NuDatePicker
              label="End Date To"
              formik={formik}
              placeholder="Choose"
              name="endDate"
              width=" w-1/2"
            />
            <NuSelect
              label="Priority"
              options={priority}
              formik={formik}
              placeholder="Choose"
              name="priority"
              width=" w-1/2"
            />           
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter 
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default AllTask;

const header = [
  {
    headone: "",
    width: "w-[3%] ",
    // isLeft: true,
  },
  {
    headone: "Activity",
    width: "w-[23%] ",
    isLeft: true,
  },
  {
    headone: "Assigned to",
    width: "w-[15%]",
    isLeft: true,
  },
  {
    headone: "Start Date",
    width: "w-[10%]",
  },
  {
    headone: "Due Date",
    width: "w-[10%]",
  },
  {
    headone: "Duration",
    width: "w-[10%]",
  },
  {
    headone: "Priority",
    width: "w-[10%]",
  },
  {
    headone: "Status",
    width: "w-[10%]",
  },
  {
    headone: " ",
    width: "w-[5%]",
  },
  {
    headone: "Action",
    width: "w-[4%]",
    isLeft: true,
  }
];