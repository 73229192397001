import { notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../Services/axiosCommon';
import Dasboard from '../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import {
    BackButton,
    CancelButton,
    SubmitButton,
  } from "../../../Components/Utils/Buttons";
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuMultiSelect, NuSelect, NuUpload } from '../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../Components/Utils/CustomImgUpload';
import NuDate from '../../../Components/Utils/NuDate';

function EditEmployee() {
    const {officeId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state.data;
    const [projectList, setProjectList] = useState([]);
    const [image, setImage] = useState({ preview: "", raw: "" });
  const [api, contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    email: data?.email || "",
    mobileNo: data?.mobileNo || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    branchId: officeId,
    gender: data?.gender || "",
    dob: data?.dob || "",
    dateOfJoin: data?.dateOfJoin || "",
    position: data?.position || "",
    emergencyName: data?.emergencyName || "",
    emergencyRelationship: data?.emergencyRelationship || "",
    emergencyMobileNo: data?.emergencyMobileNo || "",
    location: data?.location || "",
    state: data?.state || "",
    city: data?.city || "",
    pincode: data?.pincode || "",
    country: data?.country || "",
    // projectWorked: [],
    country_code:data?.country_code||"",
    currentProject: data?.currentProject||[],
    type: 1,
    category: data?.category || "",
    nationalId: data?.nationalId || "",
    nationalIdExpiryDate: (data?.nationalIdExpiryDate && NuDate({value:data?.nationalIdExpiryDate,format:"YYYY-MM-DD"}) ) || "",
    passportNumber: data?.passportNumber || "",
    passportExpiryDate: (data?.passportExpiryDate && NuDate({value:data?.passportExpiryDate,format:"YYYY-MM-DD"}) ) || "",
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
    //   item_code:yup.string().required("Item Code is Requried")
    }),
    onSubmit: (value,{resetForm}) => {
      let formData = new FormData();
      formData.append("userData", JSON.stringify(value));
      formData.append('userImage', image.raw); 
      axiosInstance
        .put(`/User/${data?.userId}`, formData)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            resetForm();
            navigate(-1);
            api.success({
              message: `Description Creation successfully`,
              description: res?.data?.msg || "Added In The Description",
              placement: "bottomRight",
            });
          } else {
            api.error({
              message: `Description Creation failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `Description Creation failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = ()=>{
    setImage({
      preview: "",
      raw: "",
    });
  }


  function getAllProject() {
    axiosInstance.get(`/DropDown/${officeId}/getAllProjects`).then((res) => {
      let allProject = res.data?.data;
      let finalData = [];
      res.data?.data &&
        allProject.forEach((i) => {
          let x = {
            value: i.projId,
            label: i.projName,
          };
          finalData.push(x);
        });
      setProjectList(finalData);
    });
  }

  useEffect(()=>{
    getAllProject()
  },[])


  function getHOF() {
    let cDate = new Date();
    return cDate.setFullYear(cDate.getFullYear() - 18);
  }

  return (
    <div className=" w-full h-full py-2 px-4 ">
        {/* <div className=""> */}
        <div className="md:flex justify-between w-full">
            <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Employess</p>)},
            {title:(<p className=' text-[12px] '>Edit Employess</p>)}
            ]} />
            <BackButton/>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-hidden mt-4">
          <div className=" w-full h-full flex justify-start content-start flex-wrap  overflow-y-auto">
          <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Employee Profile</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
            <CustomImgUpload
                isRemove={true}
                removeFunction={handleImageRemove}
                handleChange={handleChangeImg}
                preview={image.preview || data?.imageUrl || ""}
                />
          <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Employee Details</p>
      <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
            <NuInputText
              label="First Name"
              placeholder="First Name"
              formik={formik}
              name="firstName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Last Name"
              placeholder="Last Name"
              formik={formik}
              name="lastName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputEmail
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuMobileNumber
              label="Mobile"
              placeholder="Mobile"
              formik={formik}
              name="mobileNo"
              nunCode="country_code"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
                label="Gender"
                options={[
                    {value: "M", label: "Male"},
                    {value: "F", label: "Female"},
                ]}
                formik={formik}
                placeholder="Choose"
                name="gender"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            <NuDatePicker
              label="Date of Birth"
              formik={formik}
              name="dob"
              width=" w-full "
              max={NuDate({
                value: getHOF(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            <NuDatePicker
              label="Date of Join"
              formik={formik}
              name="dateOfJoin"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
            />
            <NuSelect
                label="Position"
                options={[
                    {value: "Manager", label: "Manager"},
                    {value: "Front Desk", label: "Front Desk"},
                ]}
                formik={formik}
                placeholder="Choose"
                name="position"
                width="w-full md:w-1/2 lg:w-1/3"
              />
            <NuMultiSelect
              label="Assigned Project"
              options={projectList}
              formik={formik}
              placeholder="Choose"
              // isRequired={true}
              name="currentProject"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            {/* <NuMultiSelect
              label="Project Worked"
              options={[
                {value: "Project One", label: "Project One"},
                {value: "Project Two", label: "Project Two"},
            ]}
              formik={formik}
              placeholder="Choose"
              // isRequired={true}
              name="projectWorked"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuSelect
                label="Category"
                options={[
                    {value: "Own", label: "Own"},
                    {value: "Rental", label: "Rental"}
                ]}
                formik={formik}
                placeholder="Choose"
                name="category"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="National Id"
              placeholder="National Id"
              formik={formik}
              name="nationalId"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
                label="NationId Expiry Date"
                formik={formik}
                name="nationalIdExpiryDate"
                width="w-full md:w-[50%] lg:w-1/3"
            />
            <NuInputText
              label="Passport Number"
              placeholder="Passport Number"
              formik={formik}
              name="passportNumber"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
                label="Passport Expiry Date"
                formik={formik}
                name="passportExpiryDate"
                width="w-full md:w-[50%] lg:w-1/3"
            />
            <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Address</p>
            <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
            <NuInputText
              label="Location"
              placeholder="location"
              formik={formik}
              name="location"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="City"
              placeholder="City"
              formik={formik}
              name="city"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="State"
              placeholder="State"
              formik={formik}
              name="state"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Country"
              placeholder="Country"
              formik={formik}
              name="country"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              label="Pincode"
              placeholder="Pincode"
              formik={formik}
              name="pincode"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <p className='w-full pb-2 pt-4 px-4 text-sm font-medium text-[#111537]'>Emergency Contact</p>
            <div className="border-t border-[1px] border-slate-200/60 border-solid w-full mx-4"></div>
            <NuInputText
              label="Name"
              placeholder="Name"
              formik={formik}
              name="emergencyName"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuMobileNumber
              label="Mobile"
              placeholder="Mobile"
              formik={formik}
              name="emergencyMobileNo"
              nunCode="country_code_one"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
                label="Relationship"
                options={[
                    {value: "Father", label: "Father"},
                    {value: "Mother", label: "Mother"},
                    {value: "Brother", label: "Brother"},
                    {value: "Sister", label: "Sister"}
                ]}
                formik={formik}
                placeholder="Choose"
                name="emergencyRelationship"
                width="w-full md:w-1/2 lg:w-1/3"
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton
                  handleClick={() => {
                    navigate(-1)
                  }}
                />
                <SubmitButton/>
              </div>
            </div>
            </div>
            </div>
            </form>
    </div>
  )
}

export default EditEmployee