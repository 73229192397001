import React from "react";
import NuDate from "../../../../Components/Utils/NuDate";

function Delayed({ data }) {
  console.log("data",data);
  return (
    <div className=" w-full h-full overflow-y-auto pb-4">
      <table className="overflow-hidden text-center  text-sm border-collapse  w-full overflow-y-auto">
        <thead className="border-b  rounded-md overflow-hidden font-medium  text-sm">
          <tr
            className=""
            style={{
              borderTop: "1px solid #E9E8EC ",
              borderBottom: "1px solid #E9E8EC ",
            }}
          >
            <th
              scope="col"
              className="sticky py-2 w-[80%] font-medium text-[#6C6580] text-xs text-start pl-2 "
            >
              Task
            </th>
            <th
              scope="col"
              className="sticky py-2 w-[20%] font-medium text-[#6C6580] text-xs"
            >
             Due Date
            </th>
          </tr>
        </thead>
        <tbody className=" px-2 ">
          {data &&
            data.map((Item, Index) => {
              return (
                <tr
                  key={Index}
                  className="px-3 py-1 border-b transition duration-300 ease-in-out hover:bg-neutral-100  "
                  style={{ borderBottom: "1px solid #E9E8EC " }}
                >
                  <td className="text-[#4F4768] font-normal text-[12px] truncate text-start pl-3 py-1">
                    {Item.task_name || "-"}
                  </td>
                  <td className=" text-[#4F4768] font-normal text-[12px] py-1">
                    {Item?.date != "" ? (
                      <NuDate value={Item?.date} format="DD-MMM-YY" />
                    ) : (
                      "-" || "-"
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default Delayed;
