import { Outlet } from "react-router-dom"
const MainMyTask = () =>{

    return(
        <div className=" w-full h-full">
         <Outlet/>
        </div>
    )
}


export default MainMyTask;