import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../Components/Icons/Dasboard";



function ReportIndex() {
  let ReportList = [
    {
      title:"Projects - Overall",
      path:"/reports/projects",
      SubMenu:[
        {
          title:"Bidding", 
          path:"",
        },
        {
          title:"Projects Status", 
          path:"",
        },
        {
          title:"Projects Revenue", 
          path:"",
        },
      ]
    },
    {
      title:"Project",
      path:"/reports/tasks",
      SubMenu:[
        {
          title:"Daily Progress Report", 
          path:"/reports/project/daily-progress-report",
        },
        {
          title:"Overall Project Report", 
          path:"",
        },
        {
          title:"Manpower", 
          path:"",
        },
        {
          title:"Material & Others", 
          path:"",
        },
      ]
    },
    {
      title:"Costing",
      path:"/reports/members",
      SubMenu:[
        {
          title:"Manpower", 
          path:"",
        },
        {
          title:"Material & Others", 
          path:"",
        },
      ]
    },
    {
      title:"Activities",
      path:"/reports/clients",
      SubMenu:[
        {
          title:"Activities - Overall", 
          path:"",
        },
        {
          title:"Daily Activity", 
          path:"",
        },
        {
          title:"Pending Activities", 
          path:"",
        },
        {
          title:"Installation Progress", 
          path:"",
        },
      ]
    },
    {
      title:"Payments",
      path:"/reports/members",
      SubMenu:[
        {
          title:"Invoice Outgoing", 
          path:"",
        },
        {
          title:"Invoice Incoming", 
          path:"",
        },
        {
          title:"Payments yet to Receive", 
          path:"",
        },
        {
          title:"Payments yet to Receive", 
          path:"",
        },
      ]
    },
    {
      title:"Other",
      path:"/reports/clients",
      SubMenu:[
        {
          title:"Clients", 
          path:"/reports/clients",
        },
        {
          title:"Members", 
          path:"",
        },
      
      ]
    }
  ]
  return (
    <div className="w-full h-[calc(100vh-60px)]  px-4 py-2 overflow-hidden "> 
          <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Report List</p>)}]} />
            <div className=' w-full h-[calc(100vh-100px)]  overflow-y-auto'>
              <div className=' w-full h-auto  grid grid-cols-4  2xl:grid-cols-6 gap-3 py-4'>
                {
                  ReportList && ReportList.map((item,idx)=>{
                    return(
                      <div key={idx}>
                      {/* <Link to={item.path} className=' text-mainColor-400 hover:' > */}
                      <div className=' py-4 px-4 rounded-md  flex  items-center text-white bg-slate-300 font-medium text-xs'
                      style={{border:'1px solid #dedede'}}
                      >{item.title}</div>
                      {
                        item?.SubMenu && item?.SubMenu.map((links,indx)=>{
                          return (
                            <Link to={links.path} className=' w-full text-mainColor-400 hover:text-mainColor-400 my-2' key={indx}>
                      <div className='w-full py-4 rounded-md hover:shadow-[0_3px_10px_rgb(0,0,0,0.2)] px-4 text-mainColor-400 font-medium text-xs'
                      // style={{border:'1px solid #dedede'}}
                      >{links.title}</div>
                            </Link>
                          )
                        })
                      }
                      {/* </Link>  */}
                      </div>
                    )
                  })
                }               
              </div>

            </div>
    </div>
  )
}

export default ReportIndex;