import { Link } from 'react-router-dom';
import More from '../../../../../../Components/Icons/More';
import { Modal, notification } from 'antd';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { ExclamationCircleFilled } from "@ant-design/icons";
import { GetAccess } from '../../../../../../Components/Utils/roles';


const {confirm} = Modal


const ViewMore = ({ data,getData,id}) => {
  const [api, contextHolder] = notification.useNotification();


  const handleDelete = async(id,removevalue) =>{
    confirm({
        title: `Do you Want to delete ${removevalue}?`,
        centered:true,
        icon: <ExclamationCircleFilled />,
        content: `The ${removevalue} will be removed from the Incoming List`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/payments/${id}`).then((res) => {
            if(res.status === 201||res.status === 200) {
                getData();
                api.success({
                  message: `Incoming deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `Incoming delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `Incoming delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}


  return (
    <>
      <div className='text-[#344767]  relative dropdown inline-block z-50'>
        {contextHolder}
        <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
          <span className='pt-1 z-10'>
            <More/>
          </span>
        </div>
        <ul className='dropdown-menuOne bg-red-400  text-[#344767] text-left  rounded-lg absolute -left-[100px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
        <li className=' w-[100px] bg-black block m-0'> 
            <Link
              className='bg-white text-black hover:text-black hover:bg-gray-100 py-2 px-2 z-10 block whitespace-no-wrap text-xs font-medium' 
             to={`/payments/invoice-in/view/${id}`} state={{data:data}}>View</Link>
          
          </li>
         {
          GetAccess() && (
            <li className=' w-[100px] bg-black block m-0'>

            <Link 
              className='bg-white text-black hover:text-black hover:bg-gray-100 py-2 px-2 z-10 block whitespace-no-wrap text-xs font-medium' 
            to={`/payments/invoice-in/edit/${id}`} state={{data:data}}>Edit</Link>
            
          </li>
          )
         }

        {
          GetAccess() && (
            <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 cursor-pointer py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              onClick={
                () => {
                  handleDelete(data.paymentId,data?.paymentId);
                }
              }
            >
              Delete
            </span>
          </li>  
          )
         }
        </ul>
      </div>


    </>
  );
};

export default ViewMore;
