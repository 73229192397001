import { Link } from 'react-router-dom';
import More from '../../../../../../Components/Icons/More';



const ViewMoreSubTask = ({ data, getClients,id,projectId }) => {

  return (
    <>
      <div className='text-[#344767]  relative dropdown inline-block z-50'>
        <div className=' cursor-pointer underline-offset-8 text-sm mx-auto font-semibold flex items-center'>
          <span className='pt-1'>
            <More/>
          </span>
        </div>
        <ul className='dropdown-menuOne bg-red-400  text-[#344767] text-left  rounded-lg absolute -left-[100px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
        <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              
            >
              <Link to={`/tasks/subtask/view/${id}`} state={{projectId:projectId}}>View</Link>
              
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ViewMoreSubTask;
