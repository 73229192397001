import React from "react";

function TaskByMembers({ data }) {
  return (
    <div className=" w-full overflow-y-auto h-full pb-4">
      <table className="overflow-hidden text-center  text-sm border-collapse w-full overflow-y-auto">
        <thead className="border-b  rounded-md overflow-hidden font-medium  text-sm">
          <tr
            className=""
            style={{
              borderTop: "1px solid #E9E8EC ",
              borderBottom: "1px solid #E9E8EC ",
            }}
          >
            <th
              scope="col"
              className="sticky py-2 w-[45%] font-medium text-start pl-2 text-[#6C6580]  text-xs"
            >
              Name
            </th>
            <th
              scope="col"
              className="sticky py-2 w-[20%] font-medium text-[#6C6580]  text-xs"
            >
              Total
            </th>
            <th
              scope="col"
              className="sticky py-2 w-[20%] font-medium text-[#6C6580]  text-xs"
            >
              Open
            </th>
            <th
              scope="col"
              className="sticky py-2 w-[20%] font-medium text-[#6C6580]  text-xs"
            >
              Completed
            </th>
          </tr>
        </thead>
        <tbody className=" px-2 ">
          {data &&
            data.map((Item, Index) => {
              return (
                <tr
                  key={Index}
                  className="p-3 border-b transition duration-300 ease-in-out hover:bg-neutral-100  h-8"
                  style={{ borderBottom: "1px solid #E9E8EC " }}
                >
                  <td className=" text-xs text-start pl-3">
                    {Item.name || "-"}
                  </td>
                  <td className=" text-xs text-[#4F4768] truncate">{Item?.total_task || 0}</td>
                  <td className=" text-xs text-[#4F4768]">{Item?.open || 0}</td>
                  <td className=" text-xs text-[#4F4768]">{Item?.closed || 0}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default TaskByMembers;
