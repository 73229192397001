import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../../../Services/axiosCommon';
import { BackButton } from '../../../../Components/Utils/Buttons';

function RoleView() {
  const {roleViewId} = useParams()
  const location = useLocation()
  const [data, setData] = useState([])
  const project = [
    {id:1, name:'View'},
    {id:2, name:'Create'},
    {id:3, name:'Edit'},
    {id:4, name:'Delete'},
  ]
  const myTask = [
    {id:5, name:'View'},
    {id:6, name:'Create'},
    {id:7, name:'Edit'},
    {id:8, name:'Delete'},
  ]
  const mySubTask = [
    {id:9, name:'View'},
    {id:10, name:'Create'},
    {id:11, name:'Edit'},
    {id:12, name:'Delete'},
  ]
  const allTask = [
    {id:13, name:'View'},
    {id:14, name:'Create'},
    {id:15, name:'Edit'},
    {id:16, name:'Delete'},
  ]
  const allSubTask = [
    {id:17, name:'View'},
    {id:18, name:'Create'},
    {id:19, name:'Edit'},
    {id:20, name:'Delete'},
  ]
  const notes = [
    {id:21, name:'View'},
    {id:22, name:'Create'},
    {id:23, name:'Edit'},
    {id:24, name:'Delete'},
  ]
  const members = [
    {id:25, name:'View'},
    {id:26, name:'Create'},
    {id:27, name:'Edit'},
    {id:28, name:'Delete'},
  ]
  const team = [
    {id:29, name:'View'},
    {id:30, name:'Create'},
    {id:31, name:'Edit'},
    {id:32, name:'Delete'},
  ]
  const client = [
    {id:33, name:'View'},
    {id:34, name:'Create'},
    {id:35, name:'Edit'},
    {id:36, name:'Delete'},
  ]
  const setting = [
    {id:37, name:'View'},
    {id:38, name:'Edit'},
  ]
  const profileSetting = [
    {id:39, name:'View'},
    {id:40, name:'Create'},
    {id:41, name:'Edit'},
    {id:42, name:'Delete'},
  ]
  const roleSetting = [
    {id:43, name:'View'},
    {id:44, name:'Create'},
    {id:45, name:'Edit'},
    {id:46, name:'Delete'},
  ]
  const userSetting = [
    {id:47, name:'View'},
    {id:48, name:'Create'},
    {id:49, name:'Edit'},
    {id:50, name:'Delete'},
  ]
  const projectSetting = [
    {id:51, name:'View'},
    {id:52, name:'Create'},
    {id:53, name:'Edit'},
    {id:54, name:'Delete'},
  ]
  const taskSetting = [
    {id:55, name:'View'},
    {id:56, name:'Create'},
    {id:57, name:'Edit'},
    {id:58, name:'Delete'},
  ]
  const noteSetting = [
    {id:59, name:'View'},
    {id:60, name:'Create'},
    {id:61, name:'Edit'},
    {id:62, name:'Delete'},
  ]
  const isProjectShow = project.some(element => data.includes(element.id));
  const isMyTask = myTask.some(element => data.includes(element.id));
  const isMySubTask = mySubTask.some(element => data.includes(element.id));
  const isAllTask = allTask.some(element => data.includes(element.id));
  const isAllSubTask = allSubTask.some(element => data.includes(element.id));
  const isNotes = notes.some(element => data.includes(element.id));
  const isMembers = members.some(element => data.includes(element.id));
  const isTeam = team.some(element => data.includes(element.id));
  const isClient = client.some(element => data.includes(element.id));
  const isSetting = setting.some(element => data.includes(element.id));
  const isProfileSetting = profileSetting.some(element => data.includes(element.id));
  const isRoleSetting = roleSetting.some(element => data.includes(element.id));
  const isUserSetting = userSetting.some(element => data.includes(element.id));
  const isProjectSetting = projectSetting.some(element => data.includes(element.id));
  const isTaskSetting = taskSetting.some(element => data.includes(element.id));
  const isNoteSetting = noteSetting.some(element => data.includes(element.id));

  useEffect(() => {
    axiosInstance.get(`/rolePermission/role/${roleViewId}`).then(res=>{
      res?.data?.data?.permissions && setData(res?.data?.data?.permissions)
    }).catch(error=>console.log(error))
  },[])


  return (
    <div className="w-full h-[80vh] overflow-hidden my-1 flex justify-center items-center">
      <div className=" w-full h-full overflow-y-scroll pb-6">
        <div className=" w-full flex justify-between items-center">
          <h3 className=" font-medium text-sm">
            Role Name : {location.state?.name || "-"}{" "}
          </h3>
          <BackButton />
        </div>
        <p className=" font-medium text-xs">Permissions</p>
        <div className=" py-3 px-2">
          {isProjectShow && 
          <>
          <p className=" font-semibold text-xs">Project</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                project.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isMyTask && 
          <>
          <p className=" font-semibold text-xs">My Task</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                myTask.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isMySubTask && 
          <>
          <p className=" font-semibold text-xs">My Sub Task</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                mySubTask.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isAllTask && 
          <>
          <p className=" font-semibold text-xs">All Task</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                allTask.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isAllSubTask && 
          <>
          <p className=" font-semibold text-xs">All Sub Task</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                allSubTask.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isNotes && 
          <>
          <p className=" font-semibold text-xs">Notes</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                notes.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isMembers && 
          <>
          <p className=" font-semibold text-xs">Members</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                members.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isTeam && 
          <>
          <p className=" font-semibold text-xs">Team</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                team.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isClient && 
          <>
          <p className=" font-semibold text-xs">Client</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                client.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isSetting && 
          <>
          <p className=" font-semibold text-xs">Setting</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                setting.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isProfileSetting && 
          <>
          <p className=" font-semibold text-xs">Profile Setting</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                profileSetting.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isRoleSetting && 
          <>
          <p className=" font-semibold text-xs">Role Setting</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                roleSetting.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isUserSetting && 
          <>
          <p className=" font-semibold text-xs">User Setting</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                userSetting.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isProjectSetting && 
          <>
          <p className=" font-semibold text-xs">Project Setting</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                profileSetting.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isTaskSetting && 
          <>
          <p className=" font-semibold text-xs">Task Setting</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                taskSetting.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
            {isNoteSetting && 
          <>
          <p className=" font-semibold text-xs">Note Setting</p>
          <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pb-3 pt-1">
              {data &&
                noteSetting.filter((role) => data.includes(role.id)).map(
                  (item) => {
                    return (
                      <div
                        key={item.id}
                        className=" w-full bg-slate-100 p-3 rounded-md"
                      >
                        {item.name}
                      </div>
                    );
                  }
                )}
            </div></>}
        </div>
        {/* <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  py-3 px-2">
            {
              data &&
                  AllPermission.filter(role=> data.includes(role.id)).map(item=>{
                    return (
                      <div key={item.id} className=" w-full bg-slate-100 p-3 rounded-md">
                      {item.name}
                      </div>
                    )
                  })
                }
            </div> */}
        {/* <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  py-3 px-2">
            {
              data &&
                  AllPermission.filter(role=> data.includes(role.id)).map(item=>{
                    return (
                      <div key={item.id} className=" w-full bg-slate-100 p-3 rounded-md">
                      {item.name}
                      </div>
                    )
                  })
                }
            </div> */}
      </div>
    </div>
  );
}

export default RoleView;