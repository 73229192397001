import React from 'react'
import { BackButton } from '../../../../../../Components/Utils/Buttons'
import { Link, useLocation } from 'react-router-dom'
import Dasboard from '../../../../../../Components/Icons/Dasboard'
import BreadCrumb from '../../../../../../Components/Utils/Breadcrumbs'
import NuDate from '../../../../../../Components/Utils/NuDate'
import NuAmount from '../../../../../../Components/Utils/NuAmount'

function ViewInvoiceIn() {
  const location = useLocation();
  const data = location.state?.data;
  return (
    <div className="w-full h-[calc(100vh-60px)]  px-4 py-2 overflow-hidden ">
    <div className="md:flex justify-between w-full">
      <BreadCrumb
        items={[
          {
            title: (
              <Link to="/dashboard">
                <Dasboard color="gray" width={10} height={10} />{" "}
              </Link>
            ),
          },
          { title: ( <Link to="/payments/invoice-in">
            <p className=" text-[12px] ">Incoming</p>
          </Link>) },
          { title: <p className=" text-[12px] ">View</p> },
        ]}
      />
      <div className=" flex justify-between items-center float-right">
        <BackButton />
      </div>
    </div>
    <div className=" w-full  h-[calc(100vh-120px)] overflow-y-auto mt-3 rounded-lg shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] p-4 ">
      <div className=" w-full h-auto grid grid-cols-1  md:grid-cols-3">
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Project </p>
          <p className=' text-xs  text-mainColor-400'>{data?.project_name||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Invoice # </p>
          <p className=' text-xs  text-mainColor-400'>{data?.invoice_no||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Date </p>
          <p className=' text-xs  text-mainColor-400'>{data?.date &&  data?.date !="" && data?.date !="None" && <NuDate value={data?.date} format="DD-MMM-YYYY"  />  ||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Submitted on</p>
          <p className=' text-xs  text-mainColor-400'>{data?.submittedOn &&  data?.submittedOn !="" && data?.submittedOn !="None" && <NuDate value={data?.submittedOn} format="DD-MMM-YYYY"  />||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Amount</p>
          <p className=' text-xs  text-mainColor-400'> <NuAmount value={data?.amount||0}/></p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Received/Paid Amount </p>
          <p className=' text-xs  text-mainColor-400'><NuAmount value={data?.receivedAmount||0}/></p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Balance Amount </p>
          <p className=' text-xs  text-mainColor-400'><NuAmount value={ data?.amount - data?.receivedAmount||0}/></p>
        </div>

        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Description </p>
          <p className=' text-xs  text-mainColor-400'>{data?.description||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Status </p>
          <p className=' text-xs  text-mainColor-400'>{data?.status||"-"}</p>
        </div>
        <div className=' my-2'>
          <p className=' text-[13px] font-medium text-mainColor-400'>Comments </p>
          <p className=' text-xs  text-mainColor-400'>{data?.comments||"-"}</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ViewInvoiceIn;