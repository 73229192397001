import { useEffect, useState } from 'react';
import axios from '../../../../Services/axiosCommon';
import { NuInputSubmit } from '../../../../Components/Utils/NuInput';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Modal, notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextSmallBold, TextSmallNormal } from '../../../../Components/Utils/Text';

const { confirm } = Modal;

const Client = () => {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [priority, setPriority] = useState([]);
  const [createNew, setCreateNew] = useState({
    itemName: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      itemName: yup.string().required('Status is Required'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let finalArray = priority;
      finalArray.push(value.itemName);
      let finalObject = {
        keyname: 'clientPosition',
        value: finalArray,
      };
      axios
        .put('/Metadata/update', finalObject)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            resetForm();
            getDatas();
            api.success({
              message: `Client Postion  Created successfully`,
              description: response.data.msg || '',
              placement: 'bottomRight',
            });
          } else {
            api.error({
              message: `Client Postion Created failed`,
              description: response.data.msg || 'Network error',
              placement: 'bottomRight',
            });
          }
        })
        .catch((error) => {
          api.error({
            message: `Client Position  Created failed`,
            description: error.data.msg || 'Sever error (or) Network error',
            placement: 'bottomRight',
          });
        });
    },
  });

  const getDatas = async () => {
    await axios
      .get(`/Metadata/clientPosition`)
      .then((res) => {
        let data = res.data?.data?.keyValues;
        let tempData = [];
        data.forEach((item) => {
          let tempObject = { value: item, label: item };
          tempData.push(tempObject);
        });
        res.data?.data?.keyValues && setData(tempData);
        res.data?.data?.keyValues && setPriority(res.data?.data?.keyValues);
      })
      .catch((err) => {
        api.error({
          message: `Client Position fail to Load`,
          description: err.data.msg | 'Network error',
          placement: 'bottomRight',
        });
      });
  };

  const handleDelete = async (removevalue) => {
    confirm({
      title: `Do you Want to delete ${removevalue}?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `The ${removevalue} will be removed from the Clients Position`,
      onOk() {
        let newArray = [];

        for (let i = 0; i < priority.length; i++) {
          if (priority[i] !== removevalue) {
            newArray.push(priority[i]);
          }
        }

        let finalObject = {
          keyname: 'clientPosition',
          value: newArray,
        };
        axios
          .put('/Metadata/update', finalObject)
          .then((response) => {
            getDatas();
            api.success({
              message: `Client Position Deleted successfully`,
              description: response.data.msg || 'Network error',
              placement: 'bottomRight',
            });
          })
          .catch((error) => {
            api.error({
              message: `Client Postion  Deleted unsuccessfully`,
              description: error?.response?.data?.msg || 'Network error or Server Error',
              placement: 'bottomRight',
            });
          });
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    getDatas();
  }, []);
  return (
    <div className=' w-full'>
      {contextHolder}
      <form onSubmit={formik.handleSubmit}>
        <NuInputSubmit label='Role' placeholder='Enter Role' name='itemName' width='w-full' formik={formik} />
      </form>
      <div className=' px-3'>
        <div className='w-full shadow rounded-lg h-44 border-2 border-red-500 my-4 overflow-x-hidden overflow-y-auto'>
          <ul className=''>
            {data.map((List, idx) => {
              return (
                <li
                  className=' m-1  flex justify-between items-center text-xs bg-violet-50 rounded text-main-title py-2 px-2 hover:bg-slate-200'
                  key={idx}
                >
                  <TextSmallNormal text={List.value || "-"} />
                  {List?.value && (
                    <span
                      onClick={() => {
                        handleDelete(List?.value);
                      }}
                      className=' text-blue-900 cursor-pointer'
                    >
                      {/* <FontAwesomeIcon icon={faXmark} /> */}
                      X
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Client;
