import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { notification } from 'antd';
import axiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuInputEmail, NuInputText, NuMobileNumber, NuTextArea } from '../../../../Components/Utils/NuInput';


function CreateBranch() {
  const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
  // const imagetype = ["png", "jpg", "jpeg", "JPG", "PNG", "JPEG"];
  // const [selectedImage, setSelectedImage] = useState(null);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [image, setImage] = useState({ preview: "", raw: "" });
//   const [attachment, setAttachment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [createNew,setCreateNew] = useState({
    company_id:1,
    branch_name:"",
    email:"",
    mobile:"",
    website:"",
    address:"" ,
    currency:"",
    countryCode:"",
  })
//   const handleChangeImg = (e) => {
//     if (e.target.files.length) {
//       setImage({
//         preview: URL.createObjectURL(e.target.files[0]),
//         raw: e.target.files[0],
//       });
//     }
//   };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
        branch_name : yup.string().required('Branch name is required.'),
        email : yup.string().required('Email is required.'),
    }),

    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);
    //   let formData = new FormData();
    //   formData.append('biddingData', JSON.stringify(value));
    //   if (image.raw) {
    //     // selectedFile.forEach((file) => {
    //       formData.append("attachments", image.raw);
    //     // });
    //   } else {
    //     formData.append("attachments","")
    //   }
      axiosInstance.post('/company_branch/', value)
        .then((res) => {
            if(res.data.status === true){
              setIsLoading(false);
              resetForm();
              navigate(-1);
              api.success({
                message: 'Success',
                description: res.data.msg || 'Branch Created',
                placement: 'bottomRight',
                duration: 3,
                role: 'alert',
              })
              
            }
            else{
              setIsLoading(false);
              api.error({
                message : 'Failed',
                description: res.data.msg || 'Error',
                placement :'bottomRight',
                duration: 3,
                role: 'alert',
              })
            }
        })

        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          api.error({
            message: `Failed`,
            description: err?.data?.msg || 'Network error',
            placement: "bottomRight",
            duration: 3,
          });
        });
      
    }
  });
  return (
    <div className="w-full h-full px-4 py-2">
    {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Settings</p> },
            { title: <p className=" text-[12px] ">Branches</p> },
            { title: <p className=" text-[12px] text-[#4E4B4B]">Create</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton/>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[65vh] bg-white rounded-xl overflow-hidden">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            {/* <NuSelect
              label="Client ID"
              options={[
                { value: 1, label: "A" },
                { value: 2, label: "B" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="clientId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <NuInputText
              isRequired={true}
              label="Branch Name"
              placeholder="Branch Name"
              formik={formik}
              name="branch_name"
              width="w-full md:w-1/3"
            />
            <NuInputEmail
              isRequired={true}
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/3"
            />
            <NuMobileNumber
              label="Mobile"
              nunCode='countryCode'
              placeholder="Mobile"
              formik={formik}
              name="mobile"
              width="w-full md:w-1/3"
            />
            <NuInputText
              label="Website"
              placeholder="Website"
              formik={formik}
              name="website"
              width="w-full md:w-1/3"
            />
            {/* <NuSelectCurrency
              label="Currency"
              formik={formik}
              name="currency"
              width="w-full md:w-1/3"
            /> */}
            <NuTextArea
              label="Address"
              placeholder="Address"
              formik={formik}
              name="address"
              width='w-full md:w-1/3'
            />
              {/* <NuDatePicker
              label="Submission Due Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="submissionDueDate"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateBranch;