import React, { useState } from "react";


const ExpandableManpower = ({}) => {
    const [data, setData] = useState([1,2,3,4,5,6,7,8,])



  return (
    <>
    {
        data.map((item, index) => (
            <tr className={`w-[100%] bg-white text-xs font-medium`} style={{borderBottom:'1px solid #c7dfe0'}} key={index}>
            <td className=" w-[5%] py-3 px-1">
                <div className=" w-full gap-1 flex">
                <input 
            type="checkbox" 
            // checked={titleList.some(id=>id===rowData?.titleId)}
            // onChange={()=>titleSelect(rowData?.titleId)}
            />
                {index+1}
                </div>
            </td>
            <td className=" w-[35%] py-3 px-1">{`Description of the some index ${index+1} `}</td>
            <td className=" w-[15%] py-3 px-1">Kg</td>
            <td className=" w-[10%] py-3 px-1">{parseInt(Math.random()*10)*(index+1)}</td>
            <td className=" w-[15%] py-3 px-1">{parseInt(Math.random()*1000)*(index+1)}</td>
            <td className=" w-[15%] py-3 px-1">{parseInt(Math.random()*1000)*(index+1)}</td>
            <td className=" w-[5%] py-3 px-1"> </td>
           </tr>
        ))
    }
    </>
  );
};

export default ExpandableManpower;
