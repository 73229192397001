import { useEffect, useState } from "react";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import axiosInstance from "../../../../Services/axiosCommon";
import { useFormik } from "formik";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import { Link } from "react-router-dom";
import Dasboard from "../../../../Components/Icons/Dasboard";
import { BackButton, CreateButton } from "../../../../Components/Utils/Buttons";
import { Button, Modal } from "antd";
import { NuSelectwithSearch } from "../../../../Components/Utils/NuInput";
import NuDate from "../../../../Components/Utils/NuDate";


function ClientsReport() {
  const {value} = useFetchMataData(`/Metadata/clientPosition`)
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilter,setIsFilter] = useState(false)
  const [data,setData] = useState([])
  const [xlsData,setXlsData] = useState([])
  const [filterData,setFilterData]= useState({
    job_role: "",
    location: ""
  });


  function getAllData(){
    axiosInstance.get(`/report/client`).then(res=>{
      // console.log("ssss",res?.data?.data);
      let forMap = res?.data?.data;
      let tempArr = []
      forMap && forMap.forEach(item=>{
      let tempData = {
        "Company Name":item.company_name||"",
        "Location":item.location||"",
        "Contact Person": item?.contact_person||"",
        "Job Role":item?.job_role||"",
        "Mobile":item?.mobile||"",
        "Website":item?.website||"",
      }
      tempArr.push(tempData)
      })
      setXlsData(tempArr);
      res.data?.data && setData(res?.data?.data)
    }).catch(err=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    getAllData()
  },[])


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   mobileNo: yup
    //     .string()
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(10, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    // }),
    onSubmit: async (value, { resetForm }) => {
    
      setIsFilter(true)
      axiosInstance
      .get(`/report/client?job_role=${value.job_role}`)
      .then((res) => {
        setFilterOpen(false)
        let forMap = res?.data?.data;
        let tempArr = []
        forMap && forMap.forEach(item=>{
        let tempData = {
          "Company Name":item.company_name||"",
          "Location":item.location||"",
          "Contact Person": item?.contact_person||"",
          "Job Role":item?.job_role||"",
          "Mobile":item?.mobile||"",
          "Website":item?.website||"",
        }
        tempArr.push(tempData)
        })
        setXlsData(tempArr);
        res.data?.data && setData(res?.data?.data)
        

      })
      .catch((error) => {
        console.log(error);
      });
    },
  });


  
  function ClearFilter(){
    formik.resetForm({})
    getAllData()
    setIsFilter(false)
  }


  let headers = [
    {
      title: "Sl.No",
      width: "5%",
      field: "job_role",
      textAlign: "center",
      rowAlign: "center",
    },
    {
      title: "Company Name",
      width: "13%",
      field: "company_name",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Location",
      width: "10%",
      field: "location",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Contact Person",
      width: "10%",
      field: "contact_person",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Job Role",
      width: "8%",
      field: "job_role",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Mobile",
      width: "8%",
      field: "mobile",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Email",
      width: "8%",
      field: "email",
      textAlign: "left",
      rowAlign: "left",
    },
    {
      title: "Website",
      width: "8%",
      field: "website",
      textAlign: "left",
      rowAlign: "left",
    },
  ];



  return (
    <div className=" w-full h-[90vh] px-4 py-2 overflow-hidden">
      <div className="md:flex justify-between w-full">
      <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<Link to='/reports'><p className=' text-[12px] '>Reports Lists</p></Link>)},{title:(<p className=' text-[12px] py-[1px]'>Clients</p>)}]} />
      <div className='  flex justify-center items-center float-right'>
      <div className=" pb-2 w-full flex justify-center gap-2 items-center ">
      <div className=" flex justify-center items-center gap-3 md:mr-6">
      <CreateButton label="Filter" handleClick={()=>setFilterOpen(true)}   />
      {isFilter&& <CreateButton label='Clear Filter' handleClick={ClearFilter} />}
      {/* <ExportXls csvData={xlsData} fileName="Clients Report"  /> */}
      {/* <CreateButton label="PDF" handleClick={()=>{}}   /> */}
      {/* <ClientDownloadLink headers={headers} data={data}
                      fileName={`Client Report ${NuDate({
                        value: Date(),
                        format: "DD-MMM-YYY",
                      })}.pdf`}
      /> */}
      <BackButton/>
      </div>
      </div>
      </div>
      </div>

      <div className=" w-full h-[98%]   overflow-y-auto">
      <table className="table-auto w-full" style={{ border: 'none', borderCollapse: 'collapse' }}>
      <thead className=" w-full bg-[#2A475E]">
      <tr className="text-xs  font-medium   py-2 ">
      {header.map((header, i) => (
                <th className={`py-2 ${header.width} font-normal ${header?.isLeft?"text-left":"text-center"} text-xs px-2 text-white`} key={i} style={{ border: 'none', borderCollapse: 'collapse' }}>
                  {header.headone}
                </th>
              ))}
      </tr>
      </thead>
      <tbody>
      {
        data && data.map((item,idx)=>{
          return(
            <tr className='`w-[100%] bg-[#F6F9FF] text-xs' key={idx}>
            <td className=' pl-2 py-2'>{idx+1}</td>
            <td className=' pl-2 py-2 w-[13%]'>{item?.company_name||""}</td>
            <td className=' pl-2 py-2  w-[10%]'>{item?.location||""}</td>
            <td className=' pl-2 py-2  w-[10%]'>{item?.contact_person||""}</td>
            <td className=' pl-2 py-2  w-[8%]'>{item?.job_role||""}</td>
            <td className=' pl-2 py-2  w-[8%]'>{item?.mobile||""}</td>
            <td className=' pl-2 py-2  w-[8%]'>{item?.email||""}</td>
            <td className=' pl-2 py-2  w-[8%]'>{item?.website||""}</td>
          </tr>
          )
        })
      }
      </tbody>
      </table>
      </div>
      <Modal
        open={filterOpen}
        title={"Filter Client"}
        width={"30%"}
        className="my-modal"
        centered
        onCancel={()=>setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuSelectwithSearch
              label="Job Role"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="job_role"
              width=" w-full"
            />
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter 
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default ClientsReport;


const header = [
  {
    headone: "Sl.no",
    width: "w-[3%] ",
    isLeft: true,
  },
  {
    headone: "Company Name",
    width: "w-[13%] ",
    isLeft: true,
  },
  {
    headone: "Location",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Contact Person",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Job Role",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Mobile",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Email",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Website",
    width: "w-[8%]",
    isLeft: true,

  },
];