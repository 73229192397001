import React from 'react'
import MyTask from './MyTask'

function MobileTask({Data,getData,firstProject}) {
  return (
    <div className=" w-full  h-[68vh] overflow-y-auto">
    {
      Data.map((item, i) => (
                <MyTask firstProject={firstProject} item={item} key={i} getData={getData} />
      ))
    }
    </div>
  )
}

export default MobileTask