import React from 'react'
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import { CreateButton } from '../../../../../../Components/Utils/Buttons';


function ViewSwitcher({
    onViewModeChange,
    onViewListChange,
    isChecked,
    viewMode,   
    hasEdit,
    setHasEdit
}) {
  return (
    <div className=' flex items-center gap-2'>
              <div className="  flex  items-center my-3 gap-4 relative">
                  <p className=" text-xs">Edit Task</p>
                  <input
                    class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-[#BFD0FF] checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-[#1292DC] checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type="checkbox"
                    checked={hasEdit}
                    name="notif_for_task"
                    onClick={() => setHasEdit(!hasEdit)}
                    role="switch"
                  />
                </div>
        {/* <CreateButton svg={false} label='Hour' color={viewMode==ViewMode.Hour?"bg-[#2A475E]":"bg-[#119DEE]"} handleClick={()=>onViewModeChange(ViewMode.Hour)} /> */}
        {/* <CreateButton svg={false} label='Quarter of Day' color={viewMode==ViewMode.QuarterDay?"bg-[#2A475E]":"bg-[#119DEE]"} handleClick={()=>onViewModeChange(ViewMode.QuarterDay)} /> */}
        {/* <CreateButton svg={false} label='Half of Day' color={viewMode==ViewMode.HalfDay?"bg-[#2A475E]":"bg-[#119DEE]"} handleClick={()=>onViewModeChange(ViewMode.HalfDay)} /> */}
        <CreateButton svg={false} label='Day' color={viewMode==ViewMode.Day?"bg-[#2A475E]":"bg-[#119DEE]"} handleClick={()=>onViewModeChange(ViewMode.Day)} />
        <CreateButton svg={false} label='Week' color={viewMode==ViewMode.Week?"bg-[#2A475E]":"bg-[#119DEE]"} handleClick={()=>onViewModeChange(ViewMode.Week)} />
        <CreateButton svg={false} label='Month' color={viewMode==ViewMode.Month?"bg-[#2A475E]":"bg-[#119DEE]"} handleClick={()=>onViewModeChange(ViewMode.Month)} />
        <CreateButton svg={false} label='Year' color={viewMode==ViewMode.Year?"bg-[#2A475E]":"bg-[#119DEE]"} handleClick={()=>onViewModeChange(ViewMode.Year)} />
        <div className="  flex  items-center my-3 gap-4 relative">
                  <p className=" text-xs">Show Task</p>
                  <input
                    class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-[#BFD0FF] checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-[#1292DC] checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type="checkbox"
                    checked={isChecked}
                    name="notif_for_task"
                    onClick={() => onViewListChange(!isChecked)}
                    role="switch"
                  />
                </div>
    </div>
  )
}

export default ViewSwitcher