import React from 'react'

function ViewManpowerItems({ item, data, open, onCancel }) {
    const displayItem = item || data;
  return (
    <div className=" w-full h-auto grid grid-cols-1  md:grid-cols-3">
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Role</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.role || "-"}</p>
      </div>
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Type</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.type==0?"Own":"Rental" || "-"}</p>
      </div>
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Quantity</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.quantity || "-"}</p>
      </div>
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>No Of Days</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.days || "-"}</p>
      </div>
      <div className='my-2'>
        <p className='text-[13px] font-medium text-mainColor-400 py-1'>Amount</p>
        <p className='text-xs text-mainColor-400'>{displayItem?.amount || "-"}</p>
      </div>
    </div>
  )
}

export default ViewManpowerItems;