import ProjectStatus from "./projectStatus";

const ProjectSetting = () => {
    return (
      <div className=' w-full '>
        <div className=" w-full flex justify-between items-center">
          <h3 className=" text-sm text-mainColor-400 font-semibold">Projects</h3>
          {/* <NormalHeading title={'Projects'} /> */}
          {/* <BackButton/> */}
        </div>
  
        <div className='w-full flex flex-wrap justify-center md:justify-start items-center'>
          <div className=' w-full md:w-1/2 lg:w-1/3'>
            <ProjectStatus/>
          </div>
          {/* <div className=' w-full md:w-1/2 lg:w-1/3'>
            <MemberPositions/>
          </div> */}

        </div>
      </div>
    );
  };
  
  export default ProjectSetting;