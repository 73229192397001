import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import { BackButton, EditButton } from '../../../Components/Utils/Buttons';
import { NormalHeading } from '../../../Components/Utils/Heading';
import Dasboard from '../../../Components/Icons/Dasboard';
import NuDate from '../../../Components/Utils/NuDate';

function ViewEmployee() {
    const location = useLocation();
    const data = location.state.data;
  return (
    <div className=" w-full h-full py-2 px-4 ">
        {/* <div className=""> */}
        <div className="md:flex justify-between w-full">
            <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Employess</p>)},
            {title:(<p className=' text-[12px] '>View Employess</p>)}
            ]} />
            <div className='flex justify-end gap-2'>
             <Link to={`/main/employees/${data?.branchId}`}/><BackButton/><Link/>
             {/* <Link to={`/main/employees/${data?.branchId}/edit/${data?.agentId}`} state={{data: data}}> */}
                <EditButton height='8'/>
                {/* </Link> */}
            </div>
        </div>
        <div className="w-full mx-auto h-[calc(100vh-145px)] bg-white rounded-md overflow-y-scroll p-3 mt-4">
      <NormalHeading title={"Employee Details"} />
    <div className='flex'> 
         <div className='w-full  flex '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>
            <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>First Name</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.firstName || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Last Name</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.lastName || '-'}</p>
                  </div>
                  {/* <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Company Name</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.companyName || '-'}</p>
                  </div> */}
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Gender</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.gender || '-'}</p>
                  </div>
                   <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Email</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.email || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Phone</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.mobileNo || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Date Of Birth</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.dob || '-'}</p>
                  </div>
                 
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Date Of Joining</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.dateOfJoin || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Passport Number</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.passportNumber || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Passport Expiry Date</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'><NuDate value={data?.passportExpiryDate || '-'} formate="DD-MM-YYYY" /></p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>National Id</p>
                    <p className='w-1/2 py-2  pl-5 text-xs  text-[#606279]'>{data?.nationalId || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>National Id Expiry Date</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'><NuDate value={data?.nationalIdExpiryDate || '-'} formate="DD-MM-YYYY" /></p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.category || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Position</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.position || '-'}</p>
                  </div>
                 
        </div>
    </div>
    <div className='border-r border-[1px] border-slate-200/60 border-solid'/>


            <div className='w-full  flex  '>
            <div className='w-full rounded-[20px]  bg-white py-4  flex  flex-col '>        
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Location</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.location}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>City</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.city || '-'}</p>
                  </div>         
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>State</p>
                    <p className='w-1/2 py-2 pl-5 text-xs  text-[#606279]'>{data?.state || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Country</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.country || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Pincode</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.pincode || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Status</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.isActive == true ? "Active" :data?.isActive == false ? "In-Active" : "-"}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Emergency Name</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.emergencyName || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Emergency Mobile Number</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.emergencyMobileNo || '-'}</p>
                  </div>
                  <div className='odd:bg-white even:bg-slate-50 w-full flex px-2'>
                    <p className='w-1/2 py-2 text-xs font-medium text-[#111537]'>Emergency Relationship</p>
                    <p className='w-1/2 py-2 pl-5  text-xs  text-[#606279]'>{data?.emergencyRelationship || '-'}</p>
                  </div>
                 
          </div>
        </div>
      </div>
              
          </div>
    </div>
  )
}

export default ViewEmployee