import { Modal, notification } from 'antd';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { NuInputfile, NuInputText } from '../../../../../Components/Utils/NuInput';
import { LongButton } from '../../../../../Components/Utils/Buttons';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];



function CreateAttachments({open,onCancel,getData}) {
    const { estmationId } = useParams();
    const [api, contextHolder] = notification.useNotification();
    const [isLoading,setIsLoading] = useState(false);
    const [image, setImage] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [filePathList,setFilePathList] =useState([])
    const [removefilePathList,setRemoveFilePathList] =useState([])
  
    function handleImageChange(e) {
      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = []
      files.map(item=>{
        if (item.size < maxFileSize ){
          fileArr.push(item)
        }else{
          api.warning({
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            placement: "bottomRight",
          });
        }
      })    
      let tempArr = []
      setSelectedFile(fileArr);
      files.map(item=>{
        if (item.size < maxFileSize ){
        let fileName = item?.name
        let fileExtention =  fileName.split(".").reverse()[0]
        if(filetype?.includes(fileExtention)){
          tempArr.push(fileName)
        }else{
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
      })
      setImage(tempArr)
    }


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
           estimationId: Number(estmationId),
           filesToRemove:[] 
        },
        validateOnChange: false,
        validateOnBlur: false,
        // validationSchema:yup.object({
        //   name:yup.string().required("Tittle is Requried")
        // }),
        onSubmit: (value,{resetForm}) => {   
          setIsLoading(true); 
          let formData =new FormData()
          formData.append("data",JSON.stringify(value))
          if(selectedFile !== null){
            selectedFile.forEach((file) => {
              formData.append("attachments", file);
            });
          }else{
            formData.append("attachments",[])
          } 

          axiosInstance
          .post("estimation/attachments", formData)
            .then((res) => {
              console.log(res);
              if (res.status === 201 || res.data.status == true) {
                getData();
                setIsLoading(false); 
                resetForm();
                api.success({
                  message: `Attachment Creation successfully`,
                  description: res?.data?.msg || "Added In Tittle",
                  placement: "bottomRight",
                });
                onCancel();
              } else {
                setIsLoading(false); 
                api.error({
                  message: `Tittle Creation failed`,
                  description: res?.data?.msg || "Network error",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false); 
              api.error({
                message: `Tittle Creation failed`,
                description: err?.data?.msg || "Sever error (or) Network error",
                placement: "bottomRight",
              });
            });
        },
      });
  return (
    <>
    {contextHolder}
    <Modal
        open={open}
        title={"Create Attachments"}
        width={"50%"}
        className="my-modal"
        centered
        footer={[]}
        onCancel={onCancel}
      >
              <form onSubmit={formik.handleSubmit} >
        <div className=" w-full flex flex-wrap">
           <NuInputfile
                label="Tittle"
                placeholder="Tittle"
                handleChange={handleImageChange}
                name="name"
                width="w-full"
            />
            <LongButton isLoading={isLoading} />
        </div>
        </form>
      </Modal>
    </>
  )
}

export default CreateAttachments