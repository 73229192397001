import { useState } from "react";
import axios from "../../../Services/axiosCommon";
import { useEffect } from "react";
import NuPills from "../../../Components/Utils/Nupills";
import NuDate from "../../../Components/Utils/NuDate";
import { Button } from "antd";
import EditProfile from "./editProfile";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../Components/Icons/Dasboard";
import NotificationSettings from "./notication";



function UserProfile() {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    // const  id  = userDetails.id;
    const [user, setUser] = useState([]);
    const [open,setOpen] = useState(false);
  
    // const getUserDetails = async () => {
    //   await axios
    //     .get(`/User/${id}`)
    //     .then(async (res) => {
    //       console.log(res?.data?.data);
    //       (await res?.data?.data) && setUser(res?.data?.data);
    //     })
    //     .catch((error) => console.error(error));
    // };



    // useEffect(() => {
    //   getUserDetails();
    //   // getNotificationDetail();
    // }, []);
    console.log('derty', user);
    let Name = user?.firstName||""
  
    function calculateAge(dateOfBirth) {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }
  
    return (
      <>
            <div className=" w-full">
            {/* <BreadCrumb
              items={[
                {
                  title: (
                    <Link to="/dashboard">
                      <Dasboard color="gray" width={10} height={10} />{" "}
                    </Link>
                  ),
                },
                { title: <p className=" text-[12px] ">User Profile</p> },
              ]}
            /> */}
          </div>
        <h2 className=" text-sm font-semibold text-[#4F4768] mb-4">User Profile View : </h2>
        <div className=' w-[98%] mx-auto py-7 h-5/6 lg:h-4/6 flex rounded-3xl  border-solid bg-white border-8 border-[#119DEE]/90 border-r-0 border-y-0 shadow-lg'>
          <div className=' w-2/6 lg:w-2/6 px-4  flex flex-col justify-center border-r-2 border-solid border-l-0 border-y-0  border-r-gray-50'>
            <div className=' w-36 h-36 rounded-full bg-[#BFD0FF] flex justify-center items-center mx-auto'>
              <div className=' w-[124px] h-[124px] rounded-full bg-[#A1B9FF] flex justify-center items-center'>
                <div className=' w-[110px] h-[110px] rounded-full bg-[#119DEE] font-medium text-white text-3xl capitalize flex justify-center items-center overflow-hidden'>
                  {user.imageUrl && user.imageUrl !=""?<img src={user.imageUrl} width='140%' className=' rounded-full' alt={'userimage'} />:`${Name.charAt(0)||""}`}
                </div>
              </div>
            </div>
            <div className='text-center'>
              <ul className='list-none pt-5'>
                <li>
                  <p className='text-sm text-mainblack-500 font-semibold'>
                    {user?.firstName} {user?.lastName}
                  </p>
                </li>
                <li>
                  <p className=' font-medium text-xs text-mainblack-300'>{user?.position}</p>
                </li>
                <li>
                  <div className='w-3/12 my-3  mx-auto text-center'>
                    <NuPills
                      width=' w-16'
                      bgColor='bg-[#E7F9E7]'
                      textColor='text-[#39D42B]'
                      fontWight=' font-semibold'
                      fontSize='text-[12px]'
                    >
                      {user.isActive ? 'Active' : 'Inactive'}
                    </NuPills>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className=' h-full w-full px-6'>
            <div className='w-full lg:h-1/3 '>
              {/* <h1 className='my-3 lg:my-0 font-semibold text-mainblack-500'>Official Information</h1> */}
              <div>
                <ul className='list-none grid grid-cols-3 lg:grid-cols-6 gap-3 '>
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-[#4F4768] text-xs'>Date Of Birth</h5>
                    <p className=' text-maingray-300 text-xs'> {user?.dob?(<NuDate value={user?.dob} format="DD-MMM-YYYY" />):"-"} </p>
                  </div>
                  </li>
  
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-[#4F4768] text-xs'>Date Of Join</h5>
                    <p className=' text-maingray-300 text-xs'> {user?.dateOfJoin?(<NuDate value={user?.dateOfJoin} format="DD-MMM-YYYY" />):"-"} </p>
                  </div>
                  </li>
  
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-[#4F4768] text-xs'>Date Of Relieving</h5>
                    <p className=' text-maingray-300 text-sm'> {user?.dateOfRelieving?(<NuDate value={user?.dateOfRelieving} format="DD-MMM-YYYY" />):"-"} </p>
                  </div>
                  </li>
  
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-[#4F4768] text-xs'>Gender</h5>
                    <p className=' text-maingray-300 text-xs'> {user?.gender==='M'?"Male":"Female"} </p>
                  </div>
                  </li>
  
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-[#4F4768] text-xs'>Age</h5>
                    <p className=' text-maingray-300 text-xs'> {user?.dob?calculateAge(user?.dob):'-'} </p>
                  </div>
                  </li>
  
                </ul>
              </div>
            </div>
            <div className='w-full lg:h-1/3  '>
              {/* <h1 className='my-3 lg:my-0 font-semibold text-mainblack-500'>Contact Information</h1> */}
  
              <div>
                <ul className='list-none grid grid-cols-3 lg:grid-cols-6 gap-3'>
                <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-[#4F4768] text-xs'>Mobile</h5>
                    <p className=' text-maingray-300 text-xs'> {user?.mobileNo?(user?.mobileNo):'-'} </p>
                  </div>
                  </li>
  
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-[#4F4768] text-xs'>Email</h5>
                    <p className=' text-maingray-300 text-xs'> {user?.email?(user?.email):'-'} </p>
                  </div>
                  </li>
  
                  {/* <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-maingray-500 text-sm'>Country</h5>
                    <p className=' text-maingray-300 text-sm'> {user?.country?(user?.country):'-'} </p>
                  </div>
                  </li>
  
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-maingray-500 text-sm'>State</h5>
                    <p className=' text-maingray-300 text-sm'> {user?.state?(user?.state):'-'} </p>
                  </div>
                  </li>
  
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-maingray-500 text-sm'>City</h5>
                    <p className=' text-maingray-300 text-sm'> {user?.city?(user?.state):'-'} </p>
                  </div>
                  </li>
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-maingray-500 text-sm'>Pincode</h5>
                    <p className=' text-maingray-300 text-sm'> {user?.pincode?(user?.pincode):'-'} </p>
                  </div>
                  </li> */}
                </ul>
              </div>
            </div>
  
            {/* <div className='w-full h-1/3'>
              <h1 className=' my-3 lg:my-0 font-semibold text-mainblack-500'>Emergency Information </h1>
              <div>
                <ul className='list-none grid grid-cols-3 lg:grid-cols-6 gap-3'>
                <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-maingray-500 text-sm'>Name</h5>
                    <p className=' text-maingray-300 text-sm'> {user?.emergencyName?(user?.emergencyName):'-'} </p>
                  </div>
                  </li>
  
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-maingray-500 text-sm'>Relation</h5>
                    <p className=' text-maingray-300 text-sm'> {user?.emergencyRelationship?(user?.emergencyRelationship):'-'} </p>
                  </div>
                  </li>
  
                  <li className=' w-full h-full flex flex-col'>
                  <div className=' lg:my-6'>
                  <h5 className=' my-1 font-semibold text-maingray-500 text-sm'>Mobile</h5>
                    <p className=' text-maingray-300 text-sm'> {user?.emergencyMobileNo?(user?.emergencyMobileNo):'-'} </p>
                  </div>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <div className=" w-full">
            <h2 className=" text-sm  text-[#4F4768] font-semibold mt-4">Settings : </h2>
            <div className=" w-full flex items-center gap-6 p-4">
              <div className=" flex justify-between gap-4 items-center my-2">
              <p className=" font-normal text-xs text-[#4F4768]">Profile</p> <Link to={'/user-details/edit'}><Button className=" text-xs">Edit</Button></Link>
              </div>
              <div className=" flex justify-between gap-4 items-center my-2">
              <p className=" font-normal text-[#4F4768] text-xs">Change Password</p> <Link to={'/user-details/password-change'}><Button className=" text-xs">Change</Button></Link>
              </div>
              <div className=" flex justify-between gap-4 items-center my-2">
              <p className=" font-normal text-[#4F4768] text-xs">Email & Notification </p> <Button className=" text-xs" onClick={()=>{setOpen(true)}}>Change</Button>
              </div>
            </div>
        </div>
      {/* <NotificationSettings id={id} data={noti} open={open} onColse={()=>setOpen(false)} /> */}
      </>
    );
  }
  
  export default UserProfile;