import React from "react";
import { Route } from "react-router-dom";
import MainCompanyInfo from "../Views/Web/Settings/CompanyInfo/MainCompanyInfo";
import CompanyInfoIndex from "../Views/Web/Settings/CompanyInfo";
import MainSettings from "../Views/Web/Main/MainSettings";
import EditCompany from "../Views/Web/Settings/CompanyInfo/Update";
import ChangePassword from "../Views/Web/User/changePassword";
import ProfileNotificationSettings from "../Views/Web/User/notication";
import EditProfile from "../Views/Web/User/editProfile";
import UserProfile from "../Views/Web/User/userProfile";
import User from "../Views/Web/User/index";
import RoleMain from "../Views/Web/Settings/Role/roleMain";
import RoleSetting from "../Views/Web/Settings/Role";
import CreateRole from "../Views/Web/Settings/Role/createRole";
import EditRoleTwo from "../Views/Web/Settings/Role/editRoleTwo";
import RoleView from "../Views/Web/Settings/Role/roleView";
import MainManPowerRole from "../Views/Web/Settings/ManPowerRole/MainManPowerRole";
import ManPowerRoleIndex from "../Views/Web/Settings/ManPowerRole";
import CreateManPowerRole from "../Views/Web/Settings/ManPowerRole/CreateManPowerRole";
import EditManPower from "../Views/Web/Settings/ManPowerRole/EditManPower";
import TaskSetting from "../Views/Web/Settings/Tasks";
import ProjectSetting from "../Views/Web/Settings/Projects";
import NotesSetting from "../Views/Web/Settings/Notes";
import ClientSetting from "../Views/Web/Settings/Clients";
import NotificationSettings from "../Views/Web/Settings/Notification";
import BackupIndex from "../Views/Web/Settings/Backup";
import DivisionSettings from "../Views/Web/Settings/Division";
import ProgressCoating from "../Views/Web/Settings/Category";
import SettingsPayments from "../Views/Web/Settings/Payment";
import UnitSetting from "../Views/Web/Settings/Unit";
import BiddingStatus from "../Views/Web/Settings/BiddingStatus";
import MainUsers from "../Views/Web/Settings/AllUsers/mainUsers";
import AllUsers from "../Views/Web/Settings/AllUsers";
import CreateUser from "../Views/Web/Settings/AllUsers/CreateUser";
import BranchMain from "../Views/Web/Settings/CompanyBranches/BranchMain";
import CreateBranch from "../Views/Web/Settings/CompanyBranches/CreateBranch";
import EditBranch from "../Views/Web/Settings/CompanyBranches/EditBranch";
import BranchIndex from "../Views/Web/Settings/CompanyBranches";
import ViewBranch from "../Views/Web/Settings/CompanyBranches/ViewBranch";


function SettingsRoutes() {
  return (
    <Route path="settings" element={<MainSettings />}>
      <Route path="company-info" element={<MainCompanyInfo />}>
        <Route index element={<CompanyInfoIndex />} />
        <Route path="edit" element={<EditCompany />} />
      </Route>
      <Route path='branch' element={<BranchMain/>}>
          <Route index element={<BranchIndex/>}/>
          <Route path='create' element={<CreateBranch/>}/>
          <Route path='edit/:id' element={<EditBranch/>}/>
          <Route path='view/:id' element={<ViewBranch/>}/>
        </Route>
      <Route path="all-user" element={<MainUsers />}>
        <Route index element={<AllUsers />} />
        <Route path="create" element={<CreateUser />} />
        {/* <Route path="edit/:manPowerRoleEditId" element={<EditManPower />} /> */}
        {/* <Route path="notifications" element={<ProfileNotificationSettings />} /> */}
      </Route>
      <Route path="roles" element={<RoleMain />}>
      <Route index element={<RoleSetting />} />
      <Route path="create" element={<CreateRole />} />
      <Route path="edit-role/:roleId" element={<EditRoleTwo />} />
      <Route path="role/view/:roleViewId" element={<RoleView />} />
    </Route>
    <Route path="man-power-role" element={<MainManPowerRole />}>
       <Route index element={<ManPowerRoleIndex/>}/>
       <Route path="create" element={<CreateManPowerRole />} />
      <Route path="edit/:manPowerRoleEditId" element={<EditManPower />} />
      {/* <Route path="role/view/:manPowerRoleViewId" element={<RoleView />} /> */}
    </Route>
    <Route path="task-settings" element={<TaskSetting />} />
    <Route path="project-settings" element={<ProjectSetting />} />
    <Route path="notes-settings" element={<NotesSetting />} />
    <Route path="client-settings" element={<ClientSetting />} />
    <Route path="notification" element={<NotificationSettings />} />
    <Route path="backup" element={<BackupIndex />} />
    <Route path="payments" element={<SettingsPayments />} />
    <Route path="unit" element={<UnitSetting />} />
    <Route path="category" element={<ProgressCoating />} />
    <Route path="division" element={<DivisionSettings />} />
    <Route path="biddingstatus" element={<BiddingStatus />} />
    </Route>
  );
}

export default SettingsRoutes;
