import React, { useEffect, useState } from 'react'
import BidLayout from '../../BidLayout'
import TitleEquipment from './TilteEquipment';
import { useParams } from 'react-router-dom';
import { Modal, notification } from 'antd';
import axiosInstance from '../../../../../Services/axiosCommon';
import { useFormik } from 'formik';
import { ExclamationCircleFilled } from '@ant-design/icons';
import * as yup from 'yup'
import { EditButton, LongButton } from '../../../../../Components/Utils/Buttons';
import Pagination from '../../../../../Components/Utils/Pagenation';
import { NuSelect } from '../../../../../Components/Utils/NuInput';
import ImportEquipment from './import';
import CreateEquipmentTittle from './CreateBOQTittle';
import MultiEditEquipment from './MultiEdit';

function EquipmentIndex() {
  const { estmationId } = useParams();
  const [data,setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilter,setIsFilter]= useState(false);
  const [filterData,setFilterData]= useState({
     unit: ""
  });
  const [afterFilter,setAfterFilter]= useState({})
  const [searchQuery, setSearchQuery] = useState("");
  const [totalData, setTotalData] = useState(0);
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();
  const [multiEditOpen, setMultiEditOpen] = useState(false);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [selectedExpandedItems, setSelectedExpandedItems] = useState([]);
  const [flatSelectedItemIds, setFlatSelectedItemIds] = useState([]);

  const handleMainCheckboxChange = (titleId, items) => {
    setSelectedTitles((prevSelectedTitles) =>
      prevSelectedTitles.includes(titleId)
        ? prevSelectedTitles.filter((id) => id !== titleId)
        : [...prevSelectedTitles, titleId]
    );

    setSelectedExpandedItems((prevSelectedExpandedItems) => ({
      ...prevSelectedExpandedItems,
      [titleId]: selectedTitles.includes(titleId)
        ? [] // Clear items if main checkbox is deselected
        : items.map(item => item.id), // Select all items if main checkbox is selected
    }));
  };

  const handleExpandedCheckboxChange = (titleId, itemId) => {
    setSelectedExpandedItems((prevSelectedExpandedItems) => ({
      ...prevSelectedExpandedItems,
      [titleId]: prevSelectedExpandedItems[titleId]?.includes(itemId)
        ? prevSelectedExpandedItems[titleId].filter((id) => id !== itemId)
        : [...(prevSelectedExpandedItems[titleId] || []), itemId],
    }));
  };

  useEffect(() => {
    const allSelectedIds = Object.values(selectedExpandedItems).flat();
    setFlatSelectedItemIds(allSelectedIds);
  }, [selectedExpandedItems]);

  const isTitleSelected = (titleId) => selectedTitles.includes(titleId);

  function getEquipment() {
    axiosInstance
      .get(`/equipment/new/${estmationId}?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalData(res.data?.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getEquipment();
   },[])

   function SearchData(e) {
    e.preventDefault();
    if (searchQuery != "") {
      axiosInstance
        .get(
          `/equipment/new/${estmationId}?page=${pageIndex}&per_page=${dataCount}`,
          { params: { search: `${searchQuery}` } }
        )
        .then((res) => {
          setData(res.data?.data);
          setTotalData(res.data?.total);
        })
        .catch((err) => console.log(err));
    } else {
      getEquipment();
    }
  }

   const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      
    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value)
      setIsFilter(true)
      axiosInstance
      .get(`/equipment/new/${estmationId}?page=${pageIndex}&per_page=${dataCount}`,{params:{unit:`${value.unit}`}})
      .then((res) => {
        setFilterOpen(false)
        setData(res.data?.data);
        setTotalData(res.data?.total)

      })
      .catch((error) => {
        console.log(error);
      });
    },
  });

  function FilterPage(){
    axiosInstance
      .get(`/equipment/new/${estmationId}?page=${pageIndex}&per_page=${dataCount}`,{params:{unit:`${afterFilter.unit}`}})
      .then((res) => {
        setData(res.data?.data);
        setTotalData(res.data?.total)

      }).catch(err=>console.log(err))
  }


  function ClearFilter(){
    formik.resetForm({})
    getEquipment();
    setIsFilter(false)
  }

  useEffect(()=>{
   if(isFilter){
    FilterPage();
   }else if(searchQuery !=""){
      SearchData();
    }else{
    getEquipment()
    }
  },[pageIndex,dataCount])

  useEffect(() => {
    if (searchQuery == "") {
       getEquipment();
    }
  }, [searchQuery]);

  const handleDelete = async(id) =>{
    confirm({
        title: `Do you Want to delete Equipment Items?`,
        centered:true,
        width:"25%",
        icon: <ExclamationCircleFilled />,
        content: `The Equipment Items will be removed from the Equipment`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/equipment/multi-delete`, {
            headers: {
              'Content-Type': 'application/json',
            },
            data: { "ids": id } // Pass the payload as the `data` key in `delete`
          }).then((res) => {
            if(res.status === 201||res.status === 200) {
                getEquipment();
                api.success({
                  message: `Equipment Multi Item has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `Equipment Multi Item has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `Equipment Multi Item has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}

  return (
    <>
    {contextHolder}
    <BidLayout
        setOpen={setOpen}
        onCreateClick={() => {
          setCreateModalOpen(true);
        }}
        isFilter={isFilter}
        setFilterOpen={setFilterOpen}
        ClearFilter={ClearFilter}
        SearchData={SearchData}
        setSearchQuery={setSearchQuery}
    >
      <div className="w-full h-[calc(100vh-150px)]">
          <div className=" w-full h-[calc(100vh-170px)]">
          {flatSelectedItemIds.length > 0 ? 
            <div className="w-full flex justify-end gap-2 py-2">
            <EditButton value="Multi Edit" handleClick={() => { setMultiEditOpen(true) }}/>
            <button
              type='button'
              className="px-3 h-8 cursor-pointer text-xs text-red-500 bg-white rounded-md flex items-center"
              style={{ border: "1px solid red" }}
              onClick={() => {handleDelete(flatSelectedItemIds)}}
            >
              Multi Delete
            </button>
          </div>
            :
            null
            }
            <table
              className="table-auto static top-0 w-full"
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <thead className=" w-full bg-[#28A0D9]">
                <tr className="text-xs  font-medium  py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-2 ${header.width} px-1 font-normal ${
                        header?.isLeft ? "text-left" : "text-center"
                      } text-xs text-white`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
            <div className={` w-full ${flatSelectedItemIds.length > 0 ? "h-[calc(100vh-250px)]" : "h-[calc(100vh-200px)]"}  overflow-y-auto relative`}>
              <table
                className="table-auto  w-full"
                style={{ border: "none", borderCollapse: "collapse" }}
              >
                <tbody>
                  {data &&
                    data.map((item, i) => {
                      return (
                        <TitleEquipment
                          data={item}
                          getData={getEquipment}
                          estiId={estmationId}
                          selectedTitles={selectedTitles}
                          selectedExpandedItems={selectedExpandedItems}
                          onMainCheckboxChange={handleMainCheckboxChange}
                          onExpandedCheckboxChange={
                            handleExpandedCheckboxChange
                          }
                          isTitleSelected={isTitleSelected}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className=" h-7 my-[2px] px-3">
            <Pagination
              total={totalData}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              dataCount={dataCount}
              setDataCount={setDataCount}
            />
          </div>
        </div>
        <CreateEquipmentTittle
          open={createModalOpen}
          onCancel={() => {
            setCreateModalOpen(false);
          }}
          getData={getEquipment}
        />
        <MultiEditEquipment
          open={multiEditOpen}
          onCancel={() => {
            setMultiEditOpen(false);
          }}
          getData={getEquipment}
          seletedIds={flatSelectedItemIds}
        />
      </BidLayout>
      <ImportEquipment
        open={open}
        onCancel={() => setOpen(false)}
        getData={getEquipment}
        label="Import"
        estmationId={estmationId}
      />
      <Modal
        open={filterOpen}
        title={"Filter BOQ"}
        width={"30%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuSelect
              label="Unit"
              options={[
                { value: "kg", label: "Kg" },
                { value: "lit", label: "lit" },
                { value: "ton", label: "ton" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="unit"
              width="w-full"
            />
            <LongButton value='Filter'/>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default EquipmentIndex

const header = [
  // {
  //   headone: "ID",
  //   width: "w-[3%]",
  //   // isLeft: true,
  // },
  {
      headone: "Sl.No",
      width: "w-[5%]",
      isLeft: true,
    },
  {
    headone: "Description",
    width: "w-[20%]",
    isLeft: true,
  },
  {
    headone: "Own/Rental",
    width: "w-[12%]",
    isLeft: true,
  },
  {
    headone: "Unit",
    width: "w-[12%]",
    isLeft: true,
  },
  {
    headone: "Qty.",
    width: "w-[12%]",
    isLeft: true,
  },
  {
    headone: "No. of Days",
    width: "w-[10%]",
    isLeft: true,

  },

  {
    headone: "Unit Price",
    width: "w-[12%]",
    isLeft: true,

  },
  {
    headone: "Amount",
    width: "w-[12%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];