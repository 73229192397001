import { Modal, notification } from 'antd'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { NuInputNumber, NuInputText, NuSelect } from '../../../../../Components/Utils/NuInput';
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';

function CreateSubItemsOthers({open,onCancel,getData,estiId,itId}) {
  console.log("finding",estiId);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    estimationId:"",
    description:"",
    IT_Id:"",
    unit:"",
    quantity:"",
    unitPrice:"",
    amount:""
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
      description:yup.string().required("Item Name is Requried")
    }),
    onSubmit: (value,{resetForm}) => {    
      setIsLoading(true);
      const values = {
        ...value,
        estimationId: Number(estiId),
        IT_Id: itId,
      }
      axiosInstance
      .post(`/other/single-other`, values)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            setIsLoading(false);
            getData();
            resetForm();
            api.success({
              message: `Other Item Creation successfully`,
              description: res?.data?.msg || "Added In Tittle",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Other Item  Creation failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          api.error({
            message: `Others Item Creation failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    const amount = formik?.values?.quantity * formik?.values?.unitPrice
    formik.setFieldValue("amount", amount);
  }, [formik?.values?.quantity,formik?.values?.unitPrice]);

  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={"Create BOQ Item"}
      width={"50%"}
      className="my-modal"
      centered
      footer={[]}
      onCancel={onCancel}
    >
    <form onSubmit={formik.handleSubmit} >
      <div className=" w-full flex flex-wrap">
         <NuInputText
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full"
              isRequired={true}
          />
          <NuSelect
            label="Unit"
            options={[
             {value: "kg", label:"Kg"},
             {value: "lit", label:"lit"},
             {value: "ton", label:"ton"},
            ]}
            formik={formik}
            placeholder="Choose"
            name="unit"
            width="w-full md:w-1/3"
            />
           <NuInputNumber
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              label="Unit Price"
              placeholder="Unit Price"
              formik={formik}
              name="unitPrice"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              label="Total Price"
              placeholder="Total Price"
              formik={formik}
              name="amount"
              width="w-full md:w-1/3"
              disable={true}
            />
            <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
              <CancelButton handleClick={onCancel} />
              <SubmitButton isLoading={isLoading} />
            </div>
      </div>
      </form>
    </Modal>
    </>
  )
}

export default CreateSubItemsOthers