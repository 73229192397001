import React from 'react'
import {  TextSmallBold } from '../../../Components/Utils/Text'
import DoughnutTwo from "../../../Components/Utils/DoughnutTwo";
import { TaskPriorityDough, TaskStatusDough } from '../../../Components/Utils/TaskDoughnut';
import useFetchMataData from '../../../Components/Hooks/useFetchMataData';

function Tasks({ data, STPC, TPC, dataStatus, STSC, TSC, total, access = false }) {

  function genStatusDataInternal(statusData, SubByStatus, TaskByStatus) {
    let finalItem = {}
    statusData && statusData.map(item => {
      let numberOne = SubByStatus[item] || 0
      let numberTwo = TaskByStatus[item] || 0
      let x = numberOne + numberTwo;
      finalItem[item] = x
    })
    let returnValue = {
      label: [],
      data: [],
    };
    let X = Object.keys(finalItem);
    let Y = Object.values(finalItem);
    returnValue.label = X;
    returnValue.data = Y;
    let sum = Y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    if (sum != 0) {
      return <div className=" cursor-pointer"><TaskStatusDough finalItem={finalItem} dataWithLabel={returnValue} /></div>;
    } else {
      return <DoughnutTwo />;
    }
  }



  function genPriorityDataInternal(statusData, SubByStatus, TaskByStatus) {
    let finalItem = {}
    statusData && statusData.map(item => {
      let numberOne = SubByStatus[item] || 0
      let numberTwo = TaskByStatus[item] || 0
      let x = numberOne + numberTwo;
      finalItem[item] = x
    })
    let returnValue = {
      label: [],
      data: [],
    };
    let X = Object.keys(finalItem);
    let Y = Object.values(finalItem);
    returnValue.label = X;
    returnValue.data = Y;
    let sum = Y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    if (sum != 0) {
      return <div className=" cursor-pointer"><TaskPriorityDough finalItem={finalItem} dataWithLabel={returnValue} /></div>;
    } else {
      return <DoughnutTwo />;
    }
  }

  const {value:Priority} = useFetchMataData(`/Metadata/taskpriority`)
  const {value:Status} = useFetchMataData(`/Metadata/taskstatus`)

  return (
    <div className=" w-full h-full  p-1 overflow-hidden rounded-lg" style={{ border: "1px solid #E9E8EC" }}>
     <TextSmallBold text="Tasks" />
      <div className=" w-full h-[95%]  p-1 flex flex-col gap-3 items-center">
        <div className=' w-full h-[50%]  flex gap-3 items-center'>
          <div className=' w-[60%] h-full flex flex-col gap-1'>
            <div className=' py-1 text-center text-xs flex items-center justify-center bg-[#119DEE] text-white rounded-md shadow'>
              Total : <span className=' text-lg ml-2 font-semibold'>{total||0}</span>
            </div>
            <div className=' w-full h-full  flex flex-wrap p-1'>
            {Status && Status.map((item, index) => {
              return (
                <TempCom title={item?.label} valueOne={STSC} valueTwo={TSC} key={index} />
              )
            })}
            </div>
          </div>
          <div className=' w-[40%] h-full  flex justify-center items-center'>
          <div className=' w-[70%] mx-auto'>
              {genStatusDataInternal(dataStatus, STSC, TSC)}
            </div>
          </div>
        </div>
        <div className=' w-full h-[50%] flex gap-3 items-center p-1'>
        <div className=' w-[50%] h-full  flex justify-center items-center'>
        <div className=' w-[58%] mx-auto'>
              {genPriorityDataInternal(data, STPC, TPC)}
            </div>
        </div>
          <div className=' w-[50%] h-full'>
          <TextSmallBold text="Priority" />
          {Priority &&
                  Priority.map((item, index) => {
                    // console.log((STPC[item]||0)+(TPC[item]||0));
                    return (
                      <TempComponent
                        title={item?.label || "-"}
                        value={(STPC[item?.label]||0)+(TPC[item?.label]||0) || 0}
                        bgColor={
                          item?.label?.toLowerCase() == "low" ?"bg-[#FFEAB5]"
                            : item?.label?.toLowerCase() == "high" 
                            ? "bg-[#F101011A]"
                            : item?.label?.toLowerCase() == "medium"
                            ? "bg-[#F35B041A]"
                            : "bg-[#007BEC1A]"
                        }
                        textColor={
                          
                          item?.label?.toLowerCase() == "low" ?"text-[#E8A700]"
                          :
                          item?.label?.toLowerCase() == "high" 
                            ? "text-[#F10101]"
                            :item?.label?.toLowerCase() == "medium" 
                            ? "text-[#F35B04]"
                            : "text-[#007BEC]"
                        }
                        key={index}
                      />
                    );
                  })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tasks

function TempCom({ title, valueOne, valueTwo }) {
  let numberOne = valueOne[title] || 0
  let numberTwo = valueTwo[title] || 0
  return (
    <div className=' w-[48%] flex  items-center '>
      <p className=' text-xs'>{title} : {numberOne + numberTwo}</p>      
    </div>
  )
}

function TempComponent({ bgColor, textColor, value, title }) {
  return (
    <div
      className={`w-[90%] ${bgColor} rounded-[4px] flex justify-between items-center my-1 py-[2px] px-2`}
    >
      <p className=" text-xs text-[#4F4768]">{title}</p>
      <p className={`text-base font-semibold ${textColor}`}>
        {value || "0"}
      </p>
    </div>
  );
}