import { Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { NuInputText } from "../../../../../../Components/Utils/NuInput";
import axiosInstance from "../../../../../../Services/axiosCommon";
import { useNumber } from '../../../../../../Context/ProjectIdContext';
import { useParams } from "react-router-dom";


const CreateTitle = ({ open, getData, onCancel, data,projectList,firstProject }) => {
  // const {number, updateNumber} = useNumber();
  const {projectId} = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    projectId:"",
    title_name:""
  });


  useEffect(() => {
    data&&setCreateNew({
      projectId:data.projectId,
      title_name:data.title_name,
    })
  },[data])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
      title_name:yup.string().required("Title is Requried")
    }),
    onSubmit: (value,{resetForm}) => {
      // formik.resetForm({ values: "" });
      value.projectId = projectId;
      axiosInstance
        .post("/title/", value)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            getData(projectId);
            resetForm();
            api.success({
              message: `Title Creation successfully`,
              description: res?.data?.msg || "Added In The Title",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Title Creation failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `Title Creation failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title={data ? "Edit Title" : "Create Title"}
        width={"25%"}
        className="my-modal"
        centered
        onCancel={onCancel}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuInputText
              label="Title"
              placeholder="Title Name"
              formik={formik}
              name="title_name"
              width="w-full"
            />
            {/* <NuSelect
             label="Project"
              placeholder="Select Project"
              formik={formik}
              name="projectId"
              width="w-full"
              options={projectList}
            /> */}
<button
              type="submit"
              // htmlType="submit"
              // size="large"
              className="w-11/12 bg-[#119DEE] py-3 rounded-lg cursor-pointer text-sm outline-none border-none hover:text-white focus:text-white text-white  mx-auto"
            >
              Create 
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CreateTitle;
