import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import MainRentalManpower from '../Views/Web/Contacts/RentalManpower/main'
import RentalManpower from '../Views/Web/Contacts/RentalManpower'
import CreateRentalManpower from '../Views/Web/Contacts/RentalManpower/createRentalManpower'
import EditRentalManpower from '../Views/Web/Contacts/RentalManpower/editRentalManpower'
import RentalManpowerProfile from '../Views/Web/Contacts/RentalManpower/rentalManpowerProfile'
import { Wrapper } from '../Components/Utils/roles'


const MainContacts = lazy(()=>import('../Views/Web/Contacts/MainContacts'))
const MainClient =lazy(()=>import('../Views/Web/Contacts/Clients/clientMain')) 
const Client =lazy(()=>import('../Views/Web/Contacts/Clients/index')) 
const CreateClients =lazy(()=>import('../Views/Web/Contacts/Clients/createClient')) 
const EditClients =lazy(()=>import('../Views/Web/Contacts/Clients/editClient')) 
const ClientView =lazy(()=>import('../Views/Web/Contacts/Clients/clientView')) 
const MainVendor =lazy(()=>import('../Views/Web/Contacts/Vendor/vendorMain')) 
const Vendor =lazy(()=>import('../Views/Web/Contacts/Vendor/index')) 
const CreateVendor =lazy(()=>import('../Views/Web/Contacts/Vendor/createVendor')) 
const EditVendor =lazy(()=>import('../Views/Web/Contacts/Vendor/editVendor')) 
const VendorView =lazy(()=>import('../Views/Web/Contacts/Vendor/vendorView')) 
const MainSubCon =lazy(()=>import('../Views/Web/Contacts/SubCon/subConMain')) 
const SubCon =lazy(()=>import('../Views/Web/Contacts/SubCon')) 
const CreateSubCon =lazy(()=>import('../Views/Web/Contacts/SubCon/createSubCon')) 
const SubConView =lazy(()=>import('../Views/Web/Contacts/SubCon/subConView')) 
const EditSubCon = lazy(()=>import('../Views/Web/Contacts/SubCon/editSubCon'))


function ContactsRoutes() {
  return (
    <Route path="contacts" element={<MainContacts/>} >
     <Route path="clients/:officeId" element={<MainClient />}>
      <Route index element={< Wrapper accessKey={true}  Component={Client} /> } />
      <Route path="create" element={< Wrapper accessKey={true}  Component={CreateClients} /> } />
      <Route path="edit/:clientEditId" element={< Wrapper accessKey={true}  Component={EditClients} />} />
      <Route path="view/:clientViewId" element={< Wrapper accessKey={true}  Component={ClientView} /> } />
     </Route>
     <Route path="vendor/:officeId" element={<MainVendor />}>
    <Route index element={< Wrapper accessKey={true}  Component={Vendor} />} />
    <Route path="create" element={< Wrapper accessKey={true}  Component={CreateVendor} />} />
    <Route path="edit/:vendorEditId" element={< Wrapper accessKey={true}  Component={EditVendor} />} />
    <Route path="view/:vendorViewId" element={< Wrapper accessKey={true}  Component={VendorView} />} />
  </Route>
  <Route path="sub-con/:officeId" element={<MainSubCon />}>
    <Route index element={< Wrapper accessKey={true}  Component={SubCon} />} />
    <Route path="create" element={< Wrapper accessKey={true}  Component={CreateSubCon} />} />
    <Route path="edit/:subConEditId" element={< Wrapper accessKey={true}  Component={EditSubCon} />} />
    <Route path="view/:subConViewId" element={< Wrapper accessKey={true}  Component={SubConView} />} />
  </Route>
  <Route path="rental-man/:officeId" element={<MainRentalManpower />}>
    <Route index element={< Wrapper accessKey={true}  Component={RentalManpower} />} />
    <Route path="create" element={< Wrapper accessKey={true}  Component={CreateRentalManpower} />} />
    <Route path="profile/:rentalManpowerid" element={< Wrapper accessKey={true}  Component={RentalManpowerProfile} />} />
    <Route path="edit/:rentalManpowerEditId" element={< Wrapper accessKey={true}  Component={EditRentalManpower} />} />          
  </Route>
  </Route>
  )
}

export default ContactsRoutes