import React, { useState } from 'react'
import ProgressLayout from '../ProgressLayout';
import TitleManpower from './TilteManpower';
import { useParams } from 'react-router-dom';
import useFetchMataData from '../../../../../../Components/Hooks/useFetchMataData';
import { useNumber } from '../../../../../../Context/ProjectIdContext';
import axiosInstance from '../../../../../../Services/axiosCommon';
import { GetAccess } from '../../../../../../Components/Utils/roles';
import CreateManPowerTitle from './CreateManPowerTitle';

function ManpowerIndex() {
  const { officeId } = useParams();
  const { projectId } = useParams();

  const {value:Category} = useFetchMataData(`/Metadata/category`)
  const {value:Division} = useFetchMataData(`/Metadata/division`)
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [totalData, setTotalData] = useState(0);
   const { number, updateNumber } = useNumber();
  const [percentOpen,setPercentOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalTasks, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([])
  const [priority, setPriority] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [isFilter,setIsFilter]= useState(false);
  const [notFirst,setNotFirst]=useState(false)
  const [filterData,setFilterData]= useState({
    category:"",
    division:"",
    item:"",
    
  });
  const [afterFilter,setAfterFilter]= useState({})
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });
  function getManPower() {
    axiosInstance
    
      .get(`/pro_manpower/get-all?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalData(res.data?.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    
    <ProgressLayout 
    setOpen={setOpen}
    setSearchQuery={setSearchQuery}
    // SearchData={SearchData}
    isFilter={isFilter}
    setFilterOpen={setFilterOpen}
    onCreateClick={()=>{setCreateModalOpen(true)}}
    // ClearFilter={ClearFilter}
    CreateAccess={GetAccess()}
    // ImportAccess={GetAccess()}
    officeId={officeId}
    projectId={projectId}
      >
          <CreateManPowerTitle
        open={createModalOpen}
        onCancel={() => {
          setCreateModalOpen(false);
        }}
        getData={getManPower}
      />
        <div className=" w-full h-[calc(100vh-120px)]">
        <table
            className="table-auto static top-0 w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
                        <thead className=" w-full bg-[#28A0D9]">
              <tr className="text-xs  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-2 ${header.width} px-1 font-normal ${
                      header?.isLeft ? "text-left" : "text-center"
                    } text-xs text-white`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <div className=" w-full h-[calc(100vh-175px)] overflow-y-auto relative">
          <table
            className="table-auto  w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <tbody>
                {
                    data && data.map((item, i)=>{
                        return (
                            <TitleManpower/>
                        )
                    })
                }
            </tbody>
          </table>
          </div>
        </div>
    </ProgressLayout>
  )
}

export default ManpowerIndex



const header = [
  // {
  //   headone: "ID",
  //   width: "w-[3%]",
  //   // isLeft: true,
  // },
  {
      headone: "Sl.No",
      width: "w-[5%]",
      isLeft: true,
    },
  {
    headone: "Description",
    width: "w-[35%]",
    isLeft: true,
  },

  {
    headone: "Unit",
    width: "w-[15%]",
    isLeft: true,
  },
  {
    headone: "Qty. ",
    width: "w-[10%]",
    isLeft: true,

  },

  {
    headone: "Unit Price",
    width: "w-[15%]",
    isLeft: true,

  },
  {
    headone: "Amount",
    width: "w-[15%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];