import React from 'react'
import { Route } from 'react-router-dom'
import { BidsIndex, MainBid, ViewBids,CreateBids, EditBids } from '../Views/Web/Biddings/Bids'
import MainEstimation from '../Views/Web/Biddings/Estimation/MianEstimation'
import { BOQIndex, MainBOQ } from '../Views/Web/Biddings/Estimation/BOQ'
import { MainMaterial, MaterialIndex } from '../Views/Web/Biddings/Estimation/Material'
import { EquipmentIndex, MainEquipment } from '../Views/Web/Biddings/Estimation/Equipment'
import { MainOthers, OthersIndex } from '../Views/Web/Biddings/Estimation/Others'
import { MainProfitMargin, ProfitMarginIndex } from '../Views/Web/Biddings/Estimation/ProfitMargin'
import { AttachmentsIndex, MainAttachments } from '../Views/Web/Biddings/Estimation/Attachments'
import { MainTC, TCIndex } from '../Views/Web/Biddings/Estimation/T&C'
import MainManpower from '../Views/Web/Biddings/Estimation/Manpower/MainManpower'
import ManpowerIndex from '../Views/Web/Biddings/Estimation/Manpower/ManpowerIndex'
import MainBidding from '../Views/Web/Biddings/MainBidding'
import CreateBOQ from '../Views/Web/Biddings/Estimation/BOQ/CreateBOQTittle'



function BidRoutes() {
  return (
    <Route path="bidding" element={<MainBidding />}>
        <Route path="bids/:officeId" element={<MainBid/>} >
         <Route index element={<BidsIndex/>} />
         <Route path='create' element={<CreateBids/>} />
         <Route path='edit/:bidEditId' element={<EditBids/>} />
         <Route path='view/:bidViewId' element={<ViewBids/>} />
        </Route>
        <Route path=':officeId/estimation/:estmationId' element={<MainEstimation/>} >
          <Route path='boq' element={<MainBOQ/>}>
           <Route index element={<BOQIndex/>} />
          </Route>
          <Route path='material' element={<MainMaterial/>}>
           <Route index element={<MaterialIndex/>} />
          </Route>
          <Route path='manpower' element={<MainManpower/>}>
           <Route index element={<ManpowerIndex/>} />
          </Route>
          <Route path='equipment' element={<MainEquipment/>}>
           <Route index element={<EquipmentIndex/>} />
          </Route>
          <Route path='others' element={<MainOthers/>}>
           <Route index element={<OthersIndex/>} />
          </Route>
          <Route path='profit-margin' element={<MainProfitMargin/>}>
           <Route index element={<ProfitMarginIndex/>} />
          </Route>
          <Route path='attachments' element={<MainAttachments/>}>
           <Route index element={<AttachmentsIndex/>} />
          </Route>
          <Route path='tc' element={<MainTC/>}>
           <Route index element={<TCIndex/>} />
          </Route>
        </Route>
    </Route>
  )
}

export default BidRoutes