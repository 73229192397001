import React, { useEffect, useState } from 'react'
import DataTables from '../../../../Components/Utils/DataTable';
import SearchBar from '../../../../Components/Utils/SearchBar';
import ViewMore from './ViewMore';
import axiosInstance from '../../../../Services/axiosCommon';
import { CreateButton } from '../../../../Components/Utils/Buttons';
import { Link } from 'react-router-dom';

function ManPowerRoleIndex() {
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [roleList, setRoleList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSrearch, setIsSreach] = useState(false);
    async function getNotes() {
        await axiosInstance
          .get(`/manpower-role/`)
          .then(async (res) => {
            (await res?.data?.data) && setData(res?.data?.data);
            setTotalRows(res.data?.total_records);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
      useEffect(()=>{
          getNotes();
      },[])
    const column = [
      {
        name: "SI.NO",
        selector: (row) => row?.serial_number,
        sortable: true,
        width:"10%"
      },
      {
        name: "Job Role",
        selector: (row) => <p className="capitalize">{row.job_role || "-"}</p>,
        sortable: true,
        width:"25%"
      },
      {
        name: "Type",
        selector: (row) => (
          <p className="capitalize">{row?.type === 0 ? "Own" : row?.type === 1 ? "Rental" : "-"}</p>
        ),
        sortable: true,
        reorder: true,
        width:"20%"
      },
      {
        name: "Working Hours",
        selector: (row) => row?.working_hours,
        sortable: true,
        reorder: true,
        width:"20%"
      },
      {
        name: "Amount Per Hour",
        selector: (row) => row?.amount_per_hour,
        sortable: true,
        reorder: true,
        width:"20%"
      },
      {
        cell: (row) => (
            <ViewMore
              id={row?.id}
              getData={getNotes}
              data={row}
            />
        ),
        allowOverflow: true,
        button: true,
        width: "25px",
      },
    ];
  return (
    <div className=" w-full h-[100%] p-2">
        <div className=" w-[98%] mx-auto h-[100%] rounded-md overflow-hidden bg-white">
          <div className=" w-full flex justify-between items-center px-2 mb-2">
            <h3 className=" text-sm text-mainColor-400 font-semibold">Man Power Role</h3>
            <div className='flex gap-2'>
            <form className="flex items-center mx-0" 
            // onSubmit={SearchData}
            >
              <SearchBar 
            //   handleChange={(e) => setSearchQuery(e.target.value)} 
              />
            </form>
            <Link to={`/settings/man-power-role/create`}><CreateButton label="Create"/></Link>
            </div>
          </div>
          <DataTables
            column={column}
            datas={data}
            // handlePageChange={handlePageChange}
            // handlePerRowsChange={handlePerRowsChange}
            scrollHight="69vh"
            totalRows={totalRows}
          />
        </div>
      </div>
  )
}

export default ManPowerRoleIndex;