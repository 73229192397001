import { Button, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import CustomImgUpload from '../../../Components/Utils/CustomImgUpload';
import {
  NuDatePicker,
  NuInputEmail,
  NuInputNumber,
  NuInputText,
  NuLabel,
  NuSelect,
} from '../../../Components/Utils/NuInput';
import axios from '../../../Services/axiosCommon';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs';
import Dasboard from '../../../Components/Icons/Dasboard';
import NuDate from '../../../Components/Utils/NuDate';

const EditProfile = ({ }) => {
    const navigate = useNavigate();
    const [open,setOpen] =useState(true);
    const [api, contextHolder] = notification.useNotification();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const [imgRemove,setimgRemove] =useState(false);
    const  id  = userDetails.id;


  const [image, setImage] = useState({ preview: '', raw: '' });
  const [createNew, setCreateNew] = useState({
    email: ' ',
    mobileNo: ' ',
    firstName: ' ',
    lastName: '',
    gender: '',
    dob: '',
    dateOfJoin: ' ',
    dateOfRelieving: null,
    roleId: '',
    position: ' ',
    emergencyName: ' ',
    emergencyRelationship: ' ',
    emergencyMobileNo: ' ',
    location: ' ',
    state: ' ',
    city: ' ',
    pincode: ' ',
    country: '',
    imageUrl: '',
    isActive: true,
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const onCancel= () =>{
    navigate(-1);
    setOpen(false);
}

  const getUserDetails = async () => {
    await axios
      .get(`/User/${id}`)
      .then(async (res) => {
        console.log(res?.data?.data);
        (await res?.data?.data) && setCreateNew(res?.data?.data);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      firstName:yup.string().required("First Name is Required"),
      lastName:yup.string().required("Last Name is Required"),
      dob: yup
        .string()
        .required("Date of Birth Required"),
      gender: yup.string().required("Gender is Required"),

      mobileNo: yup
        .string()
        .required("Mobile Number is required")
        .matches(
          /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Enter Valid Mobile Number"
        )
        .min(10, "Invalid Mobile Number")
        .max(10, "Invalid Mobile Number"),
      // joinDate: yup.string().required("Join Date is required"),
    }),
    onSubmit: async (value) => {
      if(imgRemove){
        value.imageUrl = ""
      }
      let formData = new FormData();

      formData.append('userData', JSON.stringify(value));
      formData.append('userImage', image.raw);

         axios
            .put(`/User/${id}`, formData)
            .then((res) => {
              console.log(res);
              // getData();
              onCancel();
            })
            .catch((err) => {
              console.log(err);
            })
        // : axios
        //     .post('/User/', formData)
        //     .then((res) => {
        //       console.log(res);
        //       getData();
        //       onCancel();
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
    },
  });


  function getHOF(){
    let cDate = new Date()
    return cDate.setFullYear(cDate.getFullYear() - 18)
  }

  function handleRemove(){
    if(image.raw){
      setImage({
         preview: "", raw: "" 
      })
    }else if(createNew?.imageUrl){
      setimgRemove(true)
      setCreateNew({...createNew,imageUrl:""})
    }
  }


  return (
    <div className=' w-full h-full px-4'>
    {contextHolder}
          {/* <Modal
    open={open}
    title={ 'Edite Member' }
    width={'50%'}
    centered
    className='my-modal'
    onCancel={onCancel}
    footer={[]}
  > */}
              <div className=" w-full">
            <BreadCrumb
              items={[
                {
                  title: (
                    <Link to="/dashboard">
                      <Dasboard color="gray" width={10} height={10} />{" "}
                    </Link>
                  ),
                },
                { title: <p className=" text-[12px] ">User Profile</p> },
                { title: <p className=" text-[12px] ">Edit</p> },
              ]}
            />
          </div>
      <div className=' overflow-y-scroll h-[80vh]'>
        <form onSubmit={formik.handleSubmit}>
          <div className=' w-full flex flex-wrap'>
            <CustomImgUpload
              handleChange={handleChangeImg}
              preview={image.preview||createNew.imageUrl||""}
              width='w-4/12'
              isRemove={true}
              removeFunction={handleRemove}
            />
            <NuInputText label='First Name' placeholder='First Name' formik={formik} name='firstName' width='w-4/12'  isRequired={true} />
            <NuInputText label='Last Name' placeholder='Last Name' formik={formik} name='lastName' width='w-4/12' isRequired={true} />
            {/* <NuDatePicker label='Date of Birth' formik={formik} name='dob' width='w-4/12' /> */}
            {/* <NuDatePicker label='Date of Join' formik={formik} name='dateOfJoin' width='w-4/12' /> */}
            <NuDatePicker
              label="Date of Birth"
              formik={formik}
              name="dob"
              width=" w-full md:w-4/12"
              max={NuDate({
                value: getHOF(),
                format: "yyyy-MM-DD",
              })}
              isRequired={true}
            />
            <NuDatePicker
              label="Date of Join"
              formik={formik}
              name="dateOfJoin"
              width=" w-full md:w-4/12"
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
            />
            <NuSelect
              label='Gender'
              options={[
                { value: 'M', label: 'Male' },
                { value: 'F', label: 'Female' },
              ]}
              formik={formik}
              placeholder='Choose'
              name='gender'
              width='w-4/12'
              isRequired={true}
            />
           
            <NuInputEmail label='Email' placeholder='example@email.com' formik={formik} name='email' width='w-4/12' isRequired={true} />
            <NuSelect
              label='Country'
              options={[{ value: 'India', label: 'India' }]}
              formik={formik}
              placeholder='Choose'
              name='country'
              width='w-4/12'
            />
            {/* <NuSelect
              label='State'
              options={[
                { value: 'Tamil Nadu', label: 'Tamil Nadu' },
                { value: 'Kerala', label: 'Kerala' },
              ]}
              formik={formik}
              placeholder='Choose'
              name='state'
              width='w-4/12'
            /> */}
            <NuInputText label='State' placeholder='State' formik={formik} name='state' width='w-4/12' />

            <NuInputText label='City' placeholder='City' formik={formik} name='city' width='w-4/12' />
            <NuInputText label='Location' placeholder='Location' formik={formik} name='location' width='w-4/12' />
            <NuInputNumber label='Pincode' placeholder='Pincode' formik={formik} name='pincode' width='w-4/12' />
            <NuInputNumber label='Mobile' placeholder='Mobile' formik={formik} name='mobileNo' width='w-4/12' isRequired={true} />
            <NuLabel label='Emergency Contact' />
            <NuInputText label='Name' placeholder='Name' formik={formik} name='emergencyName' width='w-4/12' />
            <NuInputText
              label='Relation'
              placeholder='Relation'
              formik={formik}
              name='emergencyRelationship'
              width='w-4/12'
            />
            <NuInputNumber
              label='Mobile'
              placeholder='Mobile'
              formik={formik}
              name='emergencyMobileNo'
              width='w-4/12'
            />
          </div>
          <div className=' w-full flex justify-end items-center'>
            <Button onClick={onCancel} className=' mr-4'>
              Cancel
            </Button>
            <Button htmlType='submit' type='primary'>
              Submit
            </Button>
          </div>
        </form>
      </div>
    {/* </Modal> */}
    </div>
  );
};

export default EditProfile;
