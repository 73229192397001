import {Modal, notification } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { NuSelect } from "../../../../Components/Utils/NuInput";
import axios from "../../../../Services/axiosCommon";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";


const CreateRole = ({ open, getData, onCancel,userID, data,role ,roleList,userAccessId }) => {
  const [api, contextHolder] = notification.useNotification();
    const [createNew, setCreateNew] = useState({

        userId:"",
        roleId:"",
     });

  
console.log("User role",userID);
console.log("Data",role);

  
    useEffect(() => {
      setCreateNew({
        userId:userID,
        roleId:role||""
      })
    }, []);
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: async (value) => {
        data?.userRoleId
          ? axios
              .put(`/userrole/${data?.userRoleId}`, value)
              .then((res) => {
                getData(1);
                api.success({
                  message: `User Role  Updated successfully`,
                  description: res?.data?.msg||" ",
                  placement: 'bottomRight',
                });
                onCancel();
              })
              .catch((err) => {
                console.log(err);
                api.success({
                  message: `User Role  Updated successfully`,
                  description: err?.response?.data?.msg||" ",
                  placement: 'bottomRight',
                });
              })
          : 
           axios.post(`/userrole/`, value)
              .then((res) => {
                getData(1);
                api.success({
                  message: `User Role  Updated successfully`,
                  description: res?.data?.msg||" ",
                  placement: 'bottomRight',
                });
                onCancel();
              })
              .catch((err) => {
                console.log(err);
                api.success({
                  message: `User Role  Updated successfully`,
                  description: err?.response?.data?.msg||" ",
                  placement: 'bottomRight',
                });
              });
            }
    });
  
    return (
      <Modal open={open} title={'Role'}  centered onCancel={onCancel} footer={[]}>
        {contextHolder}
        <div className=' overflow-y-scroll'>
          <form onSubmit={formik.handleSubmit}>
            <div className=' w-full flex flex-wrap my-5'>
              <NuSelect
                label='Role'
                options={roleList}
                formik={formik}
                placeholder='Choose'
                name='roleId'
                width='w-full'
              />
            </div>
            <div className=' w-full flex justify-end gap-4 items-center'>
              <CancelButton handleClick={onCancel} />
              <SubmitButton/>
            </div>
          </form>
        </div>
      </Modal>
    );
  };
  
  export default CreateRole;