import React from 'react'
import NuDate from '../../../../Components/Utils/NuDate';

function Upcoming({data}) {
  return (
    <div className=" w-full h-full overflow-y-auto pb-4">
    <table className="overflow-hidden text-center  text-xs border-collapse  w-full overflow-y-auto">
      <thead className="border-b  rounded-md overflow-hidden font-medium  text-xs">
        <tr
          className="p-2"
          style={{
            borderTop: "1px solid #E9E8EC ",
            borderBottom: "1px solid #E9E8EC ",
          }}
        >
            <th
              scope="col"
              className="sticky py-2 w-[80%] font-medium text-[#6C6580] text-xs text-start pl-2 "
            >
              Task
            </th>
            <th
              scope="col"
              className="sticky py-2 w-[20%] font-medium text-[#6C6580] text-xs"
            >
             Due Date
            </th>
        </tr>
      </thead>
      <tbody className=" px-2 ">
        {data &&
          data.map((Item, Index) => {
            return (
              <tr
                key={Index}
                className=" border-b transition duration-300 ease-in-out hover:bg-neutral-100 px-3 py-2"
                style={{ borderBottom: "1px solid #E9E8EC " }}
              >
                <td className="  text-[12px] text-[#4F4768] truncate text-start pl-3 py-1">
                  {Item.task_name||"-"}
                </td>
                {/* <td className=" text-[12px] text-[#4F4768]  py-2">
                  {Item?.priority||'-' } 
                </td> */}
                <td className=" text-[12px] text-[#4F4768]  py-1">
                  {Item?.date !=""?(<NuDate value={Item?.date} format="DD-MMM-YY" />):"-" ||'-'}
                </td>
                {/* <td className=" text-[12px] text-[#4F4768] py-2">
                  {Item?.status || '-'}
                </td> */}
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
  )
}

export default Upcoming