import { useState } from 'react';
import CreateRole from './createRole';
import CreateStatus from './createStatus';


const ViewMore = ({ data,role, getMember,allRoleList,userID }) => {
  const [showRole,setShowRole] =useState(false);
  const [showStatus,setShowStatus] =useState(false);
  return (
    <>
      <div className='text-[#344767]  relative dropdown inline-block z-50'>
        <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
          <span className='pt-1'>
            <svg width='6' height='18' viewBox='0 0 4 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.6875 7.72339C0.859072 7.72339 0.1875 7.05182 0.1875 6.22339C0.1875 5.39496 0.859072 4.72339 1.6875 4.72339C2.51593 4.72339 3.1875 5.39496 3.1875 6.22339C3.1875 7.05182 2.51593 7.72339 1.6875 7.72339Z'
                fill='#BDC1C6'
              />
              <path
                d='M0.1875 1.72339C0.1875 2.55182 0.859073 3.22339 1.6875 3.22339C2.51593 3.22339 3.1875 2.55182 3.1875 1.72339C3.1875 0.894962 2.51593 0.223389 1.6875 0.223389C0.859073 0.223389 0.1875 0.894961 0.1875 1.72339Z'
                fill='#BDC1C6'
              />
              <path
                d='M1.6875 12.2234C0.859072 12.2234 0.1875 11.5518 0.1875 10.7234C0.1875 9.89496 0.859072 9.22339 1.6875 9.22339C2.51593 9.22339 3.1875 9.89496 3.1875 10.7234C3.1875 11.5518 2.51593 12.2234 1.6875 12.2234Z'
                fill='#BDC1C6'
              />
            </svg>
          </span>
        </div>
        <ul className='dropdown-menuOne bg-red-400  text-[#344767] text-left  rounded-lg absolute -left-24 -top-4 overflow-hidden hidden  z-50 shadow-lg'>
          <li className=''>
          <span
              className='bg-white hover:bg-gray-100 py-2 cursor-pointer px-2 block text-blue-400 whitespace-no-wrap text-xs font-medium'
             onClick={()=>{setShowStatus(true);}}
            >
              Status
            </span>
          </li>
          <li className=' w-[100px] bg-black'>
            <span
              className='bg-white hover:bg-gray-100 py-2 px-2 block cursor-pointer text-blue-400 whitespace-no-wrap text-xs font-medium'
             onClick={()=>{setShowRole(true);}}
            >
              Role
            </span>
          </li>
        </ul>
      </div>
      
      <CreateRole
      open={showRole}
      getData={getMember}
      data={data}
      userID={userID}
      role={role}
      roleList={allRoleList}
      onCancel={() => {
        setShowRole(false);
      }}
       />
    <CreateStatus
      open={showStatus}
      getData={getMember}
      status={data}
      userID={userID}
      onCancel={() => {
        setShowStatus(false);
      }}
       />
    </>
  );
};

export default ViewMore;
