import React, { useEffect, useState } from 'react'
import SearchBar from '../../../../Components/Utils/SearchBar'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { Link, useParams } from 'react-router-dom'
import Dasboard from '../../../../Components/Icons/Dasboard'
import { BackButton, CreateButton } from '../../../../Components/Utils/Buttons'
import axiosInstance from '../../../../Services/axiosCommon'
import Pagination from '../../../../Components/Utils/Pagenation'
import Doc from '../../../../Components/Icons/Doc'
import Project from '../../../../Components/Icons/Project'
import NuDate from '../../../../Components/Utils/NuDate'
import NuAmount from '../../../../Components/Utils/NuAmount'
import ViewMore from './viewMore'

function BidsIndex() {
    const {officeId} = useParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [open, setOpen] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [dataCount, setDataCount] = useState(20);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);


    function getData(){
      axiosInstance.get(`/bidding/${officeId}/details?page=${pageIndex}&per_page=${dataCount}`).then((res) => {
        setData(res.data?.data);
        setTotal(res.data?.total_records);
      }).catch(err=>{

      })
    }

    function SearchData(e) {
        e.preventDefault();
        if (searchQuery != "") {
          axiosInstance
            .get(
              `/bidding/${officeId}/details?page=${pageIndex}&per_page=${dataCount}`,
              { params: { search: `${searchQuery}` } }
            )
            .then((res) => {
              setData(res.data?.data);
              setTotal(res.data?.total_records);
            })
            .catch((err) => console.log(err));
        } else {
        //   getTask(firstProject);
        }
      }

    useEffect(()=>{
      getData()
    },[])

  return (
    <div className=' px-4 py-2 overflow-y-hidden'>
                    <div className="md:flex justify-between w-full mb-2">
            <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Bidding</p>)},
            {title:(<p className=' text-[12px] '>Bids</p>)}
            ]} />

            <div className=" flex justify-end items-center gap-3 mr-6">
            <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
              </form>
              {true && <CreateButton label="Import" handleClick={()=>setOpen(true)}    />}
              {true && <Link to={`/main/bidding/bids/${officeId}/create`}  ><CreateButton label={"Bid"}/></Link>}
                  {/* <BackButton/> */}
                </div>
            </div>
            <div className=" w-full h-[calc(100vh-120px)] ">
            <div className=" w-full h-[calc(100vh-150px)] overflow-y-auto relative">
            <table
            className="table-auto static top-0 w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
                        <thead className=" w-full bg-[#28A0D9]">
              <tr className="text-xs  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-2 ${header.width} px-1 font-normal ${
                      header?.isLeft ? "text-left" : "text-center"
                    } text-xs text-white`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <table
            className="table-auto static top-0 w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <tbody>
                {
                    data && data.map((item, i)=>{
                        return (
                            <tr className=' w-full ' key={i}>
                            <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400 w-[10%]">
                            <Link className=' font-normal text-black' to={`/main/bidding/bids/${officeId}/view/${item?.biddingId}`} state={{data:item}}>{item?.referenceNo|| "#"}</Link>
                            </td>
                            <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400 w-[25%]">
                            <Link className=' font-normal text-black' to={`/main/bidding/bids/${officeId}/view/${item?.biddingId}`} state={{data:item}}>
                             {item?.projectName|| "#"}
                             </Link>
                            </td>
                            <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400 w-[15%]">
                             {item?.clientName|| "-"}
                            </td>
                            <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400 w-[10%]">
                             {item?.dueDate && <NuDate value={item?.dueDate} format="DD-MMM-YYYY" /> || "-"}
                            </td>
                            <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400 w-[10%]">
                            {item?.submittedOn && <NuDate value={item?.submittedOn} format="DD-MMM-YYYY" /> || "-"}
                            </td>
                            <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400 w-[15%]">
                             {item?.finalValue && <NuAmount value={item?.finalValue} />|| "0"}
                            </td>
                            <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400 w-[10%]">
                             {item?.biddingStatus|| "#"}
                            </td>
                            <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400 w-[5%]">
                            <div className=" w-full flex justify-center items-center">
                                <Link to={`/main/bidding/${officeId}/estimation/${item?.biddingId}/boq`}>
                                <Project color='red'/>
                                </Link>
                        <ViewMore id={item?.biddingId||""} getData={getData} data={item} />
                      </div>
                            </td>
                        </tr>
                        )
                    })
                }
            </tbody>
          </table>
            </div>
            <div className=" h-7 my-[2px] px-3">
          <Pagination
            total={total}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            dataCount={dataCount}
            setDataCount={setDataCount}
          />
        </div>
            </div>
    </div>
  )
}

export default BidsIndex


const header = [
    // {
    //   headone: "ID",
    //   width: "w-[3%]",
    //   // isLeft: true,
    // },
    {
        headone: "Ref.No",
        width: "w-[10%]",
        isLeft: true,
      },
    {
      headone: "Project",
      width: "w-[25%]",
      isLeft: true,
    },

    {
      headone: "Client",
      width: "w-[15%]",
      isLeft: true,
    },
    {
      headone: "Bid Due Date ",
      width: "w-[10%]",
      isLeft: true,

    },

    {
      headone: "Submitted on",
      width: "w-[10%]",
      isLeft: true,
  
    },
    {
      headone: "Value",
      width: "w-[15%]",
      isLeft: true,
    },
    {
        headone: "Status",
        width: "w-[10%]",
        isLeft: true,
      },
    {
      headone: "Action",
      width: "w-[5%]",
    },
  ];