import React, { useState } from "react";
import ViewMoreTask from "./ViewMoreMyTask";
import NuDate from "../../../../../../Components/Utils/NuDate";
import { Link } from "react-router-dom";
import { Progress } from "antd";
import UpdatePercentage from "./update";
import ViewMoreSubTask from "./ViewMoreMySubTask";
import axiosInstance from "../../../../../../Services/axiosCommon";
import CreateToday from "./createTodayComplete";

const ExpandableMyTask = ({ item, getData, firstProject, projectId, officeId }) => {
  const [percentOpen, setPercentOpen] = useState(false);

  function DateDiff(createDate, expiryDate) {
    createDate = new Date(createDate);
    expiryDate = new Date(expiryDate);
    let timeDiff = Math.abs(expiryDate.getTime() - createDate.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays == 0 ? diffDays + 1 : diffDays + 1;
  }

    function updateSubTaskRead(id){
      axiosInstance.put(`SubTask/isRead/${id}`,{
        isRead:1
      }).then(res=>{
    
      }).catch(err=>{
        console.log("err");
      })
    }

    function updateTaskRead(id){
      axiosInstance.put(`Task/isRead/${id}`,{
        isRead:1
      }).then(res=>{
    
      }).catch(err=>{
        console.log("err");
      })
    }
    

  return (
    <>
              <tr
                className={`w-[100%] bg-[#F6F9FF] my-1 `}
              >
                <td className=' w-[3%] text-center'>
                <div className=" w-full h-full gap-1 flex justify-center items-center">
                {
                    item?.isRead == false && <div className=" w-2 h-2 bg-[#1292DC] rounded-full shadow"></div>
                  }
                </div>
                </td>

                <td className=" w-[31%] py-2 text-xs text-left font-normal text-mainColor-400">
                <Link className=" text-mainColor-400 hover:text-mainColor-400 ml-2 line-clamp-2" to={`/project/tasks/task-plan/${officeId}/${projectId}/view/${item?.id}`} state={{projectId:firstProject}} onClick={()=>item?.taskLink?updateTaskRead(item?.id):updateSubTaskRead(item?.id)}> {item?.name || "-"}</Link>
                </td>

                <td className=" w-[13%] py-2 text-xs text-center font-normal text-mainColor-400">
                  {item?.startDate != "None" || item?.startDate != " " ? (
                    <NuDate value={item?.startDate} format="DD-MMM-YYYY" />
                  ) : (
                    "-"
                  )}
                </td>
                <td className=" w-[13%] py-2 text-xs text-center font-normal text-mainColor-400">
                  {item?.endDate != "None" || item?.endDate != " " ? (
                    <NuDate value={item?.endDate} format="DD-MMM-YYYY" />
                  ) : (
                    "-"
                  )}
                </td>
                <td className=" w-[10%] py-2 text-xs text-center font-normal text-mainColor-400">
                  {((item?.startDate != "None" || item?.startDate != " ") &&
                    (item?.endDate != "None" || item?.endDate != " ") &&
                    DateDiff(item?.startDate, item?.endDate)) ||
                    "-"}
                </td>
                <td className=" w-[10%] py-2 text-xs text-center font-normal text-mainColor-400">
                  {item?.priority || "-"}
                </td>
                <td className=" w-[10%] py-2 text-xs text-center font-normal text-mainColor-400">
                  {item?.status || "-"}
                </td>
                <td className=" w-[5%] py-2 text-xs text-center font-normal text-mainColor-400">
                <Progress
              onClick={() => {
                setPercentOpen(true);
              }}
              type="circle"
              size={30}
              strokeWidth={12}
              format={(percent) => (
                <p
                  className={`cursor-pointer ${
                    item?.completion > 95
                      ? "text-[#87d068]"
                      : item?.completion > 50
                      ? "text-[#108ee9]"
                      : "text-[#D2BD00]"
                  } text-xs font-semibold`}
                >
                  {percent}
                </p>
              )}
              percent={Math.floor(item?.completion )|| 0}
              strokeColor={
                item?.completion > 95
                  ? {
                      "0%": "#87d068",
                      "100%": "#87d068",
                    }
                  : item?.completion > 50
                  ? {
                      "0%": "#108ee9",
                      "100%": "#108ee9",
                    }
                  : {
                      "0%": "#D2BD00",
                      "100%": "#D2BD00",
                    }
              }
            />
                </td>
                <td className=" w-[4%] text-center  py-2">
                  {
                  item?.taskLink ? <ViewMoreTask data={item} id={item?.id} projectId={firstProject} officeId={officeId} />:<ViewMoreSubTask data={item} id={item?.id} projectId={firstProject} />
                  
                  }
                </td>
              </tr>

              {
                          item?.isInstEnabled == true ? 
          
                          <CreateToday
                          projectId={firstProject}
                          open={percentOpen}
                          onCancel={() => {
                            setPercentOpen(false);
                          }}
                          taskId={item?.id}
                          getData={getData}
                          list={[]}
                          data={item?.completion}
                        />
                        :
                        <UpdatePercentage
                        projectId={firstProject}
                        open={percentOpen}
                        onCancel={() => {
                          setPercentOpen(false);
                        }}
                        Id={item?.id}
                        data={item?.completion}
                        getData={getData}
                        taskLink={item?.taskLink}
                 />
}

    </>
  );
};

export default ExpandableMyTask;
