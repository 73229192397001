import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { useFormik } from "formik";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNumber } from "../../../../Context/ProjectIdContext";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import ItemLayout from "../ItemLayout";
import NuDate from "../../../../Components/Utils/NuDate";
import ViewMore from "./ViewMore";
import Pagination from "../../../../Components/Utils/Pagenation";
import CreateActivity from "./CreateActivity";
import { CreateButton } from "../../../../Components/Utils/Buttons";
import ImportActivity from "./import";
import ViewActivity from "./ViewActivity";
import { GetAccess } from "../../../../Components/Utils/roles";
const { confirm } = Modal;

function IndexActivity() {
  const { officeId } = useParams();
  const [openImport, setOpenImport] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  const { number, updateNumber } = useNumber();
  const [percentOpen, setPercentOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalTasks, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);
  console.log(data, "huhi");

  const [status, setStatus] = useState([]);
  const [priority, setPriority] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [notFirst, setNotFirst] = useState(false);
  const [filterData, setFilterData] = useState({
    SDF: "",
    EDT: "",
    status: "",
    priority: "",
  });
  const [afterFilter, setAfterFilter] = useState({});
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });
  const [createModalOpen, setCreateModalOpen] = useState(false); // State to handle modal open/close

  function SearchData(e) {
    setNotFirst(true);
    e.preventDefault();
    if (searchQuery != "") {
      axiosInstance
        .get(
          `/Activity/${officeId}?page=${pageIndex}&per_page=${dataCount}`,
          { params: { search: `${searchQuery}` } }
        )
        .then((res) => {
          setData(res.data?.data);
          setTotalProject(res.data?.total_records);
        })
        .catch((err) => console.log(err));
    } else {
      getTask(firstProject);
    }
  }

  function SearchPageDate() {
    axiosInstance
      .get(
        `/Activity/${officeId}?page=${pageIndex}&per_page=${dataCount}`,
        { params: { search: `${searchQuery}` } }
      )
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records);
      })
      .catch((err) => console.log(err));
  }

  function getTask() {
    axiosInstance
      .get(`/Activity/${officeId}?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (searchQuery == "") {
       getTask();
    }
  }, [searchQuery]);


  useEffect(() => {
    if (searchQuery != "") {
      SearchPageDate()
    }else{
      getTask();
    }
  },[pageIndex,dataCount])

  return (
    <>
      <ItemLayout
        setOpen={setOpen}
        setSearchQuery={setSearchQuery}
        SearchData={SearchData}
        CreateAccess={GetAccess("activity_item_create")}
        ImportAccess={GetAccess("activity_item_create")}
        // isFilter={isFilter}
        // setFilterOpen={setFilterOpen}
        // ClearFilter={ClearFilter}
        onCreateClick={() => setCreateModalOpen(true)} // Handle button click
      >
        <div className="w-full h-[calc(100vh-150px)]">
          <div className="w-full h-[calc(100vh-170px)] overflow-y-auto relative">
            <table
              className="table-auto w-full "
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <thead className="w-full bg-[#2A475E] sticky top-0 z-[99]">
                <tr className="text-xs font-medium py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-2 ${header.width} px-1 font-normal ${
                        header?.isLeft ? "text-left" : "text-center"
                      } text-xs text-white`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, i) => (
                    <tr className="w-full" key={i}>
                      <td className="py-2 px-1 text-xs text-center font-normal text-mainColor-400">
                        {item?.serial_number || "#"}
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                        onClick={() => {
                          setSelectedItem(item);
                          setViewOpen(true);
                        }}
                      >
                        {item?.item_code || "-"}
                      </td>
                      <td
                        className="py-2 px-1 text-xs text-left font-normal text-mainColor-400 cursor-pointer"
                        onClick={() => {
                          setSelectedItem(item);
                          setViewOpen(true);
                        }}
                      >
                        {item?.description || "-"}
                      </td>
                      <td className="py-2 px-1 text-xs text-center font-normal text-mainColor-400">
                        <div className="w-full flex justify-center items-center">
                          <ViewMore
                            id={item?.id || ""}
                            getData={getTask}
                            data={item}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="h-6 my-2">
            <Pagination
              total={totalTasks}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              dataCount={dataCount}
              setDataCount={setDataCount}
            />
          </div>
        </div>
       
        <CreateActivity
          open={createModalOpen}
          onCancel={() => {
            setCreateModalOpen(false);
          }}
          projectList={projectList || []}
          getData={getTask}
          firstProject={firstProject}
        />

        <Modal
          open={viewOpen}
          onCancel={() => setViewOpen(false)}
          title={"View Activity"}
          width="50%"
          centered
          className="my-modal"
          footer={[]}
        >
          <ViewActivity
            item={selectedItem}
            open={viewOpen}
            onCancel={() => setViewOpen(false)}
          />
        </Modal>
      </ItemLayout>
      <ImportActivity
        open={open}
        onCancel={() => setOpen(false)}
        getData={getTask}
        label="Import"
        officeId={officeId}
      />
    </>
  );
}

export default IndexActivity;

const header = [
  {
    headone: "ID",
    width: "w-[5%]",
  },
  {
    headone: "Item Code",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Description",
    width: "w-[70%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];
