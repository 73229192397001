import { Button, Modal, notification } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { NuInputText, NuLabel } from "../../../../Components/Utils/NuInput";
import axios from "../../../../Services/axiosCommon";
import { useNavigate } from "react-router-dom";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import {
  BiddingPermission,
  BiddingStatusSettingsPermission,
  BudgetPermission,
  CategorySettingsPermission,
  ContactPermission,
  DivisionSettingsPermission,
  FinancePermission,
  FinanceSettingsPermission,
  ItemPermission,
  JobRoleSettingsPermission,
  ManPowerRoleSettingsPermission,
  ModulePermission,
  MyDiaryPermission,
  MyDiarySettingsPermission,
  NotificationSettingsPermission,
  PettyCashPermission,
  PlanningPermission,
  ProfileSettingsPermission,
  ProgressPermission,
  ProjectPermission,
  ProjectSettingsPermission,
  TaskPermission,
  TaskSettingsPermission,
  TeamsPermission,
  TimesheetPermission,
  UnitSettingsPermission,
  UserRolesSettingsPermission,
  UserSettingsPermission,
} from "./ListOfRole";

function CreateRole() {
  const [api, contextHolder] = notification.useNotification();
  const [roleInfo, setRoleInfo] = useState([]);
  const navigate = useNavigate();

  const [createNewRole, setCreateNewRole] = useState({
    role: "",
    permissions: [],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNewRole,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   name: yup
    //     .string()
    //     .required("Name Required")
    //     .matches(/^(?![0-9]*$)[a-zA-Z]+$/, "Enter Valid Name"),
    //   dob: yup
    //     .string()
    //     .max(new Date(Date.now() - 567648000000), "Must be at least 18 years")
    //     .required("Date of Birth Required"),
    //   gender: yup.string().required("Gender is Required"),
    //   city: yup.string().matches(/^[A-Za-z]+$/, "Enter Valid City Name"),
    //   state: yup.string().required("Place Select State"),
    //   pincode: yup
    //     .string()
    //     .required("Pincode is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Pincode"
    //     )
    //     .min(6, "Invalid Pincode")
    //     .max(6, "Invalid Pincode"),
    //   address: yup
    //     .string()
    //     .matches(
    //       /^(?![0-9]*$)(?![0-9\s]*$)[a-zA-Z0-9'\.\-\s\,\/\(\)\#]+$/,
    //       "Enter Valid Address"
    //     ),

    //   mobileNo: yup
    //     .string()
    //     .required("Mobile Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(10, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    //   joinDate: yup.string().required("Join Date is required"),
    // }),
    onSubmit: async (value, { resetForm }) => {
      value.permissions = roleInfo;
      console.log(value);
      axios
        .post("/rolePermission/add", value)
        .then((response) => {
          console.log(response.data.status);
          if (response.status === 201 || response.data.status == true) {
            resetForm();
            setRoleInfo([]);
            api.success({
              message: `User Role  Created successfully`,
              // description:'',
              placement: "bottomRight",
            });
            navigate(-1);
          } else {
            api.error({
              message: `User Role Created failed`,
              description: response?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((error) => {
          api.error({
            message: `User Role Created failed`,
            description: error?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });

  function DisableFun(e) {
    let fullAccess = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155,156];
    console.log(e.target.checked);
    if (e.target.checked) {
      setRoleInfo(fullAccess);
    } else {
      setRoleInfo([]);
    }
  }

  const selectRow = (name, id) => {
    // let selectedIndex = userRole.findIndex((data) => data.name === name);
    let testUser;
    if (roleInfo && roleInfo.some((Userid) => Userid === id)) {
      testUser = roleInfo.filter((roleId) => roleId !== id);
      setRoleInfo(testUser);
    } else {
      setRoleInfo([...roleInfo, id]);
    }
  };
  return (
    <div className="w-full overfl h-[80vh]  overflow-hidden">
      {contextHolder}
      <div className=" h-full w-full overflow-y-scroll px-3">
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex justify-between items-center">
            <NuInputText
              label="Role Name"
              placeholder="Role Name"
              formik={formik}
              name="role"
              width="w-6/12"
            />
            <div className=" text-center">
              <input
                type="checkbox"
                className="px-3 mr-2 py-2 accent-main-blue"
                // name={user.name}
                // checked={users.some((u) => u?.id === user?.id)}
                // disabled={DisableFun(role.id)}
                // checked={roleInfo.some((id) => id === role.id)}
                onChange={DisableFun}
              />
              <span className=" text-text">Select All</span>
            </div>
          </div>
          {ModulePermission.map((item, index) => {
            return (
              <div key={index} className=" mb-2">
                {/* <NuLabel label={item?.name} /> */}
                <p className=" px-4 text-xs font-semibold">{item?.name}</p>
                <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title pt-[2px] px-2">
                  {item.roles.map((role, index) => {
                    return (
                      <div
                        key={index}
                        className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                      >
                        <span className=" text-text">{role.name}</span>
                        <input
                          type="checkbox"
                          className="px-3 mr-2 py-2 accent-main-blue"
                          checked={roleInfo.some((id) => id === role.id)}
                          onChange={() => selectRow(item.name, role.id)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}

          {roleInfo.some((id) => id == 1) &&
            ItemPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          {roleInfo.some((id) => id == 2) &&
            BiddingPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          {roleInfo.some((id) => id == 3) &&
            ProjectPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          {roleInfo.some((id) => id == 4) &&
            BudgetPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          {roleInfo.some((id) => id == 5) &&
            PlanningPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 6) &&
            TaskPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 7) &&
            ProgressPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 8) &&
            FinancePermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 9) &&
            PettyCashPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 10) &&
            MyDiaryPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 11) &&
            ContactPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 12) &&
            TimesheetPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 13) &&
            TeamsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            ProfileSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            UserRolesSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            UserSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            ProjectSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            TaskSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            MyDiarySettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            ManPowerRoleSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            JobRoleSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            NotificationSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {roleInfo.some((id) => id == 15) &&
            UnitSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          {roleInfo.some((id) => id == 15) &&
            FinanceSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          {roleInfo.some((id) => id == 15) &&
            CategorySettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          {roleInfo.some((id) => id == 15) &&
            DivisionSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          {roleInfo.some((id) => id == 15) &&
            BiddingStatusSettingsPermission.map((item, index) => {
              return (
                <div key={index} className=" mb-2">
                  {/* <NuLabel label={item?.name} /> */}
                  <p className=" px-4 font-semibold text-xs">{item?.name}</p>
                  <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title  pt-1 px-2">
                    {item.roles.map((role, index) => {
                      return (
                        <div
                          key={index}
                          className=" w-full bg-slate-100 flex justify-between items-center p-3 rounded-md"
                        >
                          <span className=" text-text">{role.name}</span>
                          <input
                            type="checkbox"
                            className="px-3 mr-2 py-2 accent-main-blue"
                            checked={roleInfo.some((id) => id === role.id)}
                            onChange={() => selectRow(item.name, role.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          <div className=" w-full mt-4 mb-3 pb-10 flex gap-3 justify-end items-center">
            <CancelButton
              handleClick={() => {
                navigate(-1);
              }}
            />
            <SubmitButton />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateRole;
