import React from "react";
import { useEffect, useState } from "react";
import {
  NuDatePicker,
  NuInputNumber,
  NuInputText,
  NuLabel,
  NuSelect,
  NuTextArea,
  NuTimePicker,
} from "../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../../Services/axiosCommon";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
// import Attachement from "./Attachement";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../Components/Icons/Dasboard";
import * as yup from "yup";
import { notification } from "antd";
import Attachements from "./Attachements";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
// import Attachement from "../Notes/Attachement";
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
// export const videotype = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];

export default function CreateBids({ data }) {
  const {officeId} = useParams();
  let {value:Category} = useFetchMataData("/Metadata/category")
  let {value:Division} = useFetchMataData("/Metadata/division")
  let {value:BiddingStatus} = useFetchMataData("/Metadata/biddingstatus")
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState([]);
  const [status, setStatus] = useState([]);
  const [createNew, setCreateNew] = useState({
    projectName:"",
    clientId:"",
    referenceNo:"",
    dueDate:"",
    submittedOn:"" ,
    submittedBy:null,
    submittedTo:null,
    comments:"",
    category:"",
    revision:"",
    bidValue:"",
    finalValue:"",
    biddingStatus:"",
    scopeOfWork:"",
    division:"",
    branchId:"",
  });


  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [proClient, setProClient] = useState([]);


  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item=>{
      if (item.size < maxFileSize ){
        fileArr.push(item)
      }else{
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })    
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item=>{
      if (item.size < maxFileSize ){
      let fileName = item?.name
      let fileExtention =  fileName.split(".").reverse()[0]
      if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      }else{
        let x = URL.createObjectURL(item)
        tempArr.push(x)
      }
    }
    })
    setImage(tempArr)
  }

  // const getStatus = () => {
  //   axios
  //     .get(`/Metadata/noteStatus`)
  //     .then((res) => {
  //       let data = res.data?.data?.keyValues;
  //       let tempData = [];
  //       data.forEach((item) => {
  //         let tempObject = { value: item, label: item };
  //         tempData.push(tempObject);
  //       });
  //       res.data?.data?.keyValues && setStatus(tempData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getClients = async () => {
    await axios
      .get(`/DropDown/${officeId}/client`)
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data;
        status.forEach((item) => {
          let tempObject = { value: item.clientId, label: item.companyName };
          FinalData.push(tempObject);
        });
        response.data?.data && setProClient(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    // getStatus();
    getClients()
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
        projectName: yup.string().required("Project Name is Required"),
        // clientId: yup.string().required("Client "),
    }),
    onSubmit: async (value) => {
      value.branchId = officeId
      setIsLoading(true);
      let formData = new FormData();
      formData.append("details", JSON.stringify(value));
      // formData.append("attachments", image.raw);
      if(selectedFile !== null){
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }else{
        formData.append("attachments",[])
      } 

      data
        ? axios
            .put(`/bidding/update_bidding/${data?.biddingId}`, formData)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                setImage({ preview: "", raw: "" });
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/bidding/create_bidding", formData)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                setImage([]);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });
  return (
    <div className="w-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="/mainNotes">
                  <p className=" text-[12px] ">Bidding</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div>
        <div
          className=" w-full mx-auto h-[calc(100vh-140px)] rounded-[6px] mt-2 overflow-y-scroll"
          style={{ border: "1px solid #C5C5C5" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex justify-between items-center">
              <NuLabel label="Bidding" />
              {/* <BackButton /> */}
            </div>
            <div className="flex flex-wrap ">
             
                <NuInputText
                  label="Project Name"
                  isRequired={true}
                  placeholder="Project Name"
                  formik={formik}
                  name="projectName"
                  width="w-full md:w-1/2"
                />

                <NuInputText
                  label="Reference No"
                  isRequired={true}
                  placeholder="Reference No"
                  formik={formik}
                  name="referenceNo"
                  width="w-full md:w-1/4"
                />

                <NuInputNumber
                  label="Project Bid Value"
                  isRequired={true}
                  placeholder="Project Bid Value"
                  formik={formik}
                  name="bidValue"
                  width="w-full md:w-1/4"
                />


                <NuInputNumber
                  label="Final Bid Value"
                  isRequired={true}
                  placeholder="Project Bid Value"
                  formik={formik}
                  name="finalValue"
                  width="w-full md:w-1/4"
                />


                <NuSelect
                  label="Client"
                  options={proClient}
                  formik={formik}
                  placeholder="Choose"
                  name="clientId"
                  width="w-full md:w-1/4"
                  isRequired={true}
                />


                <NuDatePicker
                  label="Bidding Due Date"
                  formik={formik}
                  name="dueDate"
                //   min={NuDate({
                //     value: Date.now(),
                //     format: "yyyy-MM-DD",
                //   })}
                  width=" w-full md:w-1/4"
                />

                <NuDatePicker
                  label="Submitted On"
                  formik={formik}
                  name="submittedOn"
                //   min={NuDate({
                //     value: Date.now(),
                //     format: "yyyy-MM-DD",
                //   })}
                  width=" w-full md:w-1/4"
                />

                <NuInputText
                  label="Revision"
                  formik={formik}
                  placeholder="Revision"
                  name="revision"
                  width="w-full md:w-1/4"
                  // isRequired={true}
                />

                <NuSelect
                  label="Category"
                  options={Category||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full md:w-1/4"
                  isRequired={true}
                />

                <NuSelect
                  label="Division"
                  options={Division||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full md:w-1/4"
                  isRequired={true}
                />


                <NuSelect
                  label="Status"
                  options={BiddingStatus||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="biddingStatus"
                  width="w-full md:w-1/4"
                  isRequired={true}
                />

                <NuTextArea
                  label="Scope Of Work"
                  placeholder="Scope Of Work"
                  formik={formik}
                  name="scopeOfWork"
                  width="w-full md:w-1/2"
                />

                <NuTextArea
                  label="Comments"
                  placeholder="Title"
                  formik={formik}
                  name="comments"
                  width="w-full md:w-1/2"
                />


                <Attachements
                  handleChange={handleImageChange}
                  preview={image || data?.imageUrl || ""}
                  width="w-full md:w-1/2"
                  label="Attach"
                  multiple={true}

                />

            </div>
            <div className="flex justify-between items-center">
              <div className="w-1/2">

              </div>

              <div
                className=" mr-2 rounded-md"
                style={{ border: "1px solid #C5C5C5" }}
              >

              </div>
            </div>

            <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
