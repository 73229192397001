import React, { lazy } from 'react'
import { Route } from 'react-router-dom'


const Auth =lazy(()=>import('../Views/Web/Main/MainAuth')) 
const Login =lazy(()=>import('../Views/Web/Auth/index')) 

function AuthRoutes() {
  return (
    <Route path="/auth" element={<Auth />}>
    <Route path="login" element={<Login />} />
  </Route>
  )
}

export default AuthRoutes