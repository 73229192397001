import { Modal, notification } from 'antd'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import axiosInstance from '../../../../../Services/axiosCommon';
import { NuInputNumber, NuInputText, NuSelect } from '../../../../../Components/Utils/NuInput';
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';

function MultiEditEquipment({open,onCancel,getData,seletedIds}) {
//   console.log("finding",estiId);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    item: "",
    unit: "",
    quantity: "",
    unitPrice: "",
    totalPrice: "",
    type: "",
    no_of_days: "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
    //   name:yup.string().required("Tittle is Requried")
    }),
    onSubmit: (value,{resetForm}) => {    
      setIsLoading(true);
      const filteredValues = Object.fromEntries(
        Object.entries(value).filter(([_, v]) => v !== "")
      );
      const values = {
        ...filteredValues,
        ids: seletedIds
      }
      axiosInstance
      .put(`/equipment/multi-update`, values)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            setIsLoading(false);
            getData();
            resetForm();
            api.success({
              message: `Equipment Multi Item Edit successfully`,
              description: res?.data?.msg || "Added In Tittle",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Equipment Multi Item Edit failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          api.error({
            message: `Equipment Multi Item Edit failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    const totalPrice = formik?.values?.quantity * formik?.values?.unitPrice
    formik.setFieldValue("totalPrice", totalPrice);
  }, [formik?.values?.quantity,formik?.values?.unitPrice]);

  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={"Multi Edit Equipment Item"}
      width={"50%"}
      className="my-modal"
      centered
      footer={[]}
      onCancel={onCancel}
    >
    <form onSubmit={formik.handleSubmit} >
      <div className=" w-full flex flex-wrap">
         {/* <NuInputText
              label="Item Name"
              placeholder="Item Name"
              formik={formik}
              name="item"
              width="w-full"
          /> */}
          <NuSelect
                  label="Type"
                  options={[
                    {value: "Own", label:"Own"},
                    {value: "Rental", label:"Rental"},
                  ]}
                  formik={formik}
                  placeholder="Choose"
                  name="type"
                  width="w-full md:w-1/3"
                />
          <NuSelect
                  label="Unit"
                  options={[
                    {value: "kg", label:"Kg"},
                    {value: "lit", label:"lit"},
                    {value: "ton", label:"ton"},
                  ]}
                  formik={formik}
                  placeholder="Choose"
                  name="unit"
                  width="w-full md:w-1/3"
                />
                <NuInputNumber
              label="No Of Days"
              placeholder="Quantity"
              formik={formik}
              name="no_of_days"
              width="w-full md:w-1/3"
            />
                <NuInputNumber
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />
           <NuInputNumber
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              label="Unit Price"
              placeholder="Unit Price"
              formik={formik}
              name="unitPrice"
              width="w-full md:w-1/3"
            />
            {/* <NuInputNumber
              label="Total Price"
              placeholder="Total Price"
              formik={formik}
              name="totalPrice"
              width="w-full md:w-1/3"
              disable={true}
            /> */}
            <div className="w-full flex justify-end item-end float-right mx-auto md:mx-0 gap-2 mt-2 md:mr-4">
              <CancelButton handleClick={onCancel} />
              <SubmitButton isLoading={isLoading} />
            </div>
      </div>
      </form>
    </Modal>
    </>
  )
}

export default MultiEditEquipment;