import {  useNavigate } from "react-router-dom";
import {
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import AxiosInstance from "../../../../Services/axiosCommon";
import { useEffect, useState } from "react";
import {
  NuDatePicker,
  NuInputEmail,
  NuInputNumber,
  NuInputText,
  NuInputfile,
  NuSelectwithSearch,
} from "../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import { TextBoldSM } from "../../../../Components/Text/BoldText";
import { TextMediumXS } from "../../../../Components/Text/MediumText";
import NuDate from "../../../../Components/Utils/NuDate";

const EditCompany = ({ getData }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [company, setCompany] = useState({
    companyName: "",
    companyWebsite: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    mobile: "",
    email: "",
    currency: "",
    crNumber: "",
    taxNumber: "",
    financialStartYear: "",
    financialEndYear: "",
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  function getCompanyINfo() {
    AxiosInstance.get(`/CompanyInfo/1`)
      .then((res) => {
        res.data?.data &&
          setCompany({
            city: res.data?.data?.city,
            companyId: res?.data?.data?.companyId,
            companyLogo: res.data?.data?.companyLogo,
            companyName: res.data?.data?.companyName,
            companyWebsite: res.data?.data?.companyWebsite,
            state: res.data?.data?.state,
            country: res.data?.data?.country,
            email: res.data?.data?.email,
            mobile: res.data?.data?.mobile,
            pincode: res.data?.data?.pincode,
            currency: res.data?.data?.currency,
            crNumber: res.data?.data?.crNumber,
            taxNumber: res.data?.data?.taxNumber,
            financialStartYear: res.data?.data?.financialStartYear !="None" && res.data?.data?.financialStartYear !="" && NuDate({value:res.data?.data?.financialStartYear,format:"YYYY-MM-DD"}),
            financialEndYear: res.data?.data?.financialEndYear !="None" && res.data?.data?.financialEndYear !="" && NuDate({value:res.data?.data?.financialEndYear,format:"YYYY-MM-DD"}),
          });
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getCompanyINfo();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: company,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (value, { resetForm }) => {
      value.mobile = `${value.mobile}`
      value.pincode = `${value.pincode}`
      if(!value.financialStartYear){
        value.financialStartYear = null
      }

      if(!value.financialEndYear){
        value.financialEndYear = null
      }
      
      let formData = new FormData();
      

      // delete value?.companyLogo;

      formData.append("data", JSON.stringify(value));
      // if (image.raw) {
        formData.append("companyLogo", image.raw);
      // }

      value.companyId
        ? AxiosInstance.put(`/CompanyInfo/1`, formData)
            .then((res) => {
              if (res?.data?.status == true) {
                resetForm();
                window.location.reload(true);
                navigate(-1);
              } else {
              }
            })
            .catch((err) => {
              console.log(err);
            })
        : AxiosInstance.post("/CompanyInfo/", formData)
            .then((res) => {
              resetForm();
              navigate(-1);
            })
            .catch((err) => {
              console.log(err);
            });
    },
  });

  return (
    <div className=" h-full overflow-y-auto">
      <form onSubmit={formik.handleSubmit}>
        <div className=" w-full flex justify-between ">
          <TextBoldSM content={"Company Info"} />
        </div>
        <div
          className=" w-full py-3"
          style={{ borderBottom: "1px solid gray" }}
        >
          <TextMediumXS content={"Company Logo"} />
          <div className=" pl-12 py-3 ">
            <div className=" w-3/12 h-48 rounded-md overflow-hidden bg-stone-200">
              <img
                src={image.preview || company.companyLogo}
                alt="#"
                width="100%"
              />
            </div>
            <NuInputfile
              handleChange={handleChangeImg}
              preview={image.preview || company.companyLogo || ""}
              width="w-1/4"
            />
          </div>
        </div>
        <div className=" w-full py-3">
          <TextMediumXS content={"Company Information"} />
        </div>
        <div className=" w-full flex flex-wrap ">
          <div className=" w-full grid grid-cols-3 gap-x-8 ">
            <NuInputText
              label="Company Name"
              placeholder="Company Name"
              width="w-full"
              name="companyName"
              formik={formik}
            />
            <NuInputNumber
              label="Contact Number"
              placeholder="00"
              formik={formik}
              name="mobile"
              width="w-full"
            />
            <NuInputText
              label="Country"
              placeholder="Country"
              width="w-full"
              name="country"
              formik={formik}
            />
            <NuInputText
              label="Province / State"
              formik={formik}
              placeholder="Province / State"
              name="state"
              width="w-full"
            />
            <NuInputText
              label="Town / City"
              placeholder="Town / City"
              formik={formik}
              name="city"
              width="w-full"
            />
            <NuInputNumber
              label="Pincode"
              placeholder="0"
              formik={formik}
              name="pincode"
              width="w-full"
            />
            <NuInputEmail
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full"
            />
            <NuInputText
              label="Website"
              placeholder="Website"
              formik={formik}
              name="companyWebsite"
              width="w-full"
            />
            <NuSelectwithSearch
              label="Currency"
              placeholder="Currency"
              formik={formik}
              name="currency"
              width="w-full"
              options={[]}
            />
            <NuInputText
              label="CR Number"
              placeholder="CR Number"
              formik={formik}
              name="crNumber"
              width="w-full"
            />
            <NuInputText
              label="TAX Number"
              placeholder="TAX Number"
              formik={formik}
              name="taxNumber"
              width="w-full"
            />
          </div>
        </div>
        <div className=" w-full py-3">
          <TextMediumXS content={"Financal Year"} />
        </div>
        <div className=" w-full flex flex-wrap ">
          <div className=" w-full grid grid-cols-3 gap-x-8 ">
          <NuDatePicker
              label="Start"
              placeholder=" "
              formik={formik}
              name="financialStartYear"
              width="w-full"
            />
            <NuDatePicker
              label="End"
              placeholder=" "
              formik={formik}
              name="financialEndYear"
              width="w-full"
            />
          </div>
        </div>
        <div className=" w-full justify-end flex items-center gap-3">
          <SubmitButton />
          <CancelButton handleClick={() => navigate(-1)} />
        </div>
      </form>
    </div>
  );
};

export default EditCompany;
