import React, { lazy } from "react";
import { Route } from "react-router-dom";
import MainPayments from "../Views/Web/Project/SubMenu/Payments/MainPayments";
import MainInvoiceIn from "../Views/Web/Project/SubMenu/Payments/InvoiceIn/MainInvoiceIn";
import InvoiceInIndex from "../Views/Web/Project/SubMenu/Payments/InvoiceIn/InvoiceInIndex";
import CreateInvoiceIn from "../Views/Web/Project/SubMenu/Payments/InvoiceIn/CreateInvoiceIn";
import EditInvoiceIn from "../Views/Web/Project/SubMenu/Payments/InvoiceIn/EditInvoiceIn";
import ViewInvoiceIn from "../Views/Web/Project/SubMenu/Payments/InvoiceIn/ViewInvoiceIn";
import MainInvoiceOut from "../Views/Web/Project/SubMenu/Payments/InvoiceOut/MainInvoiceOut";
import InvoiceOutIndex from "../Views/Web/Project/SubMenu/Payments/InvoiceOut/InvoiceOutIndex";
import CreateInvoiceOut from "../Views/Web/Project/SubMenu/Payments/InvoiceOut/CreateInvoiceOut";
import EditInvoiceOut from "../Views/Web/Project/SubMenu/Payments/InvoiceOut/EditInvoiceOut";
import ViewInvoiceOut from "../Views/Web/Project/SubMenu/Payments/InvoiceOut/ViewInvoiceOut";
import MainPattyCash from "../Views/Web/Project/SubMenu/Payments/OtherExpenses/MainOtherExpenses";
import MainOtherExpenses from "../Views/Web/Project/SubMenu/Payments/OtherExpenses/MainOtherExpenses";
import OtherExpensesIndex from "../Views/Web/Project/SubMenu/Payments/OtherExpenses/OtherExpensesIndex";
import CreateOtherExpenses from "../Views/Web/Project/SubMenu/Payments/OtherExpenses/CreateOtherExpenses";
import EditOtherExpenses from "../Views/Web/Project/SubMenu/Payments/OtherExpenses/EditOtherExpenses";
import ViewOtherExpenses from "../Views/Web/Project/SubMenu/Payments/OtherExpenses/ViewOtherExpenses";


function ProjectFinanceRoutes() {
  return (
    <Route path="payments" element={<MainPayments />}>
      <Route path="invoice-in/:officeId/:projectId" element={<MainInvoiceIn />}>
        <Route index element={<InvoiceInIndex />} />
        <Route path="create" element={<CreateInvoiceIn />} />
        <Route path="edit/:invoiceInEditId" element={<EditInvoiceIn />} />
        <Route path="view/:invoiceInViewId" element={<ViewInvoiceIn />} />
      </Route>
      <Route path="invoice-out/:officeId/:projectId" element={<MainInvoiceOut />}>
        <Route index element={<InvoiceOutIndex />} />
        <Route path="create" element={<CreateInvoiceOut />} />
        <Route path="edit/:invoiceOutEditId" element={<EditInvoiceOut />} />
        <Route path="view/:invoiceOutViewId" element={<ViewInvoiceOut />} />
      </Route>
      <Route path="other-expenses/:officeId/:projectId" element={<MainOtherExpenses />}>
        <Route index element={<OtherExpensesIndex />} />
        <Route path="create" element={<CreateOtherExpenses />} />
        <Route path="edit/:pattyCashEditId" element={<EditOtherExpenses />} />
        <Route path="view/:pattyCashViewId" element={<ViewOtherExpenses />} />
      </Route>
    </Route>
  );
}

export default ProjectFinanceRoutes;
