import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import PrivateRoute from './Services/PrivateRouter'
import App from './App'
import SettingsRoutes from './Routes/Settings.routes'
import NoteRoutes from './Routes/note.routes'
import { lazy } from 'react'
import ReportsRoutes from './Routes/reports.routes'
import PageNotFound from './Components/Utils/PageNotFound'
import ItemRoutes from './Routes/item.routes'
import MembersRoutes from './Routes/members.routes'
import UserDetailsRoutes from './Routes/userDetails.routes'
import ProjectRoutes from './Routes/project.routes'
import ProjectCostingRoutes from './Routes/project.costing.routes'
import ProjectProgressRoutes from './Routes/project.progress.routes'
import ProjectTimeRoutes from './Routes/project.timesheet.routes'
import ProjectPettyCashRoutes from './Routes/project.pettycash.routes'
import ProjectFinanceRoutes from './Routes/project.finance.routes'
import ProjectTaskRoutes from './Routes/project.task.routes'
import BidRoutes from './Routes/bid.routes'
import AuthRoutes from './Routes/auth.routes'
import axiosInstance from './Services/axiosCommon'
import ContactsRoutes from './Routes/contacts.routes'
import ProjectManagerSingle from './Views/Dashboard/Admin/SingleProject'
import MainDashbord from './Views/Dashboard/DashboardMain'

const MainProjectPage = lazy(()=>import('./Views/Web/Main/MainProjectPage'))
const ProjectDashboard = lazy(()=>import('./Views/Web/Project/ProjectView'))
const MainDashboard =lazy(()=>import('./Views/Web/Main/MainDashboard'))
const DashboardIndex =lazy(()=>import('./Views/Web/MainDashboard/DashboardIndex'))
const OfficeIndex =lazy(()=>import('./Views/Web/offices'))
const MainPage =lazy(()=>import('./Views/Web/Main/MainPage'))
const OfficeDashboard =lazy(()=>import('./Views/Dashboard/index'))

function MainRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "") {
      window.location.replace("/main-page/dashboard");
    }
    if (location.pathname === "/auth") {
      window.location.replace("/auth/login");
    }
    if (location.pathname === "/main") {
      navigate("/main/dashboard");
    }
    if (location.pathname === "/project") {
      navigate("/project/dashboard");
    }
    if(location.pathname==="/settings"){
      navigate('/settings/company-info');
    }
  }, [location]);


  useEffect(() => {
    if(location.pathname == "/main-page/dashboard"||location.pathname == "/main-page/offices"){
      fetchOffice()
    }
    
  },[navigate])


  function fetchOffice(){
    axiosInstance.get(
      `/company_branch/?page=1&per_page=20`
    )
      .then((res) => {
        let value = res?.data?.data?.total
      let branch =res?.data?.data?.data[0]?.branchId||1
        console.log("test",res?.data?.data?.total);
        
        if (value == 1) {
          navigate(`/main/dashboard/${branch}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }


  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <App />
          </PrivateRoute>
        }
      >
        {SettingsRoutes()}
        {UserDetailsRoutes()}
        <Route path="main-page" element={
          <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
            <MainDashboard />
          </Suspense>
          }>
          <Route path="dashboard" element={
            <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
             <DashboardIndex/>
            </Suspense>
          }></Route>
          <Route path="offices" element={
           <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
            <OfficeIndex />
           </Suspense>
          }></Route>
        </Route>
        <Route path="/main" element={
           <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>       
              <MainPage />
            </Suspense>
        }>
          <Route path="dashboard/:officeId" element={
            <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
             <MainDashbord/>
            </Suspense>
          }>
            <Route index element={<OfficeDashboard/>} />
            <Route path='project/:proDashboardId' element={<ProjectManagerSingle/>} />
          </Route>
          {BidRoutes()}
          {ProjectRoutes()}
          {ItemRoutes()}
          {NoteRoutes()}
          {ReportsRoutes()}
          {MembersRoutes()}
          {ContactsRoutes()}
        </Route>
        <Route path="/project" element={
           <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>       
              <MainProjectPage />
            </Suspense>
        }>
         <Route path="dashboard/:officeId/:projectId" element={
            <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
              <ProjectDashboard/>
            </Suspense>
          }></Route>
          {ProjectCostingRoutes()}
          {ProjectProgressRoutes()}
          {ProjectTimeRoutes()}
          {ProjectPettyCashRoutes()}
          {ProjectFinanceRoutes()}
          {ProjectTaskRoutes()}
        </Route>
      </Route>
      {AuthRoutes()}
      <Route path='*' element={<PageNotFound/>} />
      </Routes>
  )
}

export default MainRoutes