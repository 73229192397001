import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from "../../../../Services/axiosCommon";
import { CancelButton, CreateButton } from '../../../../Components/Utils/Buttons';
import { Navigate, useParams } from 'react-router-dom';
import { NuInputText, NuTextArea } from '../../../../Components/Utils/NuInput';
import { Modal, notification } from 'antd';
import * as yup from "yup";

const CreateActivity = ({open, getData, onCancel, data,projectList,firstProject }) => {
  const {officeId} = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    item_code: '',
    description: '',
    branch: officeId
  });
  useEffect(() => {
    data&&setCreateNew({
      item_code:data.item_code,
      description:data.description,
    })
  },[data])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema:yup.object({
      item_code:yup.string().required("Item Code is Requried")
    }),
    onSubmit: (value,{resetForm}) => {
     
      axiosInstance
        .post("/Activity/", value)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            getData();
            resetForm();
            api.success({
              message: `Description Creation successfully`,
              description: res?.data?.msg || "Added In The Description",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Description Creation failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `Description Creation failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });

 

  return (
    <>
    {contextHolder}
    <Modal
      open={open}
      title={data ? "Edit Activity" : "Create Activity"}
      width={"50%"}
      className="my-modal"
      centered
      onCancel={onCancel}
      footer={[]}
    >
    <form onSubmit={formik.handleSubmit} >
     <div className=" w-full flex flex-wrap">
            <NuInputText
              label="Item Code"
              placeholder="Item Code"
              formik={formik}
              name="item_code"
              width="w-full"
            />
             <NuTextArea
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width="w-full"
            />
           
<button
              type="submit"
             
              className="w-11/12 bg-[#119DEE] py-3 rounded-lg cursor-pointer text-sm outline-none border-none hover:text-white focus:text-white text-white  mx-auto"
            >
              Create 
            </button>
          </div>
     
    </form>
    </Modal>
    </>
  );
};

export default CreateActivity;
