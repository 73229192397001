import React, { useEffect, useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons'
import { NuInputNumber, NuInputText, NuLabel, NuSelect } from '../../../../Components/Utils/NuInput'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import axios from "../../../../Services/axiosCommon";
import Dasboard from '../../../../Components/Icons/Dasboard';
import * as yup from 'yup';
import axiosInstance from '../../../../Services/axiosCommon';

function EditManPower() {
    const { manPowerRoleEditId } = useParams();
    console.log("vvvv",manPowerRoleEditId);
    const [data,setData] = useState([]);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [projectList, setProjectList] = useState([]);

    const getData = () =>{
        axiosInstance.get(`/manpower-role/${manPowerRoleEditId}`)
        .then((res)=>{
           console.log("res",res?.data?.data);
           res?.data?.data && setData(res?.data?.data)
        }).catch((err)=>{
           console.log(err);
        })
    }

    useEffect(()=>{
        getData();
    },[])
  
    const [createNew, setCreateNew] = useState({
        job_role: "",
        type: 0,
        working_hours: "",
        amount_per_hour: ""
    });
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: true,
      validationSchema: yup.object({
      //   title: yup.string().required("Title is Required"),
      //   status: yup.string().required("Please Select Status"),
      }),
      onSubmit: async (value) => {
        setIsLoading(true);
  
        data
          ? axios
              .put(`/manpower-role/${data?.id}`, value)
              .then((res) => {
                if (res.data.status == true) {
                  setIsLoading(false);
                  api.success({
                    message: `Add successfully`,
                    description: res?.data?.msg || " created",
                    placement: "bottomRight",
                  });
                  navigate(-1);
                } else {
                  setIsLoading(false);
                  api.error({
                    message: `Unsuccessfully`,
                    description: res?.data?.msg || "creation failed",
                    placement: "bottomRight",
                  });
                }
              })
              .catch((err) => {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: err?.response?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              })
          : axios
              .post("/manpower-role/", value)
              .then((res) => {
                if (res.data.status == true) {
                  setIsLoading(false);
                  api.success({
                    message: `Add successfully`,
                    description: res?.data?.msg || " created",
                    placement: "bottomRight",
                  });
                  navigate(-1);
                } else {
                  setIsLoading(false);
                  api.error({
                    message: `Unsuccessfully`,
                    description: res?.data?.msg || "creation failed",
                    placement: "bottomRight",
                  });
                }
              })
              .catch((err) => {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: err?.response?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              });
      },
    });

    useEffect(() => {
        data&&setCreateNew({
            job_role: data?.job_role || "",
            type: data?.type || "",
            working_hours: data?.working_hours || "",
            amount_per_hour: data?.amount_per_hour || ""
        })
      },[data])

  return (
    <div className="w-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="/mainNotes">
                  <p className=" text-[12px] ">ManPowerRole</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div>
        <div
          className=" w-full mx-auto h-[85%] rounded-[6px] mt-2"
          style={{ border: "1px solid #C5C5C5" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex justify-between items-center">
              <NuLabel label="Man Power Role" />
            </div>
            <div className="flex flex-wrap justify-start">
               <NuInputText
                 label="Job Role"
                 formik={formik}
                 placeholder="Job Role"
                 name="job_role"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />
                <NuSelect
                  label="Type"
                  options={[
                    {value: 0, label: "Own"},
                    {value: 1, label: "Rental"},
                  ]}
                  formik={formik}
                  placeholder="Choose"
                  name="type"
                  width="w-full md:w-1/3"
                  isRequired={true}
                />
              <NuInputNumber
                 label="Working Hours"
                 formik={formik}
                 placeholder="Working Hours"
                 name="working_hours"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />
                <NuInputNumber
                 label="Amount Per Hour"
                 formik={formik}
                 placeholder="Amount Per Hour"
                 name="amount_per_hour"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />
            </div>
            <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
            </form>
          </div>
        </div>
    </div>
  )
}

export default EditManPower