import React, {useState } from "react";
import EventCalender from "./Maincal";

export default function Calender({

  eventDatas,
  Details,
  setDayDetails,
}) {

  console.log("props data",eventDatas);

  let pend = eventDatas?.filter(item=>item.status=="Pending");
  let comp = eventDatas?.filter(item=>item.status=="Closed");
  let testOver = eventDatas?.filter(item=>item.status=="Open");


  const data2 = setDataEvents(pend);
  const data3 = setDataEvents(comp);
  const data4 = setDataEvents(testOver);

  const [selectDay, setSelectDay] = useState("");


  function setDataEvents(data) {
    let m = 1;
    for (let i in data) {
      for (let j = i; j < data.length; j++) {
        if (i !== j) {
          if (data[i].due_date == data[j].due_date) {
            data.splice(j, 1);
            j -= 1;
            m++;
          }
        }
      }
      data[i].count = m;
      m = 1;
    }
    let eventData = {
      events: [],
      eventTimeFormat: {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      },
      textColor: "black",
      backgroundColor: "white",
    };
    let tmpEventsData = [];
    if (Array.isArray(data)) {
      // data.map((list, idx) => {
      //   tmpEventsData.push({
      //     title: list?.count,
      //     start: list.completion_date,
      //     end: list.endDate,
      //   });
      // });
      data.map((list, idx) => {
        tmpEventsData.push({
          title: list?.count,
          start: list.due_date,
          end: list.due_date,
        });
      });
      eventData.events = tmpEventsData;
      eventData.textColor =
        data[0]?.status === "Pending"
          ? "#FFFFFF"
          : data[0]?.status === "Closed"
          ? "#FFFFFF"
          : data[0]?.status === "Open"
          ? "#FFFFFF"
          : "black";
      eventData.backgroundColor =
        data[0]?.status === "Pending"
          ? "#ffd700"
          : data[0]?.status === "Closed"
          ? "#008000"
          : data[0]?.status === "Open"
          ? "#FF0000"
          : "black";
         
      return eventData;
    }
  }

  function OnSelectDay(e) {
    setSelectDay(e);
    Details();
    setDayDetails(e);
  }


 
  return (
    <div className="">
      <div className="">
        <EventCalender
          eventDatas={eventDatas}
          selectDay={selectDay}
          weekends={true}
          eventsSource={[data2, data3, data4]}
          initialView="dayGridMonth"
          editable={false}
          selectable={true}
          OnSelectDay={OnSelectDay}
        />
      </div>
    </div>
  );
}
