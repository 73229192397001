import React, { useState } from 'react';
import ToggleUp from '../../../../../../Components/Icons/ToggleUp';
import ToggleDown from '../../../../../../Components/Icons/ToggleDown';
import ExpandableBOQ from './ExpandableManpower';

const TitleManpower = ({}) => {
  const [isExpanded, setIsExpanded] = useState(false);


  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    // sessionStorage.setItem(`${projectId}-title-${rowData?.titleId}`,!isExpanded);
  };
  

  return (
    <>
      <tr className=' sticky top-0 w-[100%] z-[49]  bg-[#EFF6FF]' style={{borderTop:'2px solid white',borderBottom:'2px solid white'}}>
        <td className=' w-[5%] py-2 pl-1 text-center '>
          <div className=' w-full flex'>
          <input 
            type="checkbox" 
            // checked={titleList.some(id=>id===rowData?.titleId)}
            // onChange={()=>titleSelect(rowData?.titleId)}
            />
        { 
          true && <div className='  w-3 h-7 rounded-md cursor-pointer  ' onClick={handleToggle}>{isExpanded ?<ToggleUp/>:<ToggleDown/>}</div>
        }
        
          </div>
        </td>
        <td colspan="5" className=' w-full py-1   text-xs font-medium text-mainColor-400'>
          <div className=' w-full flex gap-2 justify-between items-center '>
           <div className=' w-[55%] flex gap-2 items-center '>
           {"Same Title"||"-"}
           <div className=" w-[13px] h-[13px] flex justify-center items-center">
           </div>
           </div>

          <div className='  w-[45%] ml-6 flex flex-col text-left gap-1 font-normal'>
            {/* <p className='  text-[11px] text-red-400 '> Sch (Start : {rowData?.startDate && rowData?.startDate !="None" &&<NuDate value={rowData?.startDate} format="DD-MMM-YYYY"/>} | Due : {rowData?.endDate && rowData?.endDate !="None" && <NuDate value={rowData?.endDate} format="DD-MMM-YYYY"/>} | Total : {rowData?.totalDays||0} {rowData?.totalDays&& rowData?.totalDays>1?"days":"day"})</p> */}
            {/* <p className='  text-[11px] text-red-400 '> Task (Total : {rowData?.totalTasks||0} | Open : {rowData?.taskOpened||0} | Close : {rowData?.taskClosed||0})</p> */}
          </div>
          </div>
        </td>

        <td className=' w-[5%] text-center px-3  py-0'>
          <div className='w-full h-full flex items-center gap-2 '>
            <div className=' w-[48%] h-10 flex items-center'>
            {/* <ViewMoreTitle getTitle={getData} projectId={projectId} data={rowData} /> */}
            </div>
            <div className=' w-[48%] h-10 flex items-center'>
            {/* {MyTaskCreate && <Link to="/tasks/create" state={{data:rowData,Start:StartDate,End:EndDate}} ><Plus color='#3267FF' /></Link>} */}
            </div>
          </div>
            
        </td >
       
      </tr>
      {
        isExpanded && <ExpandableBOQ />
      }
      </>
  );
};

export default TitleManpower;

