import React from 'react'
import Notes from './notes';

function NotesSetting() {
  return (
    <div className=' w-full '>
      <div className=' w-full flex justify-between items-start'>
      <h3 className=" text-sm text-mainColor-400 font-semibold">My Diary</h3>
      </div>
    

    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center'>
      <div className=' w-full md:w-1/2 lg:w-1/3'>
        <Notes/>
      </div>
      <div className=' w-full md:w-1/2 lg:w-1/3'>
        
      </div>

    </div>
  </div>
  )
}

export default NotesSetting;